import styles from "./SaveAndDiscardButtonSet.module.css";
import React from "react";

type SaveAndDiscardButtonSetProps = {
    yesAction: () => void
    noAction: () => void
    status?: number
    className?: string
    yesTitle?: string
    noTitle?: string
}

const SaveAndDiscardButtonSet = ({
                                     yesAction,
                                     noAction,
                                     className,
                                     status,
                                     yesTitle = "Yes",
                                     noTitle = "No"
                                 }: SaveAndDiscardButtonSetProps) => {
                                     console.log("*******"+status)
    return <div className={[styles.Container, className].join(" ")}>
        <button onClick={noAction}
                className={styles.DiscardButton}>
            {noTitle}
        </button>

        {status > 0 && <button
        className={styles.DisableButton}
        >{yesTitle}</button>}

          {(status === 0 || status === null || status === undefined) && <button
            onClick={yesAction}
            className={styles.ApplyButton}
        >{yesTitle}</button>}
    </div>;
};

export default SaveAndDiscardButtonSet;