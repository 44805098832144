/* eslint-disable*/
import React, { useState, useCallback, useEffect } from "react";
import styles from "./ManageCampaignTable.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { Fragment } from "react";
import PageContent from "../../General/PageContent/PageContent";
import Table from "../../General/Table/Table";
import TableFooter, { PAGE_SIZES } from "../../General/Table/TableFooter/TableFooter";
import { mapCampaignPriority } from "../../../utils/object-mappers/manage-campaign/map-campaignpriority";
import SaveAndDiscardButtonSet from "../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";
import { useHistory } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { Msg } from "../CreateCampaign/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { useAppSelector } from "../../../redux/store";
import BackButton from "../../General/Button/Button";

const ManageCampaignPriority = (props) => {
    const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[1]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCampaigns, setTotalCampaigns] = useState(0);
    const [Campaigns, setCampaigns] = useState([]);
    const history = useHistory();
    const [sortBy, setSortBy] = useState("latestLive");
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const [isLoaded, setIsLoaded] = useState(true);

    const goToPage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const changePageSizeTo = useCallback((newPageSize) => {
        setCurrentPage(1);
        setSelectedPageSize(newPageSize);
    }, []);

    const columns = [
        {
            Header: "Campaign Name",
            accessor: "campaignName"
        },
        {
            Header: "Priority",
            accessor: "priority"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Status on",
            accessor: "statusOn"
        },
        {
            Header: "Associated Customer List",
            accessor: "associatedCustomerList"
        }
    ];

    useEffect(() => {
        localStorage.setItem("blockNavigation", "false");
        (async () => {
            let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns`);
            let callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`

                }
            };
            const params = {
                //page: currentPage,
                pagesize: selectedPageSize,
                ...(sortBy && { sortBy })
            };
            url.search = new URLSearchParams(params).toString();

            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            //setTotalCampaigns(serverResponseJson.totalCampaigns);
            setCampaigns(mapCampaignPriority(serverResponseJson.campaigns));
            //setIsLoaded(false);
        })();

    }, [selectedPageSize, sortBy]);

    return (
        <Fragment>
            <TitleWithPathHeader
                title={"Manage Campaign"}
                path={" Manage Campaign/Campaign Priority"}
            />
            <PageContent className={styles.Priority}>
                <div style={{ textAlign: "left" }}>
                    <label>
                        Campaign Priority<span>*</span>
                    </label>
                    {/* <p className={styles.UpdateFormSubTitle}>Assign a priority level to this campaign.</p>
    <p className={styles.UpdateFormSubTitle}>You can drag and rearrange the campaign level in the table.</p> */}
                    <div>
                        <Table columns={columns} data={Campaigns} />
                    </div>
                </div>
                {/* <SaveAndDiscardButtonSet
                    yesTitle="Confirm"
                    noTitle="Back"
                    yesAction={() => {
                        history.push("/ctmp/dashboard");
                        toast.success(<Msg id="" name="" />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                        })
                    }}
                    noAction={() => {
                        history.push("/ctmp/dashboard");
                    }}
                    className={styles.Buttons}
                /> */}
                <BackButton
                    onClick={() => {
                        history.push("/ctmp/dashboard");
                    }}
                    name="Back"
                />
            </PageContent>
        </Fragment >
    );
};

export default ManageCampaignPriority;