import React, { useMemo, useState} from "react";
import {Popover} from 'react-tiny-popover'

import styles from "./RowAction.module.css"

type MenuOption = {
    key: string
    name: string
    onClick: () => void
}

type RowActionProps = {
    menu?: JSX.Element
    options?: MenuOption[]
}

const RowAction = ({menu, options}: RowActionProps): JSX.Element => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const content = useMemo(() => {
        return menu || <div className={styles.MenuContainer}>
            {options.map(o => <div className={styles.MenuItem} key={o.key} onClick={o.onClick}>{o.name}</div>)}
        </div>
    }, [menu, options])

    return <Popover isOpen={isPopoverOpen}
                    positions={['bottom']}
                    containerStyle={{zIndex:"1"}}
                    padding={5}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={content}>
        <button onClick={() => setIsPopoverOpen(!isPopoverOpen)} className={options?options[0].name ==="version"?styles.VersionActionContainer:styles.ActionContainer:styles.ActionContainer}>
            <div className={styles.Dot}/>
            <div className={styles.Dot}/>
            <div className={styles.Dot}/>
        </button>
    </Popover>
}

export default RowAction