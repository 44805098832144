import React,{useEffect, useState} from "react";
import Select from "react-select";

import styles from "./ManageContentTableHead.module.css";
import { SelectStyle } from "../../../General/Selector/Selector";
import ManageContentTableFilters
    from "./ManageContentTableFilters/ManageContentTableFilters";

const options = [
    { label: "Latest Created", value: "latestCreated" },
    { label: "Oldest Created", value: "oldestCreated" }
    //{ label: "Latest Published", value: "latestLive" },
    //{ label: "Oldest Published", value: "oldestLive" }
];

const ManageContentTableHead = ({ onSortChange, onFiltersChange, filters }) => {
    const [defaultValueSort,setDefaultValueSort] = useState();

    useEffect(()=>{
     if(Object.keys(filters).length > 0){
        setDefaultValueSort(null)
     }
    },[filters])

    const selectStylesApiObject = React.useMemo(() => {
        return {
            ...SelectStyle,
            control: provided => ({
                ...provided,
                border: ".1rem solid #CECFD0"
            })
        };
    }, []);

    return <div className={styles.TableHead}>
        <div className={styles.TableHeadLeft}>
            <div className={styles.SortByContainer}>
                <label className={styles.SortBy}>Sort By</label>
                <Select className={styles.SortBySelect}
                    styles={selectStylesApiObject}
                    value={defaultValueSort}
                    isClearable
                    options={options}
                    onChange={(sortByOption) => {
                        setDefaultValueSort(sortByOption);
                        onSortChange(
                            sortByOption ? sortByOption.value : undefined);
                    }}
                />
            </div>
            <ManageContentTableFilters onFiltersChange={onFiltersChange} filters={filters} />
        </div>
    </div>;
};

export default ManageContentTableHead;