import { MsalAuthProvider, LoginType } from "react-aad-msal";

let msalConfig
if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging'){
    msalConfig = {
        auth: {
            clientId: "628cb71f-6c85-42b9-b0e8-651ff2b3da04",
            authority: "https://login.microsoftonline.com/b7be26fb-0007-4e34-9f39-088aa7bc74b0",
            redirectUri: "https://portal-marketing.stg.telematicsct.com/ctmp/dashboard"
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };
}
else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'){
    msalConfig = {
        auth: {
            clientId: "734a1e55-b999-4375-a1a5-98582754512a",
            authority: "https://login.microsoftonline.com/014106b1-ea3a-48aa-88f8-58cbb5ddee11",
            redirectUri: "https://portal-marketing.telematicsct.com/ctmp/dashboard"
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };
}
else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'){
    msalConfig = {
        auth: {
            clientId: "5b4b30b7-36e9-45f1-97a6-a4b98d8864e7",
            authority: "https://login.microsoftonline.com/b7be26fb-0007-4e34-9f39-088aa7bc74b0",
            redirectUri: "https://portal-marketing.dev.telematicsct.com/ctmp/dashboard"

        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };
}
else {
    msalConfig = {
        auth: {
            clientId: "5b4b30b7-36e9-45f1-97a6-a4b98d8864e7",
            authority: "https://login.microsoftonline.com/b7be26fb-0007-4e34-9f39-088aa7bc74b0",
            redirectUri: "http://localhost:3000/ctmp/dashboard"

        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };
}


const authenticationParameters = {
    scopes: [
        "user.read"
    ]
};

// Options
const options = {
    loginType: LoginType.Redirect
    // tokenRefreshUri: window.location.origin +'/auth.html'
}

export const authProvider = new MsalAuthProvider(msalConfig, authenticationParameters, options)