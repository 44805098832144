import React, { useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./RowActionMenu.module.css";
import { Context } from "../../../../../context";
import { useAppSelector } from "../../../../../redux/store";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../../ManageTemplate/CreateTemplate/DiscardTemplate/DiscardTemplate";

/* eslint-disable */
import {
    STATUS_APPROVED,
    STATUS_CREATIVE_APPROVED,
    STATUS_CREATIVE_PENDING,
    STATUS_CAMPAIGN_PENDING,
    STATUS_DELETED,
    STATUS_DRAFT,
    STATUS_EXPIRED,
    STATUS_LIVE,
    STATUS_REQUESTED,
    STATUS_TESTING,
    STATUS_NEW,
    STATUS_PUBLISHED,
    STATUS_INUSE
} from "../Status/Status";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id }) => {
    const history = useHistory();
    const { capability, value } = useContext(Context);
    const [capabilities, setCapabilities] = capability;
    const [context, setContext] = value;
    const jwt = useAppSelector((state) => state.authReducer.jwt);

    const deleteCopy = async (copyId) => {
        const url = new URL(
            `${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/${copyId}`
        );
        const callParams = {
            method: "DELETE",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
                // "user-info": JSON.stringify({ userName: userName, userEmail: email }),
            },
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const mystatus = await serverResponse.json();
        return serverResponse.status;
        // const reloadPage = await serverResponse.then(window.location.reload());
    };

    const publishCopy = async (copyId, action) => {
        // let toBePublishedObjectParsed = templateobj;
        let toBePublishedObjectParsed = {
            status: "Draft"
        };
        action === "publish"
            ? (toBePublishedObjectParsed.status = "Published")
            : (toBePublishedObjectParsed.status = "Draft");

        const url = new URL(
            `${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/${copyId}/publish`
        );
        const callParams = {
            method: "PUT",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
                "X-CORRELATIONID": 0
            },
            body: JSON.stringify(toBePublishedObjectParsed)
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const mystatus = await serverResponse.json();
        return serverResponse.status;
    };

    const actions = useMemo(() => {
        switch (action.status.toLowerCase()) {
            case STATUS_DRAFT:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div
                            key="view"
                            className={styles.MenuItem}
                            onClick={() => {
                                history.push(`/ctmp/copy/view-copy/${id}`);
                            }}
                        >
                            View
                        </div> : "",
                    <div
                        key="edit"
                        className={styles.MenuItem}
                        onClick={() => {
                            history.push(`/ctmp/copy/edit-copy/${id}`);
                        }}
                    >
                        Edit
                    </div>,
                    <PopupDialog
                        key={"publish" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    const statusVar = await publishCopy(id, "publish");
                                    statusVar !== 200 &&
                                        localStorage.setItem("forceCopyPublish", "false");
                                    statusVar === 200 &&
                                        localStorage.setItem("forceCopyPublish", "true");
                                    setContext("publish");
                                }}
                                noAction={closeModal}
                                className="Publish Copy"
                                visiblity="true"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            <div
                                key="publish"
                                onClick={openModal}
                                className={styles.MenuItem}
                            >
                                Publish
                            </div>
                        )}
                    />,
                    // <div key="download" className={styles.MenuItem}>
                    //     Download
                    // </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <PopupDialog
                            key={"delete" + id}
                            height="30rem"
                            width="67rem"
                            renderPopupContent={(closeModal) => (
                                <DiscardDialog
                                    yesAction={async () => {
                                        let statusVar = await deleteCopy(id);
                                        statusVar === 200 &&
                                        localStorage.setItem("forceCopyDelete", "true");
                                         statusVar !== 200 &&
                                        localStorage.setItem("forceCopyDelete", "false");
                                        setContext("delete")
                                    }}
                                    noAction={closeModal}
                                    className="Delete Copy"
                                    visiblity="true"
                                />
                            )}
                            renderControlContent={(openModal) => (
                                <div
                                    key="deleteCopy"
                                    onClick={openModal}
                                    className={styles.MenuItem}
                                >
                                    Delete
                                </div>
                            )}
                        /> : ""
                ];
            case STATUS_REQUESTED:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div
                            key="view"
                            className={styles.MenuItem}
                            onClick={() => {
                                history.push(`/ctmp/copy/view-copy/${id}`);
                            }}
                        >
                            View
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_CREATIVE_PENDING:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div key="view" className={styles.MenuItem} onClick={() => { }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_CAMPAIGN_PENDING:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div key="view" className={styles.MenuItem} onClick={() => { }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_TESTING:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div key="view" className={styles.MenuItem} onClick={() => { }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_APPROVED:
            case STATUS_CREATIVE_APPROVED:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div key="view" className={styles.MenuItem} onClick={() => { }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_PUBLISHED:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div
                            key="view"
                            className={styles.MenuItem}
                            onClick={() => {
                                history.push(`/ctmp/copy/view-copy/${id}`);
                            }}>
                            View
                        </div> : "",
                    <PopupDialog
                        key={"unpublish" + id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={async () => {
                                    const statusVar = await publishCopy(id, "unpublish");
                                    statusVar !== 200 &&
                                        localStorage.setItem("forceCopyUnpublish", "false");
                                    statusVar === 200 &&
                                        localStorage.setItem("forceCopyUnpublish", "true");
                                    setContext("unpublish");
                                }}
                                noAction={closeModal}
                                className="UnPublish Copy"
                                visiblity="true"
                            />
                        )}
                        renderControlContent={(openModal) => (
                            <div
                                key="unpublish"
                                onClick={openModal}
                                className={styles.MenuItem}
                            >
                                Unpublish
                            </div>
                        )}
                    />
                ];
            case STATUS_INUSE:
                    return [
                        capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                            <div
                                key="view"
                                className={styles.MenuItem}
                                onClick={() => {
                                    history.push(`/ctmp/copy/view-copy/${id}`);
                                }}>
                                View
                            </div> : ""
                    ];
            case STATUS_LIVE:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div
                            key="view"
                            className={styles.MenuItem}
                            onClick={() => {
                                history.push(`/ctmp/copy/view-copy/${id}`);
                            }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : ""
                ];
            case STATUS_EXPIRED:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div key="view" className={styles.MenuItem} onClick={() => { }}>
                            View
                        </div> : "",
                    <div
                        onClick={() => {
                            // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
                        }}
                        key="edit"
                        className={styles.MenuItem}
                    >
                        Edit
                    </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ?
                        <div key="publish" className={styles.MenuItem}>
                            Publish
                        </div> : "",
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : "",
                ];
            case STATUS_DELETED:
            case STATUS_NEW:
                return [
                    capabilities.includes(CapabilityConstants.CONTENT_VIEW) ?
                        <div
                            key="view"
                            className={styles.MenuItem}
                            onClick={() => {
                                //history.push(`/ctmp/copy/view-content/${id}`);
                            }}
                        >
                            View
                        </div> : "",
                    <div
                        key="edit"
                        className={styles.MenuItem}
                        onClick={() => {
                            // history.push(`/ctmp/copy/edit-copy/${id}`);
                        }}
                    >
                        Edit
                    </div>,
                    // <div
                    //     key="delete"
                    //     onClick={() => {
                    //         //deleteCampaign(id);
                    //     }}
                    //     className={styles.MenuItem}
                    // >
                    //     Download
                    // </div>,
                    capabilities.includes(CapabilityConstants.CONTENT_DELETE) ?
                        <div
                            key="delete"
                            onClick={() => {
                                // deleteCampaign(id);
                            }}
                            className={styles.MenuItem}
                        >
                            Delete
                        </div> : ""
                ];
            default:
                return [
                    <div key="na" className={styles.MenuItem}>
                        N/A
                    </div>,
                ];
        }
    }, [action]);

    return <div className={styles.MenuContainer}>{actions}</div>;
};

export default RowActionMenu;
