/* eslint-disable*/
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Select from "react-select";
import styles from "./CreateCampaign.module.css";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/Message-square.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as NewTag } from "../../../assets/icons/new-tag.svg";
import { ReactComponent as Volume } from "../../../assets/icons/volume.svg";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { ReactComponent as Minus } from "../../../assets/icons/Minus.svg";
import { ReactComponent as CloseX } from "../../../assets/icons/Close.svg";
// import { format } from "date-fns";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import PageContent from "../../General/PageContent/PageContent";
import MultiPicker from "../../General/MultiPicker/MultiPicker";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import LabeledDescriptionInput from "../../General/LabeledDescription/LabeledDescriptionInput";
import LabeledCardTemplateAndCopy from "../../General/LabeledCardTemplateAndCopy/LabeledCardTemplateAndCopy";
import LabeledDiv from "../../General/LabeledDiv/LabeledDiv";
import RadioButtonControl, {
  RadioButtonOption
} from "../../General/RadioButtonControl/RadioButtonControl";
import CreateSubCategory from "./CreateSubCategory";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import DateSelector from "../../General/PopupSelector/DateSelector/DateSelector";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import * as RoleStatus from '../ManageCampaignTable/Row/RowActionMenu/RoleStatus';
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import Button from "../../General/Button/Button";
import BackButton from "../../General/Button/Button";

import { Slide, toast } from "react-toastify";
import { Msg } from "../CreateCampaign/AlertComponent"
import { ErrorMsg } from "./AlertComponent";
import { Msg_File } from "./AlertFileComponent";
import { ErrorMsg_File } from "./AlertFileComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg"
import CreateNewDeliveryRule from "./CreateNewDeliveryRule";
import Preview from "../ManageCampaignTable/Row/Preview/Preview";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";
import LabeledCheckbox from "../../General/LabeledCheckbox/LabeledCheckbox";

// import id from "date-fns/esm/locale/id/index.js";
/* eslint-disable  @typescript-eslint/no-unused-vars */

const CreateCampaign = (props) => {
  const history = useHistory();
  const location = useLocation();
  let jwt = useAppSelector((state) => state.authReducer.jwt);
  const [isLoaded, setIsLoaded] = useState(false);
  const userName = useAppSelector((state) => state.authReducer.userName);
  //const email = useAppSelector((state) => state.authReducer.email);
  const [editCampaign, setEditCampaign] = useState(false);
  const [viewCampaign, setViewCampaign] = useState(false);
  const [createCampaignObj, setCreateCampaignObj] = useState<any>({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [brand, setBrand] = useState<any>();
  const [campaignName, setCampaignName] = useState();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [category, setCategory] = useState<any>();
  const [selectedChannelsEditList, setSelectedChannelsEditList] = useState([]);

  // const [subCategory, setSubCategory] = useState<any>();
  const [subCategory, setSubCategory] = useState<any>();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [shortDescription, setShortDescription] = useState();
  const [creativeApproval, setCreativeApproval] = useState<RadioButtonOption>({ title: "No", value: false });
  const [creativeApprovalDate, setCreativeApprovalDate] = useState<string>();
  const [divisionApproval, setDivisionApproval] = useState<RadioButtonOption>({ title: "No", value: false });
  const [divisionApprovalDate, setDivisionApprovalDate] = useState<string>();
  const [legalApproval, setLegalApproval] = useState<RadioButtonOption>({ title: "No", value: false });
  const [legalApprovalDate, setLegalApprovalDate] = useState<string>();
  // const [customerList, setCustomerList] = useState<string>();
  const [customerList, setCustomerList] = useState([]);
  const [deliveryRules, setDeliveryRules] = useState([]);
  const [creativeApprovalFile, setCreativeApprovalFile] = useState();
  const [divisionApprovalFile, setDivisionApprovalFile] = useState();
  const [legalApprovalFile, setLegalApprovalFile] = useState();
  const [version, setVersion] = useState();
  const [createdDate, setcreatedDate] = useState();
  const pathName = history.location.pathname.split("/");
  const [navSelected, setNavSelected] = useState(pathName[4]);

  const [emailTemplatesArray, setEmailTemplatesArray] = useState([]);
  const [CTMarketingTemplateArray, setCTMarketingTemplateArray] = useState([]);
  const [GeneralMarketingTemplateArray, setGeneralMarketingTemplateArray] = useState([]);
  const [emailCopyArray, setEmailCopyArray] = useState([]);
  const [CTCopyArray, setCTCopyArray] = useState([]);
  const [GMCopyArray, setGMCopyArray] = useState([]);
  const [copyList, setCopyList] = useState([]);
  const [channelList, setChannelList] = useState([])

  const [template, setTemplate] = useState([]);
  const [roleStatus, setRoleStatus] = useState([]);
  const [status, setStatus] = useState("Draft");
  const [inputList, setInputList] = useState([]);
  const [adhocStartDate, setAdhocStartDate] = useState<string>();
  const [adhocEndDate, setAdhocEndDate] = useState<string>();
  const [uniformStagger, setUniformStagger] = useState<RadioButtonOption>({ title: "No", value: false });
  const [vinBasedMessages, setVinBasedMessages] = useState<RadioButtonOption>();
  const [quotaOfMessages, setQuotaOfMessages] = useState<any>();
  const [items, setItems] = useState([]);
  const [priority, setPriority] = useState<any>();
  const [s3CreativeFileKey, sets3CreativeFileKey] = useState();
  const [s3DivisionFileKey, sets3DivisionFileKey] = useState();
  const [s3LegalFileKey, sets3LegalFileKey] = useState();
  const [deleteCreativeFlag, setDeleteCreativeFlag] = useState(false);
  const [deleteDivisionFlag, setDeleteDivisionFlag] = useState(false);
  const [deleteLegalFlag, setDeleteLegalFlag] = useState(false);
  const [editCreativeFileName, setEditCreativeFileName] = useState();
  const [editDivisionFileName, setEditDivisionFileName] = useState();
  const [editLegalFileName, setEditLegalFileName] = useState();

  const [editLegalFileNameInfo, setEditLegalFileNameInfo] = useState();
  const [editCreativeFileNameInfo, setEditCreativeFileNameInfo] = useState();
  const [editDivisionFileNameInfo, setEditDivisionFileNameInfo] = useState();
  const [selectedImageEmail, setSelectedImageEmail] = useState();
  const [selectedImageCT, setSelectedImageCT] = useState();
  const [selectedImageGM, setSelectedImageGM] = useState();
  const [createdByCampaign, setCreatedByCampaign] = useState();
  const [isTransactional, setIsTransactional] = useState(false);
  const [duplicateCategory,setDuplicateCategory] = useState(false);

  /* handle customerList */
  const handleCustomerList = async (value) => {
    setCustomerList(value);
    if (value === "") {
      setItems([])
    } else {
      const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/list`);
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
          // "user-info": JSON.stringify({ userName: userName, userEmail: email })

        }
      }
      const params = {
        search: value
      };
      url.search = new URLSearchParams(params).toString();
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((response) => {
        const res = response;
        if (res.status === "No Records Found!") {
          setItems([{ id: 0, name: "No Records" }])
        } else {
          setItems(res);
        }
      });
    }
  }
  /*suggest handler customerList */
  const onSuggestHandler = (selectedName, selectedId) => {
    setCustomerList([{ name: selectedName, id: selectedId }]);
    setItems([])
  }

  const options_priority = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' }
  ]

  /* get values in edit mode */
  useEffect(() => {
    if (props.campaignId) {
      const getCampaignDetails = async () => {
        setIsLoaded(true);
        // const url = new URL(
        //   `https://mktpl.dev.telematicsct.com/v1/campaigns/${props.campaignId}`
        // );
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${props.campaignId}?version=${props.version}`
        );

        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
            // "user-name": userName,
            // "user-email": email
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response[0];
          setStatus(res.status);
          setCreatedByCampaign(res.createdBy);
          setBrand({ label: res.brandName, value: res.brandId });
          setCampaignName(res.campaignName);
          setPriority({ label: res.priority, value: res.priority });
          setShortDescription(res.shortDescription);
          setCategory({ label: res.category.name, value: res.category.id });
          if (res.category.subCategories[0].label !== "" && res.category.subCategories.length > 0) {
            setSubCategory({
              label: res.category.subCategories[0].displayName,
              value: res.category.subCategories[0].id,
              displayName: res.category.subCategories[0].name
            });
          }
          setDeliveryRules(res.deliveryRules);
          res.channel.map((channel) => {
            setSelectedChannels((selectedChannels) => [
              ...selectedChannels,
              channel.name
            ]);
          });
          res.channel.map((channel) => {
            setSelectedChannelsEditList((selectedChannelsEditList) => [
              ...selectedChannelsEditList,
              channel.name
            ]);
          });
          res.isCreativeApproval === true ? setCreativeApproval({ title: "Yes", value: res.isCreativeApproval }) :
            setCreativeApproval({ title: "No", value: res.isCreativeApproval });
          res.isDivisionApproval === true ? setDivisionApproval({ title: "Yes", value: res.isDivisionApproval }) :
            setDivisionApproval({ title: "No", value: res.isDivisionApproval });
          res.isLegalApproval === true ? setLegalApproval({ title: "Yes", value: res.isLegalApproval }) :
            setLegalApproval({ title: "No", value: res.isLegalApproval });
          setVersion(res.version);
          setcreatedDate(res.createdDate);

          if (res.creativeApprovalDate) {
            let creative_approval_date = new Date(res.creativeApprovalDate);
            let creative_approval_date_output =
              creative_approval_date.getMonth() +
              1 +
              "/" +
              (creative_approval_date.getDate() + 1) +
              "/" +
              creative_approval_date.getFullYear();
            setCreativeApprovalDate(creative_approval_date_output)
          }

          if (res.divisionApprovalDate) {
            let division_approval_date = new Date(res.divisionApprovalDate);
            let division_approval_date_output =
              division_approval_date.getMonth() +
              1 +
              "/" +
              (division_approval_date.getDate() + 1) +
              "/" +
              division_approval_date.getFullYear();
            setDivisionApprovalDate(division_approval_date_output)
          }

          if (res.legalApprovalDate) {
            let legal_approval_date = new Date(res.legalApprovalDate);
            let legal_approval_date_output =
              legal_approval_date.getMonth() +
              1 +
              "/" +
              (legal_approval_date.getDate() + 1) +
              "/" +
              legal_approval_date.getFullYear();
            setLegalApprovalDate(legal_approval_date_output)
          }

          if (res.adhocStartDate !== null) {
            let startdate = new Date(res.adhocStartDate);
            let startdateOutput =
              startdate.getMonth() +
              1 +
              "/" +
              (startdate.getDate() + 1) +
              "/" +
              startdate.getFullYear();
            setAdhocStartDate(startdateOutput)
          } else {
            setAdhocStartDate("MM/DD/YYYY")
          }
          if (res.adhocStartDate !== null) {
            let enddate = new Date(res.adhocEndDate);
            let enddateOutput =
              enddate.getMonth() +
              1 +
              "/" +
              (enddate.getDate() + 1) +
              "/" +
              enddate.getFullYear();
            setAdhocEndDate(enddateOutput)
          } else {
            setAdhocEndDate("MM/DD/YYYY");
          }
          res.throttleCampaign === "throttle" ? setUniformStagger({ title: "Advanced Throttling", value: res.throttleCampaign })
            : res.throttleCampaign === "stagger" ? setUniformStagger({ title: "Uniform Stagger", value: res.throttleCampaign })
              : res.throttleCampaign === false ? setUniformStagger({ title: "No", value: res.throttleCampaign }) : ""

          setQuotaOfMessages(res.quotaOfMessages);
          res.vinBasedMessagesQuotes === true ? setVinBasedMessages({ title: "Yes", value: res.vinBasedMessagesQuotes })
            : setVinBasedMessages({ title: "No", value: res.vinBasedMessagesQuotes })

          res.vinQuota.map((quota) => {
            setInputList((inputList) => [
              ...inputList,
              { vin: quota.vin, quota: quota.quota }
            ]);
          });

          if (res.customerList !== null) {
            res.customerList.map((customer) => {
              setCustomerList((customerList) => [
                ...customerList,
                { name: customer.name, id: customer.id }
              ]);
            });
          }

          if (res.emailTemplate !== null) {
            if (res.emailTemplate[0].id === 'undefined') {
              setEmailTemplatesArray((emailTemplatesArray) => [
                ...emailTemplatesArray,
                { name: "Select...", id: "" }
              ]);
            } else {
              res.emailTemplate.map((template) => {
                setEmailTemplatesArray((emailTemplatesArray) => [
                  ...emailTemplatesArray,
                  { name: template.name, id: template.id }
                ]);
              })
              getImageURL({ value: res.emailTemplate[0].id, label: res.emailTemplate[0].name }, "Email")
            }
          }

          if (res.emailTemplateCopy !== null) {
            if (res.emailTemplateCopy[0].id === "Select" || res.emailTemplateCopy[0].id === "") {
              setEmailCopyArray((emailCopyArray) => [
                ...emailCopyArray,
                { label: "Select...", value: "" }
              ]);
            } else {
              res.emailTemplateCopy.map((copy) => {
                setEmailCopyArray((emailCopyArray) => [
                  ...emailCopyArray,
                  { label: copy.name, value: copy.id }
                ]);
              })
            }
          }

          if (res.generalMarketingTemplate !== null) {
            if (res.generalMarketingTemplate[0].id === 'undefined') {
              setGeneralMarketingTemplateArray((GeneralMarketingTemplateArray) => [
                ...GeneralMarketingTemplateArray,
                { name: "Select...", id: "" }
              ]);
            } else {
              res.generalMarketingTemplate.map((template) => {
                setGeneralMarketingTemplateArray((GeneralMarketingTemplateArray) => [
                  ...GeneralMarketingTemplateArray,
                  { name: template.name, id: template.id }
                ]);
              })
              getImageURL({ value: res.generalMarketingTemplate[0].id, label: res.generalMarketingTemplate[0].name }, "General Marketing Card")
            }
          }

          if (res.generalMarketingTemplateCopy !== null) {
            if (res.generalMarketingTemplateCopy[0].id === "Select" || res.generalMarketingTemplateCopy[0].id === "") {
              setGMCopyArray((GMCopyArray) => [
                ...GMCopyArray,
                { label: "Select...", value: "" }
              ]);
            } else {
              res.generalMarketingTemplateCopy.map((copy) => {
                setGMCopyArray((GMCopyArray) => [
                  ...GMCopyArray,
                  { label: copy.name, value: copy.id }
                ]);
              })
            }
          }


          if (res.ctMarketingCardTemplate !== null) {
            if (res.ctMarketingCardTemplate[0].id === "undefined") {
              setCTMarketingTemplateArray((CTMarketingTemplateArray) => [
                ...CTMarketingTemplateArray,
                { name: "Select...", id: "" }
              ]);
            } else {
              res.ctMarketingCardTemplate.map((template) => {
                setCTMarketingTemplateArray((CTMarketingTemplateArray) => [
                  ...CTMarketingTemplateArray,
                  { name: template.name, id: template.id }
                ]);
              })
              getImageURL({ value: res.ctMarketingCardTemplate[0].id, label: res.ctMarketingCardTemplate[0].name }, "CT")
            }
          }



          if (res.ctMarketingCardCopy !== null) {
            if (res.ctMarketingCardCopy[0].id === "Select" || res.ctMarketingCardCopy[0].id === "") {
              setCTCopyArray((CTCopyArray) => [
                ...CTCopyArray,
                { label: "Select...", value: "" }
              ]);
            } else {
              res.ctMarketingCardCopy.map((copy) => {
                setCTCopyArray((CTCopyArray) => [
                  ...CTCopyArray,
                  { label: copy.name, value: copy.id }
                ]);
              })
            }
          }

          res.divisionApprovalFileInfo !== null ?
            setEditDivisionFileName(res.divisionApprovalFileInfo.filName) : ""
          res.divisionApprovalFileInfo !== null ?
            sets3DivisionFileKey(res.divisionApprovalFileInfo.s3FileKey) : ""
          // res.divisionApprovalFileInfo !== null ?
          // setEditDivisionFileNameInfo(res.divisionApprovalFileInfo):""

          res.legalApprovalFileInfo !== null ?
            setEditLegalFileName(res.legalApprovalFileInfo.filName) : ""
          res.legalApprovalFileInfo !== null ?
            sets3LegalFileKey(res.legalApprovalFileInfo.s3FileKey) : ""


          res.creativeApprovalFileInfo !== null ?
            setEditCreativeFileName(res.creativeApprovalFileInfo.filName) : "";
          res.creativeApprovalFileInfo !== null ?
            sets3CreativeFileKey(res.creativeApprovalFileInfo.s3FileKey) : "";
            
          setIsTransactional(res.isTransactional);
        });
        setIsLoaded(false);
      };
      getCampaignDetails();

      if (pathName[3] === "edit-campaign") {
        setEditCampaign(true);
      } else if (pathName[3] === "view-campaign") {
        setViewCampaign(true);
      }

    }
  }, []);
  /* campaign create object */
  useEffect(() => {
    setCreateCampaignObj({
      ...((campaignName && { campaignName }) as any),
      createdBy: userName,
      createdByCampaign: createdByCampaign,
      modifiedDate: new Date().toISOString(),
      modifiedBy: userName,
      version: version,
      createdDate: createdDate,
      tags: [],
      ...((brand && { brandId: brand.value }) as any),
      ...((brand && { brandName: brand.label }) as any),
      ...(selectedChannels && {
        channel: selectedChannels.map((c) => ({
          name: c
        }))
      }),
      ...((category && {
        category: {
          id: category.value,
          name: category.label,
          ...((subCategory && {
            subCategories: [
              {
                id: subCategory.value,
                name: subCategory.displayName,
                displayName: subCategory.label
              }
            ]
          }) as any)
        }
      }) as any),
      ...(customerList && {
        customerList: customerList
      }),
      ...(deliveryRules && { deliveryRules }),
      workFlow: "",
      shortDescription: shortDescription,
      creativeApproval_Default: true,
      creativeApproval: creativeApproval && creativeApproval.value,
      creativeApprovalDate: creativeApprovalDate,
      divisionApproval: divisionApproval && divisionApproval.value,
      divisionApprovalDate: divisionApprovalDate,
      legalApproval: legalApproval && legalApproval.value,
      legalApprovalDate: legalApprovalDate,
      emailTemplate: emailTemplatesArray,
      emailTemplateCopy: emailCopyArray,
      generalMarketingTemplateCopy: GMCopyArray,
      ctMarketingCardTemplateCopy: CTCopyArray,
      ctMarketingCardTemplate: CTMarketingTemplateArray,
      generalMarketingTemplate: GeneralMarketingTemplateArray,
      template: selectedChannels[0] === "Email" ? emailTemplatesArray
        : selectedChannels[0] === "CT Marketing Card" ? CTMarketingTemplateArray : GeneralMarketingTemplateArray,
      contentCopy: selectedChannels[0] === "Email" ? emailCopyArray
        : selectedChannels[0] === "CT Marketing Card" ? CTCopyArray : GMCopyArray,
      status: status,
      roleStatus: status === "Draft" ? RoleStatus.Status_Draft : "",
      throttleCampaign: uniformStagger && uniformStagger.value,
      vinBasedMessagesQuotes: vinBasedMessages && vinBasedMessages.value,
      adhocStartDate: adhocStartDate,
      adhocEndDate: adhocEndDate,
      quotaOfMessages: quotaOfMessages,
      vinQuota: inputList,
      creativeApprovalFile: creativeApprovalFile,
      legalApprovalFile: legalApprovalFile,
      divisionApprovalFile: divisionApprovalFile,
      priority: priority && priority.value,
      deliveryRules: deliveryRules,
      creativeApprovalFileInfo: editCreativeFileNameInfo,
      divisionApprovalFileInfo: editDivisionFileNameInfo,
      legalApprovalFileInfo: editLegalFileNameInfo,
      deleteCreativeFlag: deleteCreativeFlag,
      deleteDivisionFlag: deleteDivisionFlag,
      deleteLegalFlag: deleteLegalFlag,
      isTransactional: isTransactional
    });
  }, [
    brand,
    campaignName,
    category,
    customerList,
    deliveryRules,
    selectedChannels,
    subCategory,
    shortDescription,
    creativeApproval,
    divisionApproval,
    divisionApprovalDate,
    legalApproval,
    legalApprovalDate,
    emailTemplatesArray,
    CTMarketingTemplateArray,
    GeneralMarketingTemplateArray,
    emailCopyArray,
    CTCopyArray,
    GMCopyArray,
    template,
    status,
    roleStatus,
    uniformStagger,
    vinBasedMessages,
    adhocStartDate,
    adhocEndDate,
    quotaOfMessages,
    inputList,
    creativeApprovalDate,
    creativeApprovalFile,
    divisionApprovalFile,
    legalApprovalFile,
    priority,
    editCreativeFileNameInfo,
    editDivisionFileNameInfo,
    editLegalFileNameInfo,
    deleteCreativeFlag,
    deleteDivisionFlag,
    deleteLegalFlag,
    isTransactional
  ]);
  /* edit campaign  block */
  useEffect(() => {
    if (props.campaignId) {
      if (viewCampaign) {
        localStorage.setItem("blockNavigation", "false")
      } else {
        localStorage.setItem("blockNavigation", "true")
      }
    }
  }, [createCampaignObj])

  /*adhoc changes */
  useEffect(() => {
    if (props.campaignId) {

      ((uniformStagger.value === true || uniformStagger.value === "stagger") ?
        (setQuotaOfMessages(null), setVinBasedMessages({ title: "No", value: false }), setInputList([])) :
        inputList.length < 1 ? (setQuotaOfMessages(null), setVinBasedMessages({ title: "No", value: false }), setInputList([{ vin: "", quota: "" }])) : setInputList([]))
    } else {
      (uniformStagger.value === false || uniformStagger.value === "stagger") ?
        (setQuotaOfMessages(null), setVinBasedMessages({ title: "No", value: false }), setInputList([])) : (setInputList([{ vin: "", quota: "" }]))
    }
  }, [uniformStagger])

  /* to be completed */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !brand
      ? [...toBeCompletedArray, "Brand"]
      : toBeCompletedArray;
    if (vinBasedMessages) {
      if (vinBasedMessages.value === true) {
        let valueArr = inputList.map((item) => item.vin);
        let duplicateItem = valueArr.some((item, idx) => valueArr.indexOf(item) != idx);

        for (var i in inputList) {
          (inputList[i].vin == "" || inputList[i].quota == "")
            ? toBeCompletedArray = [...toBeCompletedArray, "Vin/Quota"]
            : duplicateItem === true
              ? toBeCompletedArray = [...toBeCompletedArray, "Vin number should not be a duplicate at record " + i]
              : toBeCompletedArray = toBeCompletedArray;
        }
      } else {
        console.log("")
      }

    }
    toBeCompletedArray = uniformStagger ? uniformStagger.value === "throttle" && !vinBasedMessages
      ? [...toBeCompletedArray, "Vin Based Exceptions"] : toBeCompletedArray
      : toBeCompletedArray;
    toBeCompletedArray = uniformStagger ? uniformStagger.value === "throttle" && !quotaOfMessages
      ? [...toBeCompletedArray, "Maximum Quota Of Messages should be valid number"] : toBeCompletedArray
      : toBeCompletedArray;
    // toBeCompletedArray =  category ? category.label === "AdHoc Campaigns" && (adhocStartDate.toString() === "MM/DD/YYYY")
    //   ? [...toBeCompletedArray, "Start Date"] : toBeCompletedArray
    //   : toBeCompletedArray;
    // toBeCompletedArray =  category ? category.label === "AdHoc Campaigns" && (adhocEndDate.toString() === "MM/DD/YYYY")
    //   ? [...toBeCompletedArray, "End Date"] : toBeCompletedArray
    //   : toBeCompletedArray;
    toBeCompletedArray = category ? category.label === "AdHoc Campaigns" && !adhocStartDate
      ? [...toBeCompletedArray, "Start Date"] : toBeCompletedArray
      : toBeCompletedArray;
    toBeCompletedArray = category ? category.label === "AdHoc Campaigns" && !adhocEndDate
      ? [...toBeCompletedArray, "End Date"] : toBeCompletedArray
      : toBeCompletedArray;
    // toBeCompletedArray = !createContentDate
    // ? [...toBeCompletedArray, "Date"]
    // : toBeCompletedArray;
    toBeCompletedArray = !campaignName
      ? [...toBeCompletedArray, "Campaign Name"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !selectedChannels || !selectedChannels.length
        ? [...toBeCompletedArray, "Channels"]
        : toBeCompletedArray;
    toBeCompletedArray = !category
      ? [...toBeCompletedArray, "Category"]
      : toBeCompletedArray;
    toBeCompletedArray = !subCategory
      ? [...toBeCompletedArray, "Sub Categories"]
      : toBeCompletedArray;
    toBeCompletedArray = !shortDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = (selectedChannels.indexOf("Email") !== -1 ? !(emailTemplatesArray.length) || emailTemplatesArray[0].id === "" || !emailTemplatesArray || !emailCopyArray || !(emailCopyArray.length) : "")
      ? [...toBeCompletedArray, "Please select Email Template"]
      : toBeCompletedArray;
    toBeCompletedArray = (selectedChannels.indexOf("CT Marketing Card") !== -1 ? !(CTMarketingTemplateArray.length) || CTMarketingTemplateArray[0].id === "" || !CTMarketingTemplateArray || !CTCopyArray || !(CTCopyArray.length) : "")
      ? [...toBeCompletedArray, "Please select CT Marketing Card Template"]
      : toBeCompletedArray;
    toBeCompletedArray = (selectedChannels.indexOf("General Marketing Card") !== -1 ? !(GeneralMarketingTemplateArray.length) || GeneralMarketingTemplateArray[0].id === "" || !GeneralMarketingTemplateArray || !GMCopyArray || !(GMCopyArray.length) : "")
      ? [...toBeCompletedArray, "Please select General Marketing Card Template"]
      : toBeCompletedArray;
    toBeCompletedArray = !customerList || !customerList.length
      ? [...toBeCompletedArray, "Customer List"]
      : toBeCompletedArray;
    // toBeCompletedArray = !deliveryRules || !deliveryRules.length
    //   ? [...toBeCompletedArray, "Delivery Rules"]
    //   : toBeCompletedArray;
    toBeCompletedArray = !creativeApproval ? [...toBeCompletedArray, "Creative Approval"] : toBeCompletedArray;
    // toBeCompletedArray = !creativeApprovalDate ? [...toBeCompletedArray, "Creative Approval Date"] : toBeCompletedArray;
    toBeCompletedArray = !divisionApproval ? [...toBeCompletedArray, "Division Approval"] : toBeCompletedArray;
    // toBeCompletedArray = !divisionApprovalDate ? [...toBeCompletedArray, "Division Approval Date"] : toBeCompletedArray;
    toBeCompletedArray = !legalApproval ? [...toBeCompletedArray, "Legal Approval"] : toBeCompletedArray;
    // toBeCompletedArray = !legalApprovalDate ? [...toBeCompletedArray, "Legal Approval Date"] : toBeCompletedArray;

    setToBeCompleted(toBeCompletedArray);
  }, [
    brand,
    campaignName,
    category,
    customerList,
    deliveryRules,
    selectedChannels,
    shortDescription,
    subCategory,
    creativeApproval,
    creativeApprovalDate,
    divisionApproval,
    divisionApprovalDate,
    legalApproval,
    legalApprovalDate,
    emailTemplatesArray,
    emailCopyArray,
    CTMarketingTemplateArray,
    CTCopyArray,
    GeneralMarketingTemplateArray,
    GMCopyArray,
    adhocStartDate,
    adhocEndDate,
    quotaOfMessages,
    vinBasedMessages,
    inputList
  ]);

  /* save campaign */
  const saveCampaign = useCallback(async (createCampaignObj) => {
    setIsLoaded(true);
    localStorage.setItem("blockNavigation", "false");
    let formData = new FormData();
    let url, methodValue;

    if (createCampaignObj.legalApprovalDate === "MM/DD/YYYY") {
      createCampaignObj.legalApprovalDate = "";
    } if (createCampaignObj.creativeApprovalDate === "MM/DD/YYYY") {
      createCampaignObj.creativeApprovalDate = "";
    } if (createCampaignObj.divisionApprovalDate === "MM/DD/YYYY") {
      createCampaignObj.divisionApprovalDate = "";
    } if (createCampaignObj.adhocStartDate === "MM/DD/YYYY") {
      createCampaignObj.adhocStartDate = "";
    } if (createCampaignObj.adhocEndDate === "MM/DD/YYYY") {
      createCampaignObj.adhocEndDate = "";
    }
    if (createCampaignObj.deleteCreativeFlag === true) {
      formData.append("creativeApprovalDeleteFlag", createCampaignObj.deleteCreativeFlag)
    }
    if (createCampaignObj.deleteDivisionFlag === true) {
      formData.append("divisionalApprovalDeleteFlag", createCampaignObj.deleteDivisionFlag)
    }
    if (createCampaignObj.deleteLegalFlag === true) {
      formData.append("legalApprovalDeleteFlag", createCampaignObj.deleteLegalFlag)
    }

    formData.append("campaignName", createCampaignObj.campaignName);
    formData.append('shortDescription', createCampaignObj.shortDescription);
    formData.append("status", createCampaignObj.status);
    (props.campaignId !== undefined) ? formData.append("createdBy", createCampaignObj.createdByCampaign) : formData.append("createdBy", userName);
    formData.append("createdDate", createCampaignObj.createdDate !== undefined ? createCampaignObj.createdDate : createCampaignObj.modifiedDate);
    formData.append("modifiedDate", createCampaignObj.modifiedDate);
    formData.append("modifiedBy", userName);
    formData.append("legalApprovalDate", createCampaignObj.legalApprovalDate);
    formData.append("divisionApprovalDate", createCampaignObj.divisionApprovalDate);
    formData.append("creativeApprovalDate", createCampaignObj.creativeApprovalDate);
    formData.append(`tags[${0}]`, "sampleTag");
    formData.append("brandId", createCampaignObj.brandId);
    formData.append("priority", createCampaignObj.priority !== undefined ? createCampaignObj.priority : "3");
    formData.append("brandName", createCampaignObj.brandName);
    createCampaignObj.channel.forEach((item, i) => {
      formData.append(`channel[${i}][name]`, (item.name))
    });
    createCampaignObj.customerList.forEach((item) => {
      formData.append(`customerList[${0}][id]`, (item.id))
      formData.append(`customerList[${0}][name]`, (item.name))
    });

    formData.append(`template[${0}][id]`, (createCampaignObj.template[0].id === "Select" || createCampaignObj.template[0].id === '' ? "" : createCampaignObj.template[0].id))
    formData.append(`template[${0}][name]`, (createCampaignObj.template[0].name === "Select..." ? "" : createCampaignObj.template[0].name))
    formData.append(`contentCopy[${0}][id]`, (createCampaignObj.contentCopy[0].value === 'Select' || createCampaignObj.contentCopy[0].value === '' ? "" : createCampaignObj.contentCopy[0].value))
    formData.append(`contentCopy[${0}][name]`, (createCampaignObj.contentCopy[0].label === 'Select...' ? "" : createCampaignObj.contentCopy[0].label))

    createCampaignObj.deliveryRules.forEach((item, i) => {
      formData.append(`deliveryRules[${0}][ruleName]`, (item.ruleName))
      formData.append(`deliveryRules[${0}][parameter]`, (item.parameter))
      formData.append(`deliveryRules[${0}][logicalConnector]`, (item.logicalConnector))
      formData.append(`deliveryRules[${0}][numberOfDays]`, (item.numberOfDays))
    });
    //formData.append("deliveryRules", createCampaignObj.deliveryRules !== undefined? createCampaignObj.deliveryRules :"");
    formData.append("workFlow", "sample");
    formData.append(`category[id]`, (createCampaignObj.category.id))
    formData.append(`category[name]`, (createCampaignObj.category.name))
    formData.append(`category[subCategories][${0}][id]`, (createCampaignObj.category.subCategories[0].id))
    formData.append(`category[subCategories][${0}][name]`, (createCampaignObj.category.subCategories[0].name))
    formData.append(`category[subCategories][${0}][displayName]`, (createCampaignObj.category.subCategories[0].displayName))
    formData.append("creativeApproval", createCampaignObj.creativeApproval_Default);
    formData.append("isCreativeApproval", createCampaignObj.creativeApproval);
    formData.append("isDivisionApproval", createCampaignObj.divisionApproval);
    formData.append('isLegalApproval', createCampaignObj.legalApproval);
    formData.append("adhocStartDate", createCampaignObj.adhocStartDate);
    formData.append("adhocEndDate", createCampaignObj.adhocEndDate);
    formData.append(`generalMarketingTemplateCopy[${0}][id]`, createCampaignObj.generalMarketingTemplateCopy.length > 0 ? createCampaignObj.generalMarketingTemplateCopy[0].value : []);
    formData.append(`generalMarketingTemplateCopy[${0}][name]`, createCampaignObj.generalMarketingTemplateCopy.length > 0 ? createCampaignObj.generalMarketingTemplateCopy[0].label === "Select..." ? "" : createCampaignObj.generalMarketingTemplateCopy[0].label : []);
    formData.append(`generalMarketingTemplate[${0}][id]`, createCampaignObj.generalMarketingTemplate.length > 0 ? createCampaignObj.generalMarketingTemplate[0].id : []);
    formData.append(`generalMarketingTemplate[${0}][name]`, createCampaignObj.generalMarketingTemplate.length > 0 ? createCampaignObj.generalMarketingTemplate[0].name === "Select..." ? "" : createCampaignObj.generalMarketingTemplate[0].name : []);
    formData.append(`ctMarketingCardCopy[${0}][id]`, createCampaignObj.ctMarketingCardTemplateCopy.length > 0 ? createCampaignObj.ctMarketingCardTemplateCopy[0].value : []);
    formData.append(`ctMarketingCardCopy[${0}][name]`, createCampaignObj.ctMarketingCardTemplateCopy.length > 0 ? createCampaignObj.ctMarketingCardTemplateCopy[0].label === "Select..." ? "" : createCampaignObj.ctMarketingCardTemplateCopy[0].label : []);
    formData.append(`ctMarketingCardTemplate[${0}][id]`, createCampaignObj.ctMarketingCardTemplate.length > 0 ? createCampaignObj.ctMarketingCardTemplate[0].id : []);
    formData.append(`ctMarketingCardTemplate[${0}][name]`, createCampaignObj.ctMarketingCardTemplate.length > 0 ? createCampaignObj.ctMarketingCardTemplate[0].name === "Select..." ? "" : createCampaignObj.ctMarketingCardTemplate[0].name : []);
    formData.append(`emailTemplateCopy[${0}][id]`, createCampaignObj.emailTemplateCopy.length > 0 ? createCampaignObj.emailTemplateCopy[0].value : []);
    formData.append(`emailTemplateCopy[${0}][name]`, createCampaignObj.emailTemplateCopy.length > 0 ? createCampaignObj.emailTemplateCopy[0].label === "Select..." ? "" : createCampaignObj.emailTemplateCopy[0].label : []);
    formData.append(`emailTemplate[${0}][id]`, createCampaignObj.emailTemplate.length > 0 ? createCampaignObj.emailTemplate[0].id : []);
    formData.append(`emailTemplate[${0}][name]`, createCampaignObj.emailTemplate.length > 0 ? createCampaignObj.emailTemplate[0].name === "Select..." ? "" : createCampaignObj.emailTemplate[0].name : []);
    formData.append("throttleCampaign", createCampaignObj.throttleCampaign);
    formData.append("vinBasedMessagesQuotes", createCampaignObj.vinBasedMessagesQuotes);
    formData.append("version", createCampaignObj.version !== undefined ? createCampaignObj.version : "0.0");
    createCampaignObj.vinQuota !== null ?
      createCampaignObj.vinQuota.forEach((item, i) => {
        formData.append(`vinQuota[${i}][vin]`, (item.vin));
        formData.append(`vinQuota[${i}][quota]`, (item.quota === null ? "" : item.quota));
      }) : []

    formData.append("creativeApprovalFile", createCampaignObj.creativeApprovalFile !== null || createCampaignObj.creativeApprovalFile !== undefined ? createCampaignObj.creativeApprovalFile : null);
    formData.append("divisionApprovalFile", createCampaignObj.divisionApprovalFile ? createCampaignObj.divisionApprovalFile : null);
    formData.append("legalApprovalFile", createCampaignObj.legalApprovalFile ? createCampaignObj.legalApprovalFile : null);
    formData.append('quotaOfMessages', createCampaignObj.quotaOfMessages === null ? 0 : (createCampaignObj.quotaOfMessages));
    formData.append('isTransactional', createCampaignObj.isTransactional);

    (props.campaignId !== undefined)
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${props.campaignId}`
      )),
        (methodValue = "PUT"))
      : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns`)),
        (methodValue = "POST"));
    const serverResponse = await fetch(url.toString(), {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`
        // "user-info": JSON.stringify({ userName: userName, userEmail: email })
      },
      //body: JSON.stringify(createCampaignObj)
      body: formData
    });
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const serverResponseJson: any = await serverResponse.json();
    return serverResponse.status
  }, []);

  // handle input change vin
  const handleInputChangeVin = (e, index, refName) => {
    let regularExpression = /^[0-9a-zA-Z]+$/;
    const inputvalue = e;
    const name = refName;
    const list = [...inputList];
    list[index][name] = inputvalue;
    if ((regularExpression.test(inputvalue))) {
      setInputList(list);
    } else {
      setInputList([{ vin: "", quota: inputList[index].quota }]);
    }
  };

  // handle input change quota
  const handleInputChange = (e, index, refName) => {
    const value = e;
    const name = refName;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { vin: "", quota: "" }]);
  };

  //check numeric validation
  const checkNumericValidation = (value) => {
    let regularExpression = /^[0-9\b]+$/;

    if (regularExpression.test(String(value).toLowerCase())) {
      setQuotaOfMessages(value)
    } else {
      setQuotaOfMessages(null)
    }
  }
  //downloadFile
  const downloadFile = async (fileType) => {
    let inputkey = "";
    if (fileType === "creative") {
      inputkey = s3CreativeFileKey;
    } else if (fileType === "legal") {
      inputkey = s3LegalFileKey;
    } else {
      inputkey = s3DivisionFileKey;
    }

    fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${inputkey}`, {
      method: 'GET',
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          // `FileName.zip`,
          // `${editCreativeFileName}`
          fileType === "creative" ? `${editCreativeFileName}` :
            fileType === "legal" ? `${editLegalFileName}` :
              `${editDivisionFileName}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  /*reset when flag is true */
  useEffect(() => {
    if (props.campaignId !== undefined) {
      if (deleteCreativeFlag === true) {
        setCreativeApprovalFile(undefined)
        setEditCreativeFileName(undefined)
      } else if (deleteDivisionFlag === true) {
        setDivisionApprovalFile(null)
        setEditDivisionFileName(null)
      } else if (deleteLegalFlag === true) {
        setLegalApprovalFile(null)
        setEditLegalFileName(null)
      }
      else {
        console.log("")
      }
    } else { console.log("") }

  }, [deleteCreativeFlag, deleteLegalFlag, deleteDivisionFlag])

  /*get subcategories */
  const getSubcategories = async (categoryname) => {
    setSubCategoryList([]);
    if (categoryname === "") {
      setSubCategory(null)
    } else {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/subcategories?category=${categoryname}`
      );

      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          // "user-name": userName,
          // "user-email": email
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((response) => {

        if (response !== null && response.length !== 0) {
          response.map((result) => {
            setSubCategoryList((subCategoryList) => [
              ...subCategoryList,
              { label: result.displayName, value: result.id, displayName: result.name }
            ]);
          })
        } else {
          setSubCategoryList((subCategoryList) => [
            ...subCategoryList,
            { label: "", value: "", displayName: "" }
          ]);
        }

      })
    }
  }

  useEffect(() => {
    if (subCategoryList === null || subCategoryList.length === 0) {
      setSubCategory(null)
      handleChange(null)
    }
  }, [subCategoryList])

  const handleChange = (value) => {
    if (value === "" || value === null || value === undefined) {
      setSubCategory(null)
    } else {
      setSubCategory(value)
    }
  };


  const getCopyList = async (option, channelSelected) => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/template/${option.value}`
    );

    const callParams = {
      method: "GET",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = serverResponse.json().then((response) => {
      if (serverResponse.status === 200) {
        response.map((result) => {
          setCopyList((copyList) => [
            { label: result.name, value: result.id },
            ...copyList,
          ]);
        })
      } else {
        setCopyList([])
      }
    });
    return serverResponse.status;
  }

  const getImageURL = async (option, channelSelected) => {
    if (option.value !== '') {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/${option.value}/image-previewurl`
      );

      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((response) => {
        const res = response;
        if (channelSelected === "Email") {
          setSelectedImageEmail(res.previewImageUrl)
        } else if (channelSelected === "CT") {
          setSelectedImageCT(res.previewImageUrl)
        } else {
          setSelectedImageGM(res.previewImageUrl)
        }

      });
      return serverResponse.status;
    }
  }


  const getTemplateImage = async (option, channelSelected) => {
    setCopyList([])
    if (option === null) {
      if (channelSelected === "Email") {
        setEmailCopyArray([{ label: "Select...", value: "" }])
        setEmailTemplatesArray([{ name: "Select...", id: "" }])
        setSelectedImageEmail(null);
      } else if (channelSelected === "CT") {
        setCTCopyArray([{ label: "Select...", value: "" }])
        setSelectedImageCT(null);
        setCTMarketingTemplateArray([{ name: "Select...", id: "" }])
      } else {
        setSelectedImageGM(null);
        setGMCopyArray([{ label: "Select...", value: "" }])
        setGeneralMarketingTemplateArray([{ name: "Select...", id: "" }])
      }
    } else {
      if (channelSelected === "Email") {
        setEmailCopyArray([{ label: "Select...", value: "" }])
        setEmailTemplatesArray([{ name: option.label, id: option.value }])
      } else if (channelSelected === "CT") {
        setCTCopyArray([{ label: "Select...", value: "" }])
        setCTMarketingTemplateArray([{ name: option.label, id: option.value }])
      } else {
        setGMCopyArray([{ label: "Select...", value: "" }])
        setGeneralMarketingTemplateArray([{ name: option.label, id: option.value }])
      }
      const imageurl = await getImageURL(option, channelSelected);
      if (imageurl === 200) {
        const copyList = await getCopyList(option, channelSelected)
        if (copyList !== 200) {
          // setCopyList(null)
          console.log("404 error")
        }
      }
    }
  }

  const setCopyDetails = (option, copySelected) => {
    if (option === null) {
      if (copySelected === "Email") {
        setEmailCopyArray([])
      } else if (copySelected === "CT") {
        setCTCopyArray([])
      } else {
        setGMCopyArray([])
      }
    } else {
      if (copySelected === "Email") {
        setEmailCopyArray([{ label: option.label, value: option.value }, ...emailCopyArray])
      } else if (copySelected === "CT") {
        setCTCopyArray([{ label: option.label, value: option.value }, ...CTCopyArray])
      } else {
        setGMCopyArray([{ label: option.label, value: option.value }, ...GMCopyArray])
      }
    }
  }

  useEffect(() => {
    if (props.campaignId) {

      if (selectedChannels) {
        if (selectedChannels[0] === "Email") {
          setCTCopyArray([{ label: "Select...", value: "" }])
          setSelectedImageCT(null);
          setCTMarketingTemplateArray([{ name: "Select...", id: "" }]);
          setSelectedImageGM(null)
          setGMCopyArray([{ label: "Select...", value: "" }])
          setGeneralMarketingTemplateArray([{ name: "Select...", id: "" }])

        } else if (selectedChannels[0] === "CT Marketing Card") {
          setEmailTemplatesArray([{ name: "Select...", id: "" }]);
          setSelectedImageEmail(null);
          setEmailCopyArray([{ label: "Select...", value: "" }])
          setSelectedImageGM(null)
          setGMCopyArray([{ label: "Select...", value: "" }])
          setGeneralMarketingTemplateArray([{ name: "Select...", id: "" }])
        } else if (selectedChannels[0] === "General Marketing Card") {
          setEmailTemplatesArray([{ name: "Select...", id: "" }]);
          setSelectedImageEmail(null);
          setEmailCopyArray([{ label: "Select...", value: "" }])
          setCTCopyArray([{ label: "Select...", value: "" }])
          setSelectedImageCT(null);
          setCTMarketingTemplateArray([{ name: "Select...", id: "" }]);
        }
      }
    }

    for (var i = 0; i < channelList.length - 1; i++) {
      if (selectedChannels.findIndex((s) => s === channelList[i]) === -1) {
        if (channelList[i] === "Email") {
          setEmailTemplatesArray([{ name: "Select...", id: "" }]);
          setSelectedImageEmail(null);
          setEmailCopyArray([{ label: "Select...", value: "" }])
        } else if (channelList[i] === "CT Marketing Card") {
          setCTCopyArray([{ label: "Select...", value: "" }])
          setSelectedImageCT(null);
          setCTMarketingTemplateArray([{ name: "Select...", id: "" }]);
        } else if (channelList[i] === "General Marketing Card") {
          setSelectedImageGM(null)
          setGMCopyArray([{ label: "Select...", value: "" }])
          setGeneralMarketingTemplateArray([{ name: "Select...", id: "" }])
        } else {
          setEmailTemplatesArray([{ name: "Select...", id: "" }]);
          setSelectedImageEmail(null);
          setEmailCopyArray([{ label: "Select...", value: "" }])
          setCTCopyArray([{ label: "Select...", value: "" }])
          setSelectedImageCT(null);
          setCTMarketingTemplateArray([{ name: "Select...", id: "" }]);
          setSelectedImageGM(null)
          setGMCopyArray([{ label: "Select...", value: "" }])
          setGeneralMarketingTemplateArray([{ name: "Select...", id: "" }])
        }
      }
    }
  }, [channelList])

  const checkDuplicateCategories = async () => {
        let duplicateCount = 0;
        const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/search`);
        const callParams = {
          method: "POST",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          },
          body: JSON.stringify({
            "category": [createCampaignObj.category.name],
            "subCategories": [createCampaignObj.category.subCategories[0].name]
          })
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = await serverResponse.json().then((response) => {
            if (response.totalCampaigns > 0){
              console.log("totalCampaigns - ", response.totalCampaigns);
              duplicateCount = response.totalCampaigns;
            }
        })
          return duplicateCount;
      }

  return (
    <Fragment>
      {props.campaignId && editCampaign ? (
        <TitleWithPathHeader
          title="Edit Campaign"
          path="/manage campaign/edit campaign"
        />
      ) : props.campaignId && viewCampaign ? (
        <TitleWithPathHeader
          title="View Campaign"
          path="/manage campaign/view campaign"
        />
      ) : (
        <TitleWithPathHeader
          title="Create Campaign"
          path="/manage campaign/create campaign"
        />
      )}

      <PageContent className={styles.CreateCampaign}>
        {(isLoaded === true) ? <LoadSpinner /> : ""}
        <div className={props.campaignId !== undefined && !viewCampaign && status !== "Draft" ? styles.DisableCampaign : ""}>
          {viewCampaign ? (
            <div className={styles.ViewNotice}>
              As a viewer, no changes made on this page will be saved.
            </div>
          ) : null}
          <div className={styles.BrandAndCampaign}>
            <LabeledSelect
              optionsUrl="v1/ctmp/brand"
              className={styles.Select}
              defaultLabel={brand ? brand.label : undefined}
              defaultId={brand ? brand.value : undefined}
              title={
                <>
                  Brand<span>*</span>
                </>
              }
              onValueChange={(option) => setBrand(option)}
            />
            <LabeledInput
              title={
                <>
                  Campaign Name<span>*</span>
                </>
              }
              className={styles.CampaignNameCombo}
              data={campaignName}
              onValueChange={(value) => setCampaignName(value)}
            />
          </div>
          <div className={styles.ChannelContainer}>
            <label>
              Channels<span>*</span>
            </label>
            <MultiPicker
              setSelected={(selected) => setSelectedChannels(selected)}
              setSelectedList={(selected) => setChannelList(selected)}
              previousSelected={selectedChannels}
              status=""
              mode=""
              data={[
                {
                  id: 1,
                  icon: MailIcon,
                  title: "Email",
                  description:
                    "Choose a template and send automated transactional or marketing lite emails to the selected audience."
                },
                {
                  icon: MessageIcon,
                  title: "Push Notification",
                  description:
                    "Choose a template and send automated transactional or marketing lite push notifications to the selected audience."
                },
                {
                  icon: Phone,
                  title: "CT Marketing Card",
                  description:
                    "Choose a template and send automated transactional or marketing lite OneApp Cards to the selected audience."
                },
                {
                  icon: NewTag,
                  title: "General Marketing Card",
                  description:
                    "Choose a template and send automated transactional or marketing lite cards to the selected audience."
                },
                {
                  icon: Volume,
                  title: "Announcement Center",
                  description:
                    "Choose a template and send automated transactional or marketing lite announcements to the selected audience."
                }
              ]}
            />
          </div>
          {/* ---------Add New SubCategory-------*/}
          <div>
          <CreateSubCategory title={
            <>
              + Add Sub Category
            </>}></CreateSubCategory>
          </div> 
          <div className={styles.CategorySubCategoryCombo}>
            <LabeledSelect
              className={styles.Select}
              optionsUrl="v1/ctmp/category"
              title={
                <>
                  Select Category<span>*</span>
                </>
              }
              defaultLabel={category ? category.label : undefined}
              defaultId={category ? category.value : undefined}
              onValueChange={async (option) => {
                setCategory(option);

                setUniformStagger({ title: "No", value: false });
                setVinBasedMessages({ title: "No", value: false });
                setQuotaOfMessages(null);
                setInputList([{ vin: "", quota: "" }])
                setAdhocStartDate("MM/DD/YYYY");
                setAdhocEndDate("MM/DD/YYYY");
                if (option === null) {
                  setSubCategory(null)
                  await getSubcategories(null)
                } else {
                  const status = await getSubcategories(option.value)
                }
              }}
            />
            <div style={{ display: "flex", flexDirection: "column", width: "50%", color: "black" }}>
              <label>Select Sub Category<span>*</span></label>
              <Select
                className={styles.SubCategoryDropDown}
                value={subCategory}
                onChange={(option) => handleChange(option)}
                isClearable
                options={subCategoryList}
              >
              </Select>
            </div>
          </div>
          {/*----------Transactional Checkbox------ */}
           {category && category.label === "AdHoc Campaigns" ?
           <div className={styles.EnableAdHocTransactional}>
            <LabeledCheckbox
              title="Is Transactional ?"
              className={styles.transactionalCheckbox}
              data={isTransactional}
              onValueChange={(value) => {setIsTransactional(value); console.log(value)}}
            />
           </div>
            : ""}
          {/*----------Adhoc Campaigns Area------ */}
          <div className={category ?
            (category.label !== "AdHoc Campaigns" && category.label !== "Advertisement Campaigns") ?
              styles.disableAdhoc : "" : styles.disableAdhoc}>
            <LabeledDiv
              className={styles.Adhoc}
              title={
                <>
                  Category Settings
                </>
              }
            >

              <div className={styles.AdhocDatesCombo}>
                <label>
                  Start Date<span> *</span>
                </label>
                <DateSelector
                  className={styles.CalendarSelector}
                  onDiscard={console.log}
                  onApply={(date) => setAdhocStartDate(date)}
                  value={adhocStartDate}
                />
              </div>
              <div className={styles.AdhocDatesCombo}>
                <label>
                  End Date<span> *</span>
                </label>
                <DateSelector
                  className={styles.CalendarSelector}
                  onDiscard={console.log}
                  onApply={(date) => setAdhocEndDate(date)}
                  value={adhocEndDate}
                />
              </div>
              <div className={styles.AdhocDatesCombo1}>
                <label>
                  Throttle Campaign?<span> *</span>
                </label>
                <RadioButtonControl
                  previousSelection={uniformStagger}
                  onSelect={(value) => {
                    setUniformStagger(value);
                  }}
                  options={[
                    {
                      title: "No",
                      value: false
                    },
                    { title: "Uniform Stagger", value: "stagger" },
                    { title: "Advanced Throttling", value: "throttle" }
                  ]}
                />
              </div>

              {(uniformStagger === undefined) ? "" : uniformStagger.value === "throttle" ? (
                <React.Fragment>
                  <div className={styles.AdhocDatesCombo}>
                    <LabeledInput
                      className=""
                      placeholder="Provide a figure"
                      data={quotaOfMessages}
                      onValueChange={(value) => { checkNumericValidation(value) }}
                      title={
                        <>
                          Maximum quota of messages or emails per day<span> *</span>
                        </>
                      }
                    />
                  </div>
                  <div className={styles.AdhocDatesCombo1}>
                    <label>
                      Add VIN based message quotas exceptions?<span> *</span>
                    </label>
                    <RadioButtonControl
                      previousSelection={vinBasedMessages}
                      onSelect={(value) => {
                        setVinBasedMessages(value);
                      }}
                      options={[
                        {
                          title: "Yes",
                          value: true
                        },
                        { title: "No", value: false }
                      ]}
                    />
                  </div></React.Fragment>) : ""}

              {(vinBasedMessages === undefined || vinBasedMessages.value === false) ? "" : vinBasedMessages.value === true ? (
                inputList.map((x, i) => {
                  return (
                    <React.Fragment>
                      <div className={styles.wrapper}>
                        <div className={styles.AdhocDatesCombo_quotaException1}>
                          <LabeledInput
                            className="VINNumber"
                            placeholder="VIN Number"
                            data={x.vin}
                            onValueChange={(e) => handleInputChangeVin(e, i, "vin")}
                            title={i === 0 ?
                              <>
                                VIN based quota exception<span> *</span>
                              </>
                              : ""}
                          />
                        </div>
                        <div className={styles.AdhocDatesCombo_quotaException2}>
                          <LabeledInput
                            className=""
                            placeholder="Quota"
                            data={x.quota}
                            onValueChange={(e) => handleInputChange(e, i, "quota")}
                            title={i === 0 ?
                              <>
                                Maximum Quota<span> *</span>
                              </>
                              : ""}
                          />
                          {/* {inputList.length - 1 === i && <div className={styles.plusSymbol} onClick={handleAddClick}><Plus></Plus></div>}
         {inputList.length !== 1 && <div className={styles.minusSymbol}  onClick={() => handleRemoveClick(i)}><Minus></Minus></div>}
         </div></div></React.Fragment>)})) : "" }  */}
                          {inputList.length - 1 === 0 && <div className={styles.plusSymbol} onClick={handleAddClick}><Plus></Plus></div>}
                          {inputList.length > 1 ? inputList.length - 1 === i && <div className={styles.minusSymbol} onClick={handleAddClick}><Plus></Plus></div> : ""}
                          {inputList.length !== 1 && i > 0 && <div className={styles.minusSymbol} onClick={() => handleRemoveClick(i)}><Minus></Minus></div>}
                          {inputList.length !== 1 && i === 0 && <div className={styles.minusSymboList} onClick={() => handleRemoveClick(i)}><Minus></Minus></div>}
                        </div></div></React.Fragment>)
                })) : ""}
            </LabeledDiv>
          </div>
          {/* ----------End of Adhoc ---------*/}
          <LabeledDescriptionInput
            textAreaPlaceholder=""
            data={shortDescription}
            title={
              <>
                Description<span>*</span>
              </>
            }
            onValueChange={(value) => setShortDescription(value)}
          />

          {/*-------------------Email Template Copy Start-------------*/}

          <div className={styles.CategorySubCategoryCombo}>
            <LabeledSelect
              className={(selectedChannels.indexOf("Email") !== -1 && brand && brand.label.length > 0) ? styles.Select : styles.DisableTemplate}
              optionsUrl="v1/template/channel/Email"
              title={
                <>
                  Email Template<span> *</span>
                </>
              }
              defaultLabel={emailTemplatesArray.length > 0 ? emailTemplatesArray[0].name : undefined}
              defaultId={emailTemplatesArray.length > 0 ? emailTemplatesArray[0].id : undefined}
              onValueChange={(option) => getTemplateImage(option, "Email")}
            />
            <Preview value={selectedImageEmail ? selectedImageEmail : ""}
              classNameRef={selectedImageEmail ? "" : styles.DisableImagePreview}
              title="Preview"
            ></Preview>
            {/* <LabeledSelect
            className={styles.Select}
            title={
              <>
                Email Copy<span> *</span>
              </>
            }
            // optionsUrl="v1/content-copy/list?status=Published"
            options={copyList}
            defaultLabel={emailCopyArray.length > 0 ? emailCopyArray[0].name : undefined}
            defaultId={emailCopyArray.length > 0 ? emailCopyArray[0].id : undefined}
            onValueChange={(option) => setEmailCopyArray([...emailCopyArray,option])}
          /> */}
            <div style={{ display: "flex", flexDirection: "column", width: "45%", color: "black" }}>
              <label className={selectedChannels.indexOf("Email") !== -1 ? "" : styles.DisableLabel}>Email Copy</label>
              <Select
                className={selectedChannels.indexOf("Email") !== -1 ? styles.SubCategoryDropDown : styles.DisableCopy}
                //  value={selectedChannels.indexOf("Email") !== -1 ?emailCopyArray : ""}
                value={emailCopyArray ? emailCopyArray.length > 0 ? ({ value: emailCopyArray[0].value, label: emailCopyArray[0].label }) : undefined : undefined}
                //value={emailCopyArray}
                onChange={(option) => { setCopyDetails(option, "Email") }}
                defaultValue={{ label: "Select...", value: "" }}
                isClearable
                options={copyList}
              >
              </Select>
            </div>
          </div>

          {/*-----------------Email Template Copy End --------------*/}

          {/*-------------------CT Template Copy Start-------------*/}

          <div className={styles.CategorySubCategoryCombo}>
            <LabeledSelect
              className={selectedChannels.indexOf("CT Marketing Card") !== -1 ? styles.Select : styles.DisableTemplate}
              optionsUrl="v1/template/channel/CT Marketing Card"
              title={
                <>
                  CT Marketing Card Template<span> *</span>
                </>
              }
              defaultLabel={CTMarketingTemplateArray.length > 0 ? CTMarketingTemplateArray[0].name : undefined}
              defaultId={CTMarketingTemplateArray.length > 0 ? CTMarketingTemplateArray[0].id : undefined}
              onValueChange={(option) => getTemplateImage(option, "CT")}
            />
            <Preview value={selectedImageCT ? selectedImageCT : ""}
              classNameRef={selectedImageCT ? "" : styles.DisableImagePreview}
              title="Preview"
            ></Preview>
            {/* <LabeledSelect
            className={styles.Select}
            title={
              <>
                CT Marketing Copy<span> *</span>
              </>
            }
            // optionsUrl="v1/content-copy/list?status=Published"
            optionsUrl="v1/ctmp/category"
            defaultLabel={emailCopyArray.length > 0 ? emailCopyArray[0].name : undefined}
            defaultId={emailCopyArray.length > 0 ? emailCopyArray[0].id : undefined}
            onValueChange={(option) => setEmailCopyArray([...emailCopyArray,option])}
          /> */}
            <div style={{ display: "flex", flexDirection: "column", width: "45%", color: "black" }}>
              <label className={selectedChannels.indexOf("CT Marketing Card") !== -1 ? "" : styles.DisableLabel}> CT Marketing Copy</label>
              <Select
                className={selectedChannels.indexOf("CT Marketing Card") !== -1 ? styles.SubCategoryDropDown : styles.DisableCopy}
                //value={CTCopyArray}
                value={CTCopyArray ? CTCopyArray.length > 0 ? ({ value: CTCopyArray[0].value, label: CTCopyArray[0].label }) : undefined : undefined}
                onChange={(option) => { setCopyDetails(option, "CT") }}
                defaultValue={{ label: "Select...", value: "" }}
                isClearable
                options={copyList}
              >
              </Select>
            </div>
          </div>

          {/*-----------------CT Template Copy End --------------*/}

          {/*-------------------GM Template Copy Start-------------*/}

          <div className={styles.CategorySubCategoryCombo}>
            <LabeledSelect
              className={selectedChannels.indexOf("General Marketing Card") !== -1 ? styles.Select : styles.DisableTemplate}
              optionsUrl="v1/template/channel/General Marketing Card"
              title={
                <>
                  General Marketing Card Template<span> *</span>
                </>
              }
              defaultLabel={GeneralMarketingTemplateArray.length > 0 ? GeneralMarketingTemplateArray[0].name : undefined}
              defaultId={GeneralMarketingTemplateArray.length > 0 ? GeneralMarketingTemplateArray[0].id : undefined}
              onValueChange={(option) => getTemplateImage(option, "GT")}
            />
            <Preview value={selectedImageGM ? selectedImageGM : ""}
              classNameRef={selectedImageGM ? "" : styles.DisableImagePreview}
              title="Preview"
            ></Preview>
            <div style={{ display: "flex", flexDirection: "column", width: "45%", color: "black" }}>
              <label className={selectedChannels.indexOf("General Marketing Card") !== -1 ? "" : styles.DisableLabel}>General Marketing Card Copy</label>
              <Select
                className={selectedChannels.indexOf("General Marketing Card") !== -1 ? styles.SubCategoryDropDown : styles.DisableCopy}
                //value={GMCopyArray}
                value={GMCopyArray ? GMCopyArray.length > 0 ? ({ value: GMCopyArray[0].value, label: GMCopyArray[0].label }) : undefined : undefined}
                onChange={(option) => { setCopyDetails(option, "GM") }}
                defaultValue={{ label: "Select...", value: "" }}
                isClearable
                options={copyList}
              >
              </Select>
            </div>
          </div>

          {/*-----------------GM Template Copy End --------------*/}

          {/* <div className={styles.TemplateContainer}>
          <LabeledCardTemplateAndCopy
            visiblity={selectedChannels.indexOf("Email") !== -1 ? "true" : "false"}
            cardTitle="Email"
            defaultLabel={emailTemplatesArray.length > 0 ? emailTemplatesArray[0] : undefined}
            defaultId={emailTemplatesArray.length > 0 ? emailTemplatesArray[0].id : undefined}
            defaultLabelCopy={emailCopyArray.length > 0 ? emailCopyArray[0].name : undefined}
            defaultIdCopy={emailCopyArray.length > 0 ? emailCopyArray[0].id : undefined}
            templatesArray={(emailTemplatesArray) => setEmailTemplatesArray(emailTemplatesArray)}
            copyArray={(emailCopyArray) => setEmailCopyArray(emailCopyArray)}
            title={
              <>
                Email Template<span>*</span>
              </>
            }
          />
          <LabeledCardTemplateAndCopy
            visiblity={selectedChannels.indexOf("CT Marketing Card") !== -1 ? "true" : "false"}
            cardTitle="CT Marketing Card"
            defaultLabel={console.log("default")}
            defaultId={console.log("default")}
            defaultLabelCopy={console.log("default")}
            defaultIdCopy={console.log("default")}
            templatesArray={(CTMarketingTemplateArray) => setCTMarketingTemplateArray(CTMarketingTemplateArray)}
            copyArray={(CTCopyArray) => setCTCopyArray(CTCopyArray)}
            title={
              <>
                CT Marketing Card Template<span>*</span>
              </>
            }
          />
          <LabeledCardTemplateAndCopy
            visiblity={selectedChannels.indexOf("General Marketing Card") !== -1 ? "true" : "false"}
            cardTitle="General Marketing Card"
            defaultLabel={console.log("default")}
            defaultId={console.log("default")}
            defaultLabelCopy={console.log("default")}
            defaultIdCopy={console.log("default")}
            copyArray={(GMCopyArray) => setGMCopyArray(GMCopyArray)}
            templatesArray={(GeneralMarketingTemplateArray) => setGeneralMarketingTemplateArray(GeneralMarketingTemplateArray)}
            title={
              <>
                General Marketing Card Template<span>*</span>
              </>
            }
          />
        </div> */}
        </div>
        <div style={{ marginTop: "2rem" }}>
          <LabeledDiv
            className={styles.Approval}
            title={
              <>
                Approval<span>*</span>
              </>
            }
          >
            <div className={styles.CreativeApproval}>
              <label>
                Creative Approval<span>*</span>
              </label>
              <RadioButtonControl
                previousSelection={creativeApproval}
                onSelect={(value) => {
                  setCreativeApproval(value);
                }}
                options={[
                  {
                    title: "Yes",
                    value: true
                  },
                  { title: "No", value: false }
                ]}
              />
              <div className={creativeApproval.value === false ? styles.DisableCalendarFile : styles.EnableCalendarFile}>
                <DateSelector
                  className={styles.CalendarSelector}
                  onDiscard={console.log}
                  onApply={(date) => setCreativeApprovalDate(date)}
                  value={creativeApprovalDate}
                /></div>
            </div>

            <div className={styles.DivisionApproval}>
              <label>
                Division Approval<span>*</span>
              </label>
              <RadioButtonControl
                previousSelection={divisionApproval}
                onSelect={(value) => {
                  setDivisionApproval(value);
                }}
                options={[
                  {
                    title: "Yes",
                    value: true
                  },
                  { title: "No", value: false }
                ]}
              />
              <div className={divisionApproval.value === false ? styles.DisableCalendarFile : styles.EnableCalendarFile}>
                <DateSelector
                  className={styles.CalendarSelector}
                  onDiscard={console.log}
                  onApply={(date) => setDivisionApprovalDate(date)}
                  value={divisionApprovalDate}
                />
              </div>
            </div>

            <div className={styles.LegalApproval}>
              <label>
                Legal Approval<span>*</span>
              </label>

              <RadioButtonControl
                previousSelection={legalApproval}
                onSelect={(value) => {
                  setLegalApproval(value);
                }}
                options={[
                  {
                    title: "Yes",
                    value: true
                  },
                  { title: "No", value: false }
                ]}
              />
              <div className={legalApproval.value === false ? styles.DisableCalendarFile : styles.EnableCalendarFile}>
                <DateSelector
                  className={styles.CalendarSelector}
                  onDiscard={console.log}
                  onApply={(date) => setLegalApprovalDate(date)}
                  value={legalApprovalDate}
                /></div>
            </div>

            <div className={styles.ApprovalProof}>
              <div id={styles.innerGrid}>
                <div className={creativeApproval.value === false ? styles.DisableCalendarFile : ""}>
                  <LabeledFileUploadBar
                    title="(Optional) Upload approval proof"
                    newUpload="no"
                    uploadType="uploadApprovalProof"
                    resetFile=""
                    uploadedFile={(value) => { setCreativeApprovalFile(value) }}
                    onUploadClick={() => {
                      console.log("");
                    }}
                  />
                </div>
                <div>
                  {/*----------Delete File and Download for Creative Approval-------------*/}

                  <div className={styles.ParentContainer}>
                    <div className={styles.child1}>
                      {props.campaignId !== undefined && !deleteCreativeFlag && editCreativeFileName !== undefined ?
                        <div>
                          <label>
                            File uploaded
                            <br />
                            {props.campaignId !== undefined ? (
                              <a
                                //href='https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=assets/marketing-platform/emailTemplate/832314109865/images/test.zip'
                                className={styles.CampaignFileDownload}
                                onClick={() => downloadFile('creative')}
                                download
                              >
                                {editCreativeFileName}
                              </a>
                            ) : (
                              ""
                            )}
                          </label>
                        </div> : ""}
                    </div>

                    <div className={styles.child1}>
                      <div style={{ paddingTop: "3.3rem" }}>
                        <PopupDialog
                          height="30rem"
                          width="67rem"
                          renderPopupContent={(closeModal) => (
                            <SaveDialog
                              saveAction={async () => {
                                setDeleteCreativeFlag(true);
                                toast.success(
                                  <Msg_File id="delete" templateName={editCreativeFileName} />,
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true,
                                    autoClose: 3000,
                                    icon: <Success />,
                                    transition: Slide,
                                    closeOnClick: true
                                  }
                                );
                                closeModal();

                              }}
                              discardAction={closeModal}
                              className="DeleteCopyFile"
                              itemsToBeComplete={toBeCompleted}
                            />
                          )}
                          renderControlContent={(openModal) => (
                            props.campaignId !== undefined && !deleteCreativeFlag && editCreativeFileName !== undefined ?
                              <BackButton
                                onClick={openModal}
                                name="Delete Copy File" /> : <div></div>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {/*----------End Delete File and Download for Creative Approval-------------*/}

                </div>
              </div>
            </div>

            <div className={styles.DivisionApprovalUploads}>
              <div id={styles.innerGrid}>
                <div className={divisionApproval.value === false ? styles.DisableCalendarFile : ""}>
                  <LabeledFileUploadBar
                    title="(Optional) Upload approval proof"
                    newUpload="no"
                    resetFile=""
                    uploadType="uploadDivisionProof"
                    uploadedFile={(value) => { setDivisionApprovalFile(value) }}
                    onUploadClick={() => {
                      console.log("CLICKED");
                    }}
                  />
                </div>
                <div>
                  {/*----------Delete File and Download for Division Approval-------------*/}

                  <div className={styles.ParentContainer}>
                    <div className={styles.child1}>
                      {props.campaignId !== undefined && !deleteDivisionFlag && editDivisionFileName !== undefined ?
                        <div>
                          <label>
                            File uploaded
                            <br />
                            {props.campaignId !== undefined ? (
                              <a
                                //href='https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=assets/marketing-platform/emailTemplate/832314109865/images/test.zip'
                                className={styles.CampaignFileDownload}
                                onClick={() => downloadFile('division')}

                              >
                                {editDivisionFileName}
                              </a>
                            ) : (
                              ""
                            )}
                          </label>
                        </div> : ""}
                    </div>

                    <div className={styles.child1}>
                      <div style={{ paddingTop: "3.3rem" }}>
                        <PopupDialog
                          height="30rem"
                          width="67rem"
                          renderPopupContent={(closeModal) => (
                            <SaveDialog
                              saveAction={async () => {
                                setDeleteDivisionFlag(true);
                                toast.success(
                                  <Msg_File id="delete" templateName={editDivisionFileName} />,
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true,
                                    autoClose: 3000,
                                    icon: <Success />,
                                    transition: Slide,
                                    closeOnClick: true
                                  }
                                );
                                closeModal();

                              }}
                              discardAction={closeModal}
                              className="DeleteCopyFile"
                              itemsToBeComplete={toBeCompleted}
                            />
                          )}
                          renderControlContent={(openModal) => (
                            props.campaignId !== undefined && !deleteDivisionFlag && editDivisionFileName !== undefined ?
                              <BackButton
                                onClick={openModal}
                                name="Delete Copy File" /> : <div></div>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {/*----------End Delete File and Download for Division Approval-------------*/}

                </div>
              </div>
            </div>

            <div className={styles.LegalApprovalProof}>
              <div id={styles.innerGrid}>
                <div className={legalApproval.value === false ? styles.DisableCalendarFile : ""}>
                  <LabeledFileUploadBar
                    title="(Optional) Upload approval proof"
                    resetFile=""
                    newUpload="no"
                    uploadType="uploadLegalProof"
                    uploadedFile={(value) => setLegalApprovalFile(value)}
                    onUploadClick={() => {
                      console.log("CLICKED");
                    }}
                  />
                </div>
                <div>
                  {/*----------Delete File and Download for Legal Approval-------------*/}

                  <div className={styles.ParentContainer}>
                    <div className={styles.child1}>
                      {props.campaignId !== undefined && !deleteLegalFlag && editLegalFileName !== undefined ?
                        <div>
                          <label>
                            File uploaded
                            <br />
                            {props.campaignId !== undefined ? (
                              <a
                                //href='https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=assets/marketing-platform/emailTemplate/832314109865/images/test.zip'
                                className={styles.CampaignFileDownload}
                                onClick={() => downloadFile('legal')}

                              >
                                {editLegalFileName}
                              </a>
                            ) : (
                              ""
                            )}
                          </label>
                        </div> : ""}
                    </div>

                    <div className={styles.child1}>
                      <div style={{ paddingTop: "3.3rem" }}>
                        <PopupDialog
                          height="30rem"
                          width="67rem"
                          renderPopupContent={(closeModal) => (
                            <SaveDialog
                              saveAction={async () => {
                                setDeleteLegalFlag(true);
                                toast.success(
                                  <Msg_File id="delete" templateName={editLegalFileName} />,
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true,
                                    autoClose: 3000,
                                    icon: <Success />,
                                    transition: Slide,
                                    closeOnClick: true
                                  }
                                );
                                closeModal();

                              }}
                              discardAction={closeModal}
                              className="DeleteCopyFile"
                              itemsToBeComplete={toBeCompleted}
                            />
                          )}
                          renderControlContent={(openModal) => (
                            props.campaignId !== undefined && !deleteLegalFlag && editLegalFileName !== undefined ?
                              <BackButton
                                onClick={openModal}
                                name="Delete Copy File" /> : <div></div>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {/*----------End Delete File and Download for Legal Approval-------------*/}

                </div>
              </div>
            </div>
          </LabeledDiv>
        </div>
        <div className={props.campaignId !== undefined && !viewCampaign && status !== "Draft" ? styles.DisableCampaign : ""}>
          <div className={styles.CustomerListAndDeliveryRulesContainer}>
            <LabeledInput
              className=""
              data={customerList.length > 0 ? customerList[0].name : ""}
              // onValueChange={(value) => setCustomerList(value)}
              onValueChange={(value) => handleCustomerList(value)}
              title={
                <>
                  Customer List<span>*</span>
                </>
              }
            />
            {items.length > 0 ?
              items[0].name === "No Records" ? <p style={{ fontSize: "1.4rem" }}>No Records</p> :
                <table style={{ border: "1px solid black", width: "100%" }}>
                  {items && items.map((suggestion, i) =>
                    <tr>
                      <td key={i + "name"} className={styles.suggestion} onClick={() => onSuggestHandler(suggestion.name, suggestion.id)}>{suggestion.name}</td>
                    </tr>
                  )}
                </table> : ""}
            {/* <div className={styles.suggestion} onClick={() => onSuggestHandler(suggestion.email)}>{items ? items.map((x) => x.name) : ""}</div> */}
            {/* {items? console.log(items.map(x => x.name)): ""} */}
            {/* <label>
            Customer List<span>*</span>
              </label>
        <div style={{ width: "134rem" }}>
          <ReactSearchAutocomplete
            inputSearchString={customerList.length > 0 ? customerList[0].name : ""}
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            showIcon={false}
            styling={{height: "38px",boxShadow: "0 0rem 0rem rgba(0, 0, 0, 0.15)",border:"0.1rem solid var(--tme-grey-5-greys)",borderRadius:"0.4rem",fontFamily: "Toyota Type Regular",fontSize:"1.4rem",placeholderColor: "#8b8c90"}}
            autoFocus={false}
            formatResult={formatResult}
           
          />
          </div> */}
            {/* ---------Campaign Priority-------*/}
            <div>
              <LabeledSelect
                className={styles.Select}
                options={options_priority}
                title={
                  <>
                    Campaign Priority<span></span>
                  </>
                }
                defaultLabel={priority ? priority.label : "3"}
                defaultId={priority ? priority.value : "3"}
                onValueChange={(option) => {
                  setPriority(option);
                }}
              />
            </div>
            {/* ---------Add New Delivery Rules-------*/}
            <div>
              <CreateNewDeliveryRule
                newDeliveryRules={(value) => { setDeliveryRules(value) }}
                selectedCategory={category ? category.label === "AdHoc Campaigns" ? "AdHoc Campaigns" : "" : ""}
                title={
                  <>
                    + Add New Delivery Rule
                  </>}
                data={deliveryRules ?
                  typeof deliveryRules == "string" ? deliveryRules :
                    deliveryRules.length > 0 ?
                      JSON.stringify(deliveryRules[0].ruleName) ?
                        JSON.stringify(deliveryRules[0].ruleName).slice(1, -1) : "" : "" : ""}
              />
            </div>
          </div>
        </div>

        {viewCampaign ? null : props.campaignId ? (<div><div className={styles.SaveAndClose}>
          <PopupDialog
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={() => {
                  localStorage.setItem("blockNavigation", "false");
                  history.push("/ctmp/dashboard");
                }}
                noAction={closeModal}
              />
            )}
            renderControlContent={(openModal) => (
              <Button
                onClick={openModal}
                name="Discard"
              />
            )}
          /></div>
          {toBeCompleted.length === 0 ?
            <div style={{ float: "right" }}>
              <div style={{ paddingRight: "2rem" }} className={styles.SaveAndClose}>
                <Button
                  onClick={async () => {
                    let status = await saveCampaign(createCampaignObj);
                    if (status === 200) {
                      setIsLoaded(false);
                      localStorage.setItem("blockNavigation", "false")
                      status !== 200 &&
                        toast.error(
                          <ErrorMsg_File id="save" templateName={campaignName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                      status === 200 &&
                        toast.success(
                          <Msg_File id="save" templateName={campaignName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                    } else {
                      setIsLoaded(false);
                      localStorage.setItem("blockNavigation", "false")
                      history.push("/ctmp/dashboard");
                    }
                  }}
                  name="Save"
                />
              </div><div className={styles.SaveAndClose}>
                <Button
                  onClick={async () => {
                    let status = await saveCampaign(createCampaignObj);
                    if (status === 200) {
                      setIsLoaded(false);
                      localStorage.setItem("blockNavigation", "false")
                      history.push("/ctmp/dashboard");
                      status !== 200 &&
                        toast.error(
                          <ErrorMsg_File id="save" templateName={campaignName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                      status === 200 &&
                        toast.success(
                          <Msg_File id="save" templateName={campaignName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                    } else {
                      setIsLoaded(false);
                      localStorage.setItem("blockNavigation", "false")
                      history.push("/ctmp/dashboard");
                    }
                  }}
                  name="Save&CloseCampaign"
                />
              </div></div>
            :
            <div style={{ float: "right" }}>
              <SaveAndDiscardWithPopupButtonSet
                yesPopupHeight="55rem"
                noPopupHeight="30rem"
                yesTitle="Save & Close" //save
                noTitle="Save" //discard
                className={styles.Buttons}
                renderDiscardPopupContent={(closeModal) => (
                  <SaveDialog
                    saveAction={async () => {
                      if (createCampaignObj.adhocStartDate > createCampaignObj.adhocEndDate) {
                        toast.error(
                          <ErrorMsg />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        )
                      } else {
                        var status = await saveCampaign(createCampaignObj);
                        if (status === 200) {
                          setIsLoaded(false);
                          localStorage.setItem("blockNavigation", "false");
                          closeModal();
                        }
                        status !== 200 &&
                          toast.error(
                            <ErrorMsg_File id="save" templateName={campaignName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        status === 200 &&
                          toast.success(
                            <Msg_File id="save" templateName={campaignName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );

                      }
                    }}
                    discardAction={closeModal}
                    itemsToBeComplete={toBeCompleted}
                  />
                )}
                renderSavePopupContent={(closeModal) => (
                  <SaveDialog
                    saveAction={async () => {
                      if (createCampaignObj.adhocStartDate > createCampaignObj.adhocEndDate) {
                        toast.error(
                          <ErrorMsg />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        )
                      } else {
                        var status = await saveCampaign(createCampaignObj);
                        if (status === 200) {
                          setIsLoaded(false);
                          localStorage.setItem("blockNavigation", "false");
                          closeModal();
                          history.push("/ctmp/dashboard");
                          status !== 200 &&
                            toast.error(
                              <ErrorMsg_File id="save" templateName={campaignName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          status === 200 &&
                            toast.success(
                              <Msg_File id="save" templateName={campaignName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );

                        } else {
                          closeModal();
                          history.push("/ctmp/dashboard");
                        }
                      }
                    }}
                    discardAction={closeModal}
                    itemsToBeComplete={toBeCompleted}
                  />
                )}
              /></div>}</div>) : (toBeCompleted.length === 0 ? <div>
                <div className={styles.SaveAndClose}>
                  <PopupDialog
                    height="30rem"
                    width="67rem"
                    renderPopupContent={(closeModal) => (
                      <DiscardDialog
                        yesAction={() => {
                          localStorage.setItem("blockNavigation", "false");
                          history.push("/ctmp/dashboard");
                        }}
                        noAction={closeModal}
                      />
                    )}
                    renderControlContent={(openModal) => (
                      <Button
                        onClick={openModal}
                        name="Discard"
                      />
                    )}
                  /> </div><div className={styles.SaveCampaign}>
              
                   <PopupDialog
                    height="30rem"
                    width="67rem"
                    renderPopupContent={(closeModal) => (
                      <DiscardDialog
                      className="Duplicate Categories"
                      headerText="Duplicate Categories"
                        yesAction={async() => {
                          // localStorage.setItem("blockNavigation", "false");
                          // history.push("/ctmp/dashboard");
                        let status = await saveCampaign(createCampaignObj);
                        if (status === 200) {
                          setIsLoaded(false);
                          localStorage.setItem("blockNavigation", "false")
                          history.push("/ctmp/dashboard");
                          status !== 200 &&
                            toast.error(
                              <ErrorMsg_File id="save" templateName={campaignName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          status === 200 &&
                            toast.success(
                              <Msg_File id="save" templateName={campaignName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                        } else {
                          setIsLoaded(false);
                          localStorage.setItem("blockNavigation", "false")
                          history.push("/ctmp/dashboard");
                        }
                        }}
                        noAction={() => { closeModal(); }}
                      />
                    )}
                    renderControlContent={(openModal) => (
                      <Button
                      onClick={async () => {
                        let result = await checkDuplicateCategories();
                        if(result > 0){
                          console.log("morevalues")
                          openModal();
                        }else{
                          console.log("less values")
                        }
                      }}
                      name="SaveCampaign"
                    />
                    )}
                  /> 

                </div>
              </div>
                : <SaveAndDiscardWithPopupButtonSet
                  yesPopupHeight="55rem"
                  noPopupHeight="30rem"
                  yesTitle="Save"
                  noTitle="Discard"
                  className={styles.Buttons}
                  renderDiscardPopupContent={(closeModal) => (
                    <DiscardDialog
                      yesAction={() => {
                        history.push("/ctmp/dashboard");
                      }}
                      noAction={closeModal}
                    />
                  )}
                  renderSavePopupContent={(closeModal) => (
                    <SaveDialog
                      saveAction={async () => {
                        if (createCampaignObj.adhocStartDate > createCampaignObj.adhocEndDate) {
                          alert("Start date should be less than end date!")
                          toast.error(
                            <ErrorMsg />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          )
                        } else {
                          var status = await saveCampaign(createCampaignObj);
                          if (status === 200) {
                            setIsLoaded(false);
                            localStorage.setItem("blockNavigation", "false")
                            closeModal();
                            history.push("/ctmp/dashboard");
                            status !== 200 &&
                              toast.error(
                                <ErrorMsg_File id="save" templateName={campaignName} />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Error />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            status === 200 &&
                              toast.success(
                                <Msg_File id="save" templateName={campaignName} />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Success />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                          } else {
                            setIsLoaded(false);
                            localStorage.setItem("blockNavigation", "false")
                            closeModal();
                            history.push("/ctmp/dashboard");
                          }
                        }
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                />
        )}

      </PageContent>
    </Fragment>
  );
};

export default CreateCampaign;
