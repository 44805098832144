import {useMemo, useState, useEffect} from "react";
import Select from "react-select";

import styles from "./TableHead.module.css";
import {SelectStyle} from "../../Selector/Selector";
import TableHeadFilters, {
    Filter, FilterMetaData
} from "./TableHeadFilters/TableHeadFilters";

export type SortOption = {
    label: string,
    value: string
}

type TableHeadProps = {
    sortOptions: SortOption[]
    onSortChange: (value: string | undefined) => void
    onFiltersChange: (filters: Filter[]) => void
    appliedFilters: Filter[]
    filterMetaData: FilterMetaData[]
    resetFlag:string
}

const TableHead = ({
                       sortOptions,
                       onSortChange,
                       onFiltersChange,
                       appliedFilters,
                       filterMetaData,
                       resetFlag
                   }: TableHeadProps): JSX.Element => {
            const [defaultValueSort,setDefaultValueSort] = useState();
             useEffect(()=>{
                if(appliedFilters){
                     if(appliedFilters.length > 0){
                        setDefaultValueSort(null)
                     }
                    }
                    },[appliedFilters])


    const selectStylesApiObject = useMemo(() => {
        return {
            ...SelectStyle,
            control: provided => ({
                ...provided,
                border: ".1rem solid #CECFD0"
            })
        };
    }, []);

    return <div className={styles.TableHead}>
        <div className={styles.TableHeadLeft}>
            <div className={styles.SortByContainer}>
                <label className={styles.SortBy}>Sort By</label>
                <Select className={styles.SortBySelect}
                        styles={selectStylesApiObject}
                        isClearable
                        value={defaultValueSort}
                        options={sortOptions}
                        onChange={(sortByOption) => {
                            setDefaultValueSort(sortByOption);
                            onSortChange(
                                sortByOption ? sortByOption.value : undefined);
                        }}
                />
            </div>
            <TableHeadFilters onFiltersChange={onFiltersChange}
                              filters={filterMetaData}
                              resetFlag={resetFlag}
                              appliedFilters={appliedFilters}/>
        </div>
    </div>;

};

export default TableHead;