/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useAppSelector } from "../../../redux/store";
import styles from "./CreateContent.module.css";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledDescriptionInput from "../../General/LabeledDescription/LabeledDescriptionInput.tsx";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
// import MultiPicker from "../../General/MultiPicker/MultiPicker";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
// import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
// import { ReactComponent as MessageIcon } from "../../../assets/icons/Message-square.svg";
// import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
// import { ReactComponent as NewTag } from "../../../assets/icons/new-tag.svg";
// import { ReactComponent as Volume } from "../../../assets/icons/volume.svg";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import { useHistory } from "react-router-dom";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import Button from "../../General/Button/Button";
import PublishDialog from "../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";
import { Context } from "../../../context";
import { CapabilityConstants } from "../../Constants/CapabilitiyConstants";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";

import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";

const ContentCreativeBrief = (props) => {
  const history = useHistory();
  let contentNewId;
  let extractId = history.location.pathname.split("/");
  if (props.props !== undefined) {
    contentNewId = extractId[extractId.length - 2];
  } else {
    contentNewId = extractId[extractId.length - 1];
  }
  const { capability } = useContext(Context);
  const [capabilities, setCapabilities] = capability;
  const [objectiveField, setObjectiveField] = useState();
  const [measureSuccess, setMeasureSuccess] = useState();
  const [targetAudience, setTargetAudience] = useState();
  const [personalization, setPersonalization] = useState();
  const [editModeBrief, setEditModeBrief] = useState(false);
  const [creativeBrief, setCreativeBrief] = useState();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [channelCardsDisabled, setChannelCardsDisabled] = useState(true);
  const [zipFile, setZipFile] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [saveContentBriefObject, setSaveContentBriefObject] = useState({});
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const [newContentObject, setNewContentObject] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const PersonalizationValues = [
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    },
    {
      label: "4",
      value: "4"
    }
  ];


  /*get Values from database when user clicks edit in manageContent*/
  useEffect(() => {
    if (props.props !== undefined) {
      console.log("need api for id in brief page->" + props.props);
      const getContentDetails = async () => {
        setIsLoaded(true)
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        serverResponse.json().then((response) => {
          var res = response.data;
          if (res) {
            setObjectiveField(res.objective);
            setMeasureSuccess(res.measuresuccess);
            setTargetAudience(res.targetaudience);
            if (res.personalization.length > 0) {
              setPersonalization({
                label: res.personalization[0].name,
                value: res.personalization[0].id,
              })
            }
            setCreativeBrief(res.creativebrief);
            // res.channel.length > 0 ? setChannelCardsDisabled(true) : setChannelCardsDisabled(false);
            // res.channel.map((channel) => {
            //   setSelectedChannels(
            //     (selectedChannels) => [
            //       ...selectedChannels,
            //       channel.name
            //     ]
            //   );
            // });
            //setZipFile(res.uploadFile);
            let date = new Date(res.date);
            date = date.getMonth() + 1 + "/" + (date.getDate() + 1) + "/" + date.getFullYear();
            res.date = date;
            setNewContentObject(res);
          }
        });
        setIsLoaded(false)
      };
      getContentDetails();
      if (extractId[3] === "view-content") {
        setViewMode(true);
      } else {
        setViewMode(false);
      }
    }
  }, []);

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !objectiveField
      ? [...toBeCompletedArray, "Objective"]
      : toBeCompletedArray;
    toBeCompletedArray = !measureSuccess
      ? [...toBeCompletedArray, "Measure of Success"]
      : toBeCompletedArray;
    toBeCompletedArray = !targetAudience
      ? [...toBeCompletedArray, "Target Audience"]
      : toBeCompletedArray;
    toBeCompletedArray = !personalization
      ? [...toBeCompletedArray, "Level of Personalization"]
      : toBeCompletedArray;
    toBeCompletedArray = !creativeBrief
      ? [...toBeCompletedArray, "Creative Brief"]
      : toBeCompletedArray;

    if (!channelCardsDisabled) {
      toBeCompletedArray =
        !selectedChannels || !selectedChannels.length
          ? [...toBeCompletedArray, "Channel"]
          : toBeCompletedArray;
    }
    setToBeCompleted(toBeCompletedArray);
  }, [
    objectiveField,
    measureSuccess,
    targetAudience,
    personalization,
    creativeBrief,
    selectedChannels,
    channelCardsDisabled
  ]);

  /* update the values when changed */
  useEffect(() => {
    setSaveContentBriefObject({
      objective: objectiveField,
      measuresuccess: measureSuccess,
      targetaudience: targetAudience,
      creativebrief: creativeBrief,
      // ...(selectedChannels && {
      //   channel: selectedChannels.map((c) => ({
      //     name: c
      //   }))
      // }),
      ...(personalization && {
        personalization: [
          {
            name: personalization.label,
            id: personalization.value
          }
        ]
      }),
      uploadFile: "TestFile"
    });
  }, [
    objectiveField,
    measureSuccess,
    targetAudience,
    personalization,
    zipFile,
    creativeBrief
  ]);

  /* save funtion */
  const saveContentBriefData = async (saveContentBriefObject, action) => {
    setIsLoaded(true)
    console.log(JSON.stringify(newContentObject));
    const saveContentBriefObject_final = {
      ...newContentObject,
      ...saveContentBriefObject
    }
    console.log("--->" + JSON.stringify(saveContentBriefObject_final));
    let url, methodValue;
    editModeBrief === true
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/content/${contentNewId}`
      )),
        (methodValue = "PUT"))
      : ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/content/${contentNewId}`
      )),
        (methodValue = "PUT"));

    action === "save"
      ? (saveContentBriefObject_final.status = "Draft")
      : (saveContentBriefObject_final.status = "Live");

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(saveContentBriefObject_final)
    };
    console.log("----save content object in brief----" + JSON.stringify(saveContentBriefObject_final))
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status);
    return serverResponse.status;
  };

  return (
    <React.Fragment>
      <div>
        {(isLoaded === true) ? <LoadSpinner /> : ""}
        <p className={styles.UpdateFormHeader}>Provide Creative Brief</p>
        <span className={styles.UpdateFormSubTitle}>
          With a great brief comes great creative. Use the following sections to
          give as much information and context to help create the best creative
          solution.
        </span>
        <div className={viewMode === true ? styles.NodeDisplayDisable : ""}>
          <LabeledInput
            title={
              <>
                Objective<span>*</span>
              </>
            }
            placeholder={"What do you want this brief to achieve?"}
            data={objectiveField}
            className={styles.Input}
            style={{ "margin-top": "3rem" }}
            onValueChange={(value) => setObjectiveField(value)}
          />

          <LabeledDescriptionInput
            textAreaPlaceholder="Measure of success"
            data={measureSuccess}
            padding={false}
            title={
              <>
                Measure of Success<span>*</span>
              </>
            }
            onValueChange={(value) => setMeasureSuccess(value)}
          />

          <LabeledDescriptionInput
            textAreaPlaceholder="Provide a brief description of your target audience. Any supporting documents can be attached at the end of the form."
            data={targetAudience}
            padding={false}
            title={
              <>
                Target Audience<span>*</span>
              </>
            }
            onValueChange={(value) => setTargetAudience(value)}
          />

          <div className={styles.LabeledSelect}>
            <LabeledSelect
              // optionsUrl=""
              value={personalization}
              defaultLabel={
                personalization ? personalization.label : undefined
              }
              defaultId={
                personalization ? personalization.value : undefined
              }
              options={PersonalizationValues}
              title={
                <>
                  Level of Personalization<span>*</span>
                </>
              }
              onValueChange={(option) => setPersonalization(option)}
            />
          </div>
          <LabeledDescriptionInput
            textAreaPlaceholder="Let's get started..."
            data={creativeBrief}
            padding={false}
            title={
              <>
                Creative Brief<span>*</span>
              </>
            }
            onValueChange={(value) => setCreativeBrief(value)}
          />

          {/* <hr className={styles.HorizontalSeperator} />
        <div className={styles.CreativeToggle}>
          Duplicate this creative brief for re-use on other channels:
          <span>
            <BootstrapSwitchButton
              checked={channelCardsDisabled}
              onlabel="Yes"
              offlabel="No"
              width={60}
              onChange={(checked) => {
                setChannelCardsDisabled(!checked);
              }}
            />
          </span>
        </div>

        <div className={styles.ChannelContainer}>
          <label className={channelCardsDisabled ? styles.TextDisabled : null}>
            Duplicate for the following channels:
            <span
              style={
                channelCardsDisabled
                  ? { color: "var(--tme-grey-4-greys)" }
                  : null
              }
            >
              *
            </span>
            <span
              className={channelCardsDisabled ? styles.TextDisabled : null}
            ></span>
          </label>
          <MultiPicker
            disabled={channelCardsDisabled}
            setSelected={(selected) => setSelectedChannels(selected)}
            previousSelected={selectedChannels}
            status="Template"
            data={[
              {
                id: 1,
                icon: MailIcon,
                title: "Email",
                description:
                  "Choose a template and send automated transactional or marketing lite emails to the selected audience."
              },
              {
                icon: MessageIcon,
                title: "Push Notification",
                description:
                  "Choose a template and send automated transactional or marketing lite push notifications to the selected audience."
              },
              {
                icon: Phone,
                title: "CT Marketing Card",
                description:
                  "Choose a template and send automated transactional or marketing lite OneApp Cards to the selected audience."
              },
              {
                icon: NewTag,
                title: "General Marketing Card",
                description:
                  "Choose a template and send automated transactional or marketing lite cards to the selected audience."
              },
              {
                icon: Volume,
                title: "Announcement Center",
                description:
                  "Choose a template and send automated transactional or marketing lite announcements to the selected audience."
              }
            ]}
          />
        </div> */}

          {/* <hr className={styles.HorizontalSeperator} />

          <p className={styles.UpdateFormHeader}>File attachments</p>
          <span className={styles.UpdateFormSubTitle}>
            Add a Zip folder with any additional material, images or related
            documents that you think would be useful (Trust us, anything you have
            will be really useful when it comes to the creative bit).
          </span>
          <LabeledFileUploadBar
            title="Attachments"
            newUpload="no"
            uploadType="uploadContentBrief"
            // onUploadClick={checkZipFolder(["gif", "jpg", "PNG", "jpeg"])}
            uploadedFile={setZipFile}
          /> */}
        </div>
        <div>
          {viewMode === false ? (
            <div className={styles.floatcontainer}>
              <div className={styles.floatchild}>
                <PopupDialog
                  height="30rem"
                  width="67rem"
                  renderPopupContent={(closeModal) => (
                    <DiscardDialog
                      yesAction={() => {
                        history.push("/ctmp/content");
                      }}
                      noAction={closeModal}
                      className="Discard"
                    />
                  )}
                  renderControlContent={(openModal) => (
                    <Button onClick={openModal} name="Discard" />
                  )}
                />
              </div>
              <div className={styles.floatchild}>
                <div className={extractId[3] === "edit-content" ? styles.floatchild7 : styles.floatchild8}>
                  {/* save */}
                  {toBeCompleted.length === 0 ?
                    <Button
                      onClick={async () => {
                        let statusVar = await saveContentBriefData(saveContentBriefObject, "save");
                        statusVar !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" templateName="" />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        statusVar === 200 &&
                          toast.success(
                            <Msg id="save" templateName="" />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        setIsLoaded(false)
                        localStorage.setItem("blockNavigation", "false")
                      }}
                      name="ContentSave"
                    />
                    :
                    <PopupDialog
                      height="30rem"
                      width="67rem"
                      renderPopupContent={(closeModal) => (
                        <SaveDialog
                          saveAction={async () => {
                            let statusVar = await saveContentBriefData(saveContentBriefObject, "save");
                            statusVar !== 200 &&
                              toast.error(
                                <ErrorMsg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Error />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            statusVar === 200 &&
                              toast.success(
                                <Msg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Success />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            closeModal();
                            setIsLoaded(false)
                          }}
                          discardAction={closeModal}
                          itemsToBeComplete={toBeCompleted}
                        />
                      )}
                      renderControlContent={(openModal) => (
                        <Button onClick={openModal} name="ContentSave" />
                      )}
                    />}
                  {toBeCompleted.length === 0 ?
                    <div style={{ display: "flex" }}>
                      <Button
                        onClick={async () => {
                          let statusVar = await saveContentBriefData(
                            saveContentBriefObject,
                            "save"
                          );
                          statusVar !== 200 &&
                            toast.error(
                              <ErrorMsg id="save" templateName="" />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          statusVar === 200 &&
                            toast.success(
                              <Msg id="save" templateName="" />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          setIsLoaded(false)
                          history.push("/ctmp/content");
                        }}
                        name="ContentEditSaveAndClose"
                      />
                      <Button
                        onClick={async () => {
                          let statusVar = await saveContentBriefData(saveContentBriefObject);
                          statusVar !== 200 &&
                            toast.error(
                              <ErrorMsg id="save" templateName="" />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          statusVar === 200 &&
                            toast.success(
                              <Msg id="save" templateName="" />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          closeModal();
                          history.push("/ctmp/content");
                          setIsLoaded(false)
                        }}
                        name="ContentBriefEditPublish"
                      />
                    </div> :
                    <SaveAndDiscardWithPopupButtonSet
                      yesPopupHeight="55rem"
                      noPopupHeight="30rem"
                      yesTitle="Publish" //save
                      noTitle="Save & Close" //discard
                      className={styles.EditCreativeBrief}
                      renderDiscardPopupContent={(closeModal) => (
                        //save and close
                        <SaveDialog
                          saveAction={async () => {
                            let statusVar = await saveContentBriefData(
                              saveContentBriefObject,
                              "save"
                            );
                            statusVar !== 200 &&
                              toast.error(
                                <ErrorMsg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Error />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            statusVar === 200 &&
                              toast.success(
                                <Msg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Success />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            closeModal();
                            setIsLoaded(false)
                            history.push("/ctmp/content");
                          }}
                          discardAction={closeModal}
                          itemsToBeComplete={toBeCompleted}
                        />
                      )}
                      renderSavePopupContent={(closeModal) => (
                        <PublishDialog
                          yesAction={async () => {
                            let statusVar = await saveContentBriefData(saveContentBriefObject);
                            statusVar !== 200 &&
                              toast.error(
                                <ErrorMsg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Error />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            statusVar === 200 &&
                              toast.success(
                                <Msg id="save" templateName="" />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Success />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            closeModal();
                            history.push("/ctmp/content");
                            setIsLoaded(false)
                          }}
                          noAction={closeModal}
                          bodyText="Do you want to publish content?"
                          //put "Publish Message" when user has the right to publish from content brief.
                          headerText={capabilities.includes(CapabilityConstants.CONTENT_PUBLISH) ? "Publish Message" : "No_Permissions"}
                          itemsToBeComplete={toBeCompleted}
                        />
                      )}
                    />}
                </div>
              </div>
            </div>) : <div className={styles.floatchild5}> <Button
              onClick={() => {
                history.push("/ctmp/content");
              }}
              name="Close"
            /></div>}
        </div>
      </div>
    </React.Fragment >
  );
};
export default ContentCreativeBrief;
