import styles from "./TableHeadFilters.module.css";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import DateRangeSelector from "../../../PopupSelector/DateRangeSelector/DateRangeSelector";
import MultiSelector from "../../../MultiSelector/MultiSelector";
import { RangeWithKey } from "react-date-range";
import SaveAndDiscardButtonSet from "../../../SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";
import { ReactComponent as Align } from "../../../../../assets/icons/Align.svg";
import { ReactComponent as AlignWhite } from "../../../../../assets/icons/AlignWhite.svg";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";


export enum FilterControlType {
    MultiSelector,
    DateRange
}

export type FilterMetaData = {
    type: FilterControlType
    name: string
    optionsUrl?: string
    options?: any
    className?: string
    apiCallName?: string
}

type TableHeadFiltersProps = {
    className?: string
    onFiltersChange: (filters) => void
    filters: FilterMetaData[]
    appliedFilters: Filter[]
    resetFlag:string
}

export type LabeledValueFilterOption = {
    label: string
    value: string
}

export type Filter = {
    name: string
    apiCallName?: string
    value: [LabeledValueFilterOption | RangeWithKey];
}

export function isLabeledValueFilterOption(value: LabeledValueFilterOption | RangeWithKey): value is LabeledValueFilterOption {
    return (value as LabeledValueFilterOption).value !== undefined;
}

const TableHeadFilters = ({
    className,
    onFiltersChange,
    filters,
    resetFlag,
    appliedFilters
}: TableHeadFiltersProps) => {

    const [filterState, setFilterState] = useState<Filter[]>([]);

    const [showFilterModal, setShowFilterModal] = useState(false);

    useEffect(() => {
        appliedFilters && setFilterState(appliedFilters);
    }, [appliedFilters]);

    useEffect(()=>{
        if(resetFlag === "true"){
            setFilterState([])
        }
    },[resetFlag])

    return <div className={[className].join(" ")}>
        <button
            onClick={() => setShowFilterModal(true)}
            className={
                Object.keys(filterState).length === 0
                    ? styles.Filter
                    : styles.FilterSelected}
        >
            {Object.keys(filterState).length === 0 ? (
                <React.Fragment>
                    <Align />
                    <span onClick={() => setShowFilterModal(true)}
                        style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}>
                        Filter
                    </span>
                </React.Fragment>) : (
                <React.Fragment>
                    <AlignWhite />
                    <span onClick={() => setShowFilterModal(true)}
                        style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}>
                        Filter ({Object.keys(filterState).length})
                    </span>
                </React.Fragment>
            )}
        </button>
        <ReactModal
            shouldCloseOnOverlayClick={true}
            isOpen={showFilterModal}
            onRequestClose={() => setShowFilterModal(false)}
            className={styles.FilterModal}
            overlayClassName={styles.FilterModalOverlay}
        >
            <div className={styles.FilterTitleContainer}>
                <div className={styles.FilterTitleHeading}>
                    <p>
                        <span>Filters</span>
                    </p>
                    <CloseX
                        onClick={() => setShowFilterModal(false)}
                        className={styles.CloseIcon}
                    />
                </div>
            </div>
            <div className={styles.FiltersContainer}>
                {filters.map(f => {
                    switch (f.type) {
                        case FilterControlType.DateRange:
                            return <DateRangeSelector key={f.name}
                                className=""
                                onApply={(dateRange) => setFilterState(currentState => {
                                    return [
                                        ...currentState.filter(csf => csf.name !== f.name),
                                        ...(dateRange && [{
                                            name: f.name,
                                            apiCallName: f.apiCallName,
                                            value: [...dateRange]
                                        }]
                                        ) as any];
                                }
                                )
                                }
                                value={filterState.findIndex(fs => fs.name === f.name) > -1 ? filterState.filter(fs => fs.name === f.name)[0].value[0] : null}
                                onDiscard={(dateRange) => setFilterState(currentState => [
                                    ...currentState.filter(csf => csf.name !== f.name),
                                    ...([{
                                        name: f.name,
                                        apiCallName: f.apiCallName,
                                        value: []
                                    }]
                                    ) as any]
                                )
                                } />;
                        case FilterControlType.MultiSelector:
                            return <MultiSelector key={f.name}
                                className=""
                                name={f.name}
                                optionsUrl={f.optionsUrl}
                                options={f.options}
                                value={filterState.findIndex(fs => fs.name === f.name) > -1 ? filterState.filter(fs => fs.name === f.name)[0].value : null}
                                valuesChanged={(value) => setFilterState(currentState => {
                                    return [
                                        ...currentState.filter(csf => csf.name !== f.name),
                                        ...(value && [{
                                            name: f.name,
                                            apiCallName: f.apiCallName,
                                            value: value
                                        }]) as any
                                    ];
                                })} />;
                        default:
                            return null;
                    }
                })}
            </div>
            <SaveAndDiscardButtonSet
                yesAction={() => {
                    setShowFilterModal(false);
                    onFiltersChange(filterState);
                }}
                noAction={() => {
                    setShowFilterModal(false);
                    onFiltersChange({});
                    setFilterState([]);
                }} yesTitle="Apply" noTitle="Discard"
                className={styles.Buttons} />
        </ReactModal>
    </div>;
};

export default TableHeadFilters;