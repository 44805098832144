/* eslint-disable */
import React, { Fragment, useEffect, useState, useContext } from "react";
import styles from "./TitledTableHeaderWithSearchAndActionButton.module.css";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";
import Button from "../../../Button/Button";
import { Context } from "../../../../../context";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";

type TitledTableHeaderWithSearchAndActionButtonProps = {
    title: string
    actionButtonTitle: string
    onSearchChanged: (searchValue: string) => void
    onButtonClick: () => void
}

const TitledTableHeaderWithSearchAndActionButton = ({
    title,
    actionButtonTitle,
    onSearchChanged,
    onButtonClick
}: TitledTableHeaderWithSearchAndActionButtonProps) => {

    const [timeout, setTimeoutVal] = useState<NodeJS.Timeout>();
    const [searchText, setSearchText] = useState<string>();
    const { capability } = useContext(Context);
    const [capabilities, setCapabilities] = capability;
    useEffect(() => {
        timeout && clearTimeout(timeout);
        const newTimeOut = setTimeout(() => {
            onSearchChanged(searchText);
        }, 500)
        setTimeoutVal(newTimeOut);
    }, [onSearchChanged, searchText, setTimeoutVal]);


    return <Fragment>
        <div className={styles.ContainerLeft}>
            <h3>{title}</h3>
            <span className={styles.Separator} />
            <div className={styles.SearchBarContainer}>
                <SearchIcon className={styles.SearchIcon} />
                <input className={styles.Search} placeholder="Search Customer List"
                    type="text"
                    style={{ width: "400px" }}
                    onChange={(event => setSearchText(
                        event.target.value))} />
            </div>
        </div>
        {capabilities.includes(CapabilityConstants.CUSTOMERLIST_CREATE) ?
            <Button onClick={onButtonClick}
                name={actionButtonTitle} /> : ""}
    </Fragment>;
};

export default TitledTableHeaderWithSearchAndActionButton;