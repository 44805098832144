import { format } from "date-fns";

export const mapCopy = (serverResponse) => [...serverResponse.map(
    item => ({
        action: { status: item.status, contentId: item._id },
        copyDetails: {
            copyName: item.name,
            description: item.description,
            createdDate:
                item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy")
        },
        associatedTemplates: item.associatedCampaigns,
        creator: item.createdBy,
        status: item.status
    }))];