import React from "react";

import styles from "./RadioButton.module.css";
import {RadioButtonOption} from "../RadioButtonControl";

type RadioButtonProps = {
    option: RadioButtonOption
    selected: boolean
    onSelect: (selectedOption: RadioButtonOption) => void
}

const RadioButton = ({ option, selected, onSelect }: RadioButtonProps) => {
    return <div onClick={ () => onSelect(option) }
                className={ styles.Container }>
        <div className={ selected ? [styles.Button, styles.Selected].join(" ")
            : styles.Button }/>
        <span>{ option.title }</span>
    </div>;
};

export default RadioButton;