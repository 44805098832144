import React from "react";
import RowAction from "../General/Table/RowAction/RowAction";
import Status from "./ManageCustomerListTable/Row/Status/Status";
import CustomerListDetails from "../ManageCustomerList/TableCell/CustomerListDetails";
//import { useHistory } from "react-router-dom";
import RowActionMenu from "./ManageCustomerListTable/Row/RowActionMenu/RowActionMenu";
import styles from "./ManageCustomerList.module.css";

// const GetOptions = (action: any) => {
//     const history = useHistory();
//     console.log("action=>", action.status)
//     if (action.status === "" || action.status === undefined) {
//         return [];
//     }
//     switch (action.status.toLowerCase()) {
//         case "draft":
//             return [{
//                 key: "view",
//                 name: "View",
//                 onClick: () => history.push(`/ctmp/customer-list/create-customer/re-use/vehicle-details/${action.id}`)
//             },
//             {
//                 key: "edit",
//                 name: "Edit",
//                 onClick: () => history.push(`/ctmp/customer-list/create-customer/edit/vehicle-details/${action.id}`)
//             },
//             {
//                 key: "publish",
//                 name: "Publish",
//                 onClick: () => console.log("Publish Clicked")
//             },
//             {
//                 key: "delete",
//                 name: "Delete",
//                 onClick: () => console.log("Delete Clicked", action.id)
//             }];
//         case "published":
//             return [{
//                 key: "view",
//                 name: "View",
//                 onClick: () => console.log("View Clicked")
//             }, {
//                 key: "unpublish",
//                 name: "Unpublish",
//                 onClick: () => console.log("Unpublish Clicked")
//             }];
//         default:
//             return [];
//     }
// };

export const columns = [
    {
        // Header: "Action",
        // accessor: "action",
        // Cell: ({cell: {value}}): JSX.Element => (<RowAction
        //     options={
        //         GetOptions(value)
        //     }/>)

        Header: "Action",
        accessor: "action",
        Cell: ({ cell: { value } }) => <RowAction
            menu={<RowActionMenu action={value} id={value.id} name={value.name} />} />
    },
    {
        Header: "Customer List Details",
        accessor: "customerListDetails",
        Cell: ({ cell: { value } }): JSX.Element => (<CustomerListDetails listName={value.name} listDescription={value.description} createdDate={value.createdDate} />)
    },
    {
        Header: "Associated Campaigns",
        accessor: "associatedCampaigns",
        Cell: ({ cell: { value } }): JSX.Element => {
            if (value.length === 0 || value[0] === "") {
                return null
            } else {
                return value.map((data) => <li className={styles.AssociatedCampaigns} key={data.campaignName + data.campaignID}>{data.campaignName}</li>)
            }
        }

    },
    // {
    //     Header: "% of the VINS",
    //     accessor: "percentOfVINs",
    //     Cell: ({ cell: { value } }): JSX.Element => (<div>{value} %</div>)
    // },
    // {
    //     Header: "Number of VINS",
    //     accessor: "numberOfVINs"
    // },
    {
        Header: "Created by",
        accessor: "creator"
    },
    {
        Header: "Last Updated",
        accessor: "lastUpdated",
        Cell: ({ cell: { value } }): JSX.Element => (<span
            style={{
                fontSize: "1.2rem",
                lineHeight: "2.4rem",
                color: "var(--ct-blue-secondary)"
            }}>{value}</span>)
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => <Status value={value} />
    }
];