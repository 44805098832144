import { AlertConstants } from "../../Constants/AlertConstants";

export const Msg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.copyName);
  prop.id === "save"
    ? (text = AlertConstants.SAVE)
    : prop.id === "discard"
      ? (text = AlertConstants.DISCARD)
      : prop.id === "unpublish"
        ? (text = AlertConstants.UNPUBLISH)
        : prop.id === "delete"
          ? (text = AlertConstants.DELETE)
          : (text = AlertConstants.PUBLISH);
  return (
    <div>
      <div>SUCCESSFUL</div>
      <span>
        Copy {prop.copyName ? prop.copyName : ""} is {text} successfully
      </span>
    </div>
  );
};

export const ErrorMsg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.copyName);
  prop.id === "save"
    ? (text = AlertConstants.SAVE)
    : prop.id === "discard"
      ? (text = AlertConstants.DISCARD)
      : prop.id === "unpublish"
        ? (text = AlertConstants.UNPUBLISH)
        : prop.id === "delete"
          ? (text = AlertConstants.DELETE)
          : (text = AlertConstants.PUBLISH);
  return (
    <div>
      <div style={{ color: "#BC0000 !important" }}>ERROR</div>
      <span>
        Copy {prop.copyName ? prop.copyName : ""} is not {text} successfully
      </span>
    </div>
  );
};
