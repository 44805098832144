import React from "react";

import styles from "./VersionComment.module.css";
import Moment from 'react-moment';
/* eslint-disable  @typescript-eslint/no-unused-vars */

const VersionComment = (props) => {
  Moment.globalLocal = true
  return (
    <React.Fragment>
      <div className={styles.ItemHistory}>
        <div className={styles.TrailItem}>
          <span className={styles.Circle} />
        </div>
        <div className={styles.CommentContent}>
          Comment By:
          <div>
            {/* {props.commentBy} {props.commentDate} */}
            {props.commentBy} • <Moment format="LLLL" >{props.commentDate}</Moment>
          </div>
          <div>"{props.comment}"</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VersionComment;
