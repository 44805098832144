/* eslint-disable */
import React, { useMemo, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./RowActionMenu.module.css";
import { useAppSelector } from "../../../../../redux/store";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../CreateCustomer-2/CreateCustomerRight/DiscardCustomer/DiscardCustomer";
import { Context } from "../../../../../context";
import moment from "moment";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";

/* eslint-disable */
import {
     STATUS_APPROVED,
     STATUS_CREATIVE_APPROVED,
     STATUS_CREATIVE_PENDING,
     STATUS_CAMPAIGN_PENDING,
     STATUS_DELETED,
     STATUS_DRAFT,
     STATUS_EXPIRED,
     STATUS_LIVE,
     STATUS_REQUESTED,
     STATUS_TESTING,
     STATUS_PUBLISHED,
     STATUS_INUSE
} from "../Status/Status";
import { CustomerListConstants } from "../../../../Constants/CustomerListConstants";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id, name }) => {
     const history = useHistory();
     const jwt = useAppSelector(state => state.authReducer.jwt);
     let obj;
     const { user, value, capability } = useContext(Context);
     const [role, setRole] = user;
     const [context, setContext] = value;
     const [capabilities, setCapabilities] = capability;
     const [associatedCampaigns, setAssociatedCampaigns] = useState([]);

     useEffect(() => {
          const abortController = new AbortController();
          if (id !== undefined) {
               const getCustomerDetails = async () => {
                    const url = new URL(
                         `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`
                    );
                    const callParams = {
                         method: "GET",
                         headers: {
                              "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${jwt}`
                         }
                    };
                    const serverResponse = await fetch(url.toString(), callParams, { signal: abortController.signal });
                    serverResponse.json().then((response) => {
                         const res = response.customerlists[0];
                         obj = {
                              id: res.id,
                              description: res.shortDescription,
                              status: CustomerListConstants.DRAFT,
                              createdBy: res.createdBy,
                              name: res.name,
                              vehicleDetails: res.vehicleDetails,
                              subscriptionDetails: res.subscriptionDetails,
                              usageDetails: res.usageDetails,
                              userDetails: res.userDetails,
                              compileList: res.compileList,
                              customerListType: res.customerListType
                         };
                         setAssociatedCampaigns(res.associatedCampaigns);
                    });
               };
               getCustomerDetails();
          }
          return () => {
               abortController.abort();
          };
     }, [id]);

     const deleteCustomerList = async (customerlistId) => {
          const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${customerlistId}`);
          const callParams = {
               method: "DELETE",
               headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
               }
          };

          const serverResponse = await fetch(url.toString(), callParams);
          return serverResponse.status;
     }

     const publishCustomerList = async (customerlistId, actionItem) => {
          let toBePublishedObjectParsed = obj;
          actionItem === "publish"
               ? ((toBePublishedObjectParsed.status = CustomerListConstants.PUBLISH), toBePublishedObjectParsed.goLiveDate = moment().valueOf())
               : ((toBePublishedObjectParsed.status = CustomerListConstants.DRAFT), toBePublishedObjectParsed.goLiveDate = null);

          const url = new URL(
               `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${customerlistId}`
          );
          const callParams = {
               method: "PUT",
               headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`
               },
               body: JSON.stringify(toBePublishedObjectParsed)
          };
          const serverResponse = await fetch(url.toString(), callParams);
          return serverResponse.status;
     };

     //validate - is customerlist associated with campaign
     const checkAssociatedWithCampaign = () => {
          return (
               associatedCampaigns.length > 0 ?
                    (console.log("Associated with campaign"), true) :
                    (console.log("Not associated with campaign"), false)
          );
     }

     const actions = useMemo(() => {
          switch (action.status.toLowerCase()) {
               case STATUS_DRAFT:
               case STATUS_REQUESTED:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                        {
                                             console.log("action at onClick " + JSON.stringify(action));
                                             action.type === "vinList" ?
                                                  history.push(`/ctmp/customer-list/create-customer/view/vin-list/${action.id}/${action.status}`) :
                                                  history.push(`/ctmp/customer-list/create-customer/view/vehicle-details/${action.id}`)
                                        }
                                   }}>View
                              </div> : "",

                         <div key="edit" className={styles.MenuItem}
                              onClick={() => {
                                   {
                                        action.type === "vinList" ?
                                             history.push(`/ctmp/customer-list/create-customer/edit/vin-list/${action.id}/${action.status}`) :
                                             history.push(`/ctmp/customer-list/create-customer/edit/vehicle-details/${action.id}`)
                                   }
                              }}>Edit
                         </div>,
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_PUBLISH) ?
                              <PopupDialog
                                   key={"publish" + id}
                                   height="30rem"
                                   width="67rem"
                                   renderPopupContent={(closeModal) => (
                                        <DiscardDialog
                                             yesAction={async () => {
                                                  const statusVar = await publishCustomerList(id, "publish");

                                                  statusVar !== 200 &&
                                                       localStorage.setItem("forcePublish", "false");
                                                  statusVar === 200 &&
                                                       localStorage.setItem("forcePublish", "true");
                                                  setContext("publish");
                                             }}
                                             noAction={closeModal}
                                             className="Publish Customer List"
                                             name={name}
                                        />
                                   )}
                                   renderControlContent={(openModal) => (
                                        <div
                                             key="publish"
                                             onClick={openModal}
                                             className={styles.MenuItem}
                                        >
                                             Publish
                                        </div>
                                   )}
                              /> : "",

                         //delete should be visible only for SuperAdmin and if it is not associated with campaign
                         (!checkAssociatedWithCampaign()) && capabilities.includes(CapabilityConstants.CUSTOMERLIST_DELETE) ? (
                              <PopupDialog
                                   key={"delete" + id}
                                   height="30rem"
                                   width="67rem"
                                   renderPopupContent={(closeModal) => (
                                        <DiscardDialog
                                             yesAction={async () => {
                                                  const statusVar = await deleteCustomerList(id);
                                                  statusVar !== 200 &&
                                                       localStorage.setItem("forceDelete", "false");
                                                  statusVar === 200 &&
                                                       localStorage.setItem("forceDelete", "true");
                                                  setContext("delete")
                                             }}
                                             noAction={closeModal}
                                             className="Delete Customer List"
                                             name={name}
                                        />
                                   )}
                                   renderControlContent={(openModal) => (
                                        <div key="delete" onClick={openModal} className={styles.MenuItem}>
                                             Delete
                                        </div>
                                   )}
                              />) : ""
                    ];
               case STATUS_CREATIVE_PENDING:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                   }}>View</div> : "",
                         <div onClick={() => {
                         }}
                              key="cancel approval"
                              className={styles.MenuItem}>Cancel Approval
                         </div>,
                         <div key="preview"
                              className={styles.MenuItem}>Preview</div>,
                         <div key="version control"
                              className={styles.MenuItem}>Version
                              Control</div>,
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_DELETE) ?
                              <div key="delete" className={styles.MenuItem}>Delete</div> : ""
                    ];
               case STATUS_CAMPAIGN_PENDING:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                   }}>View</div> : "",
                         <div onClick={() => {
                         }}
                              key="cancel approval"
                              className={styles.MenuItem}>Cancel Approval
                         </div>,
                         <div key="preview"
                              className={styles.MenuItem}>Preview</div>,
                         <div key="version control"
                              className={styles.MenuItem}>Version
                              Control</div>,
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_DELETE) ?
                              <div key="delete" className={styles.MenuItem}>Delete</div> : ""
                    ];
               case STATUS_TESTING:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                   }}>View</div> : "",
                         <div key="preview"
                              className={styles.MenuItem}>Preview</div>,
                         <div onClick={() => {
                         }}
                              key="approve testing"
                              className={styles.MenuItem}>Approve Testing
                         </div>,
                         <div key="fail testing" className={styles.MenuItem}>Fail
                              Testing</div>
                    ];
               case STATUS_APPROVED:
               case STATUS_CREATIVE_APPROVED:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                   }}>View</div> : "",
                         <div key="preview"
                              className={styles.MenuItem}>Preview</div>,
                         <div onClick={() => {
                         }}
                              key="edit" className={styles.MenuItem}>Edit
                         </div>,
                         <div key="launch"
                              className={styles.MenuItem}>Launch</div>,
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_DELETE) ?
                              <div key="delete" className={styles.MenuItem}>Delete</div> : ""
                    ];
               case STATUS_LIVE:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                        history.push(`/ctmp/customer-list/create-customer/view/vehicle-details/${action.id}`)
                                   }}>View
                              </div> : "",
                         //Unpublish should be visible only if it is not associated with campaign
                         (!checkAssociatedWithCampaign()) ? (
                              <PopupDialog
                                   key={"unpublish" + id}
                                   height="30rem"
                                   width="67rem"
                                   renderPopupContent={(closeModal) => (
                                        <DiscardDialog
                                             yesAction={async () => {
                                                  const statusVar = await publishCustomerList(id, "unpublish");
                                                  statusVar !== 200 &&
                                                       localStorage.setItem("forceUnpublish", "false");
                                                  statusVar === 200 &&
                                                       localStorage.setItem("forceUnpublish", "true");
                                                  setContext("unpublish");
                                             }}
                                             noAction={closeModal}
                                             className="Unpublish Customer List"
                                             name={name}
                                        />
                                   )}
                                   renderControlContent={(openModal) => (
                                        <div
                                             key="unpublish"
                                             onClick={openModal}
                                             className={styles.MenuItem}
                                        >
                                             Unpublish
                                        </div>
                                   )}
                              />) : ""
                    ];
               case STATUS_INUSE:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                        console.log("action at onClick " + JSON.stringify(action));
                                        action.type === "vinList" ?
                                             history.push(`/ctmp/customer-list/create-customer/view/vin-list/${action.id}/${action.status}`) :
                                             history.push(`/ctmp/customer-list/create-customer/view/vehicle-details/${action.id}`)
                                   }}>View
                              </div> : ""
                    ];
               case STATUS_PUBLISHED:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                        action.type === "vinList" ?
                                             history.push(`/ctmp/customer-list/create-customer/view/vin-list/${action.id}/${action.status}`) :
                                             history.push(`/ctmp/customer-list/create-customer/view/vehicle-details/${action.id}`)
                                   }}>View
                              </div> : "",
                         //Unpublish should be visible only if it is not associated with campaign
                         (action.associatedCampaigns.length > 0) ? "" : (
                              <PopupDialog
                                   key={"unpublish" + id}
                                   height="30rem"
                                   width="67rem"
                                   renderPopupContent={(closeModal) => (
                                        <DiscardDialog
                                             yesAction={async () => {
                                                  const statusVar = await publishCustomerList(id, "unpublish");
                                                  statusVar !== 200 &&
                                                       localStorage.setItem("forceUnpublish", "false");
                                                  statusVar === 200 &&
                                                       localStorage.setItem("forceUnpublish", "true");
                                                  setContext("unpublish");
                                             }}
                                             noAction={closeModal}
                                             className="Unpublish Customer List"
                                             name={name}
                                        />
                                   )}
                                   renderControlContent={(openModal) => (
                                        <div
                                             key="unpublish"
                                             onClick={openModal}
                                             className={styles.MenuItem}
                                        >
                                             Unpublish
                                        </div>
                                   )}
                              />)
                    ];
               case STATUS_EXPIRED:
                    return [
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_VIEW) ?
                              <div key="view" className={styles.MenuItem}
                                   onClick={() => {
                                   }}>View</div> : "",
                         <div key="preview"
                              className={styles.MenuItem}>Preview</div>,
                         <div onClick={() => {
                         }}
                              key="edit" className={styles.MenuItem}>Edit
                         </div>,
                         capabilities.includes(CapabilityConstants.CUSTOMERLIST_DELETE) ?
                              <div key="delete" className={styles.MenuItem}>Delete</div> : ""
                    ];
               case STATUS_DELETED:
               default:
                    return [<div key="na" className={styles.MenuItem}>N/A</div>];
          }

     }, [action]);

     return <div className={styles.MenuContainer}>
          {actions}
     </div>;
};

export default RowActionMenu;