/* eslint-disable  */
import _ from "lodash";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { Column } from "react-table";
import { useAppSelector } from "../../../redux/store";
import { mapCustomer } from "../../../utils/object-mappers/map-customer";
import Table from "../Table/Table";
import TableFooter, { PAGE_SIZES } from "../Table/TableFooter/TableFooter";
import TableHead, { SortOption } from "../Table/TableHead/TableHead";
import {
    Filter,
    FilterMetaData, isLabeledValueFilterOption
} from "../Table/TableHead/TableHeadFilters/TableHeadFilters";
import styles from "./AppTable.module.css";
import Header from "./Header/Header";
import TitledTableHeaderWithSearchAndActionButton from "./Header/TitledTableHeaderWithSearchAndActionButton/TitledTableHeaderWithSearchAndActionButton";
import { Slide, toast } from "react-toastify";
import { Msg } from "../../ManageCustomerList/CreateCustomer-2/CreateCustomerRight/AlertComponent";
import { ErrorMsg } from "../../ManageCustomerList/CreateCustomer-2/CreateCustomerRight/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { Context } from "../../../context";
import { format } from "date-fns";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";

type AppTableProps<T, R> = {
    className?: string
    title: string
    actionButtonTitle: string
    onActionButtonClick: () => void
    columns: Column[]
    filterMetaData: FilterMetaData[]
    sortOptions?: SortOption[]
    mapResponseToData: (jsonResponse: T) => R
    getTotalItems: (jsonResponse: T) => number
}

type TableFilters = {
    freeSearch?: string
    filters?: Filter[]
}

interface AppTableRenderFunction {
    <T, R>(props: AppTableProps<T, R>): JSX.Element
}

const AppTable: AppTableRenderFunction = <T, R>({
    sortOptions,
    className,
    title,
    actionButtonTitle,
    onActionButtonClick,
    columns,
    mapResponseToData,
    getTotalItems,
    filterMetaData
}: AppTableProps<T, R>): JSX.Element => {
    const [search, setSearch] = useState<string>();

    const [dataCount, setDataCount] = useState(0);
    // const [data, setData] = useState<R>();
    const [data, setData] = useState([]);
    const [sortBy, setSortBy] = useState();

    const [filters, setFilters] = useState<TableFilters>({});

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
    const jwt = useAppSelector(state => state.authReducer.jwt);
    const { user, value } = useContext(Context);
    const [role, setRole] = user;
    const [context, setContext] = value;
    const [isLoaded, setIsLoaded] = useState(true);
    const [filterResetFlag,setFilterResetFlag] = useState("false");
    //const userName = useAppSelector((state) => state.authReducer.userName);
    // const email = useAppSelector((state) => state.authReducer.email);

    useEffect(() => {
        const abortController = new AbortController();
        (async () => {
            // let url = new URL(
            //     "https://mktpl.dev.telematicsct.com/v1/customerlist");
            let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`);
            let callParams: RequestInit = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                    // "user-info": JSON.stringify({ userName: userName, userEmail: email })
                }
            };
            if (!_.isEmpty(filters)) {
                // url = new URL(
                //     "https://mktpl.dev.telematicsct.com/v1/search");
                url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/search`);

                callParams = {
                    method: "POST",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${jwt}`
                        // "user-name": userName,
                        // "user-email": email
                    },
                    body: JSON.stringify(filters.freeSearch ? { ...filters } : {
                        ...filters.filters.map(
                            value => ({
                                [value.apiCallName]: value.value.map(
                                    val => isLabeledValueFilterOption(val) ? val.label : {
                                        //fromDate: val.startDate,
                                        //toDate: val.endDate
                                        fromDate: `${format(val.startDate, "MM/dd/yyyy")}`,
                                        toDate: `${format(val.endDate, "MM/dd/yyyy")}`
                                    }

                                )
                            })
                        ).reduce(((previousValue,
                            currentValue
                        ) => ({ ...previousValue, ...currentValue })), {})
                    })
                };
            } 
            // else {
                const params = {
                    page: "" + currentPage,
                    pagesize: "" + selectedPageSize,
                    ...(sortBy && { sortBy }) as { sortBy: string }
                };
                url.search = new URLSearchParams(params).toString();
            // }

            //send date range as below format (api not accepting date as like status and other filters)
            //'{"status":["Live"],"fromDate":"03/01/2022","toDate":"03/21/2022","createdBy":["Narendar Batthineni (TMS)"]}'
            //callParams.body coming as - '{"status":["Live"],"dateRange":[{"fromDate":"03/01/2022","toDate":"03/22/2022"}],"createdBy":["Suresh Rajarapu (TMS)"]}'
            if (callParams.body) {
                let inputObjStr: any = callParams.body;
                let inputObj = JSON.parse(inputObjStr);

                let dateRangeArray = inputObj.dateRange;
                //modify when user select date range
                if (dateRangeArray) {
                    let resultObj: any = {};

                    if (inputObj.status) {
                        resultObj.status = inputObj.status;
                    }

                    let dateRange = dateRangeArray[0];

                    console.log("---" + dateRange.fromDate);
                    console.log("---" + dateRange.toDate);

                    resultObj.fromDate = dateRange.fromDate;
                    resultObj.toDate = dateRange.toDate;

                    if (inputObj.createdBy) {
                        resultObj.createdBy = inputObj.createdBy;
                    }

                    console.log("result in string format:", JSON.stringify(resultObj));

                    callParams.body = JSON.stringify(resultObj);
                }
            }

            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            console.log("serverResponseJson", serverResponseJson);
            setData(mapCustomer(serverResponseJson.customerlist));
            setDataCount(serverResponseJson.totalCustomerList);
            console.log(serverResponseJson.customerlist);
            setIsLoaded(false)
            localStorage.getItem("forcePublish") === "true"
                ? toast.success(<Msg id="publish" templateName="" />, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Success />,
                    transition: Slide,
                    closeOnClick: true
                })
                : localStorage.getItem("forcePublish") === "false"
                    ? toast.error(<ErrorMsg id="publish" />, {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Error />,
                        transition: Slide,
                        closeOnClick: true
                    })
                    : localStorage.getItem("forceDelete") === "true"
                        ? toast.success(<Msg id="delete" templateName="" />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                        })
                        : localStorage.getItem("forceDelete") === "false"
                            ? toast.error(<ErrorMsg id="delete" />, {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                            })
                            : localStorage.getItem("forceUnpublish") === "false"
                                ? toast.error(<ErrorMsg id="unpublish" />, {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true,
                                    autoClose: 3000,
                                    icon: <Error />,
                                    transition: Slide,
                                    closeOnClick: true
                                })
                                : localStorage.getItem("forceUnpublish") === "true"
                                    ? toast.success(<Msg id="unpublish" templateName="" />, {
                                        position: toast.POSITION.TOP_CENTER,
                                        hideProgressBar: true,
                                        autoClose: 3000,
                                        icon: <Success />,
                                        transition: Slide,
                                        closeOnClick: true
                                    })
                                    : "";

            localStorage.removeItem("forcePublish");
            localStorage.removeItem("forceDelete");
            localStorage.removeItem("forceUnpublish");
            setContext();
        })();
        return () => {
            abortController.abort();
        };
    }, [selectedPageSize, currentPage, sortBy, filters, context, getTotalItems, mapResponseToData]);

    useEffect(() => {
        if (search) {
            setFilters({
                freeSearch: search
            });
        } else if (!_.isEmpty(filters) && filters.freeSearch) {
            setFilters({});
        }
    }, [search]);

    const goToPage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const changePageSizeTo = useCallback((newPageSize) => {
        setCurrentPage(1);
        setSelectedPageSize(newPageSize);
    }, []);

    const onSortChange = useCallback((newSortBy) => {
         setFilters({})
         setFilterResetFlag("true")
       setSortBy(newSortBy);
    },[]);

    const onFilterChange = useCallback((filters: Filter[]) => {
        setFilterResetFlag("false")
        //set filters based on selected/cleared filters.
        if (filters !== undefined && filters.length > 0) {
            let bDone = false;
            filters.forEach(element => {
                if (element.value.length > 0 && !bDone) {
                    setFilters(currentFilters => ({
                        ...currentFilters,
                        filters: filters
                    }));
                    bDone = true;
                } else if (element.value.length <= 0) {
                    filters.pop();
                }
            });
            if (!bDone) {
                setFilters({});
            }
        } else {
            setFilters({});
        }
    }, []);

    return <div className={[className, styles.Container].join(" ")}>

        <Header>
            <TitledTableHeaderWithSearchAndActionButton title={title}
                actionButtonTitle={actionButtonTitle}
                onSearchChanged={(searchValue => setSearch(searchValue))}
                onButtonClick={onActionButtonClick} />
        </Header>
        <div className={styles.TableBody}>
            <TableHead sortOptions={sortOptions}
                appliedFilters={filters.filters}
                resetFlag = {filterResetFlag}
                filterMetaData={filterMetaData}
                onFiltersChange={onFilterChange}
                onSortChange={onSortChange} />
                
        {(isLoaded === true) ? <LoadSpinner /> : <Table columns={columns} data={data} />}
           
            <TableFooter
                changePageSizeTo={changePageSizeTo}
                selectedPageSize={selectedPageSize}
                currentPage={currentPage}
                goToPage={goToPage}
                totalItems={dataCount} />
        </div>
    </div>;
};

export default AppTable;