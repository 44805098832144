/* eslint-disable */
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ManageCopyHeader.module.css";
import Button from "../../General/Button/Button";
import { Context } from "../../../context";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { CapabilityConstants } from "../../Constants/CapabilitiyConstants";

const ManageCopyHeader = ({ onSearch }) => {
    const history = useHistory();
    const [timeout, setTimeoutVal] = useState();
    const [searchText, setSearchText] = useState();
    const { capability } = useContext(Context);
    const [capabilities, setCapabilities] = capability;

    const navToCreateCopy = useCallback(() => {
        history.push(`${history.location.pathname}/create-copy`);
    }, [history]);

    useEffect(() => {
        timeout && clearTimeout(timeout);
        setTimeoutVal(
            setTimeout(() => {
                onSearch(searchText);
            }, 500)
        );
    }, [onSearch, searchText, setTimeoutVal]);

    return (
        <div className={styles.MngTmpltHdr}>
            <div className={styles.MngTmpltHdrLeft}>
                <h3>Manage Copy</h3>
                <span className={styles.Separator} />
                <div className={styles.SearchBarContainer}>
                    <SearchIcon className={styles.SearchIcon} />
                    <input
                        className={styles.Search}
                        placeholder="Search Copy"
                        type="text"
                        style={{ width: "400px" }}
                        onChange={(event) => setSearchText(event.target.value)}
                    />
                </div>
            </div>
            {/* {capabilities.includes(CapabilityConstants.COPY_CREATE) ?
            <Button onClick={navToCreateCopy} name="Create Copy" /> : ""} */}
            <Button onClick={navToCreateCopy} name="Create Copy" />
        </div>
    );
};

export default ManageCopyHeader;
