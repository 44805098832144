
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React, { useCallback, useEffect, useState } from "react";
import Select, { OptionTypeBase } from "react-select";
import AsyncSelect from "react-select/async";

import styles from "./LabeledSelect.module.css";

import { SelectStyle } from "../Selector/Selector";
import { useAppSelector } from "../../../redux/store";
import { StylesConfig } from "react-select/src/styles";

type SelectorProps = {
    className?: any,
    title?: any,
    options?: any[],
    optionsUrl?: any,
    placeholder?: any,
    onValueChange?: any,
    defaultLabel?: string
    defaultId?: number
};

const LabeledSelect = ({
    className,
    title,
    options,
    optionsUrl,
    placeholder,
    onValueChange,
    defaultLabel,
    defaultId
}: SelectorProps) => {
    const jwt = useAppSelector(state => state.authReducer.jwt);
    // const userName = useAppSelector((state) => state.authReducer.userName);
    // const email = useAppSelector((state) => state.authReducer.email);


    const selectStylesApiObject: StylesConfig<any, any> = React.useMemo(() => {
        return {
            ...SelectStyle,
            control: provided => ({
                ...provided,
                border: ".1rem solid #CECFD0"
            })
        };
    }, []);

    // const [selectedItem, setSelectedItem] = useState<OptionTypeBase>();

    const loadOptions = useCallback((inputVal, callback) => {
        optionsUrl && (async () => {
            let url,callParams,sortedResult;
            // const url = new URL(
            //     `https://mktpl.dev.telematicsct.com/${optionsUrl}`);
            if(optionsUrl === "v1/campaigns/search"){
                 url = new URL(`${process.env.REACT_APP_API_HOSTNAME}${optionsUrl}`);
                 callParams = {
                    method:"POST",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${jwt}`
                        // "user-name": userName,
                        // "user-email": email
                    },
                    body:JSON.stringify({status: ["Live"]})
                };
            }else{
             url = new URL(`${process.env.REACT_APP_API_HOSTNAME}${optionsUrl}`);
             callParams = {
                method:"GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                    // "user-name": userName,
                    // "user-email": email
                }
            };
         }
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            console.log(serverResponseJson.status)
            console.log("serverResponseJson ", serverResponseJson)
            if (serverResponseJson.status === "No Records Found!") {
                callback([]);
            } else {
                if (optionsUrl.includes("?roles=")) {
                    const user = serverResponseJson.user;
                    for (let i = 0; i < user.length; i++) {
                        const hr = user[i].hierarchy;
                        callback(hr.map(o => ({
                            value: o.id,
                            label: o.name
                        })))
                    }
                } else {
                   const sortResults = async () => {
                   if(optionsUrl === "v1/campaigns/search"){
                    sortedResult = serverResponseJson.campaigns.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    }else{
                    sortedResult = serverResponseJson.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    }
                    return sortedResult
                    }
                    sortResults().then(
                    callback(sortedResult.map(o => ({
                        value: o.id,
                        label: o.name
                    }))))
                }
            }
        })();
    }, [optionsUrl]);

    // useEffect(() => {
    //     console.log("Calling on change with:", selectedItem)
    //     onValueChange && onValueChange(selectedItem);
    // }, [selectedItem, onValueChange]);

    return <div className={[styles.Container, className].join(" ")}>
        <label>{title}</label>
        {optionsUrl ? <AsyncSelect className={styles.Select}
            value={defaultId && defaultLabel ? ({ value: defaultId, label: defaultLabel }) : undefined}
            defaultOptions
            placeholder={placeholder}
            cacheOptions
            styles={selectStylesApiObject}
            isClearable
            loadingMessage={(_inputVal) => "Loading Options..."}
            loadOptions={loadOptions}
            onChange={(option) => {
                console.log("setting value", option)
                //setSelectedItem(option);
                // setSelectedItem(option);
                onValueChange && onValueChange(option);
            }}
        />
            :
            <Select className={styles.Select}
                styles={selectStylesApiObject}
                value={defaultId && defaultLabel ? ({ value: defaultId, label: defaultLabel }) : undefined}
                defaultOptions
                isClearable
                cacheOptions
                placeholder={placeholder}
                options={options}
                onChange={(option) => {
                    // setSelectedItem(option);
                    //console.log("setting value", option)
                    onValueChange && onValueChange(option);

                }}
            />}
    </div>;
};

export default LabeledSelect;