import React from "react";
export const Msg_File = (prop) => {
    let text;
    prop.id === "save"
      ? (text = "saved")
      : prop.id === "discard"
      ? (text = "discarded")
      : prop.id === "unpublish"
      ? (text = "unpublished")
      : prop.id === "delete"
      ? (text = "deleted")
      : (text = "published");
    return (
      <div>
        <div>SUCCESSFUL</div>
        <span>
          {prop.templateName ? prop.templateName : ""} is {text} successfully
        </span>
      </div>
    )
};

export const ErrorMsg_File = (prop) => {
  
        let text;
        console.log(prop.id + "-------------------" + prop.templateName);
        prop.id === "save"
          ? (text = "saved")
          : prop.id === "discard"
          ? (text = "discarded")
          : prop.id === "unpublish"
          ? (text = "unpublished")
          : prop.id === "delete"
          ? (text = "deleted")
          : (text = "published");
        return (
          <div>
            <div style={{color:"#BC0000 !important" }}>ERROR</div>
            <span>
              {prop.templateName ? prop.templateName : ""} is not {text} successfully
            </span>
          </div>
        );
};
