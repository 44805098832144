/* eslint-disable */
import React from "react";
import styles from "../../../../src/App.css";
import { AlertConstants } from "../../Constants/AlertConstants";
export const Msg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = AlertConstants.SAVE)
    : prop.id === "discard"
    ? (text = AlertConstants.DISCARD)
    : prop.id === "unpublish"
    ? (text = AlertConstants.UNPUBLISH)
    : prop.id === "delete"
    ? (text = AlertConstants.DELETE)
    : (text = AlertConstants.PUBLISH);
  return (
    <div>
      <div>SUCCESSFUL</div>
      <span>
        Content {prop.templateName ? prop.templateName : ""} is {text} successfully
      </span>
    </div>
  );
};

export const ErrorMsg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = AlertConstants.SAVE)
    : prop.id === "discard"
    ? (text = AlertConstants.DISCARD)
    : prop.id === "unpublish"
    ? (text = AlertConstants.UNPUBLISH)
    : prop.id === "delete"
    ? (text = AlertConstants.DELETE)
    : (text = AlertConstants.PUBLISH);
  return (
    <div>
      <div style={{color:"#BC0000 !important" }}>ERROR</div>
      <span>
        Content {prop.templateName ? prop.templateName : ""} is not {text} successfully
      </span>
    </div>
  );
};
