import styles from "./SaveDialog.module.css";
//import { ReactComponent as Check } from "../../../../../assets/icons/Check.svg";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";
// import LabeledDescriptionInput from "../../../../General/LabeledDescription/LabeledDescriptionInput";
import SaveAndDiscardButtonSet from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";

type SaveDialogProps = {
  className?: string;
  itemsToBeComplete: string[];
  discardAction: () => void;
  saveAction: () => void;
  noNote?: boolean;
};

const SaveDialog = ({
  className,
  itemsToBeComplete,
  discardAction,
  saveAction,
  noNote
}: SaveDialogProps) => {
  return (
    <div className={[className, styles.Container].join(" ")}>
      <div className={styles.ContentContainer}>
        <div className={styles.Header}>
          <p>
            {(className === undefined || className === "") ?
              <span>Save as Draft</span>
              :
              <span>{className}</span>}{/* <Check/> */}
          </p>
          <CloseX className={styles.CloseIcon} onClick={discardAction} />
        </div>
        <div className={styles.Body}>
          {itemsToBeComplete.length === 0 ? <p>Do you want to save the changes?</p> : <p>Required Fields To Be Entered</p>}
          {/* <p>Required Fields To Be Entered</p> */}
          <ul>
            {itemsToBeComplete.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>

      </div>
      <SaveAndDiscardButtonSet
        yesAction={saveAction}
        noAction={discardAction}
        noTitle="Discard"
        yesTitle="Save"
        status={itemsToBeComplete.length}
        className={styles.Buttons}
      />
    </div>
  );
};

export default SaveDialog;
