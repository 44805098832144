/* eslint-disable */
import { connect } from "react-redux";
import Modal from "react-modal";
import "./App.css";
import React, {useState, useEffect} from "react"
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import { JWT_RECEIVED } from "./redux/action-types";
import { Context } from "./context";
import { ToastContainer } from "react-toastify";
import { LOGOUT } from "./redux/action-types";
import IdleTimer from "./components/General/Timer/IdleTimer";
import { RoleConstants } from "./components/Constants/RoleConstants";
import { useAppSelector } from "./redux/store";
import { useDispatch } from "react-redux";
/* eslint-disable */import { react } from "@babel/types";

Modal.setAppElement("#root");
 
const App = ({authAccount,logout,dispatchJwtReceived}) => {
    const [context, setContext] = useState();
    const [isTimeout, setIsTimeout] = useState(false);
    const [role,setRole] = useState(RoleConstants.AUTHOR);
    const [capabilities,setCapabilities] = useState([]);
    const dispatch = useDispatch();
    //const email = useAppSelector((state) => state.authReducer.email);
  
    dispatchJwtReceived()

    useEffect(() => {
       const timer = new IdleTimer({
          timeout: 900, //expire after 15 minutes
          onTimeout: () => {
            console.log("ontimeout")
            setIsTimeout(true);
            dispatch({ type: LOGOUT });
            logout();
          },
          onExpired: () => {
            console.log("on expired")
            // do something if expired on load
            setIsTimeout(true);
          },
         
        });
        return () => {
          timer.cleanUp();
        };
      }, []);
    
    return (
      <div className="App">
      <Context.Provider value={{value:[context, setContext], user:[role,setRole], capability:[capabilities,setCapabilities]}}>
      <ToastContainer
                style={{ width: "146rem", borderRadius: "8px" }}
              />
            <Header authAccount={authAccount}/>
            <Content logout={logout}/>
        </Context.Provider>
        </div>
    );
};

const jwtReceived = (jwt) => ({ type: JWT_RECEIVED, value: jwt})

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchJwtReceived: () => dispatch(jwtReceived(ownProps.authAccount ? ownProps.authAccount.jwtIdToken : null))
})

export default connect(null, mapDispatchToProps)(App);