/* eslint-disable*/
import { useHistory } from "react-router";
import React, { useState } from "react";
import styles from "./AddUser.module.css";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AddTeam from "./AddTeam";
import EditTeam from "./EditTeam";

const ManageTeam = ({ props }) => {
  const history = useHistory();
  let { url } = useRouteMatch();
  const pathName = history.location.pathname.split("/");
  console.log(pathName + "-----" + props);
  console.log(url);
  const [subNavSelected, setSubNavSelected] = useState("addTeam");

  return (
    <React.Fragment>
    <div className={styles.RoleTabs}>
        <div
          className={
            pathName[5] === "addTeam" ? styles.RoleItemSelected : styles.RoleItem
          }
          onClick={() => {
            setSubNavSelected("addTeam");
            history.push(`/ctmp/user-management/addUser/manageTeams/${props}`);
          }}
        >
          Add New Team
        </div>

        <div
          className={
            pathName[5] === "editTeam"
              ? styles.RoleItemSelected
              : styles.RoleItem
          }
          onClick={() => {
            setSubNavSelected("editTeam");
            history.push(`/ctmp/user-management/addUser/manageTeams/editTeam`);
          }}
        >
          Edit Team
        </div>
      </div>

      <Switch>
      <Route exact path={`${url}`}>
          <Redirect to={`${url}/addTeam`} />
        </Route>
        <Route path={`${url}/addTeam`}>
          <AddTeam />
        </Route>
        <Route path={`${url}/editTeam`}>
          <EditTeam/>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ManageTeam;
