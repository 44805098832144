/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback, useContext } from "react";
import { useAppSelector } from "../../../../redux/store";
import styles from "./CreateCustomerRight.module.css";
import LabeledInput from "../../../General/LabeledInput/LabeledInput";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory, Route, Switch } from "react-router-dom";
import RuleForm from "../RuleForm/RuleForm";
import SaveAndDiscardWithPopupButtonSet from "../../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "../../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import Button from "../../../General/Button/Button";
import PopupDialog from "../../../General/PopupDialog/PopupDialog";
import PublishDialog from "../../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";
import moment from "moment";
import { Context } from "../../../../context";
import { CapabilityConstants } from "../../../Constants/CapabilitiyConstants";
import { CustomerListConstants } from "../../../Constants/CustomerListConstants";
import LoadSpinner from "../../../General/LoadSpinner/LoadSpinner";

import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";

const CreateList = ({ navSelected, itemsEntered }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [subNavSelected, setSubNavSelected] = useState("vehicle-details");
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [usageDetails, setUsageDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [compileList, setCompileList] = useState([]);
  const [customerList, setCustomerList] = useState({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [percentageCount, setPercentageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const { capability } = useContext(Context);
  const [capabilities, setCapabilities] = capability;
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    setCustomerList({
      name: name,
      description: description,
      createdBy: userName,
      vehicleDetails: vehicleDetails,
      subscriptionDetails: subscriptionDetails,
      usageDetails: usageDetails,
      userDetails: userDetails,
      compileList: compileList,
      status: "Draft"
    });
  }, [
    name,
    description,
    vehicleDetails,
    subscriptionDetails,
    usageDetails,
    userDetails,
    compileList
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    localStorage.removeItem('saveId');
    (async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/count/1000`
        /* `http://localhost:7100/v1/customerlist/count/5` //test locally */
      );
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      //Calculate percentage of customers
      //% of customers = (Final user list generated by the Compile List /Total customers) X 100
      setPercentageCount(Math.round((serverResponseJson.compiledCount / serverResponseJson.totalCustomerCount) * 100));
      //Show customerlist on the screen based on compiled count
      setTotalCount(serverResponseJson.compiledCount);
    })();
    return () => {
      abortController.abort();
    };
  }, []);

 
  const saveCustomerList = useCallback(async (customerList) => {
    setIsLoaded(true)
    let url,methodValue;
    customerList.status = "Draft";
    if(localStorage.getItem("saveId") !== null){
       url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${localStorage.getItem("saveId")}`)
       methodValue = "PUT"
    }else{
        url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`)
        methodValue = "POST"
    }
      let callParams = {
        method: methodValue,
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify(customerList)
      };
  
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    return serverResponseJson;
  }, []);

  const PublishCustomerList = async (customerList) => {
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`)
    customerList.status = CustomerListConstants.PUBLISH;
    customerList.goLiveDate = moment().valueOf();
    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status)
  };

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !name
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !description
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !(vehicleDetails.length > 0) &&
        !(subscriptionDetails.length > 0) &&
        !(usageDetails.length > 0) &&
        !(userDetails.length > 0) &&
        !(compileList.length > 0)
        ? [...toBeCompletedArray, "Rules"]
        : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    name,
    description,
    vehicleDetails,
    subscriptionDetails,
    usageDetails,
    userDetails,
    compileList
  ]);

  useEffect(() => {
    itemsEntered(toBeCompleted.length, "create")
  }, [toBeCompleted]);

  return (
    <React.Fragment>
      <div className={styles.SubHeading}>Create New List</div>
      <div className={styles.HeadingDescription}>
        Create a brand new list with specific rules and details.
      </div>
      <div className={styles.InputContainer}>
      {(isLoaded === true) ? <LoadSpinner /> : ""}
        <div className={styles.InputFields}>
          {/* CREATE NEW LIST */}
          <React.Fragment>
            <LabeledInput
              title={
                <>
                  Name<span>*</span>
                </>
              }
              className={styles.CreateCustomerCombo}
              onValueChange={(value) => setName(value)}
            />
            <LabeledInput
              title={
                <>
                  Description<span>*</span>
                </>
              }
              className={styles.CreateCustomerCombo}
              onValueChange={(value) => setDescription(value)}
            />
          </React.Fragment>
        </div>
        {/* <div className={styles.Chart}>
          Customer Count
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={percentageCount}
                text={`${percentageCount}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#0A9F98"
                })}
              />
            </div>
            <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div>
          </div>
              </div> */}
      </div>

      {/* Subnav with rule builder */}
      <div className={styles.TableNav}>
        <div
          className={
            pathName[5] === "vehicle-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("vehicle-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`
            );
          }}
        >
          Vehicle Details
        </div>
        <div
          className={
            pathName[5] === "subscription-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("subscription-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/subscription-details`
            );
          }}
        >
          Subscription Details
        </div>
        <div
          className={
            pathName[5] === "usage-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("usage-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/usage-details`
            );
          }}
        >
          Usage Details
        </div>
        <div
          className={
            pathName[5] === "user-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("user-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/user-details`
            );
          }}
        >
          User Details
        </div>
        <div
          className={
            pathName[5] === "compile-list"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("compile-list");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/compile-list`
            );
          }}
        >
          Compile List
        </div>
      </div>

      <Switch>
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={vehicleDetails}
                setNodes={setVehicleDetails}
                endpoint={"vehicle-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/subscription-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={subscriptionDetails}
                setNodes={setSubscriptionDetails}
                endpoint={"subscription-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/usage-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={usageDetails}
                setNodes={setUsageDetails}
                endpoint={"usage-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/user-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={userDetails}
                setNodes={setUserDetails}
                endpoint={"user-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/compile-list`}
          render={() => (
            <React.Fragment>
              <RuleForm
                /* nodes={compileList} */
                nodes={[...vehicleDetails, ...subscriptionDetails, ...usageDetails, ...userDetails]}
                setNodes={setCompileList}
                endpoint={"compile-list"}
                subNavSelected={subNavSelected}
                showRC={false}
              />
            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );

  function SaveAndDiscardListButtons() {
    return <div className={styles.floatcontainer}>
      {/* Discard */}
      <div className={styles.floatchild}>
        <PopupDialog
          height="30rem"
          width="67rem"
          renderPopupContent={(closeModal) => (
            <DiscardDialog
              yesAction={() => {
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              className="Discard"
            />
          )}
          renderControlContent={(openModal) => (
            <Button
              onClick={openModal}
              name="Discard"
            />
          )}
        />
      </div>
      {/* save */}
      <div className={styles.floatchild1}>
        {toBeCompleted.length === 0 ?
          <div>
            <Button
              onClick={async () => {
                var status = await saveCustomerList(customerList);
                localStorage.setItem("saveId",status.data._id)
                setIsLoaded(false)
                status.status !== "Rule Inserted Successfully" &&
                toast.error(
                  <ErrorMsg
                    id="save"
                    templateName={name}
                  />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Error />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
                status.status === "Rule Inserted Successfully" &&
                toast.success(
                  <Msg id="save" templateName={name} />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Success />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
              }}
              name="Save"
            />
          </div> :
          <PopupDialog
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <SaveDialog
                saveAction={async () => {
                  var status = await saveCustomerList(customerList);
                  status === "Rule Inserted Successfully" ? console.log("saved") : console.log("failed")
                  closeModal();
                  setIsLoaded(false)
                  status.status !== "Rule Inserted Successfully" &&
                toast.error(
                  <ErrorMsg
                    id="save"
                    templateName={name}
                  />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Error />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
                status.status === "Rule Update Successfully" &&
                toast.success(
                  <Msg id="save" templateName={name} />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Success />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
                }}
                discardAction={closeModal}
                itemsToBeComplete={toBeCompleted} />
            )}
            renderControlContent={(openModal) => (
              <Button
                onClick={openModal}
                name="Save"
              />
            )}
          />}
      </div>
      {/* save & close */}
      <div className={styles.saveclose} >
        {toBeCompleted.length === 0 ?
          <div>
            <Button
              onClick={async () => {
                let status = await saveCustomerList(customerList);
                history.push("/ctmp/customer-list");
                setIsLoaded(false)
                status.status !== "Rule Inserted Successfully" &&
                toast.error(
                  <ErrorMsg
                    id="save"
                    templateName={name}
                  />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Error />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
                status.status === "Rule Inserted Successfully" &&
                toast.success(
                  <Msg id="save" templateName={name} />,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                    icon: <Success />,
                    transition: Slide,
                    closeOnClick: true
                  }
                );
              }}
              name="Save & Close"
            />
          </div> :
          <PopupDialog
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <SaveDialog
                saveAction={async () => {
                  let status = await saveCustomerList(customerList);
                  history.push("/ctmp/customer-list");
                  closeModal();
                  setIsLoaded(false)
                  status.status !== "Rule Inserted Successfully" &&
                  toast.error(
                    <ErrorMsg
                      id="save"
                      templateName={name}
                    />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Error />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                  status.status === "Rule Inserted Successfully" &&
                  toast.success(
                    <Msg id="save" templateName={name} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                }}
                discardAction={closeModal}
                itemsToBeComplete={toBeCompleted} />
            )}
            renderControlContent={(openModal) => (
              <Button
                onClick={openModal}
                name="Save & Close"
              />
            )}
          />}
      </div>
      {/* publish */}
      <div className={styles.publish}>
        <PopupDialog
          height="30rem"
          width="67rem"
          renderPopupContent={(closeModal) => (
            <PublishDialog
              yesAction={async () => {
                await PublishCustomerList(customerList);
                closeModal();
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              bodyText="Do you want to Publish Customer List?"
              headerText={capabilities.includes(CapabilityConstants.CUSTOMERLIST_PUBLISH) ? "Publish Message" : "No_Permissions"}
              itemsToBeComplete={toBeCompleted} />
          )}
          renderControlContent={(openModal) => (
            <Button
              onClick={openModal}
              name="Publish"
            />
          )}
        />
      </div>

    </div >;
  }

};

export default CreateList;
