/* eslint-disable */
import React, { useEffect, useState, useCallback,useContext } from "react";
import _ from "lodash";
import ManageUserTableHead
    from "./ManageUserTableHead/ManageUserTableHead";
import { columns } from "./manage-user-table-structure";
import styles from "./ManageUserTable.module.css";
import Table from "../../General/Table/Table";
import TableFooter, { PAGE_SIZES } from "../../General/Table/TableFooter/TableFooter";
import { mapUsers } from "../../../utils/object-mappers/manage-users/map-users";
import { useAppSelector } from "../../../redux/store";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";

import { Slide, toast } from "react-toastify";
import { Msg } from "../AddUser/AlertComponent";
import { ErrorMsg } from "../AddUser/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { Context } from "../../../context";
import { RoleConstants } from "../../Constants/RoleConstants";

const ManageUserTable = ({ search }) => {
    let jwt = useAppSelector(state => state.authReducer.jwt);
    const email = useAppSelector((state) => state.authReducer.email);
    const [totalUsers, setTotalUsers] = useState(0);
    const [Users, setUsers] = useState([]);
    const [sortBy, setSortBy] = useState({});

    const [filters, setFilters] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
    const { user, value } = useContext(Context);
    const [role,setRole] = user;
    const [context, setContext] = value;

    useEffect(() => {
        (async () => {
            let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user`);
            let callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                    // "user-info": JSON.stringify({ userName: userName, userEmail: email })

                }
            };
            if (!_.isEmpty(filters)) {
               url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/search`);

                callParams = {
                    method: "POST",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${jwt}`
                        // "user-info": JSON.stringify({ userName: userName, userEmail: email })

                    },
                    body: JSON.stringify(
                        filters.freeSearch
                          ? { ...filters }
                          : {
                              ...Object.keys(filters)
                                .map((value) =>
                                  value === "teams" ||
                                  value === "roles" 
                                ? ({
                                    [value]: filters[value].map((val) => val.label)
                                   })
                                    : ({ [value]: filters[value] })
                                )
                                .reduce(
                                  (previousValue, currentValue) => ({
                                    ...previousValue,
                                    ...currentValue
                                  }),
                                  {}
                                )
                            }
                      )
                };
            } else {
                const params = {
                    hierarchy:role === RoleConstants.ADMIN ? email : "",
                    page: currentPage,
                    pagesize: selectedPageSize
                    // ...(sortBy && { sortBy })
                };
                url.search = new URLSearchParams(params).toString();
            }
         
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            setTotalUsers(serverResponseJson.totalUsers);
            setUsers(mapUsers(serverResponseJson.user));
            setIsLoaded(false);
            localStorage.getItem("userDelete") === "false"
            ? toast.error(<ErrorMsg id="delete" />, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                icon: <Error />,
                transition: Slide,
                closeOnClick: true
                })
            : localStorage.getItem("userDelete") === "true" ? 
                toast.success(<Msg id="delete" templateName="" />, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                icon: <Success />,
                transition: Slide,
                closeOnClick: true
                }) : ""
                localStorage.removeItem("userDelete");
                setContext();
        })();

    }, [selectedPageSize, currentPage, sortBy, filters,context]);

    useEffect(() => {

        if (search) {
            setFilters({
                freeSearch: search
            });
        } else if (!_.isEmpty(filters) && filters.freeSearch) {
            setFilters({});
        }
    }, [search]);

    const goToPage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const changePageSizeTo = useCallback((newPageSize) => {
        setCurrentPage(1);
        setSelectedPageSize(newPageSize);
    }, []);

    const onSortChange = useCallback((newSortBy) => {
        setSortBy(newSortBy);
    }, []);

    const onFilterChange = useCallback((filters) => {
        setFilters(filters);
    }, []);

    return <div className={styles.ManageUserModule}>
        <ManageUserTableHead
            onSortChange={onSortChange}
            onFiltersChange={onFilterChange}
            filters={filters}
        />
        {(isLoaded === true) ? <LoadSpinner/> : <Table columns={columns} data={Users} />}
        
        <TableFooter
            changePageSizeTo={changePageSizeTo}
            selectedPageSize={selectedPageSize}
            currentPage={currentPage}
            goToPage={goToPage}
            totalItems={totalUsers} />
    </div>;
};

export default ManageUserTable;