/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import styles from "./ManageReport.module.css";
import { Route, Switch } from "react-router-dom";
import ViewReport from "./AddReport/ViewReport";

const ManageReport = ({ match }) => {
 return (
    <div className={styles.ManageUser}>
      <Switch>
      <Route path={match.url} component={ViewReport} />
       </Switch>
    </div>
  );
};
export default ManageReport;
