/* eslint-disable */
import React from "react";
import styles from "../../../../src/App.css";
export const Msg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = "saved")
    : prop.id === "discard"
    ? (text = "discarded")
    : prop.id === "unpublish"
    ? (text = "unpublished")
    : prop.id === "delete"
    ? (text = "deleted")
    : prop.id === "subcategory"
    ? (text = "added")
    : (text = "published");
  return (
    <div>
      <div>SUCCESSFUL</div>
      <span>
      {prop.id === "subcategory" ? "Subcategory" : "Template"} {prop.templateName ? prop.templateName : ""} is {text} successfully
      </span>
    </div>
  );
};

export const ErrorMsg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = "saved")
    : prop.id === "discard"
    ? (text = "discarded")
    : prop.id === "unpublish"
    ? (text = "unpublished")
    : prop.id === "delete"
    ? (text = "deleted")
    : prop.id === "subcategory"
    ? (text = "added")
    : (text = "published");
  return (
    <div>
      <div style={{color:"#BC0000 !important" }}>ERROR</div>
      <span>
      {prop.id === "subcategory" ? "Subcategory" : "Template"} {prop.templateName ? prop.templateName : ""} is not {text} successfully
      </span>
    </div>
  );
};
