import React from "react"

import styles from "./CategoryCell.module.css"

const CategoryCell = ({data}) => {
    return <div className={styles.CategoryCellContainer}>
        <div className={styles.CategoryLabel}>{data.category}</div>
        <div className={styles.SubCategoryLabel}>{data.subCategory}</div>
    </div>
}

export default CategoryCell