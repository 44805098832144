import React from "react"

import styles from "./TemplateDetails.module.css"

const TemplateDetails = ({data}) => {
    return <div className={styles.TemplateDetailsContainer}>
        <span className={styles.TemplateDetailsHeader}>{data.templateName}</span>
        <p className={styles.TemplateDescription}>{data.description}</p>
        <span className={styles.CreatedDate}>Created: {data.createdDate}</span>
    </div>
}

export default TemplateDetails