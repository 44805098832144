import React, { useCallback,useEffect, useState } from "react";

import styles from "./DateSelector.module.css";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/Calendar.svg";
import { format } from "date-fns";
import PopupSelector from "../PopupSelector";
import DateSelectorContent from "./DateSelectorContent";

const DateSelector = ({ className, onDiscard, onApply,value}) => {
  const [name, setName] = useState(value?value : "MM/DD/YYYY");

  const setNameAndApply = useCallback(
    (date) => {
      setName(date ? `${format(date, "MM/dd/yyyy")}` : `${format(new Date(), "MM/dd/yyyy")}`);
      // onApply(name);
    },
    [onApply]
  );

  useEffect(() => {
    onApply(name);
 }, [name]);

  const onDiscardDate = useCallback(() => {
    setName("MM/DD/YYYY");
    onDiscard(name);
  }, [onDiscard]);

  return (
    <PopupSelector
      type="Small"
      className={className}
      name={value}
      renderPopupContent={(closeModal) => (
        <DateSelectorContent
          onDiscard={onDiscardDate}
          onApply={setNameAndApply}
          closeModal={closeModal}
        />
      )}
      renderCustomBox={(name, openModal) => (
        <div onClick={openModal} className={styles.Container}>
          <div className={styles.Label}>{name}</div>
          <CalendarIcon className={styles.Icon} />
        </div>
      )}
    />
  );
};

export default DateSelector;
