import RowAction from "../../General/Table/RowAction/RowAction";
import CopyDetails from "./Row/CopyDetails/CopyDetails";
import RowActionMenu from "./Row/RowActionMenu/RowActionMenu";
import Status from "./Row/Status/Status";
import styles from "./ManageCopyTable.module.css";

export const columns = [
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell: { value } }) => (
            <RowAction
                menu={<RowActionMenu action={value} id={value.contentId} />}
            />
        )
    },
    {
        Header: "Copy Details",
        accessor: "copyDetails",
        Cell: ({ cell: { value } }) => <CopyDetails data={value} />
    },
    {
        Header: "Associated Campaigns",
        accessor: "associatedTemplates",
        Cell: ({ cell: { value } }) => {
            if (value.length === 0 || value[0] === "") {
                return ""
            } else {
                return value.map((data) => <li className={styles.AssociatedCampaigns} key={data.campaignName + data.campaignID}>{data.campaignName}</li>)
            }
        }
    },
    {
        Header: "Created by",
        accessor: "creator"
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => <Status value={value} />
    }
];
