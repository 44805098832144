import React, { useMemo } from "react";

import styles from "./Status.module.css";
import { RoleConstants } from "../../../../Constants/RoleConstants";


export const STATUS_SUPERADMIN = RoleConstants.SUPERADMIN_STATUS;
export const STATUS_ADMIN = RoleConstants.ADMIN_STATUS;
export const STATUS_AUTHOR = RoleConstants.AUTHOR_STATUS;
export const STATUS_DESIGNER = RoleConstants.DESIGNER_STATUS;
export const STATUS_DEVELOPER = RoleConstants.DEVELOPER_STATUS;

const Status = ({ value }) => {
    const styleColor = useMemo(() => {
        switch (value[0].toLowerCase()) {
            case STATUS_SUPERADMIN:
                return styles.Blue;
            case STATUS_ADMIN:
            case STATUS_AUTHOR:
                return styles.Yellow;
            case STATUS_DESIGNER:
                return styles.Orange;
            case STATUS_DEVELOPER:
                return styles.Green;
            default:
                return styles.Red;
        }
    }, [value]);
    return <div className={ [styles.Status, styleColor].join(" ") }>
        { value }
    </div>;
};


export default Status;