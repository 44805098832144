/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import styles from "./CreateContent.module.css";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import { SheetJSApp } from "../../ManageCustomerList/CreateCustomer-2/CreateCustomerRight/CreateCustomerVinList";
import XLSX from "xlsx";
import { Context } from "../../../context";
import { Slide, toast } from "react-toastify";
import { Msg, ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";

// import { SheetJSApp } from "../../ManageCustomerList/CreateCustomer-2/CreateCustomerRight/CreateCustomerVinList";

const UploadCopy = (props) => {
  /* eslint-disable */
  const history = useHistory();
  const [uploadCopyTemplate, setUploadCopyTemplate] = useState();
  const [uploadCopyName, setUploadCopyName] = useState();
  const [uploadCopyDescription, setUploadCopyDescription] = useState();
  const [saveUploadObject, setSaveUploadObject] = useState({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [contentFile, setContentFile] = useState();
  const [output, setOutput] = useState([{ data: "", cols: "" }])
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const { value } = useContext(Context);
  const [context, setContext] = value;
  const [isShown, setIsShown] = useState(false);

  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);

  /*for now defaultvalues for Templates */
  const TemplateValues = [
    {
      label: "Template A1",
      value: "Template A1"
    },
    {
      label: "Template A2",
      value: "Template A2"
    }
  ];

  /* notify if user do not enter mandatory fields */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !uploadCopyTemplate
      ? [...toBeCompletedArray, "Template"]
      : toBeCompletedArray;
    toBeCompletedArray = !uploadCopyName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !uploadCopyDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    uploadCopyTemplate,
    uploadCopyName,
    uploadCopyDescription
  ]);

  /* update the values when changed */
  useEffect(() => {
    setSaveUploadObject({
      name: uploadCopyName,
      contentFile: contentFile,
      description: uploadCopyDescription,
      ...(uploadCopyTemplate && {
        template: [
          {
            name: uploadCopyTemplate.label,
            id: uploadCopyTemplate.value
          }
        ]
      })
    });
  }, [
    uploadCopyName,
    uploadCopyDescription,
    uploadCopyTemplate,
    contentFile
  ]);

  /* generate an array of column objects */
  const make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    }
    return o;
  };


  const displayResults = async (uploadedFile) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1
      });
      /* Update state */
      // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
      setData(data);
      setIsShown(false);
      setCols(make_cols(ws["!ref"]))
    };
    if (rABS) {
      reader.readAsBinaryString(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }
  }

  const showData = async () => {
    setIsShown(true);
  }

  /* save funtion */
  const saveUploadData = async (saveUploadObject) => {
    let formData = new FormData();
    // Update the formData object 
    formData.append("contentZip", contentFile);
    formData.append('name', uploadCopyName);
    formData.append('status', "Draft");
    formData.append('createdBy', userName);
    formData.append('description', uploadCopyDescription);
    formData.append('channel', JSON.stringify([{ name: "Email", id: 0 }]))
    formData.append('template', JSON.stringify([{ name: uploadCopyTemplate.label, id: uploadCopyDescription.value }]))


    console.log("--->" + JSON.stringify(saveUploadObject));
    let url;
    url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/content/data-copy`
    )

    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      // body: JSON.stringify(saveUploadObject)
      body: formData
    };
    console.log("------save upload object---------->" + JSON.stringify(saveUploadObject))
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log("response----->" + serverResponseJson.status);
    return serverResponseJson.status;
  };

  return (
    <div style={{ textAlign: "left" }}>
      <p className={styles.UpdateFormHeader}>Upload campaign copy</p>
      <span className={styles.UpdateFormSubTitle}>
        Provide the campaign copy using a content matrix spreadsheet.
      </span>
      <div style={{ marginBottom: "2rem", marginTop: "3rem" }}>
        <LabeledSelect
          className={styles.Select}
          options={TemplateValues}
          placeholder="Select"
          value={uploadCopyTemplate}
          defaultLabel={
            uploadCopyTemplate
              ? uploadCopyTemplate.label
              : undefined
          }
          defaultId={
            uploadCopyTemplate
              ? uploadCopyTemplate.value
              : undefined
          }
          title={
            <>
              Template<span>*</span>
            </>
          }
          onValueChange={(option) => setUploadCopyTemplate(option)}
        /></div>

      <div style={{ marginBottom: "2rem" }}>
        <LabeledInput
          title={
            <>
              Name<span>*</span>
            </>
          }
          data={uploadCopyName}
          placeholder="Type the campaign copy name"
          className={styles.Input}
          onValueChange={(value) => setUploadCopyName(value)}
        /></div>
      <div style={{ marginBottom: "2rem" }}>
        <LabeledInput
          title={
            <>
              Description<span>*</span>
            </>
          }
          data={uploadCopyDescription}
          className={styles.Input}
          placeholder="Type the description"
          onValueChange={(value) => setUploadCopyDescription(value)}
        /></div>
      <div style={{ marginBottom: "0.2rem" }}>
        <LabeledFileUploadBar
          title={
            <>
              Upload copy (xls file)<span>*</span>
            </>
          }
          newUpload="no"
          uploadType="uploadCopy"
          onUploadClick={(value) => { showData() }}
          uploadedFile={(value) => { setContentFile(value); displayResults(value) }}
        />
        {data.length > 0 && isShown == true ?
          <React.Fragment>
            <label style={{ paddingTop: "5rem" }}> Campaign copy preview </label>
            <div className="table-responsive" style={{ "max-height": "450px", "marginTop": "0rem", "border": "1px solid #dddddd" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {data.slice(0, 1).map((r, i) => (
                    <tr>
                      {r.map((c, k) => (
                        <tr>
                          <td style={{ backgroundColor: "#F5F6FA", padding: "2rem 2rem 3rem 2rem" }}>
                            {r[k]}
                          </td>
                          <td style={{ backgroundColor: "#FFFFFF", padding: "2rem 2rem 3rem 2rem" }}>
                            {data.slice(1)[0][k]}
                          </td>
                        </tr>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
          : ""}


        {/* <SheetJSApp name="uploadcontent"/> */}
      </div>

      <div className={styles.floatcontainer}>
        <div className={styles.floatchild}>
          <PopupDialog
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/content");
                }}
                noAction={closeModal}
                className="Discard"
              />
            )}
            renderControlContent={(openModal) => (
              // <Button onClick={openModal} name="Discard" />
              console.log("")
            )}
          />
        </div>
        <div className={styles.floatchild}>
          <div className={styles.floatchild1}>
            {/* save */}
          </div>
          <div className={styles.floatchild2} style={{ paddingLeft: "7rem" }}>
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="55rem"
              noPopupHeight="30rem"
              yesTitle="Save" //save
              noTitle="Discard" //discard
              className={styles.ButtonsEdit}
              renderDiscardPopupContent={(closeModal) => (
                //save and close
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/content");
                  }}
                  noAction={closeModal}
                  className="Discard"
                />
              )}
              renderSavePopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    let statusVar = await saveUploadData(saveUploadObject);
                    console.log("statusVar--->" + statusVar)
                    console.log(context)
                    setContext("");
                    history.push("/ctmp/content");
                    (statusVar !== "content details inserted successfully" &&
                      toast.error(
                        <ErrorMsg id="save" templateName={uploadCopyName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      ));
                    (statusVar === "content details inserted successfully" &&
                      toast.success(
                        <Msg id="save" templateName={uploadCopyName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      ));
                    closeModal();

                  }}
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted}
                />
              )}
            />
          </div>
        </div>
      </div>

    </div>
  );
};
export default UploadCopy;
