/* eslint-disable*/
import styles from "./AddUser.module.css";
import React, { useState, useCallback, useEffect } from "react";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import RadioButtonControl from "../../General/RadioButtonControl/RadioButtonControl";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import MultiSelector from "../../General/MultiSelector/MultiSelector";
/*alerts imports*/
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";

const EditRole = () => {
  const [roleName, setroleName] = useState();
  const [roleList, setRoleList] = useState();
  const [editRoleCategories, setEditRoleCategories] = useState([]);
  const [roleSubCategories, setroleSubCategories] = useState([]);
  const [approveCampaign, setApproveCampaign] = useState();
  const [addSubCategories, setAddSubCategories] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [addRoleObject, setAddRoleObject] = useState({});
  const history = useHistory();
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  /* to check everything is entered or not */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !roleList
      ? [...toBeCompletedArray, "Select Role"]
      : toBeCompletedArray;
    toBeCompletedArray = !roleName
      ? [...toBeCompletedArray, "RoleName"]
      : toBeCompletedArray;
    toBeCompletedArray = !editRoleCategories
      ? [...toBeCompletedArray, "Category"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !roleSubCategories || !roleSubCategories.length
        ? [...toBeCompletedArray, "Sub-Category"]
        : toBeCompletedArray;
    toBeCompletedArray = !approveCampaign
      ? [...toBeCompletedArray, "Approve Campaign"]
      : toBeCompletedArray;
    toBeCompletedArray = !addSubCategories
      ? [...toBeCompletedArray, "Add Subcategories"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    roleName,
    editRoleCategories,
    roleSubCategories,
    approveCampaign,
    addSubCategories,
    roleList
  ]);

  /* update the values when changed */
  useEffect(() => {
    setAddRoleObject({
      roleName: roleName,
      ...(editRoleCategories && {
        campaignCategories: editRoleCategories.map((cat) => ({
          name: cat.label,
          id: cat.value
        }))
      }),
      ...(roleSubCategories && {
        subCategories: roleSubCategories.map((c) => ({
          name: c.label,
          id: c.value
        }))
      }),
      approveCampaigns: approveCampaign && approveCampaign.value,
      subCampaigns: addSubCategories && addSubCategories.value
    });
  }, [
    roleName,
    editRoleCategories,
    roleSubCategories,
    approveCampaign,
    addSubCategories
  ]);

  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );

  const clearFields = () => {
    setAddRoleObject({});
    setEditRoleCategories([]);
    setroleSubCategories([]);
    setroleName([]);
    setAddSubCategories("");
    setApproveCampaign("");
  };

  const populateRoleValues = async (option) => {
    clearFields();
    setRoleList(option);
    console.log("--->" + JSON.stringify(option));
    if (option === "" || option === null) {
      clearFields();
    } else {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/user/roles?role=${option.label}`
      );
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
          "X-CORRELATIONID": 0
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log(JSON.stringify(serverResponseJson.role));
      const res = serverResponseJson.role[0];
      console.log("My populate values res"+ JSON.stringify(res))
      setroleName(res.roleName);
      res.campaignCategories.map((category) => {
        setEditRoleCategories((editRoleCategories) => [
          ...editRoleCategories,
          { label: category.name, value: parseInt(category.id) }
        ]);
      });
      res.subCategories.map((subcategory) => {
        setroleSubCategories((roleSubCategories) => [
          ...roleSubCategories,
          { label: subcategory.name, value: parseInt(subcategory.id) }
        ]);
      });
      res.approveCampaigns === true
        ? setApproveCampaign({ title: "Yes", value: res.approveCampaigns })
        : setApproveCampaign({ title: "No", value: res.approveCampaigns });
      res.subCampaigns === true
        ? setAddSubCategories({ title: "Yes", value: res.subCampaigns })
        : setAddSubCategories({ title: "No", value: res.subCampaigns });
    }
  };

 /* save Role */
 const saveRole = async(addRoleObject) => {
  console.log("--->" + JSON.stringify(addRoleObject));
  console.log(JSON.stringify(roleList))
  const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/roles/${roleList.value}`);
  
  let callParams = {
    method: "PUT",
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
      "X-CORRELATIONID": 0
    },
    body: JSON.stringify(addRoleObject)
  };
   const serverResponse = await fetch(url.toString(), callParams);
   const serverResponseJson = await serverResponse.json();
   return serverResponseJson.status;
};

  return (
    <React.Fragment>
      <div className={styles.EditRoleContentDisplay}>
        <div className={styles.RoleContainer}>
          <div style={{ paddingTop: "4rem", paddingLeft: "4rem" }}>
            <p className={styles.UpdateFormHeader}>Edit existing role</p>
            <span className={styles.UpdateFormSubTitle}>
              To add a existing role, please start by selecting it from the
              dropdown menu:
            </span>
            <div
              className={styles.RoleAndTeam}
              style={{ marginBottom: "2rem" }}
            >
              <LabeledSelect
                optionsUrl="v1/user/distinctRoles"
                className={styles.Input}
                value={roleList}
                defaultLabel={roleList ? roleList.label : undefined}
                defaultId={roleList ? roleList.value : undefined}
                // options={RoleValues}
                title={
                  <>
                    Select existing role<span> *</span>
                  </>
                }
                onValueChange={(value) => {
                  setRoleList(value);
                  populateRoleValues(value);
                }}
              />
            </div>
            <hr />
            <div className={!roleList ? styles.disable : ""}>
              <div className={styles.RoleAndTeam}>
                <LabeledInput
                  title={
                    <>
                      Role Name<span> *</span>{" "}
                    </>
                  }
                  data={roleName}
                  placeholder="Enter a role"
                  className={styles.Input}
                  onValueChange={(value) => setroleName(value)}
                />
              </div>

              <div className={styles.RoleAndTeam}>
                <div className={styles.teamsParent}>
                  <div>
                    <label>
                      Campaign Categories<span> *</span>
                    </label>
                  </div>
                  <MultiSelector
                    className={styles.Select}
                    name="categories"
                    optionsUrl="v1/ctmp/category"
                    // options={options}
                    valuesChanged={(editRoleCategories) =>
                      setMultiProperty(
                        editRoleCategories,
                        setEditRoleCategories
                      )
                    }
                    value={editRoleCategories}
                  />
                </div>
                <div className={styles.teamsParent}>
                  <div>
                    <label>
                      Campaign Sub-Categories<span> *</span>
                    </label>
                  </div>
                  <MultiSelector
                    className={styles.Select}
                    name="subcategories"
                    optionsUrl="v1/ctmp/subcategories"
                    valuesChanged={(roleSubCategories) =>
                      setMultiProperty(roleSubCategories, setroleSubCategories)
                    }
                    value={roleSubCategories}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={!roleList ? styles.disable : ""}>
        <div style={{ paddingLeft: "1rem", marginTop: "-8rem" }}>
          <div className={styles.RadioClass}>
            <label>
              Can Approve Campaigns<span> *</span>
            </label>

            <RadioButtonControl
              previousSelection={approveCampaign}
              onSelect={(value) => {
                setApproveCampaign(value);
              }}
              options={[
                {
                  title: "Yes",
                  value: true
                },
                { title: "No", value: false }
              ]}
            />
          </div>
          <div style={{ marginTop: "4rem" }} className={styles.RadioClass}>
            <label>
              Can Add Sub-Categories<span> *</span>
            </label>

            <RadioButtonControl
              previousSelection={addSubCategories}
              onSelect={(value) => {
                setAddSubCategories(value);
              }}
              options={[
                {
                  title: "Yes",
                  value: true
                },
                { title: "No", value: false }
              ]}
            />
          </div>
        </div>
        <div
          className={styles.SaveAndDiscardButton}
          style={{ marginTop: "5rem" }}
        >
          <SaveAndDiscardWithPopupButtonSet
            yesPopupHeight="39rem"
            noPopupHeight="55rem"
            yesTitle="Save Changes"
            noTitle="Discard"
            className={styles.Buttons}
            renderDiscardPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/user-management");
                }}
                noAction={closeModal}
              />
            )}
            renderSavePopupContent={(closeModal) => (
              <SaveDialog
                itemsToBeComplete={toBeCompleted}
                saveAction={async () => {
                   var result =  await saveRole(addRoleObject);
                   if(result === "role Updated Successfully"){
                    let displayMessage =  "The role "+addRoleObject.roleName +" has been successfully updated."
                    closeModal();
                    history.push("/ctmp/user-management");
                    toast.success(
                      <Msg message={displayMessage}/>,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                   }
                   else{
                    closeModal();
                    let displayMessage = "Failed to update the role "+addRoleObject.roleName +" please try again."
                    history.push("/ctmp/user-management");
                        toast.error(
                          <ErrorMsg message={displayMessage}/>,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        )
                   }
                  }}
                discardAction={closeModal}
                className="Edit_Role"
              />
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default EditRole;
