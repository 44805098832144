/* eslint-disable */
import React, { useMemo, useEffect, useContext } from "react";
import { Context } from "../../../../../context";
import { useHistory } from "react-router-dom";
import styles from "./RowActionMenu.module.css";
import { useAppSelector } from "../../../../../redux/store";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../CreateTemplate/DiscardTemplate/DiscardTemplate";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";

/* eslint-disable */
import {
  STATUS_APPROVED,
  STATUS_CREATIVE_APPROVED,
  STATUS_CREATIVE_PENDING,
  STATUS_CAMPAIGN_PENDING,
  STATUS_DELETED,
  STATUS_DRAFT,
  STATUS_EXPIRED,
  STATUS_LIVE,
  STATUS_REQUESTED,
  STATUS_TESTING,
  STATUS_PUBLISHED,
  STATUS_INUSE
} from "../Status/Status";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id }) => {
  const history = useHistory();
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const { user, value, capability } = useContext(Context);
  const [role, setRole] = user;
  const [capabilities, setCapabilities] = capability;
  const [context, setContext] = value;
  let templateobj;

  useEffect(() => {
    console.log("----->" + action.category);
    const abortController = new AbortController();
    if (id !== undefined) {
      const getTemplateDetails = async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/template/${id}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams, { signal: abortController.signal });
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.templates[0];
          //console.log(res);
          templateobj = {
            name: res.name,
            description: res.description,
            status: "Draft",
            channel: res.channel,
            previewImageUrl: res.previewImageUrl ? res.previewImageUrl : ""
          };
        });
      };
      getTemplateDetails();
    }
    return () => {
      abortController.abort();
    };
  }, [id]);

  const deleteTemplate = async (templateId) => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/template/${templateId}`
    );
    const callParams = {
      method: "DELETE",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
        // "user-info": JSON.stringify({ userName: userName, userEmail: email }),
      }
    };

    const serverResponse = await fetch(url.toString(), callParams);
    const mystatus = await serverResponse.json();
    return serverResponse.status;
  };

  const publishTemplate = async (templateId, action) => {
    // let toBePublishedObjectParsed = templateobj;
    let toBePublishedObjectParsed = {
      status: "Draft"
    };
    action === "publish"
      ? (toBePublishedObjectParsed.status = "Published")
      : (toBePublishedObjectParsed.status = "Draft");

    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/template/${templateId}/publish`
    );
    const callParams = {
      method: "PUT",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(toBePublishedObjectParsed)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const mystatus = await serverResponse.json();
    return serverResponse.status;
  };

  const actions = useMemo(() => {
    switch (action.status.toLowerCase()) {
      case STATUS_DRAFT:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div
              key="view"
              className={styles.MenuItem}
              onClick={() => {
                history.push(`/ctmp/template/view-template/${id}`);
              }}
            >
              View
            </div> : "",
          <div
            onClick={() => {
              history.push(`/ctmp/template/edit-template/${id}`);
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,

          <PopupDialog
            key={"publish" + id}
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={async () => {
                  const statusVar = await publishTemplate(id, "publish");
                  //console.log("status is ---->" + typeof statusVar);

                  statusVar !== 200 &&
                    localStorage.setItem("forcePublish", "false");
                  statusVar === 200 &&
                    localStorage.setItem("forcePublish", "true");
                  //location.replace("/ctmp/template");
                  setContext("publish");
                }}
                noAction={closeModal}
                className="Publish Template"
                visiblity="true"
              />
            )}
            renderControlContent={(openModal) => (
              capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
                <div
                  key="publish"
                  onClick={openModal}
                  className={styles.MenuItem}
                >
                  Publish
                </div> : ""
            )}
          />,
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <PopupDialog
              key={"delete" + id}
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={async () => {
                    const statusVar = await deleteTemplate(id);
                    statusVar !== 200 &&
                      localStorage.setItem("forceDelete", "false");
                    statusVar === 200 &&
                      localStorage.setItem("forceDelete", "true");
                    // location.replace("/ctmp/template");
                    setContext("delete")
                  }}
                  noAction={closeModal}
                  className="Delete Template"
                  visiblity="true"
                />
              )}
              renderControlContent={(openModal) => (
                <div key="delete" onClick={openModal} className={styles.MenuItem}>
                  Delete
                </div>
              )}
            /> : "",
          <div
            key="Preview"
            className={styles.MenuItem}
            onClick={() => {
              history.push(`/ctmp/template/preview/${id}`);
            }}
          >
            Preview
          </div>
        ];
      case STATUS_PUBLISHED:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div
              key="view"
              className={styles.MenuItem}
              onClick={() => {
                history.push(`/ctmp/template/view-template/${id}`);
              }}
            >
              View
            </div> : "",

          <PopupDialog
            key={"unpublish" + id}
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={async () => {
                  const statusVar = await publishTemplate(id, "unpublish");
                  //console.log(statusVar);
                  statusVar !== 200 &&
                    localStorage.setItem("forceUnpublish", "false");
                  statusVar === 200 &&
                    localStorage.setItem("forceUnpublish", "true");
                  //location.replace("/ctmp/template");
                  setContext("unpublish");
                }}
                noAction={closeModal}
                className="Unpublish Template"
                visiblity="true"
              />
            )}
            renderControlContent={(openModal) => (
              <div
                key="unpublish"
                onClick={openModal}
                className={styles.MenuItem}
              >
                Unpublish
              </div>
            )}
          />
        ];
      case STATUS_REQUESTED:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div
              key="view"
              className={styles.MenuItem}
              onClick={() => {
                history.push(`/ctmp/template/view-template/${id}`);
              }}
            >
              View
            </div> : "",
          <div
            onClick={() => {
              history.push(`/ctmp/template/edit-template/${id}`);
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,

          <PopupDialog
            key={"publish" + id}
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={async () => {
                  const statusVar = await publishTemplate(id, "publish");
                  //console.log("status is ---->" + typeof statusVar);

                  statusVar !== 200 &&
                    localStorage.setItem("forcePublish", "false");
                  statusVar === 200 &&
                    localStorage.setItem("forcePublish", "true");
                  //location.replace("/ctmp/template");
                  setContext("publish");
                }}
                noAction={closeModal}
                className="Publish Template"
                visiblity="true"
              />
            )}
            renderControlContent={(openModal) => (
              capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
                <div
                  key="publish"
                  onClick={openModal}
                  className={styles.MenuItem}
                >
                  Publish
                </div> : ""
            )}
          />,
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <PopupDialog
              key={"delete" + id}
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={async () => {
                    const statusVar = await deleteTemplate(id);
                    statusVar !== 200 &&
                      localStorage.setItem("forceDelete", "false");
                    statusVar === 200 &&
                      localStorage.setItem("forceDelete", "true");
                    // location.replace("/ctmp/template");
                    setContext("delete")
                  }}
                  noAction={closeModal}
                  className="Delete Template"
                  visiblity="true"
                />
              )}
              renderControlContent={(openModal) => (
                <div key="delete" onClick={openModal} className={styles.MenuItem}>
                  Delete
                </div>
              )}
            /> : ""
        ];
      case STATUS_CREATIVE_PENDING:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div key="view" className={styles.MenuItem} onClick={() => { }}>
              View
            </div> : "",
          <div
            onClick={() => {
              window.location.assign(`/ctmp/template/edit-template/${id}`);
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
            <div key="publish" className={styles.MenuItem}>
              Publish
            </div> : "",
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <div
              key="delete"
              onClick={() => {
                deleteCampaign(id);
              }}
              className={styles.MenuItem}
            >
              Delete
            </div> : ""
        ];
      case STATUS_CAMPAIGN_PENDING:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div key="view" className={styles.MenuItem} onClick={() => { }}>
              View
            </div> : "",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
            <div key="publish" className={styles.MenuItem}>
              Publish
            </div> : "",
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <div
              key="delete"
              onClick={() => {
                deleteCampaign(id);
              }}
              className={styles.MenuItem}
            >
              Delete
            </div> : ""
        ];
      case STATUS_TESTING:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div key="view" className={styles.MenuItem} onClick={() => { }}>
              View
            </div> : "",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
            <div key="publish" className={styles.MenuItem}>
              Publish
            </div> : "",
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <div
              key="delete"
              onClick={() => {
                deleteCampaign(id);
              }}
              className={styles.MenuItem}
            >
              Delete
            </div> : ""
        ];
      case STATUS_APPROVED:
      case STATUS_CREATIVE_APPROVED:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div key="view" className={styles.MenuItem} onClick={() => { }}>
              View
            </div> : "",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
            <div key="publish" className={styles.MenuItem}>
              Publish
            </div> : "",
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <div
              key="delete"
              onClick={() => {
                deleteCampaign(id);
              }}
              className={styles.MenuItem}
            >
              Delete
            </div> : ""
        ];
      case STATUS_INUSE:
          return [
            capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
              <div
                key="view"
                className={styles.MenuItem}
                onClick={() => {
                  history.push(`/ctmp/template/view-template/${id}`);
                }}
              >
                View
              </div> : ""
          ];
        case STATUS_LIVE:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div
              key="view"
              className={styles.MenuItem}
              onClick={() => {
                history.push(`/ctmp/template/view-template/${id}`);
              }}
            >
              View
            </div> : "",

          <PopupDialog
            key={"unpublish" + id}
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={async () => {
                  const statusVar = await publishTemplate(id, "unpublish");
                  //console.log(statusVar);
                  statusVar !== 200 &&
                    localStorage.setItem("forceUnpublish", "false");
                  statusVar === 200 &&
                    localStorage.setItem("forceUnpublish", "true");
                  //location.replace("/ctmp/template");
                  setContext("unpublish");
                }}
                noAction={closeModal}
                className="Unpublish Template"
                visiblity="true"
              />
            )}
            renderControlContent={(openModal) => (
              <div
                key="unpublish"
                onClick={openModal}
                className={styles.MenuItem}
              >
                Unpublish
              </div>
            )}
          />
        ];
      case STATUS_EXPIRED:
        return [
          capabilities.includes(CapabilityConstants.TEMPLATE_VIEW) ?
            <div key="view" className={styles.MenuItem} onClick={() => { }}>
              View
            </div> : "",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.TEMPLATE_PUBLISH) ?
            <div key="publish" className={styles.MenuItem}>
              Publish
            </div> : "",
          capabilities.includes(CapabilityConstants.TEMPLATE_DELETE) ?
            <div
              key="delete"
              onClick={() => {
                deleteCampaign(id);
              }}
              className={styles.MenuItem}
            >
              Delete
            </div> : ""
        ];
      case STATUS_DELETED:
      default:
        return [
          <div key="na" className={styles.MenuItem}>
            N/A
          </div>
        ];
    }
  }, [action]);

  return <div className={styles.MenuContainer}>{actions}</div>;
};

export default RowActionMenu;
