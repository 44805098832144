/* eslint-disable*/
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import styles from "./CreateTemplate.module.css";
//import { ReactComponent as TemplateBig } from "../../../assets/icons/Template.svg";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import PublishDialog from "../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";

const SelectedTemplate = () => {
  const history = useHistory();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [createTemplateObject, setCreateTemplateObject] = useState({});
  const [templateDescription, setTemplateDescription] = useState();
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const [templateName, setTemplateName] = useState();
  const selectedImage = history.location.state.selectedTemplateObj.previewImageUrl;

  /* update the values when changed */
  useEffect(() => {
    setCreateTemplateObject({
      name: templateName,
      description: templateDescription,
      status: "Draft",
      previewImageUrl: "",
      createdBy: userName
      //id: id
    });
  }, [templateName, templateDescription]);

  /* to check everything is entered or not */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !templateName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !templateDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [templateName, templateDescription]);

  /*save to LocalObject and push to backend*/
  const useTemplateDetails = async () => {
    localStorage.clear();
    //console.log(JSON.stringify(createTemplateObject));
    localStorage.setItem(
      "templateObject",
      JSON.stringify(createTemplateObject)
    );

    const img = selectedImage;
    createTemplateObject.status = "Draft";
    //let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/template`);
    let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/template/reuse/${id}`);

    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(createTemplateObject)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    return serverResponse.status;
  };

  return <div style={{ textAlign: "left" }}>
    <Container className={styles.CreateCampaign}>
      <p className={styles.SelectedTemplateHeader}>Rename your selected template</p>
      <LabeledInput title={<>New Name<span>*</span></>}
        className={styles.Input}
        onValueChange={value => setTemplateName(value)}
      />
      <LabeledInput title={<>New Description<span>*</span></>}
        className={styles.Input}
        onValueChange={value => setTemplateDescription(value)}
      />
      <br /><br />

      {/* <div className={styles.TemplatePreview}><TemplateBig className={styles.SelectTemplateBig} /></div>*/}

      <div className={styles.TemplatePreview}>
        <img src={selectedImage} className={styles.SelectTemplateBig} />
      </div>

      <SaveAndDiscardWithPopupButtonSet
        yesPopupHeight="55rem"
        noPopupHeight="30rem"
        yesTitle="Use"
        noTitle="Back"
        className={styles.Buttons}
        renderDiscardPopupContent={(closeModal) => (
          <DiscardDialog
            yesAction={() => {
              history.push("/ctmp/template/create-template/reuse");
            }}
            noAction={closeModal}
          />
        )}
        renderSavePopupContent={(closeModal) => (
          <PublishDialog
            yesAction={async () => {
              await useTemplateDetails();
              closeModal();
              history.push("/ctmp/template");
            }}
            noAction={closeModal}
            bodyText="Do you want to use this template?"
            headerText="Use template"
            itemsToBeComplete={toBeCompleted}
          />
        )}
      />

    </Container>
  </div>
}

export default SelectedTemplate
