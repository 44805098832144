import { format } from "date-fns";


export const mapUsers = (serverResponse) => [...serverResponse.map(
    item => ({
        action: {status: item.roles.map((c) => c.name), userId: item._id },
        userEmail: item.email,
        team: item.teams.name? [item.teams.name] : item.teams.map((c) => c.name),
        createdDate: item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy"),
        role:item.roles.name? [item.roles.name] : item.roles.map((c) => c.name) ? item.roles.map((c) => c.name) : "NA",
        creator: item.createdBy,
        status:item.status,
        id: item.id
    }))];