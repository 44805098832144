export const columns = [
  {
    Header: "User",
    accessor: "user",
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: "Email",
    accessor: "userEmail",
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: "Role",
    accessor: "role",
    Cell: ({ cell: { value } }) => value
  },
 {
    Header: "Reports to",
    accessor: "reportsTo",
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }) => value
}
];
