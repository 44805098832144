import React from "react";
/* eslint-disable  @typescript-eslint/no-unused-vars */
import styles from "./CustomerSegment.module.css";

const CustomerSegment = (props) => {
  return (
    <React.Fragment>
      <div className={styles.CustomerSegmentTable}>
        <div className={styles.TableRow}>
            <div className={styles.Col1Title}>Customer Segment</div>
            <div className={styles.Col2Title}>Template</div>
            <div className={styles.Col3Title}>Template Version</div>
            <div className={styles.Col4Title}>Channel</div>
            <div className={styles.Col5Title}>Recieved Date</div>
        </div>

        <div className={styles.TableRow}>
            <div className={styles.Col1Data}>Newsletter Target Group A</div>
            <div className={styles.Col2Data}>Template A</div>
            <div className={styles.Col3Data}>V4.6</div>
            <div className={styles.Col4Data}>Email</div>
            <div className={styles.Col5Data}>1:00pm, Thu, 05 May 2021</div>
        </div>

        <div className={styles.TableRow}>
            <div className={styles.Col1Data}>Newsletter Target Group B</div>
            <div className={styles.Col2Data}>Template B</div>
            <div className={styles.Col3Data}>V1.9</div>
            <div className={styles.Col4Data}>Email</div>
            <div className={styles.Col5Data}>1:00pm, Thu, 05 May 2021</div>
        </div>

        <div className={styles.TableRow}>
            <div className={styles.Col1Data}>Newsletter Target Group C</div>
            <div className={styles.Col2Data}>Template C</div>
            <div className={styles.Col3Data}>V3.2</div>
            <div className={styles.Col4Data}>Email</div>
            <div className={styles.Col5Data}>1:00pm, Thu, 05 May 2021</div>
        </div>

        <div className={styles.TableRow}>
            <div className={styles.Col1Data}>Newsletter Target Group D</div>
            <div className={styles.Col2Data}>Template D</div>
            <div className={styles.Col3Data}>V4.1</div>
            <div className={styles.Col4Data}>Email</div>
            <div className={styles.Col5Data}>1:00pm, Thu, 05 May 2021</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerSegment;
