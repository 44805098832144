/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { useAppSelector } from "../../../../redux/store";
import styles from "./CreateCustomerRight.module.css";
import LabeledInput from "../../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../../General/LabeledSelect/LabeledSelect";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory, Route, Switch } from "react-router-dom";
import RuleForm from "../RuleForm/RuleForm";
import SaveAndDiscardWithPopupButtonSet from "../../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "../../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import Button from "../../../General/Button/Button";
import PopupDialog from "../../../General/PopupDialog/PopupDialog";
import PublishDialog from "../../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";
import moment from "moment";
import { Context } from "../../../../context";
import { CapabilityConstants } from "../../../Constants/CapabilitiyConstants";
import LoadSpinner from "../../../General/LoadSpinner/LoadSpinner";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";

const CloneList = ({ navSelected, itemsEntered }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [subNavSelected, setSubNavSelected] = useState();
  const [selectValue, setSelectValue] = useState();
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [usageDetails, setUsageDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [compileList, setCompileList] = useState([]);
  const [customerList, setCustomerList] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [percentageCount, setPercentageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { capability } = useContext(Context);
  const [capabilities, setCapabilities] = capability;
  const [isLoaded, setIsLoaded] = useState(false);

  // const Customerlists = [
  //   {
  //     label: "Customer 1",
  //     value: "6227fab8a176b96998770219"
  //   },
  //   {
  //     label: "Customer 2",
  //     value: "6226495da176b93656722c94"
  //   }
  // ];

  useEffect(() => {
    setCustomerList({
      select: selectValue,
      name: name,
      description: description,
      createdBy: userName,
      vehicleDetails: vehicleDetails,
      subscriptionDetails: subscriptionDetails,
      usageDetails: usageDetails,
      userDetails: userDetails,
      compileList: compileList
    });
  }, [
    selectValue,
    name,
    description,
    vehicleDetails,
    subscriptionDetails,
    usageDetails,
    userDetails,
    compileList
  ]);

  const resetCustomerList = () => {
    setVehicleDetails([]);
    setSubscriptionDetails([]);
    setUsageDetails([]);
    setUserDetails([]);
    setCompileList([]);
  };

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !name
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !selectValue
      ? [...toBeCompletedArray, "Select list"]
      : toBeCompletedArray;
    toBeCompletedArray = !description
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !(vehicleDetails.length > 0) &&
        !(subscriptionDetails.length > 0) &&
        !(usageDetails.length > 0) &&
        !(userDetails.length > 0) &&
        !(compileList.length > 0)
        ? [...toBeCompletedArray, "Rules"]
        : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [selectValue, name, description, vehicleDetails, subscriptionDetails, usageDetails, userDetails, compileList]);

  useEffect(() => {
    itemsEntered(toBeCompleted.length, "clone")
  }, [toBeCompleted]);

  //count
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/count/1000`
      );
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      //Calculate percentage of customers
      //% of customers = (Final user list generated by the Compile List /Total customers) X 100
      setPercentageCount(Math.round((serverResponseJson.compiledCount / serverResponseJson.totalCustomerCount) * 100));
      //Show customerlist on the screen based on compiled count
      setTotalCount(serverResponseJson.compiledCount);
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  /*get published customerlist from database when user clicks clone in customerList*/
  // useEffect(() => {
  //   const getPublishedCustomerList = async () => {
  //     const url = new URL(
  //       `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/list`
  //     );
  //     const callParams = {
  //       method: "GET",
  //       headers: {
  //         "x-api-key": `${process.env.REACT_APP_API_KEY}`,
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${jwt}`
  //       }
  //     };
  //     const serverResponse = await fetch(url.toString(), callParams);
  //     const res = await serverResponse.json();
  //   };
  //   getPublishedCustomerList();
  // }, []);

  //get details based on selected customerlist id
  const getSelectedCustomerListDetails = async (id) => {
    console.log("selected customerlist id - " + id)
    if (id !== undefined) {
      const getCustomerDetails = async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.customerlists[0];
          res.vehicleDetails.map((vehicle) => {
            setVehicleDetails((vehicleDetails) => [
              ...vehicleDetails,
              vehicle
            ]);
          });
          res.subscriptionDetails.map((subscription) => {
            setSubscriptionDetails((subscriptionDetails) => [
              ...subscriptionDetails,
              subscription
            ]);
          });
          res.usageDetails.map((usage) => {
            setUsageDetails((usageDetails) => [
              ...usageDetails,
              usage
            ]);
          });
          res.userDetails.map((user) => {
            setUserDetails((userDetails) => [
              ...userDetails,
              user
            ]);
          });
          res.compileList.map((compile) => {
            setCompileList((compileList) => [
              ...compileList,
              compile
            ]);
          });
        });
      };
      getCustomerDetails();
    }
  };

  const saveCustomerList = async () => {
    setIsLoaded(true)
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`);
    customerList.status = "Draft";
    const serverResponse = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    });
    const serverResponseJson = await serverResponse.json();
    return serverResponse.status;
  };

  const PublishCustomerList = async () => {
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`)
    customerList.status = "Live";
    customerList.goLiveDate = moment().valueOf();
    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status)
  };

  return (
    <React.Fragment>
      <div className={styles.SubHeading}>Clone Customer List</div>
      <div className={styles.HeadingDescription}>
        Duplicate a Customer List, provide a new name and description, and edit
        the rules of the list.
      </div>
      <div className={styles.InputContainer}>
        {(isLoaded === true) ? <LoadSpinner /> : ""}
        <div className={styles.InputFields}>
          {/* CREATE NEW LIST */}
          <React.Fragment>
            <LabeledSelect
              className={styles.Select}
              optionsUrl="v1/customerlist/list"
              title={
                <>
                  Select<span>*</span>
                </>
              }
              onValueChange={(customer) => {
                resetCustomerList();
                setSelectValue(customer);
                if (customer !== undefined) {
                  getSelectedCustomerListDetails(customer.value);
                }
              }}
            />
            <LabeledInput
              title={
                <>
                  New Name<span>*</span>
                </>
              }
              className={styles.CreateCustomerCombo}
              onValueChange={(value) => setName(value)}
            />
            <LabeledInput
              title={
                <>
                  New Description<span>*</span>
                </>
              }
              className={styles.CreateCustomerCombo}
              onValueChange={(value) => setDescription(value)}
            />
          </React.Fragment>
        </div>
        {/* <div className={styles.Chart}>
          Customer Count
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={percentageCount}
                text={`${percentageCount}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#0A9F98"
                })}
              />
            </div>
            <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div>
          </div>
              </div> */}
      </div>

      {/* Subnav with rule builder */}
      <div className={styles.TableNav}>
        <div
          className={
            pathName[5] === "vehicle-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("vehicle-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`
            );
          }}
        >
          Vehicle Details
        </div>
        <div
          className={
            pathName[5] === "subscription-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("subscription-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/subscription-details`
            );
          }}
        >
          Subscription Details
        </div>
        <div
          className={
            pathName[5] === "usage-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("usage-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/usage-details`
            );
          }}
        >
          Usage Details
        </div>
        <div
          className={
            pathName[5] === "user-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("user-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/user-details`
            );
          }}
        >
          User Details
        </div>
        <div
          className={
            pathName[5] === "compile-list"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("compile-list");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/compile-list`
            );
          }}
        >
          Compile List
        </div>
      </div>

      <Switch>
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={vehicleDetails}
                setNodes={setVehicleDetails}
                endpoint={"vehicle-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/subscription-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={subscriptionDetails}
                setNodes={setSubscriptionDetails}
                endpoint={"subscription-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/usage-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={usageDetails}
                setNodes={setUsageDetails}
                endpoint={"usage-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/user-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={userDetails}
                setNodes={setUserDetails}
                endpoint={"user-fields"}
                subNavSelected={subNavSelected}
                showRC={true}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/compile-list`}
          render={() => (
            <React.Fragment>
              <RuleForm
                /* nodes={compileList} */
                nodes={[...vehicleDetails, ...subscriptionDetails, ...usageDetails, ...userDetails]}
                setNodes={setCompileList}
                endpoint={"compile-list"}
                subNavSelected={subNavSelected}
                showRC={false}
              />
            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );

  function SaveAndDiscardListButtons() {
    return <div className={styles.floatcontainer}>
      <div className={styles.floatchild1}>
        <PopupDialog
          height="30rem"
          width="67rem"
          renderPopupContent={(closeModal) => (
            <DiscardDialog
              yesAction={() => {
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              className="Discard"
            />
          )}
          renderControlContent={(openModal) => (
            <Button
              onClick={openModal}
              name="Discard"
            />
          )}
        />
      </div>
      <div className={styles.floatchild3}>
        <SaveAndDiscardWithPopupButtonSet
          yesPopupHeight="55rem"
          noPopupHeight="30rem"
          yesTitle="Publish"
          noTitle="Save"
          className={styles.Buttons}
          renderDiscardPopupContent={(closeModal) => (
            //save
            <SaveDialog
              saveAction={async () => {
                let status = await saveCustomerList();
                closeModal();
                setIsLoaded(false)
                history.push("/ctmp/customer-list");
                status !== 200 &&
                  toast.error(
                    <ErrorMsg
                      id="save"
                      templateName={name}
                    />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Error />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                status === 200 &&
                  toast.success(
                    <Msg id="save" templateName={name} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
              }}
              discardAction={closeModal}
              itemsToBeComplete={toBeCompleted} />
          )}
          renderSavePopupContent={(closeModal) => (
            // publish
            <PublishDialog
              yesAction={async () => {
                await PublishCustomerList();
                closeModal();
                history.push("/ctmp/customer-list");
              }}
              noAction={closeModal}
              bodyText="Do you want to Publish Customer List?"
              headerText={capabilities.includes(CapabilityConstants.CUSTOMERLIST_PUBLISH) ? "Publish Message" : "No_Permissions"}
              itemsToBeComplete={toBeCompleted} />
          )} />
      </div>
    </div>;
  }

};

export default CloneList;
