import React, { useRef, useState } from "react";

import styles from "./UploadBarWithButton.module.css";

const UploadBarWithButton = () => {
    const inputFile = useRef();
    const [fileChosen, setFileChosen] = useState();
    const [fileName, setFileName] = useState("No File Chosen");
    return <div className={ styles.Container }>
        <div className={ styles.FileChooserContainer }>
            <input type="file" id="file" ref={ inputFile }
                   style={ { display: "none" } }
                   onChange={ (event) => {
                       event.stopPropagation();
                       event.preventDefault();
                       const file = event.target.files[0];
                       setFileName(file.name);
                       setFileChosen(file);
                   } }
            />
            <button onClick={ () => inputFile.current.click() }
                    className={ styles.ChooseFileButton }>Choose File
            </button>
            <div className={ styles.FileNameLabel }>{ fileName }</div>
        </div>
        <button disabled={ !fileChosen } className={ styles.UploadButton }>
            Upload
        </button>
    </div>;
};

export default UploadBarWithButton;