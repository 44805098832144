import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import PopupSelector from "../PopupSelector";
import DateRangeSelectorContent from "./DateRangeSelectorContent";

const DateRangeSelector = ({ className, onDiscard, onApply, value }) => {
console.log(value);
    const [name, setName] = useState("Date Range");

    useEffect(() => {
        setName(value ? `${ format(value.startDate,
            "MM/dd/yyyy") } - ${ format(value.endDate, "MM/dd/yyyy") }`
            : "Date Range");
    }, [value]);

    return <PopupSelector className={ className }
                          name={ name }
                          renderPopupContent={ (closeModal) =>
                              <DateRangeSelectorContent
                                  onDiscard={ onDiscard }
                                  onApply={ onApply }
                                  closeModal={ closeModal }
                                  value={ value !== null ? value :"" }
                              /> }
    />;
};

export default DateRangeSelector;