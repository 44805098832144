import React from "react";
/* eslint-disable  @typescript-eslint/no-unused-vars */

import styles from "./TemplateHistoryItem.module.css";

const TemplateHistoryItem = (props) => {
  return (
    <React.Fragment>
      <div className={styles.VersionItem}>
        <div className={styles.TrailCol}>
          <span className={styles.TrailDot} />
        </div>
        <div className={styles.TemplateInfoCol}>
          <div className={styles.PrimaryLabel}>Template {props.name}</div>
          <div className={styles.VersionDateTime}>Generated: {props.datetime}</div>
        </div>
        {props.line ? <span className={styles.TrailLine} /> : null}
      </div>
    </React.Fragment>
  );
};

export default TemplateHistoryItem;
