import React, { useCallback } from "react";

import styles from "./PopupSelector.module.css";
import ReactModal from "react-modal";

const PopupSelector = ({
                           name,
                           className,
                           renderPopupContent,
                           renderCustomBox,
                           type = "Normal"
                       }) => {
    const [showModal, setShowModal] = React.useState(false);

    const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
    const openModal = useCallback(() => setShowModal(true), [setShowModal]);

    return <div key={name}
        className={ [className, styles.SelectorBoxContainer].join(" ") }>
        {
            renderCustomBox ? renderCustomBox(name, openModal)
                :
                <div className={ styles.SelectBox }
                     onClick={ () => {
                         setShowModal(true);
                     } }
                >
                    { name }
                </div> }
        <ReactModal
            shouldCloseOnOverlayClick={ true }
            isOpen={ showModal }
            onRequestClose={ () => setShowModal(false) }
            className={ [styles.ContentModal, type === "Normal" ? styles.Normal
                : styles.Small].join(" ") }
            overlayClassName={ styles.ContentModalOverlay }
        >
            {
                renderPopupContent(closeModal)
            }
        </ReactModal>
    </div>;
};

export default PopupSelector;