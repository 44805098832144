  /* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
//import { format } from "date-fns";
import styles from "./CreateContent.module.css";
import MultiPicker from "../../General/MultiPicker/MultiPicker";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import DateSelector from "../../General/PopupSelector/DateSelector/DateSelector";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { ReactComponent as Lexus } from "../../../assets/icons/lexus.svg";
import { ReactComponent as Toyota } from "../../../assets/icons/toyota.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/Message-square.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as NewTag } from "../../../assets/icons/new-tag.svg";
import { ReactComponent as Volume } from "../../../assets/icons/volume.svg";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import Button from "../../General/Button/Button";
import TemplatePreviewCard from "../../General/TemplatePreviewCard/TemplatePreviewCard";

const ClonePublishedCopy = (props) => {
  /* eslint-disable */
  const history = useHistory();
  const [cloneExistingCopy,setCloneExistingCopy] = useState();
  const [cloneTemplate,setCloneTemplate] = useState();
  const [cloneName, setCloneName] = useState();
  const [cloneDescription, setCloneDescription] = useState();
  const [saveCloneObject, setSaveCloneObject] = useState({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);

  /*get Values from database when user clicks edit in manageContent*/
//   useEffect(() => {
//     if (props.props !== undefined) {
//       console.log("need api for id in newcontent page->" + props.props);
//       const getContentDetails = async () => {
//         const url = new URL(
//           `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
//         );
//         const callParams = {
//           method: "GET",
//           headers: {
//             "x-api-key": `${process.env.REACT_APP_API_KEY}`,
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${jwt}`
//           }
//         };
//         const serverResponse = await fetch(url.toString(), callParams);
//         const serverResponseJson = serverResponse.json().then((response) => {
//           const res = response.data;
//           // console.log(res);
//           setCreateContentName(res.name);
//           setCreateContentDesription(res.description);
//           res.channel.map((channel) => {
//             setCreateContentSelectedChannels(
//               (createContentSelectedChannels) => [
//                 ...createContentSelectedChannels,
//                 channel.name
//               ]
//             );
//           });
//           res.brand.map((brand) => {
//             setCreateContentBrand((createContentBrand) => [
//               ...createContentBrand,
//               brand.name
//             ]);
//           });
//           setCreateContentSelectTemplate({
//             label: res.template[0].name,
//             value: res.template[0].id
//           });
//           setCreateContentGeneration({
//             label: res.generation[0].name,
//             value: res.generation[0].id
//           });
//           console.log(res.date + "from db");
//           let date = new Date(res.date);
//           date =
//             date.getMonth() +
//             1 +
//             "/" +
//             (date.getDate() + 1) +
//             "/" +
//             date.getFullYear();
//           setCreateContentDate(date);
//         });
//       };
//       getContentDetails();
//       setEditMode(true);
//     }
//   }, []);

  /*for now defaultvalues for Templates */
  const TemplateValues = [
    {
      label: "Template A1",
      value: "Template A1"
    },
    {
      label: "Template A2",
      value: "Template A2"
    }
  ];

  /* notify if user do not enter mandatory fields */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !cloneName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneTemplate
      ? [...toBeCompletedArray, "Template"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneExistingCopy
      ? [...toBeCompletedArray, "Existing Copy"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    cloneName,
    cloneDescription,
    cloneExistingCopy,
    cloneTemplate
    ]);

  /* update the values when changed */
  useEffect(() => {
    setSaveCloneObject({
      cloneName: cloneName,
      cloneDescription: cloneDescription,
      ...(cloneExistingCopy && {
        template: [
          {
            name: cloneExistingCopy.label,
            id: cloneExistingCopy.value
          }
        ]
      }),
      ...(cloneTemplate && {
        template: [
          {
            name: cloneTemplate.label,
            id: cloneTemplate.value
          }
        ]
      }),
      createdBy: userName
      // date: createContentDate
    });
  }, [
    cloneDescription,
    cloneName,
    cloneExistingCopy,
    cloneTemplate
  ]);

  /* save funtion */
  const saveCloneData = async (saveCloneObject) => {
    console.log("--->" + JSON.stringify(saveCloneObject));
    // let url, methodValue;
    // editMode === true
    //   ? ((url = new URL(
    //       `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
    //     )),
    //     (methodValue = "PUT"))
    //   : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content`)),
    //     (methodValue = "POST"));

    // saveContentObject.status = "Draft";

    // let callParams = {
    //   method: methodValue,
    //   headers: {
    //     "x-api-key": `${process.env.REACT_APP_API_KEY}`,
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${jwt}`,
    //     "X-CORRELATIONID": 0
    //   },
    //   body: JSON.stringify(saveContentObject)
    // };
    // console.log("------save content object---------->"+JSON.stringify(saveContentObject))
    // const serverResponse = await fetch(url.toString(), callParams);
    // const serverResponseJson = await serverResponse.json();
    // return serverResponseJson;
  };

  return (
    <div style={{ textAlign: "left" }}>
      <p className={styles.UpdateFormHeader}>Clone published campaign copy</p>
      <span className={styles.UpdateFormSubTitle}>
       Create a clone of existing copy in use on the platform.
      </span>
      <div style={{marginBottom:"2rem",marginTop:"3rem",width:"50%"}}>
      <LabeledSelect
        className={styles.Select}
        options={TemplateValues}
        placeholder="Select"
        value={cloneExistingCopy}
        defaultLabel={
          cloneExistingCopy
            ? cloneExistingCopy.label
            : undefined
        }
        defaultId={
          cloneExistingCopy
            ? cloneExistingCopy.value
            : undefined
        }
        title={
          <>
            Existing Copy<span>*</span>
          </>
        }
        onValueChange={(option) => setCloneExistingCopy(option)}
      /></div>
    <div style={{marginBottom:"2rem",width:"50%"}}>
      <LabeledSelect
        className={styles.Select}
        options={TemplateValues}
        placeholder="Select"
        value={cloneTemplate}
        defaultLabel={
          cloneTemplate
            ? cloneTemplate.label
            : undefined
        }
        defaultId={
          cloneTemplate
            ? cloneTemplate.value
            : undefined
        }
        title={
          <>
            Template<span>*</span>
          </>
        }
        onValueChange={(option) => setCloneTemplate(option)}
      /></div>
       <div style={{marginBottom:"2rem"}}>
      <LabeledInput
        title={
          <>
            New Name<span>*</span>
          </>
        }
        data={cloneName}
        placeholder="Type the campaign copy name"
        className={styles.Input}
        onValueChange={(value) => setCloneName(value)}
      /></div>
       <div style={{marginBottom:"2rem"}}>
      <LabeledInput
        title={
          <>
            New Description<span>*</span>
          </>
        }
        data={cloneDescription}
        className={styles.Input}
        placeholder="Type the description"
        onValueChange={(value) => setCloneDescription(value)}
      /></div>
      {/* <div>
      <label style={{ marginBottom: "0.2rem", marginTop: "1rem" }}>
        Campaign copy preview
      </label>
          <TemplatePreviewCard/>
      </div> */}
    
          <div className={styles.floatcontainer}>
            <div className={styles.floatchild}>
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push("/ctmp/content");
                    }}
                    noAction={closeModal}
                    className="Discard"
                  />
                )}
                renderControlContent={(openModal) => (
                  <Button onClick={openModal} name="Discard" />
                )}
              />
            </div>
            <div className={styles.floatchild}>
              <div className={styles.floatchild1}>
                {/* save */}
              </div>
              <div className={styles.floatchild2}>
                <SaveAndDiscardWithPopupButtonSet
                  yesPopupHeight="55rem"
                  noPopupHeight="30rem"
                  yesTitle="Use" //save
                  noTitle="Save" //discard
                  className={styles.ButtonsEdit}
                  renderDiscardPopupContent={(closeModal) => (
                    //save and close
                    <SaveDialog
                      saveAction={async () => {
                        let res = await saveCloneData(saveCloneObject);
                        closeModal();
                        history.push("/ctmp/content");
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                  renderSavePopupContent={(closeModal) => (
                    <SaveDialog
                    saveAction={async () => {
                      let id_newcontent = await saveCloneData(saveCloneObject);
                      closeModal();
                      if (props.props !== undefined) {
                        // history.push(
                        //   `/ctmp/content/edit-content/${props.props}/creative-brief`
                        // );
                      } else {
                        // history.push("/ctmp/content/create-content/creative-brief")
                        // history.push(
                        //   `/ctmp/content/create-content/creative-brief/${id_newcontent}`
                        // );
                      }
                    }}
                    discardAction={closeModal}
                    itemsToBeComplete={toBeCompleted}
                  />
                  )}
                />
              </div>
            </div>
          </div>
        
      </div>
    );
};
export default ClonePublishedCopy;
