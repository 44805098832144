import { authProvider } from "../../authConfig";
import { JWT_RECEIVED, LOGOUT, ReduxAction } from "../action-types";


type AuthReducerAction = ReduxAction<string>

type AuthReducerState = {
    jwt: string,
    userName: string,
    email: string,
    exp:string
}


export const initialState: AuthReducerState = {
    jwt: "",
    userName: "",
    email: "",
    exp:""
}

const getToken = async () => {
    await authProvider.getIdToken().then((value) => {
        initialState.jwt = value.idToken.rawIdToken
        initialState.exp = value.idToken.expiration
        localStorage.setItem("tokenExp",initialState.exp)
        // console.log("jwt---->"+initialState.jwt)
        initialState.userName = value.idToken.name;
        initialState.email = value.idToken.preferredName;
    });
};
getToken()

const authReducer = (state: AuthReducerState = initialState, action: AuthReducerAction): AuthReducerState => {
    switch (action.type) {
        case JWT_RECEIVED:
            // return {
            //     jwt: action.value
            // }
           // alert(action.payload.token)
           sessionStorage.setItem("initialToken",action.value);
           return state;
        case LOGOUT:
            return {...state,jwt: "",userName: "",email:""}
        default:
            return state;
    }
}

export default authReducer;