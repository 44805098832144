/* File for storing all action for roles in different stages*/

export const Action_Draft = [
    {
     action:"Draft"
    }
   ];
   export const Action_CreativeRequested = [
     {
       action:"requestCreativeApproval"
     }
   ];
   export const Action_CreativeCancelRequest = [
     {
       action:"cancelApprovalRequest"
     }
   ];
   export const Action_CreativeApproved = [
     {
       action:"creativeApprove"
     }
   ];
   export const Action_CreativeDenied = [
     {
       action:"deny"
     }
   ];
   export const Action_CampaignRequested = [
     {
     action: "requestCampaignApproval"
     }
   ];
   export const Action_CampaignApproved = [
     {
       action: "approve"
   
     }
   ];
   export const Action_CampaignDenied = [
    {
      action: "deny"
  
    }
  ];
  export const Action_CampaignCancelRequest = [
    {
      action: "cancelApprovalRequest"
  
    }
  ];
   export const Action_TestingApproved = [
     {
         action: "approveTesting"
     }
   
   ];
   export const Action_TestingFailed = [
     {
       action: "failTesting"
     }
   ];
   export const Action_Live = [
     {
       action: "Live"
     }
   ];
   export const Action_Launch = [
    {
      action: "launch"
    }
  ];
   export const Action_Pause = [
     {
       action: "pause"
     }
   ];
   export const Action_Reuse = [
     {
       action: "Reused"
     }
   ];
   export const Action_MoveToDraft = [
    {
      action: "moveToDraft"
    }
  ];
  export const Action_Closed = [
    {
      action: "expired"
    }
  ];

   
   
   