import React,{useState,useEffect} from "react";
import Select from "react-select";

import styles from "./ManageCampaignTableHead.module.css";
import { SelectStyle } from "../../../General/Selector/Selector";
import ManageCampaignTableFilters
    from "./ManageCampaignTableFilters/ManageCampaignTableFilters";

const options = [
    { label: "Latest Created", value: "latestCreated" },
    { label: "Oldest Created", value: "oldestCreated" },
    { label: "Latest Live Campaign", value: "latestLive" },
    { label: "Oldest Live Campaign", value: "oldestLive" }
];

const ManageCampaignTableHead = ({ onSortChange, onFiltersChange, filters }) => {
    const [defaultValueSort,setDefaultValueSort] = useState();

    useEffect(()=>{
     if(Object.keys(filters).length > 0){
        setDefaultValueSort(null)
     }
    },[filters])

    const selectStylesApiObject = React.useMemo(() => {
        return {
            ...SelectStyle,
            control: provided => ({
                ...provided,
                border: ".1rem solid #CECFD0"
            })
        };
    }, []);

    return <div className={ styles.TableHead }>
        <div className={ styles.TableHeadLeft }>
            <div className={ styles.SortByContainer }>
                <label className={ styles.SortBy }>Sort By</label>
                <Select className={ styles.SortBySelect }
                        styles={ selectStylesApiObject }
                        value={defaultValueSort}
                        isClearable
                        options={ options }
                        onChange={ (sortByOption) => {
                            setDefaultValueSort(sortByOption);
                            onSortChange(
                                sortByOption ? sortByOption.value : undefined);
                        } }
                />
            </div>
            <ManageCampaignTableFilters onFiltersChange={ onFiltersChange } filters={filters}/>
        </div>
    </div>;
};

export default ManageCampaignTableHead;