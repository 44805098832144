import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../General/Button/Button";

import styles from "./ManageUserHeader.module.css";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const ManageUserHeader = ({ onSearch }) => {

    const history = useHistory();
    const [timeout, setTimeoutVal] = useState();
    const [searchText, setSearchText] = useState();

    const navToCreateUser = useCallback(
        () => {
            history.push(`${history.location.pathname}/addUser`);
        },
        [history]);

    useEffect(() => {
        timeout && clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            onSearch(searchText);
        }, 500));
    }, [onSearch, searchText, setTimeoutVal]);


    return <div className={styles.MngCmpgnHdr}>
        <div className={styles.MngCmpgnHdrLeft}>
            <h3>User Management</h3>
            <span className={styles.Separator} />
            <div className={styles.SearchBarContainer}>
                <SearchIcon className={styles.SearchIcon} />
                <input className={styles.Search} placeholder="Search User List"
                    type="text"
                    style={{ width: "400px" }}
                    onChange={(event => setSearchText(
                        event.target.value))} />
            </div>
        </div>
        <Button onClick={navToCreateUser}
            name="Create New User" />
    </div>;
};

export default ManageUserHeader;