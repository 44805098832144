import React, { useMemo, useEffect, useContext, useState } from "react";
import {CapabilityConstants} from "../../../../Constants/CapabilitiyConstants";
import styles from "./RowActionMenu.module.css";
import { useAppSelector } from "../../../../../redux/store";
import { Context } from "../../../../../context";
import { useHistory } from "react-router-dom";
import * as RoleStatus from './RoleStatus';
import { RoleConstants } from "../../../../Constants/RoleConstants";

import {
     STATUS_APPROVED,
     STATUS_CREATIVE_APPROVED,
     STATUS_CREATIVE_PENDING,
     STATUS_CAMPAIGN_PENDING,
     STATUS_DELETED,
     STATUS_DRAFT,
     STATUS_EXPIRED,
     STATUS_LIVE,
     STATUS_REQUESTED,
     STATUS_TESTING,
     STATUS_CAMPAIGN_REQUESTED,
     STATUS_PAUSED
} from "../Status/Status";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const VersionRowActionMenu = ({ action, id,commentFlag }) => {
     let versionCampaignObj;
     const history = useHistory();
     const [campaignobj1,setCampaignObj1] = useState({});
     const jwt = useAppSelector(state => state.authReducer.jwt);
     const { user, value, capability } = useContext(Context);
     const [role,setRole] = user;
     const [capabilities,setCapabilities] = capability;
     const [context, setContext] = value;
     let status_roles = [],status;
     action.status === "" || action.status.length === 0 ? status_roles = [{role:RoleConstants.SUPERADMIN,
     status:"Draft"
     },
     {
       role:RoleConstants.AUTHOR,
       status:"Draft"
     },
     {
          role:RoleConstants.ADMIN,
          status:"Draft"
     },
     {
          role:RoleConstants.DEVELOPER,
          status:"Draft"
     },
     {
          role:RoleConstants.DESIGNER,
          status:"Draft"
     }
     ] : status_roles = [...status_roles, ...action.status,{
          role:RoleConstants.ADMIN,
          status:"Draft"
      },
      {
          role:RoleConstants.DEVELOPER,
          status:"Draft"
      },
      {
          role:RoleConstants.DESIGNER,
          status:"Draft"
      }];

     let latest_status = status_roles.find(status_roles => status_roles.role === role);
     status = latest_status.status;
     
     useEffect(() => {
          const abortController = new AbortController();
          if (id !== undefined) {
            const getCampaignDetails = async () => {
              const url = new URL(
                `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${id}?version=${action.version}`
              );
              const callParams = {
                method: "GET",
                headers: {
                  "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${jwt}`
                }
              };
              const serverResponse = await fetch(url.toString(), callParams,{ signal: abortController.signal });
              const serverResponseJson = serverResponse.json().then((response) => {
                const res = response[0];
                setCampaignObj1(res);
             });
            };
            getCampaignDetails();
          }
          return () => {
            abortController.abort();
          };
        }, [id]);

    const updateStatus = async (campaignId, actionReq) => {
          if (campaignId !== undefined) {
               const getCampaignDetails = async () => {
                 const url = new URL(
                   `${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${id}?version=${action.version}`
                 );
                 const callParams = {
                   method: "GET",
                   headers: {
                     "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                     "Content-Type": "application/json",
                     Authorization: `Bearer ${jwt}`
                   }
                 };
                 const serverResponse = await fetch(url.toString(), callParams);
                 const serverResponseJson = serverResponse.json().then((response) => {
                   const res = response[0];
                   console.log("-------------set-------------")
                   versionCampaignObj = res;
                });
               };
               getCampaignDetails();
             }
          // console.log(versionCampaignObj+"------"+JSON.stringify(campaignobj1));
          // const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${campaignId}`);
          versionCampaignObj.roleStatus = RoleStatus.Status_Reuse;
          // const callParams = {
          //      method: "PUT",
          //      headers: {
          //           "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          //           "Content-Type": "application/json",
          //           "Authorization": `Bearer ${jwt}`
          //         },
          //      body: JSON.stringify(versionCampaignObj)
          // };
          // //console.log(JSON.stringify(callParams))
          // const serverResponse = await fetch(url.toString(), callParams);
          // const mystatus = await serverResponse.json();
          // return mystatus.status;
     }

     const actions = useMemo((versionCampaignObj) => {
          switch (status.toLowerCase()) {
               case STATUS_DRAFT:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_REQUESTED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_CAMPAIGN_REQUESTED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                      }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_CREATIVE_PENDING:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_CAMPAIGN_PENDING:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_TESTING:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_APPROVED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_CREATIVE_APPROVED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_LIVE:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
                    case STATUS_PAUSED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_EXPIRED:
                    return [
                         capabilities.includes(CapabilityConstants.CAMPAIGN_VIEW)?
                         <div key="reuse" className={styles.MenuItem}
                              onClick={async() => {
                                        const statusVar = await updateStatus(id,"reuse_version");
                                        console.log(statusVar);
                                   }
                              }>Reuse this version</div> : "",
                         <div onClick={() => {
                              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}/${action.version}`)
                              commentFlag(true);
                         }}
                              key="comment" className={styles.MenuItem}>Leave comment
                         </div>,
                         <div key="templatedetails"
                              onClick={()=> history.push(`/ctmp/dashboard/version-control/${action.campaignId}/preview/${action.version}/details`)}
                              className={styles.MenuItem}>View Template Details</div>
                    ];
               case STATUS_DELETED:
               default:
                    return [<div key="na" className={styles.MenuItem}>N/A</div>];
          }

     }, [action]);

     return <div className={styles.MenuContainer}>
          {actions}
     </div>;
};

export default VersionRowActionMenu;