import React, { useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./RowActionMenu.module.css";
import { Context } from "../../../../../context";
import { useAppSelector } from "../../../../../redux/store";
import { CapabilityConstants } from "../../../../Constants/CapabilitiyConstants";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../../ManageTemplate/CreateTemplate/DiscardTemplate/DiscardTemplate";
/* eslint-disable */
import {
  STATUS_APPROVED,
  STATUS_CREATIVE_APPROVED,
  STATUS_CREATIVE_PENDING,
  STATUS_CAMPAIGN_PENDING,
  STATUS_DELETED,
  STATUS_DRAFT,
  STATUS_EXPIRED,
  STATUS_LIVE,
  STATUS_REQUESTED,
  STATUS_TESTING,
  STATUS_NEW
} from "../Status/Status";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id }) => {
  const history = useHistory();
  const { capability, value } = useContext(Context);
  const [context, setContext] = value;
  const [capabilities,setCapabilities] = capability;
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  const deleteContent = async (id) => {
  const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/content/${id}`
    );
    const callParams = {
      method: "DELETE",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        // "user-info": JSON.stringify({ userName: userName, userEmail: email }),
      },
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const mystatus = await serverResponse.json();
    return serverResponse.status;
  };

  const actions = useMemo(() => {
    switch (action.status.toLowerCase()) {
      case STATUS_DRAFT:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div
            key="view"
            className={styles.MenuItem}
            onClick={() => {
              history.push(`/ctmp/content/view-content/${id}`);
            }}
          >
            View
          </div> : "",
            <div
            key="edit"
            className={styles.MenuItem}
            onClick={() => {
              history.push(`/ctmp/content/edit-content/${id}`);
            }}
          >
            Edit
          </div>,
          capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          // <div
          //   key="delete"
          //   onClick={async() => {
          //     let statusVar = await deleteContent(id);
          //     statusVar === 200 &&
          //     localStorage.setItem("forceContentDelete", "true");
          //      statusVar !== 200 &&
          //     localStorage.setItem("forceContentDelete", "false");
          //     setContext("delete")
          //   }}
          //   className={styles.MenuItem}
          // >
          //   Delete
          // </div>:"",
            <PopupDialog
            key={"delete" + id}
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
                <DiscardDialog
                    yesAction={async () => {
                      let statusVar = await deleteContent(id);
                          statusVar === 200 &&
                          localStorage.setItem("forceContentDelete", "true");
                           statusVar !== 200 &&
                          localStorage.setItem("forceContentDelete", "false");
                          setContext("delete")
                    }}
                    noAction={closeModal}
                    className="Delete Content"
                    visiblity="true"
                />
            )}
            renderControlContent={(openModal) => (
                <div
                    key="deleteContent"
                    onClick={openModal}
                    className={styles.MenuItem}
                >
                    Delete
                </div>
            )}
        /> : ""
        ];
      case STATUS_REQUESTED:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div
            key="view"
            className={styles.MenuItem}
            onClick={() => {
              // history.push(`/ctmp/template/view-content/${id}`);
            }}
          >
            View
          </div> : "",
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div> : "",
            capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_CREATIVE_PENDING:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div key="view" className={styles.MenuItem} onClick={() => {}}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div> : "",
            capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_CAMPAIGN_PENDING:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div key="view" className={styles.MenuItem} onClick={() => {}}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div> : "",
            capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_TESTING:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div key="view" className={styles.MenuItem} onClick={() => {}}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div>:"",
          capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_APPROVED:
      case STATUS_CREATIVE_APPROVED:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div key="view" className={styles.MenuItem} onClick={() => {}}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div>:"",
          capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_LIVE:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div
            key="view"
            className={styles.MenuItem}
            onClick={() => {
              history.push(`/ctmp/content/view-content/${id}`);
            }}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div>:""
        ];
      case STATUS_EXPIRED:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div key="view" className={styles.MenuItem} onClick={() => {}}>
            View
          </div>:"",
          <div
            onClick={() => {
              // window.location.assign(`/ctmp/dashboard/edit-campaign/${id}`)
            }}
            key="edit"
            className={styles.MenuItem}
          >
            Edit
          </div>,
           capabilities.includes(CapabilityConstants.CONTENT_PUBLISH)?
          <div key="publish" className={styles.MenuItem}>
            Publish
          </div>:"",
          capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
          <div
            key="delete"
            onClick={() => {
              deleteCampaign(id);
            }}
            className={styles.MenuItem}
          >
            Delete
          </div>:"",
        ];
      case STATUS_DELETED:
      case STATUS_NEW:
        return [
          capabilities.includes(CapabilityConstants.CONTENT_VIEW)?
          <div
          key="view"
          className={styles.MenuItem}
          onClick={() => {
            history.push(`/ctmp/content/view-content/${id}`);
          }}
        >
          View
        </div> :"",
          <div
          key="edit"
          className={styles.MenuItem}
          onClick={() => {
           // history.push(`/ctmp/content/edit-content/${id}`);
          }}
        >
          Edit
        </div>,
         capabilities.includes(CapabilityConstants.CONTENT_DELETE)?
        <div
          key="delete"
          onClick={() => {
           // deleteCampaign(id);
          }}
          className={styles.MenuItem}
        >
          Delete
        </div> : ""
        ];
      default:
        return [
          <div key="na" className={styles.MenuItem}>
            N/A
          </div>,
        ];
    }
  }, [action]);

  return <div className={styles.MenuContainer}>{actions}</div>;
};

export default RowActionMenu;
