/* eslint-disable */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Context } from "../../../context";
import { useHistory } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import BackButton from "../../General/Button/Button";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";

import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import styles from "./CreateTemplate.module.css";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import { useAppSelector } from "../../../redux/store";

import JSZip from "jszip";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";

const CloneTemplateForm = (id) => {
    /* eslint-disable */

    const history = useHistory();
   
    const [templateName, setTemplateName] = useState();
    const [templateDescription, setTemplateDescription] = useState();
    const [existingTemplates, setExistingTemplates] = useState([]);
    const [cloneExistingTemplate, setCloneExistingTemplate] = useState();
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [viewTemplate, setViewTemplate] = useState(false);
    const [createTemplateObject, setCreateTemplateObject] = useState({});
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const userName = useAppSelector((state) => state.authReducer.userName);
    const email = useAppSelector((state) => state.authReducer.email);
    const pathName = history.location.pathname.split("/");
 
    /*get values of template*/
    useEffect(async() => {
        const url = new URL(
            `${process.env.REACT_APP_API_HOSTNAME}v1/template/search`
        );
        const callParams = {
            method: "POST",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({ "status": ["Published"] })
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
            const res = response.templates;
            res.map((template) => {
                setExistingTemplates((existingTemplates) => [
                  ...existingTemplates,
                  { label: template.name, value: template._id }
                ]);
              });
        })
    },[])

 
    /* to check everything is entered or not */
    useEffect(() => {
        let toBeCompletedArray = [];
        toBeCompletedArray = !templateName
            ? [...toBeCompletedArray, "Name"]
            : toBeCompletedArray;
        toBeCompletedArray = !templateDescription
            ? [...toBeCompletedArray, "Description"]
            : toBeCompletedArray;
        toBeCompletedArray =
            !setExistingTemplates || !setExistingTemplates.length < 0
                ? [...toBeCompletedArray, "Channel"]
                : toBeCompletedArray;
        setToBeCompleted(toBeCompletedArray);
    }, [templateName, templateDescription, setExistingTemplates]);

    /* update the values when changed */
    useEffect(() => {
        setCreateTemplateObject({
            name: templateName,
            description: templateDescription,
            createdBy: userName
          });
    }, [templateName, templateDescription]);

    /*save to LocalObject and push to backend*/
    const saveTemplateDetails = async (cloneExistingTemplate) => {
        let url, methodValue;
        createTemplateObject.cloneTemplateId = cloneExistingTemplate.value;
        url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/template/clone`);
       methodValue = "POST";

        let callParams = {
            method: methodValue,
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
                "X-CORRELATIONID": 0
            },
            body: JSON.stringify(createTemplateObject)
        };

        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = await serverResponse.json();
        console.log("serverResponseJson status - " + serverResponseJson.status);
        return serverResponse.status;
    };

    return (
        <div style={{ textAlign: "left" }}>
            <p className={styles.UpdateFormHeader}>
                {pathName[3] === "edit-template" ? "Edit template setup" :
                    pathName[3] === "view-template" ? "View template setup" :
                        "Clone template setup"}
            </p>
            <span className={styles.UpdateFormSubTitle}>
                Create a clone of existing template in use on the platform.
            </span>
        
            <LabeledSelect
                className={styles.Input}
                options={existingTemplates}
                placeholder="Select"
                value={cloneExistingTemplate}
                defaultLabel={
                    cloneExistingTemplate
                        ? cloneExistingTemplate.label
                        : undefined
                }
                defaultId={
                    cloneExistingTemplate
                        ? cloneExistingTemplate.value
                        : undefined
                }
                title={
                    <>
                        Existing Template<span>*</span>
                    </>
                }
                onValueChange={(option) => setCloneExistingTemplate(option)}
            />

            <LabeledInput
                component_status={false}
                title={<>New Name<span> *</span></>}
                data={templateName}
                placeholder="Type the template name"
                className={
                    id.actionValue === "view-template"
                        ? styles.disabledInput
                        : styles.Input
                }
                onValueChange={(value) => setTemplateName(value)}
            />
            <LabeledInput
                component_status={false}
                title={<>New Description<span> *</span></>}
                placeholder="Type the description"
                className={
                    id.actionValue === "view-template"
                        ? styles.disabledInput
                        : styles.Input
                }
                data={templateDescription}
                //   data={null}
                onValueChange={(value) => setTemplateDescription(value)}
            />

            <div className={styles.floatcontainer_create}>
                {/* <div className={styles.floatchildButton}>
                    <PopupDialog
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={() => {
                                    setContext();
                                    history.push("/ctmp/template");
                                    toast.success(
                                        <Msg id="discard" templateName={templateName} />,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            hideProgressBar: true,
                                            autoClose: 3000,
                                            icon: <Success />,
                                            transition: Slide,
                                            closeOnClick: true
                                        }
                                    );
                                }}
                                noAction={closeModal}
                            />
                        )}
                        renderControlContent={(openModal) => (
                            <BackButton onClick={openModal} name="Discard">
                                Discard
                            </BackButton>
                        )}
                    />
                </div> */}
                {/* save */}
                <div className={styles.floatchild}>
                <PopupDialog
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                            <DiscardDialog
                                yesAction={() => {
                                    setContext();
                                    history.push("/ctmp/template");
                                    toast.success(
                                        <Msg id="discard" templateName={templateName} />,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            hideProgressBar: true,
                                            autoClose: 3000,
                                            icon: <Success />,
                                            transition: Slide,
                                            closeOnClick: true
                                        }
                                    );
                                }}
                                noAction={closeModal}
                            />
                        )}
                        renderControlContent={(openModal) => (
                            <BackButton onClick={openModal} name="DiscardCreateClone">
                                Discard
                            </BackButton>
                        )}
                    />
                </div>
                <div className={styles.floatchild}>
                    <div className={styles.saveClone}>
                        {toBeCompleted.length === 0 ? (
                            <div>
                                <BackButton
                                    onClick={async () => {
                                        const statusVar = await saveTemplateDetails(cloneExistingTemplate);
                                        localStorage.setItem("blockNavigation", "false");
                                        history.push("/ctmp/template");
                                        statusVar !== 200 &&
                                            toast.error(
                                                <ErrorMsg id="save" templateName={templateName} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Error />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                        statusVar === 200 &&
                                            toast.success(
                                                <Msg id="save" templateName={templateName} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Success />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                    }}
                                    name="TemplateSave"
                                />
                            </div>
                        ) : (
                            <PopupDialog
                                height="30rem"
                                width="67rem"
                                renderPopupContent={(closeModal) => (
                                    <SaveDialog
                                        saveAction={async () => {
                                            const statusVar = await saveTemplateDetails();
                                            localStorage.setItem("blockNavigation", "false");
                                            closeModal();
                                            history.push("/ctmp/template");
                                            statusVar !== 200 &&
                                                toast.error(
                                                    <ErrorMsg id="save" templateName={templateName} />,
                                                    {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true,
                                                        autoClose: 3000,
                                                        icon: <Error />,
                                                        transition: Slide,
                                                        closeOnClick: true
                                                    }
                                                );
                                            statusVar === 200 &&
                                                toast.success(
                                                    <Msg id="save" templateName={templateName} />,
                                                    {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true,
                                                        autoClose: 3000,
                                                        icon: <Success />,
                                                        transition: Slide,
                                                        closeOnClick: true
                                                    }
                                                );
                                        }}
                                        discardAction={closeModal}
                                        itemsToBeComplete={toBeCompleted}
                                    />
                                )}
                                renderControlContent={(openModal) => (
                                    <BackButton onClick={openModal} name="TemplateSave">
                                        Save
                                    </BackButton>
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CloneTemplateForm;
