/* eslint-disable  */
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";

import styles from "./ManageUserTableHead.module.css";
import { SelectStyle } from "../../../General/Selector/Selector";
import MultiSelector from "../../../General/MultiSelector/MultiSelector";
import LabeledSelect from "../../../General/LabeledSelect/LabeledSelect";
// import ManageUserTableFilters from "./ManageUserTableFilters/ManageUserTableFilters";

const options = [
  { label: "Marketing", value: "marketing" },
  { label: "CTMP", value: "ctmp" },
  { label: "Creative Design", value: "creativedesign" },
  { label: "Testing", value: "testing" }
];

const ManageUserTableHead = ({ onSortChange, onFiltersChange, filters }) => {
  const [teamsList, setTeamsList] = useState();
  const [rolesList, setRolesList] = useState();
  let teams = [];
  let roles = [];

  const selectStylesApiObject = React.useMemo(() => {
    return {
      ...SelectStyle,
      control: (provided) => ({
        ...provided,
        border: ".1rem solid #CECFD0"
      })
    };
  }, []);

  return (
    <div className={styles.TableHead}>
      <div className={styles.TableHeadLeft}>
        <div className={styles.SortByContainer}>
          <label className={styles.SortBy}>Sort By</label>
          {/* <Select className={ styles.SortBySelect }
                        placeholder="Team"
                        styles={ selectStylesApiObject }
                        isClearable
                        options={ options }
                        onChange={ (sortByOption) => {
                            onSortChange(
                                sortByOption ? sortByOption.value : undefined);
                        } }
                /> */}
          {/* <Select
            className={styles.SortBySelect}
            placeholder="Team"
            isClearable
            styles={selectStylesApiObject}
            options={options}
            onChange={(teamsList) => {
              setTeamsList(teamsList);
              rolesList != undefined ? roles.push(rolesList) : "";
              teams.push(teamsList);
              onFiltersChange({
                ...(teamsList && { teams }),
                ...(rolesList && { roles })
              });
            }}
          /> */}
          <LabeledSelect
          className={ styles.SortBySelectRole }
          optionsUrl="v1/user/distinctTeams"
          //options={ options_role }
          placeholder="Team"
          isClearable
          value=""
          defaultLabel={""}
          defaultId={""}
          title=""
          onValueChange={(teamsList) => {
            setTeamsList(teamsList);
            rolesList != undefined ? roles.push(rolesList) : "";
            teams.push(teamsList);
            onFiltersChange({
              ...(teamsList && { teams }),
              ...(rolesList && { roles })
            });
          }}
        />
        </div>
        {/* <ManageUserTableFilters onFiltersChange={ onFiltersChange } filters={filters}/> */}

        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        {/* <Select className={ styles.SortBySelect }
                        placeholder="Role"
                        styles={ selectStylesApiObject }
                        isClearable
                         options={ options_role }
                        // optionsUrl="v1/ctmp/user/role"
                        onChange={ (rolesList) => 
                            {
                            setRolesList(rolesList);
                             teamsList !== undefined ? teams.push(teamsList) : ""
                             roles.push(rolesList)
                             onFiltersChange({
                                ...(rolesList && { roles }),
                                ...(teamsList && { teams }),
                                })
                             }    
                          }
                /> */}
        <LabeledSelect
          className={ styles.SortBySelectRole }
          optionsUrl="v1/ctmp/user/role"
          //options={ options_role }
          placeholder="Role"
          isClearable
          value=""
          defaultLabel={""}
          defaultId={""}
          title=""
          onValueChange={(rolesList) => 
            {
            setRolesList(rolesList);
             teamsList !== undefined ? teams.push(teamsList) : ""
             roles.push(rolesList)
             onFiltersChange({
                ...(rolesList && { roles }),
                ...(teamsList && { teams })
                })
             }}
        />
      </div>
    </div>
  );
};

export default ManageUserTableHead;
