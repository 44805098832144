/* eslint-disable */
import React,{ Fragment, useEffect, useState } from "react";
import styles from "./CreateCampaign.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import PageContent from "../../General/PageContent/PageContent";
import { useAppSelector } from "../../../redux/store";

const Preview = (id) => {
    const [selectedImage,setSelectedImage] = useState();
    let jwt = useAppSelector((state) => state.authReducer.jwt);

    useEffect(() => {
    if (id) {
       const getImageDetails = async () => {
        const url = new URL(
            `${process.env.REACT_APP_API_HOSTNAME}v1/template/${id.id}/image-previewurl`
          );
      
          const callParams = {
            method: "GET",
            headers: {
              "x-api-key": `${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`
            }
          };
          const serverResponse = await fetch(url.toString(), callParams);
          const serverResponseJson = serverResponse.json().then((response) => {
          const res = response;
          setSelectedImage(res.previewImageUrl)
        });
      };
      getImageDetails();
    }
  }, []);

  return (
    <Fragment>
     <TitleWithPathHeader
        title="Preview Template"
        path="/manage campaign/preview template"
      />
   
   <PageContent className={styles.CreateCampaign}>
    <div className={styles.BigSvg}>
    <img src={selectedImage?selectedImage:"No Image Found"} />
    </div>
    </PageContent>
    </Fragment>
  );
};

export default Preview;
