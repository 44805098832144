/* eslint-disable*/
import styles from "./AddUser.module.css";
import React, { useState, useCallback, useEffect } from "react";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import RadioButtonControl from "../../General/RadioButtonControl/RadioButtonControl";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import MultiSelector from "../../General/MultiSelector/MultiSelector";
/*alerts imports*/
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";

const EditTeam = () => {
  const [teamName, setTeamName] = useState();
  const [teamDescription, setTeamDescription] = useState();
  // const [teamMembers , setTeamMembers] = useState();
  const [teamList, setTeamList] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [editTeamObject,setEditTeamObject] = useState({});
  const history = useHistory();
  const jwt = useAppSelector((state) => state.authReducer.jwt);

/* to check everything is entered or not */
useEffect(() => {
  let toBeCompletedArray = [];
  toBeCompletedArray = !teamName
    ? [...toBeCompletedArray, "Team Name"]
    : toBeCompletedArray;
  toBeCompletedArray = !teamDescription
    ? [...toBeCompletedArray, "Team Description"]
    : toBeCompletedArray;
  // toBeCompletedArray = !teamMembers || !teamMembers.length
  //   ? [...toBeCompletedArray, "Select Team Members"]
  //   : toBeCompletedArray;
  toBeCompletedArray = !teamList
    ? [...toBeCompletedArray, "Select Team"]
    : toBeCompletedArray;
  setToBeCompleted(toBeCompletedArray);
}, [teamName, teamDescription, teamList]);

  /* update the values when changed */
  useEffect(() => {
    setEditTeamObject({
      teamName: teamName,
      teamDescription:teamDescription
      // ...(teamMembers && {
      //   teamMembers: teamMembers.map((c) => ({
      //     name: c.label,
      //     id: c.value,
      //   })),
      // })
    });
  }, [
    teamName,
    teamDescription
    ]);

  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );

  const clearFields = () => {
    setEditTeamObject({});
    setTeamName([]);
    setTeamDescription([]);
   };

  const populateTeamValues = async (option) => {
    clearFields();
    setTeamList(option);
    //console.log("--->" + JSON.stringify(option));
    if (option === "" || option === null) {
      clearFields();
    } else {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/user/teams/${option.value}`
      );
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
          "X-CORRELATIONID": 0
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
       console.log(JSON.stringify(serverResponseJson.teamDetails));
       const res = serverResponseJson.teamDetails;
       setTeamName(res.teamName);
       setTeamDescription(res.teamDescription)
    }
  };

 /* save Team */
 const saveTeam = async(editTeamObject) => {
  console.log("--->" + JSON.stringify(editTeamObject));
  console.log(JSON.stringify(teamList))
  const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/teams/${teamList.value}`);
  
  let callParams = {
    method: "PUT",
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
      "X-CORRELATIONID": 0
    },
    body: JSON.stringify(editTeamObject)
  };
   const serverResponse = await fetch(url.toString(), callParams);
   const serverResponseJson = await serverResponse.json();
   return serverResponse.status;
};

  return (
    <React.Fragment>
      <div className={styles.EditRoleContentDisplay}>
        <div className={styles.RoleContainer}>
          <div style={{ paddingTop: "4rem", paddingLeft: "4rem" }}>
            <p className={styles.UpdateFormHeader}>Edit existing team</p>
            <span className={styles.UpdateFormSubTitle}>
              To add a existing team, please start by selecting it from the
              dropdown menu first:
            </span>
            <div
              
              style={{ marginBottom: "4rem" }}
            >
              <LabeledSelect
                optionsUrl="v1/user/distinctTeams"
                className={styles.Input}
                value={teamList}
                defaultLabel={teamList ? teamList.label : undefined}
                defaultId={teamList ? teamList.value : undefined}
                // options={RoleValues}
                title={
                  <>
                    Select existing team<span> *</span>
                  </>
                }
                onValueChange={(value) => {
                  setTeamList(value);
                  populateTeamValues(value);
                }}
              />
            </div>
            <hr />
            <div className={!teamList ? styles.disable : ""}>
              <div>
                <LabeledInput
                  title={
                    <>
                      Team Name<span> *</span>{" "}
                    </>
                  }
                  data={teamName}
                  placeholder="Team Name"
                  className={styles.Input}
                  onValueChange={(value) => setTeamName(value)}
                />
              </div>
              <div>
                <LabeledInput
                  title={
                    <>
                      Team Description<span> *</span>{" "}
                    </>
                  }
                  data={teamDescription}
                  placeholder="Description"
                  className={styles.Input}
                  onValueChange={(value) => setTeamDescription(value)}
                />
              </div>
              {/* <div style={{marginTop:"4rem",width:"50%"}}>
            <label>
                    Team Members<span> *</span>
            </label>
            <MultiSelector
                  className={styles.Select}
                  name="Select"
                  optionsUrl="v1/ctmp/subcategories"
                  // optionsUrl="v1/ctmp/status"
                  valuesChanged={(teamMembers) =>
                    setMultiProperty(teamMembers, setTeamMembers)
                  }
                  value={teamMembers}
                />
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={!teamList ? styles.disable : ""}>
        <div
          className={styles.SaveAndDiscardButton}
          style={{ marginTop: "5rem" }}
        >
          <SaveAndDiscardWithPopupButtonSet
            yesPopupHeight="39rem"
            noPopupHeight="55rem"
            yesTitle="Save Changes"
            noTitle="Discard"
            className={styles.Buttons}
            renderDiscardPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/user-management");
                }}
                noAction={closeModal}
              />
            )}
            renderSavePopupContent={(closeModal) => (
              <SaveDialog
                itemsToBeComplete={toBeCompleted}
                saveAction={async () => {
                   var result =  await saveTeam(editTeamObject);
                   if(result === 200){
                    let displayMessage =  "The team "+editTeamObject.teamName +" has been successfully updated."
                    closeModal();
                    history.push("/ctmp/user-management");
                    toast.success(
                      <Msg message={displayMessage}/>,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                   }
                   else{
                    closeModal();
                    let displayMessage = "Failed to update the team "+editTeamObject.teamName +" please try again."
                    history.push("/ctmp/user-management");
                        toast.error(
                          <ErrorMsg message={displayMessage}/>,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        )
                   }
                  }}
                discardAction={closeModal}
                className="Edit_Team"
              />
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default EditTeam;
