import React, { useState } from "react";
import styles from "./AddComment.module.css";
import { useAppSelector } from "../../../../../redux/store";

/* eslint-disable  @typescript-eslint/no-unused-vars */

const AddComment = (props) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
   const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);
  const [comment, setComment] = useState("");
  const [toggle,setToggle] = useState(true);

  const submitComment = async () => {
    // let url = new URL(
    //   `https://mktpl.dev.telematicsct.com/v1/campaigns/${props.data.campaignId}/versions/${props.data.versionNumber}`
    // );
    let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${props.data.campaignId}/versions/${props.data.versionNumber}`);
    let callParams = {
      method: "PUT",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
        // "user-name": userName,
        // "user-email": email
      },
      body: JSON.stringify({
        comment: comment,
        commentDate: Date.now(),
        commentBy: userName
      })
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const mystatus = await serverResponse.json();
    console.log(mystatus)
    props.showComment(false);
    props.reload(true);
   // console.log(serverResponse);
  };

  return (
    <React.Fragment>
      <div className={styles.ItemHistory}>
        <span className={styles.TrailLine} />
        {/* <div className={styles.TrailItemSpace}/> */}
        <div className={styles.CommentContent}>
          <form>
            <textarea
              style={{ display: toggle ? "block" : "none" }}
              className={styles.Textbox}
              onChange={(event) => setComment(event.target.value)}
              placeholder={"Add comment..."}
            ></textarea>
          </form>
          <input
            className={styles.DoneBtn}
            type="button"
            value="Send"
            onClick={submitComment}
          />
          <input
            className={styles.CancelBtn}
            type="button"
            value="Cancel"
            onClick={() => { setToggle(false); props.showComment(false); }}
          />
        </div>
      </div>
      <div className={styles.Status} />
    </React.Fragment>
  );
};

export default AddComment;
