import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    
     }
  }
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {
  labels,
  datasets: [
    {
      label: 'Delivery',
      data:[1552, 1319, 613, 1400,1200],
      backgroundColor: 'rgb(255, 99, 132)'
     },
    {
      label: 'Opened',
      data:[1252, 1000, 500, 1200,1000],
      backgroundColor: 'rgb(75, 192, 192)'
    },
    {
      label: 'Clicked',
      data:[150, 105, 120, 90,500],
      backgroundColor: 'rgb(53, 162, 235)'
    }
  ]
};

 function FailedBillingChart() {
  return <Bar options={options} data={data} />
}

export default FailedBillingChart;