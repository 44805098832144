import React, {useState} from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';

const Box = ({ boxRef,keys,value }) => {
    const [isShown, setIsShown] = useState(true);
    return <Card ref={boxRef} style={{width: "10rem",marginTop:"10px"}}>
     {isShown?<Card.Header onMouseEnter={()=> setIsShown(false)}> {keys} </Card.Header>: <Card.Header onMouseLeave={()=> setIsShown(true)} style={{backgroundColor:"#5592FF"}}><Row><Col><FiEdit2 size="1em"/></Col><Col><MdDelete size="1em"/></Col></Row> </Card.Header>}
    <ListGroup variant="flush">
      <ListGroup.Item> {value} </ListGroup.Item>
    </ListGroup>
  </Card>;
};

export default Box;