import { ADD_CAMPAIGN } from "../action-types";

const initialState = {
    campaigns: []
}

const manageCampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CAMPAIGN:
            return {
                ...state,
                campaigns: [...state.campaigns, action.payload]
            }
        default:
            return state;
    }
}

export default manageCampaignReducer;