import React, {useContext} from "react";
import styles from "./VersionControlTable.module.css";
import { Context } from "../../../../context";
import VersionHistoryItem from "../../../General/VersionHistoryItem/VersionHistoryItem";
import VersionControlTemplateGroup from "./VersionControlTemplateGroup/VersionControlTemplateGroup";
import { format } from "date-fns";

const VersionControlTable = (props) => {
  const { user } = useContext(Context);
  const [role] = user;

  let haveData = null;
  // let versions = props.data.slice().reverse();
  let versions = props.data.sort((a, b) => parseFloat(a._id) - parseFloat(b._id)).reverse();
  if (props.data.length > 0) {
    haveData = true;
  }
  // console.log(props);

  return (
    <React.Fragment>
      <hr className={styles.HorizonalSep} />
      <div className={styles.TableRow}>
        <div className={styles.TableCol}>
          <label className={styles.ColTitle}>Latest</label>
          {haveData
            ? versions.map((item, index) => (
                <VersionHistoryItem
                  campaignId={item.campaignId}
                  key={item._id}
                  versionNumber={item.version}
                  // datetime={"12:00pm, Fri, 04 May 2035"}
                  // status={item.status}
                  datetime={format(new Date(item.modifiedDate), "dd-MMM-yyyy, HH:MM:SS")}
                  status = {item.roleStatus.filter(item => item.role === role)[0].status}
                  roleStatus = {item.roleStatus}
                  index={index}
                  comments={item.comments}
                  reload={props.reload}
                  length={versions.length}
                />
              ))
            : "Loading..."}
        </div>
        <span className={styles.VerticalSep} />
        <div className={styles.TableCol}>
          <VersionControlTemplateGroup data={props.data}/>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VersionControlTable;
