import React from "react"

import styles from "./ChannelCell.module.css"

const ChannelCell = ({data}) => {
    return <div className={styles.ChannelCellContainer}>
        {data.map((item, index) => (<span key={index} className={styles.ChannelItem}>{item}</span>))}
    </div>
}

export default ChannelCell