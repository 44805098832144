import React, { Fragment, useState } from "react";

import styles from "./ManageCampaign.module.css";
import ManageCampaignHeader from "./ManageCampaignHeader/ManageCampaignHeader";
import ManageCampaignTable from "./ManageCampaignTable/ManageCampaignTable";
import VersionControl from "./VersionControl/VersionControl";
import Preview from "./CreateCampaign/Preview";
// import VersionPreview from "./VersionControl/VersionPreview/VersionPreview";
/* eslint-disable  @typescript-eslint/no-unused-vars */

import CreateCampaign from "./CreateCampaign/CreateCampaign";
import { Route, Switch } from "react-router-dom";
import ManageCampaignPriority from "./ManageCampaignTable/ManageCampaignPriority";

const ManageCampaign = ({ match }) => {
    const [search, setSearch] = useState();

    const [campaignDetails, setCampaignDetails] = useState();

    return <div className={styles.ManageCampaign}>
        <Switch>

            <Route path={match.url + "/edit-campaign/:id/:version"} render={(match) => <Fragment>
                <CreateCampaign campaignId={match.match.params.id} version={match.match.params.version} />
            </Fragment>} />

            <Route path={match.url + "/view-campaign/:id/:version"} render={(match) => <Fragment>
                <CreateCampaign campaignId={match.match.params.id} version={match.match.params.version} />
            </Fragment>} />

            <Route path={match.url + "/create-campaign"} component={CreateCampaign} />
            <Route
                path={match.url + "/preview/:id"}
                render={(match) => (
                <Fragment>
                <Preview id={match.match.params.id} />
                </Fragment>
            )}
             />

            <Route path={match.url + "/campaign-priority"} component={ManageCampaignPriority} />

            <Route exact path={match.url} render={() => <Fragment>
                <ManageCampaignHeader onSearch={(search) => setSearch(search)} />
                <ManageCampaignTable search={search} />
            </Fragment>} />

            <Route path={match.url + "/version-control/:id"} render={({ match }) => (
                <VersionControl id={match.params.id} match={match} />
            )} />

        </Switch>
    </div>;
};

export default ManageCampaign;