/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { useAppSelector } from "../../../../redux/store"
import styles from "./CreateCustomerRight.module.css";
import LabeledInput from "../../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../../General/LabeledSelect/LabeledSelect";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory, Route, Switch } from "react-router-dom";
import RuleForm from "../RuleForm/RuleForm";
import SaveAndDiscardWithPopupButtonSet from "../../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "../../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import PopupDialog from "../../../General/PopupDialog/PopupDialog";
import Button from "../../../General/Button/Button";
import BackButton from "../../../General/Button/Button";
import DiscardDialog from "./DiscardCustomer/DiscardCustomer";
import moment from "moment";
import PublishDialog from "../../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";
import { Context } from "../../../../context";
import { CapabilityConstants } from "../../../Constants/CapabilitiyConstants";
import { CustomerListConstants } from "../../../Constants/CustomerListConstants";
import LoadSpinner from "../../../General/LoadSpinner/LoadSpinner";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";

const EditList = ({ navSelected, id }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [subNavSelected, setSubNavSelected] = useState();
  const [name, setName] = useState();
  const [selectValue, setSelectValue] = useState();
  const [description, setDescription] = useState();
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [usageDetails, setUsageDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [compileList, setCompileList] = useState([]);
  const [customerList, setCustomerList] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [percentageCount, setPercentageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [editMode, setEditMode] = useState();
  const { capability } = useContext(Context);
  const [capabilities, setCapabilities] = capability;
  const [isLoaded, setIsLoaded] = useState(false);


  /*get Values from database when user clicks edit in customerList*/
  useEffect(() => {
    if (id !== undefined) {
      const getCustomerDetails = async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.customerlists[0];
          setName(res.name);
          setDescription(res.shortDescription);
          res.vehicleDetails.map((vehicle) => {
            setVehicleDetails((vehicleDetails) => [
              ...vehicleDetails,
              vehicle
            ]);
          });
          res.subscriptionDetails.map((subscription) => {
            setSubscriptionDetails((subscriptionDetails) => [
              ...subscriptionDetails,
              subscription
            ]);
          });
          res.usageDetails.map((usage) => {
            setUsageDetails((usageDetails) => [
              ...usageDetails,
              usage
            ]);
          });
          res.userDetails.map((user) => {
            setUserDetails((userDetails) => [
              ...userDetails,
              user
            ]);
          });
          res.compileList.map((compile) => {
            setCompileList((compileList) => [
              ...compileList,
              compile
            ]);
          });
        });
      };
      getCustomerDetails();
      // console.log("--in Edit--->"+pathName[4])
      if (pathName[4] === "edit") {
        setEditMode(true);
      } else {
        setEditMode(false)
      }
    }
  }, []);

  useEffect(() => {
    setCustomerList({
      name: name,
      description: description,
      vehicleDetails: vehicleDetails,
      subscriptionDetails: subscriptionDetails,
      usageDetails: usageDetails,
      userDetails: userDetails,
      compileList: compileList
    });
  }, [
    name,
    description,
    vehicleDetails,
    subscriptionDetails,
    usageDetails,
    userDetails,
    compileList
  ]);

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !name
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !description
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !(vehicleDetails.length > 0) &&
        !(subscriptionDetails.length > 0) &&
        !(usageDetails.length > 0) &&
        !(userDetails.length > 0) &&
        !(compileList.length > 0)
        ? [...toBeCompletedArray, "Rules"]
        : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [name, description, vehicleDetails, subscriptionDetails, usageDetails, userDetails, compileList]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setIsLoaded(true);
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/count/1000`
      );
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      //Calculate percentage of customers
      //% of customers = (Final user list generated by the Compile List /Total customers) X 100
      setPercentageCount(Math.round((serverResponseJson.compiledCount / serverResponseJson.totalCustomerCount) * 100));
      //Show customerlist on the screen based on compiled count
      setTotalCount(serverResponseJson.compiledCount);
      setIsLoaded(false);
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  const saveCustomerList = async () => {
    setIsLoaded(true)
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`)
    let callParams = {
      method: "PUT",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    return serverResponse.status;
  };

  const PublishCustomerList = async () => {
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`)
    customerList.status = CustomerListConstants.PUBLISH;
    customerList.goLiveDate = moment().valueOf();
    let callParams = {
      method: "PUT",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(customerList)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status)
  };
  return (
    <React.Fragment>
      <div style={{ textAlign: "left" }}>
        <div className={styles.SubHeading}>{editMode === true ? "Edit List" : "View List"}</div>
        <div className={styles.HeadingDescription}>
          {editMode === true ? "Continue making changes to a list that is in Draft state." : "Visualise unpublished customer list data."}
        </div>
        <div className={styles.InputContainer}>
          <div className={styles.InputFields}>
            {/* CREATE NEW LIST */}
            <React.Fragment>
              {(isLoaded === true) ? <LoadSpinner /> : ""}
              {/* <LabeledSelect
              className={styles.Select}
              optionsUrl="v1/ctmp/category"
              title={"Select"}
              onValueChange={(option) => console.log("---->"+option)}
            /> */}
              <LabeledInput
                title={
                  <>
                    Name<span> *</span>
                  </>
                }
                className={editMode === true ? styles.CreateCustomerCombo : styles.CreateCustomerComboDisabled}
                data={name}
                onValueChange={(value) => setName(value)}
              />
              <LabeledInput
                title={<>
                  Description<span> *</span>
                </>}
                className={editMode === true ? styles.CreateCustomerCombo : styles.CreateCustomerComboDisabled}
                data={description}
                onValueChange={(value) => setDescription(value)}
              />
            </React.Fragment>
          </div>
          {/* <div className={styles.Chart}>
          Customer Count
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={percentageCount}
                text={`${percentageCount}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#0A9F98"
                })}
              />
            </div>
            <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div>
          </div>
              </div> */}
        </div>

        {/* Subnav with rule builder */}
        <div className={styles.TableNav2}>
          <div
            className={
              pathName[5] === "vehicle-details"
                ? styles.NavItemSelected
                : styles.NavItem
            }
            onClick={() => {
              setSubNavSelected("vehicle-details");
              history.push(
                `/ctmp/customer-list/create-customer/${navSelected}/vehicle-details/${id}`
              );
            }}
          >
            Vehicle Details
          </div>
          <div
            className={
              pathName[5] === "subscription-details"
                ? styles.NavItemSelected
                : styles.NavItem
            }
            onClick={() => {
              setSubNavSelected("subscription-details");
              history.push(
                `/ctmp/customer-list/create-customer/${navSelected}/subscription-details/${id}`
              );
            }}
          >
            Subscription Details
          </div>
          <div
            className={
              pathName[5] === "usage-details"
                ? styles.NavItemSelected
                : styles.NavItem
            }
            onClick={() => {
              setSubNavSelected("usage-details");
              history.push(
                `/ctmp/customer-list/create-customer/${navSelected}/usage-details/${id}`
              );
            }}
          >
            Usage Details
          </div>
          <div
            className={
              pathName[5] === "user-details"
                ? styles.NavItemSelected
                : styles.NavItem
            }
            onClick={() => {
              setSubNavSelected("user-details");
              history.push(
                `/ctmp/customer-list/create-customer/${navSelected}/user-details/${id}`
              );
            }}
          >
            User Details
          </div>
          <div
            className={
              pathName[5] === "compile-list"
                ? styles.NavItemSelected
                : styles.NavItem
            }
            onClick={() => {
              setSubNavSelected("compile-list");
              history.push(
                `/ctmp/customer-list/create-customer/${navSelected}/compile-list/${id}`
              );
            }}
          >
            Compile List
          </div>
        </div>

        <Switch>
          <Route
            path={`/ctmp/customer-list/create-customer/${navSelected}/vehicle-details/${id}`}
            render={() => (
              <React.Fragment>
                <RuleForm
                  nodes={vehicleDetails}
                  setNodes={setVehicleDetails}
                  endpoint={"vehicle-fields"}
                  subNavSelected={subNavSelected}
                  showRC={true}
                  classRef={editMode === true ? "edit" : "view"}
                />
                {SaveAndDiscardListButtons()}
              </React.Fragment>
            )}
          />
          <Route
            path={`/ctmp/customer-list/create-customer/${navSelected}/subscription-details/${id}`}
            render={() => (
              <React.Fragment>
                <RuleForm
                  nodes={subscriptionDetails}
                  setNodes={setSubscriptionDetails}
                  endpoint={"subscription-fields"}
                  subNavSelected={subNavSelected}
                  showRC={true}
                  classRef={editMode === true ? "edit" : "view"}
                />
                {SaveAndDiscardListButtons()}
              </React.Fragment>
            )}
          />
          <Route
            path={`/ctmp/customer-list/create-customer/${navSelected}/usage-details/${id}`}
            render={() => (
              <React.Fragment>
                <RuleForm
                  nodes={usageDetails}
                  setNodes={setUsageDetails}
                  endpoint={"usage-fields"}
                  subNavSelected={subNavSelected}
                  showRC={true}
                  classRef={editMode === true ? "edit" : "view"}
                />
                {SaveAndDiscardListButtons()}
              </React.Fragment>
            )}
          />
          <Route
            path={`/ctmp/customer-list/create-customer/${navSelected}/user-details/${id}`}
            render={() => (
              <React.Fragment>
                <RuleForm
                  nodes={userDetails}
                  setNodes={setUserDetails}
                  endpoint={"user-fields"}
                  subNavSelected={subNavSelected}
                  showRC={true}
                  classRef={editMode === true ? "edit" : "view"}
                />
                {SaveAndDiscardListButtons()}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={`/ctmp/customer-list/create-customer/${navSelected}/compile-list/${id}`}
            render={() => (
              <React.Fragment>
                <RuleForm
                  /* nodes={compileList} */
                  nodes={[...vehicleDetails, ...subscriptionDetails, ...usageDetails, ...userDetails]}
                  setNodes={setCompileList}
                  endpoint={"compile-list"}
                  subNavSelected={subNavSelected}
                  showRC={false}
                  classRef={editMode === true ? "edit" : "view"}
                />
              </React.Fragment>
            )}
          />
        </Switch>
      </div>
    </React.Fragment>
  );

  function SaveAndDiscardListButtons() {
    return editMode === true ? (
      <div className={styles.floatcontainer}>
        <div className={styles.floatchild}>
          <PopupDialog
            height="30rem"
            width="67rem"
            renderPopupContent={(closeModal) => (
              <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/customer-list");
                }}
                noAction={closeModal}
                className="Discard"
              />
            )}
            renderControlContent={(openModal) => (
              <Button
                onClick={openModal}
                name="Discard"
              />
            )}
          />
        </div>
        <div className={styles.floatchild}>
          <div className={styles.floatchild1}>
            {/* save */}
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    var status = await saveCustomerList();
                    closeModal();
                    setIsLoaded(false)
                    status !== 200 &&
                      toast.error(
                        <ErrorMsg
                          id="save"
                          templateName={name}
                        />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    status === 200 &&
                      toast.success(
                        <Msg id="save" templateName={name} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                  }}
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted} />
              )}
              renderControlContent={(openModal) => (
                <Button
                  onClick={openModal}
                  name="BtnCustomerEditSave"
                />
              )}
            />
          </div>
          <div className={styles.floatchild4}>
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="55rem"
              noPopupHeight="30rem"
              yesTitle="Publish" //save
              noTitle="Save & Close"  //discard
              className={styles.Buttons}
              renderDiscardPopupContent={(closeModal) => (
                //save and close
                <SaveDialog
                  saveAction={async () => {
                    let status = await saveCustomerList();
                    closeModal();
                    history.push("/ctmp/customer-list");
                    setIsLoaded(false)
                    status !== 200 &&
                      toast.error(
                        <ErrorMsg
                          id="save"
                          templateName={name}
                        />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    status === 200 &&
                      toast.success(
                        <Msg id="save" templateName={name} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                  }}
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted} />
              )}
              renderSavePopupContent={(closeModal) => (
                <PublishDialog
                  noNote={true}
                  yesAction={async () => {
                    // publish method
                    await PublishCustomerList();
                    closeModal();
                    history.push("/ctmp/customer-list");
                  }}
                  noAction={closeModal}
                  className="PublishCustomerList"
                  headerText={capabilities.includes(CapabilityConstants.CUSTOMERLIST_PUBLISH) ? "Publish Message" : "No_Permissions"}
                  itemsToBeComplete={toBeCompleted} />
              )} />
          </div>
        </div>
      </div>) : <div> <BackButton
        onClick={() => {
          history.push("/ctmp/customer-list");
        }}
        name="Back_CustomerList"
      /></div>;
  }
};

export default EditList;
