import React, { Fragment, useCallback, useState } from "react";
import Calendar from "react-date-range/dist/components/Calendar";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./DateSelectorContent.module.css";

const DateSelectorContent = ({ closeModal, onDiscard, onApply }) => {

    const [date, setDate] = useState();

    const closeModalAndPerformAction = useCallback((action) => {
        closeModal();
        action();
    }, [closeModal]);

    console.log(closeModal);
    return <Fragment>
        <span className={ styles.DateTitle }>Date</span>
        <Calendar
            className={ styles.DatePickerWrapper }
            onChange={ (date) => setDate(date) }
            // minDate = {new Date()}
            date={ date }
        />
        <div className={ styles.DateButtonContainer }>
            <button onClick={ () => closeModalAndPerformAction(() => onDiscard()) }
                    className={ styles.DiscardButton }>Discard
            </button>
            <button onClick={
                () => closeModalAndPerformAction(() => onApply(date))
            }
                    className={ styles.ApplyButton }>Apply
            </button>
        </div>
    </Fragment>;
};

export default DateSelectorContent;