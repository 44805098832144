import React, { useState, useCallback, useMemo, useEffect } from "react";

import styles from "./MultiSelector.module.css";
import MultiSelectorOption from "./MultiSelectorOption/MultiSelectorOption";
import OutsideClickDetector from "../Utility/OutsideClickDetector";
import { useAppSelector } from "../../../redux/store";


const refineOptions = (options) => options && options.length ? options.reduce(
    (total, next) =>
        total.findIndex(op => op.value === next.value) !== -1 ? [...total]
            : [...total, next], [])
    :
    [];

const MultiSelector = ({
    name,
    options = [],
    className,
    valuesChanged,
    optionsUrl,
    value,
    key
}) => {

    const jwt = useAppSelector(state => state.authReducer.jwt)
    // const userName = useAppSelector((state) => state.authReducer.userName);
    // const email = useAppSelector((state) => state.authReducer.email);
    const [selectedItems, setSelectedItems] = useState([]);
    const [wrapperStyles, setWrapperStyles] = useState(
        [styles.SelectBoxOptions, styles.SelectBoxHidden]);
    const [remoteOptions, setRemoteOptions] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (value) {
            setSelectedItems(value);
        } else {
            setSelectedItems([]);
        }
    }, [value]);


    useEffect(() => {
        optionsUrl && (async () => {
            setLoading(true);
            // let url = new URL(
            //     `https://mktpl.dev.telematicsct.com/${ optionsUrl }`);
            let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}${optionsUrl}`);
            let callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Authorization": `Bearer ${jwt}`
                    // "user-name": userName,
                    // "user-email": email

                }
            };
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            setRemoteOptions(serverResponseJson.map(o => ({
                value: o.id,
                label: o.name
            })));
            setLoading(false);
        })();
    }, [optionsUrl]);

    const refinedOptions = useMemo(() => {
        return optionsUrl ? refineOptions(remoteOptions) : refineOptions(
            options);
    }, [options, remoteOptions, optionsUrl]);


    const clearCallBack = useCallback(() => {
        setSelectedItems((_currentSelectedItems) => {
            return [];
        });
    }, []);

    const toggleMenu = useCallback(() => {
        setWrapperStyles((currentStyles) => {
            if (currentStyles.findIndex(
                style => style === styles.SelectBoxHidden) === -1) {
                return [...currentStyles, styles.SelectBoxHidden];
            } else {
                return [...currentStyles];
            }
        });
    }, [setWrapperStyles]);

    const doneCallBack = useCallback((itemsChanged) => {
        valuesChanged(itemsChanged);
        toggleMenu();
    }, [toggleMenu, valuesChanged]);

    const displayName = useMemo(() => loading ? "Options Loading..." : name,
        [loading, name]);

    return <OutsideClickDetector
        key={key}
        className={[className, styles.SelectorBoxContainer].join(" ")}
        callback={toggleMenu}
    >
        <div onClick={() => {
            setWrapperStyles((currentStyles) => {

                if (currentStyles.findIndex(
                    style => style === styles.SelectBoxHidden) !== -1) {
                    return [...currentStyles.filter(
                        style => style !== styles.SelectBoxHidden)];
                } else {
                    return [...currentStyles, styles.SelectBoxHidden];
                }

            });
        }} className={styles.SelectBox}>
            {selectedItems.length ? `${name} (${selectedItems.length})`
                : displayName}
        </div>
        <div className={[...wrapperStyles].join(" ")}>
            {refinedOptions.length ?
                <div className={styles.SelectBoxOptionsAndButtonsContainer}>
                    <div className={styles.Options}>
                        {
                            refinedOptions.map(
                                option => <MultiSelectorOption value={option}
                                    key={option.value}
                                    onClick={(option) => {
                                        setSelectedItems(
                                            (currentOptions) => {
                                                if (currentOptions.findIndex(
                                                    (currentOption) => currentOption.value === option.value) !== -1) {
                                                    return [...currentOptions.filter(
                                                        (currentOption) => {
                                                            return currentOption.value !== option.value;
                                                        })];
                                                } else {
                                                    return [...currentOptions, option];
                                                }
                                            });
                                    }}
                                    className={selectedItems.findIndex(
                                        (selectedOption) => selectedOption.value === option.value) !== -1
                                        ? [styles.Option, styles.OptionSelected].join(
                                            " ") :
                                        styles.Option} />)
                        }
                    </div>
                    <div className={styles.Buttons}>
                        <button disabled={selectedItems.length === 0}
                            onClick={() => clearCallBack()}
                            className={[styles.Clear, styles.Button].join(
                                " ")}>Clear
                        </button>
                        <button onClick={() => doneCallBack(selectedItems)}
                            className={styles.Button}>
                            Done
                        </button>
                    </div>
                </div>
                :
                <div className={styles.NoOptionsMessage}>No Options
                    Available</div>
            }
        </div>
    </OutsideClickDetector>;
};

export default MultiSelector;