/* eslint-disable */
import regeneratorRuntime from "regenerator-runtime";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { AuthenticationState, AzureAD } from "react-aad-msal";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    BrowserRouter as Router
} from "react-router-dom";
import App from "./App";
import { authProvider } from "./authConfig";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
/* eslint-disable */
import {fetch_intercept} from "./components/General/Fetch/FetchIntercept"

ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <Router>
                <AzureAD provider={ authProvider } forceLogin={ true }>
                    { ({
                           login,
                           logout,
                           authenticationState,
                           error,
                           accountInfo
                       }) => {
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                 return <App authAccount={accountInfo} logout={logout}/>;
                            case AuthenticationState.Unauthenticated:
                                return <div style={{
                                    fontSize: "1.8rem",
                                    lineHeight: "2rem",
                                    color: "red",
                                    textAlign: "center"
                                }}>Unauthenticated</div>;
                            case AuthenticationState.InProgress:
                                return <p>Logging in</p>;
                            default:
                                return <></>;
                        }
                    } }
                </AzureAD>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();