export const RoleConstants = {
    SUPERADMIN:"SuperAdmin",
    AUTHOR:"Author",
    DEVELOPER:"Developer",
    ADMIN:"Admin",
    DESIGNER:"Designer",
    ADMIN_STATUS:"admin",
    AUTHOR_STATUS:"author",
    DEVELOPER_STATUS:"developer",
    DESIGNER_STATUS:"designer",
    SUPERADMIN_STATUS:"superadmin"
  }
    