// import React,{ useState } from "react";
import {useContext} from "react";
import styles from "./VersionControlTableHeader.module.css";
import { Context } from "../../../../context";

const VersionControlTableHeader = (props) => {
  const { user } = useContext(Context);
  const [role] = user;
  // console.log(props)
  let haveData = null;
  // let campaign = props.data[0];
  let campaign = props.data.sort((a, b) => parseFloat(a._id) - parseFloat(b._id)).reverse()[0];
  if(props.data.length > 0){
    haveData = true;
  }

  let channelsArr = [];

  const mapChannels = () => {
    if (haveData && campaign.channel){
      campaign.channel.map((item)=> {
        channelsArr.push(item.name)
      })
    }
  }
  mapChannels();

  return (
      <div className={styles.VersionControlTableHeader}>
        <div className={styles.Group1}>
          <div className={styles.PrimaryLabel}>{(haveData && campaign.campaignName) ? campaign.campaignName : "N/A"}</div>
          <div className={styles.PrimaryLabel}><strong>Brand: </strong>{(haveData && campaign.brandName) ? campaign.brandName : "N/A"}</div>
        </div>

        <span className={styles.Separator} />

        <div className={styles.Group2}>
          <div className={styles.SecondaryLabel}><strong>Category & Subcategory: </strong>Lifecycle and Email Promotion</div>
          <div className={styles.SecondaryLabel}><strong>Created by: </strong>{(haveData && campaign.createdBy) ? campaign.createdBy : "N/A"}</div>
        </div>

        <div className={styles.Group3}>
          <div className={styles.SecondaryLabel}><strong>Status: </strong>{haveData ? campaign.roleStatus.filter(item => item.role === role)[0].status : "N/A"}</div>
          <div className={styles.SecondaryLabel}><strong>Channel: </strong>{ (channelsArr.length > 0 ? channelsArr : "N/A")}</div>
        </div>
      </div>
  );
};

export default VersionControlTableHeader;
