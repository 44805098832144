/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import styles from "./CreateCustomerRight.module.css";
import LabeledSelect from "../../../General/LabeledSelect/LabeledSelect";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory, Route, Switch } from "react-router-dom";
import RuleForm from "../RuleForm/RuleForm";
import SaveAndDiscardWithPopupButtonSet from "../../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import DiscardDialog from "../../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import PublishDialog from "../../../ManageCampaign/CreateCampaign/Dialog/PublishDialog/PublishDialog";

const ReuseList = ({ navSelected, itemsEntered }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const [subNavSelected, setSubNavSelected] = useState();
  const [selectValue, setSelectValue] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [usageDetails, setUsageDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [compileList, setCompileList] = useState([]);
  const [customerList, setCustomerList] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [percentageCount, setPercentageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setCustomerList({
      select: selectValue,
      name: name,
      description: description,
      createdBy: userName,
      vehicleDetails: vehicleDetails,
      subscriptionDetails: subscriptionDetails,
      usageDetails: usageDetails,
      userDetails: userDetails,
      compileList: compileList
    });
  }, [
    selectValue,
    name,
    description,
    vehicleDetails,
    subscriptionDetails,
    usageDetails,
    userDetails,
    compileList
  ]);

  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !selectValue
      ? [...toBeCompletedArray, "Select"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [selectValue]);

  const resetCustomerList = () => {
    setVehicleDetails([]);
    setSubscriptionDetails([]);
    setUsageDetails([]);
    setUserDetails([]);
    setCompileList([]);
  };

  useEffect(() => {
    const abortController = new AbortController();
      (async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/count/1000`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = await serverResponse.json();
        //Calculate percentage of customers
        //% of customers = (Final user list generated by the Compile List /Total customers) X 100
        setPercentageCount(Math.round((serverResponseJson.compiledCount/serverResponseJson.totalCustomerCount)*100));
        //Show customerlist on the screen based on compiled count
        setTotalCount(serverResponseJson.compiledCount);
      })();
    return () => {
      abortController.abort();
    };
  }, []);

  //get details based on selected customerlist id
  const getSelectedCustomerListDetails = async (id) => {
    console.log("selected customerlist id - " + id)
    if (id !== undefined) {
      const getCustomerDetails = async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/customerlist/${id}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.customerlists[0];
          setName(res.name);
          setDescription(res.shortDescription);
          res.vehicleDetails.map((vehicle) => {
            setVehicleDetails((vehicleDetails) => [
              ...vehicleDetails,
              vehicle
            ]);
          });
          res.subscriptionDetails.map((subscription) => {
            setSubscriptionDetails((subscriptionDetails) => [
              ...subscriptionDetails,
              subscription
            ]);
          });
          res.usageDetails.map((usage) => {
            setUsageDetails((usageDetails) => [
              ...usageDetails,
              usage
            ]);
          });
          res.userDetails.map((user) => {
            setUserDetails((userDetails) => [
              ...userDetails,
              user
            ]);
          });
          res.compileList.map((compile) => {
            setCompileList((compileList) => [
              ...compileList,
              compile
            ]);
          });
        });
      };
      getCustomerDetails();
    }
  };

  const PublishCustomerList = async () => {
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/customerlist`)
    customerList.status = "Live";
    let callParams = {
       method: "POST",
       headers: {
         "x-api-key": `${process.env.REACT_APP_API_KEY}`,
         "Content-Type": "application/json",
         Authorization: `Bearer ${jwt}`
       },
       body: JSON.stringify(customerList)
     };
     const serverResponse = await fetch(url.toString(), callParams);
     const serverResponseJson = await serverResponse.json();
     console.log(serverResponseJson.status)
   };
  useEffect(() => {
    itemsEntered(toBeCompleted.length,"reuse")
 },[toBeCompleted]);

  return (
    <React.Fragment>
      <div className={styles.SubHeading}>Re-use Published List</div>
      <div className={styles.HeadingDescription}>
        Use an existing Published List without making any changes to it.
      </div>
      <div className={styles.InputContainer}>
        <div className={styles.InputFields}>
          {/* CREATE NEW LIST */}
          <React.Fragment>
            <LabeledSelect
              className={styles.Select}
              optionsUrl="v1/customerlist/list"
              title={
                <>
                  Select<span>*</span>
                </>
              }
              onValueChange={(customer) => {
                resetCustomerList();
                setSelectValue(customer);
                if(customer !== undefined){
                  getSelectedCustomerListDetails(customer.value);
                }
              }}
            />
          </React.Fragment>
        </div>
        <div className={styles.Chart}>
          Customer Count
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={percentageCount}
                text={`${percentageCount}%`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#0A9F98"
                })}
              />
            </div>
            <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Subnav with rule builder */}
      <div className={styles.TableNav}>
        <div
          className={
            pathName[5] === "vehicle-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("vehicle-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`
            );
          }}
        >
          Vehicle Details
        </div>
        <div
          className={
            pathName[5] === "subscription-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("subscription-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/subscription-details`
            );
          }}
        >
          Subscription Details
        </div>
        <div
          className={
            pathName[5] === "usage-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("usage-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/usage-details`
            );
          }}
        >
          Usage Details
        </div>
        <div
          className={
            pathName[5] === "user-details"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("user-details");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/user-details`
            );
          }}
        >
          User Details
        </div>
        <div
          className={
            pathName[5] === "compile-list"
              ? styles.NavItemSelected
              : styles.NavItem
          }
          onClick={() => {
            setSubNavSelected("compile-list");
            history.push(
              `/ctmp/customer-list/create-customer/${navSelected}/compile-list`
            );
          }}
        >
          Compile List
        </div>
      </div>

      <Switch>
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/vehicle-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={vehicleDetails}
                setNodes={setVehicleDetails}
                endpoint={"vehicle-fields"}
                navSelected={navSelected}
                subNavSelected={subNavSelected}
                showRC={false}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/subscription-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={subscriptionDetails}
                setNodes={setSubscriptionDetails}
                endpoint={"subscription-fields"}
                navSelected={navSelected}
                subNavSelected={subNavSelected}
                showRC={false}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/usage-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={usageDetails}
                setNodes={setUsageDetails}
                endpoint={"usage-fields"}
                navSelected={navSelected}
                subNavSelected={subNavSelected}
                showRC={false}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          path={`/ctmp/customer-list/create-customer/${navSelected}/user-details`}
          render={() => (
            <React.Fragment>
              <RuleForm
                nodes={userDetails}
                setNodes={setUserDetails}
                endpoint={"user-fields"}
                navSelected={navSelected}
                subNavSelected={subNavSelected}
                showRC={false}
              />
              {SaveAndDiscardListButtons()}
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={`/ctmp/customer-list/create-customer/${navSelected}/compile-list`}
          render={() => (
            <React.Fragment>
              <RuleForm
                /* nodes={compileList} */
                nodes={[...vehicleDetails, ...subscriptionDetails, ...usageDetails, ...userDetails]}
                setNodes={setCompileList}
                endpoint={"compile-list"}
                navSelected={navSelected}
                subNavSelected={subNavSelected}
                showRC={false}
              />
            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );

  function SaveAndDiscardListButtons() {
    return <div className={styles.floatchild3}>
      <SaveAndDiscardWithPopupButtonSet
        yesPopupHeight="55rem"
        noPopupHeight="30rem"
        yesTitle="Use"
        noTitle="Discard"
        className={styles.Buttons}
        renderDiscardPopupContent={(closeModal) => (
          <DiscardDialog
            yesAction={() => {
              history.push("/ctmp/customer-list");
            } }
            noAction={closeModal} />
        )}
        renderSavePopupContent={(closeModal) => (
          // publish
          <PublishDialog
            yesAction={async () => {
              //association backend call to be implemented here once backend is ready.
              //await PublishCustomerList();
              closeModal();
              history.push("/ctmp/customer-list");
            }}
            noAction={closeModal}
            bodyText="Do you want to Use Customer List?"
            headerText="Use Customer List"
            itemsToBeComplete={toBeCompleted} />
          )} />
    </div>;
  }
  
};

export default ReuseList;
