import { format } from "date-fns";


export const mapContents = (serverResponse) => [...serverResponse.map(
    item => ({
        action: { status: item.status, contentId: item._id },
        brand: item.brand.map(c => c.name),
        generation: item.generation.map(c => c.name),
        associatedTemplates: item.template.map(c => c.name),
        associatedCampaigns: {
            associatedCampaign: item.campaign ? item.campaign.map(c => c.name) : ""
        },
        contentDetails: {
            contentName: item.name,
            description: item.description,
            createdDate: item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy")
        },
        channel: item.channel.map(c => c.name),
        creator: item.createdBy,
        categoryDetails: {
            id: item.category ? item.category.id : "",
            category: item.category ? item.category.name : "NA",
            subCategory: item.category && item.category.subCategories ? item.category.subCategories[0].name : ""
        },
        // goLiveDate: item.goLiveDate ? format(new Date(item.goLiveDate), "MM/dd/yyyy") : "N/A",
        // version: { versionNumber:  `v${ item.version }`, campaginId: item.id},
        status: item.status,
        id: item._id
    }))];