/* File for storing all status for roles in different stages*/
export const Status_Draft = [
 {
  status:"Draft"
 }
];
export const Status_CreativeRequested = [
  {
    status:"Creative Requested"
  }
];
export const Status_CreativeCancelRequest = [
  {
    status:"Draft"
  }
];
export const Status_CreativeApproved = [
  {
    status:"Creative Approved"
  }
];
export const Status_CreativeDenied = [
  {
    status:"Draft"
  }
];
export const Status_CampaignRequested = [
  {
  status: "Campaign Requested"
  }
];
export const Status_CampaignApproved = [
  {
    status: "Testing"

  }
];
export const Status_TestingApproved = [
  {
      status: "Approved"
  }

];
export const Status_TestingFailed = [
  {
    status: "Draft"
  }
];
export const Status_Live = [
  {
    status: "Live"
  }
];
export const Status_Pause = [
  {
    status: "Paused"
  }
];
export const Status_Reuse = [
  {
    status: "Reused"
  }
  
];


