import React from "react";
import RowAction from "../../General/Table/RowAction/RowAction";
import ChannelCell from "./Row/Channel/ChannelCell";
import Preview from "./Row/Preview/Preview";
import RowActionMenu from "./Row/RowActionMenu/RowActionMenu";
import Status from "./Row/Status/Status";
import TemplateDetails from "./Row/TemplateDetails/TemplateDetails";
import styles from "./ManageTemplateTable.module.css";

export const columns = [
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ cell: { value } }) => (
      <RowAction
        menu={<RowActionMenu action={value} id={value.templateId} />}
      />
    )
  },
  {
    Header: "Channel",
    accessor: "channel",
    Cell: ({ cell: { value } }) => <ChannelCell data={value} />
  },
  {
    Header: "Template Details",
    accessor: "templateDetails",
    Cell: ({ cell: { value } }) => <TemplateDetails data={value} />
  },
  {
    Header: "Associated Campaigns",
    accessor: "associatedCampaigns",
    Cell: ({ cell: { value } }) => {
      if (value.length === 0 || value[0] === "") {
        return ""
      } else {
        return value.map((data) => <li className={styles.AssociatedCampaigns} key={data.campaignName + data.campaignID}>{data.campaignName}</li>)
      }
    }
  },
  {
    Header: "Preview",
    accessor: "preview",
    Cell: ({ cell: { value } }) => <Preview value={value} />
  },
  {
    Header: "Created by",
    accessor: "creator",
    Cell: ({ cell: { value } }) => value
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }) => <Status value={value} />
  }
];
