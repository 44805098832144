import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { Col, Container, Row, TabContainer } from "react-bootstrap";
import { Fragment } from "react";
//import ReUsePublishTemplate from "./ReUsePublishTemplate";
import CloneTemplate from "./CloneTemplate";
import PageContent from "../../General/PageContent/PageContent";
import UploadTemplateForm from "./UploadTemplateForm";
import styles from "./CreateTemplate.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { TemplateConstants } from "../TemplateConstants";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import SelectedTemplate from "./SelectedTemplate";

/*styles for alert */
import "react-toastify/dist/ReactToastify.css";

const Styles = styled.ul`
  .current {
    background-color: rgba(0, 160, 240, 0.1);
    padding-right: 14.8rem;
    padding-bottom: 1em;
    padding-top: 1em;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: Toyota Type Regular;
    cursor: pointer;
    text-align: left;
  }
  .currentReuse {
    background-color: rgba(0, 160, 240, 0.1);
    padding-right: 7.3rem;
    padding-bottom: 1em;
    padding-top: 1em;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: Toyota Type Regular;
    cursor: pointer;
    text-align: left;
  }

  .idle {
    color: black;
  }
`;

const CreateTemplate = (props) => {
  const history = useHistory();
  let { path, url } = useRouteMatch();
  let splitPath = path.split("/")[3];
  const pathName = history.location.pathname.split("/");
  const [editTemplate, setEditTemplate] = useState(false);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);
  console.log(path + "--in create--" + url);
  console.log(history);

  //set view and edit template based on path
  useEffect(() => {
    if (props) {
      if (pathName[3] === "edit-template") {
        setEditTemplate(true);
      } else if (pathName[3] === "view-template") {
        setViewTemplate(true);
      }
    }
  }, []);

  return (
    <Container fluid>
      {props && editTemplate ? (
        <TitleWithPathHeader
          title={TemplateConstants.EDIT_TEMPLATE_TITLE}
          path={TemplateConstants.EDIT_TEMPLATE_PATH}
        />
      ) : props && viewTemplate ? (
        <TitleWithPathHeader
          title={TemplateConstants.VIEW_TEMPLATE_TITLE}
          path={TemplateConstants.VIEW_TEMPLATE_PATH}
        />
      ) : (
        <TitleWithPathHeader
          title={TemplateConstants.CREATE_TEMPLATE_TITLE}
          path={TemplateConstants.CREATE_TEMPLATE_PATH}
        />
      )}

      {editTemplate === true ?
        <div className={styles.CreateTemplateModule1}>
          <div className={styles.RightContentEditTemplate}>
            <Route
              render={() => (
                <UploadTemplateForm
                  props={props.templateId}
                  actionValue={splitPath}
                />
              )}
            />
          </div>
        </div> :
        viewTemplate === true ?
          <div className={styles.CreateTemplateModule1}>
            <div className={styles.RightContentEditTemplate}>
              <Route
                render={() => (
                  <UploadTemplateForm
                    props={props.templateId}
                    actionValue={splitPath}
                  />
                )}
              />
            </div>
          </div>
          :
          < PageContent className={styles.CreateTemplate}>
            <Container fluid>
              <TabContainer>
                <Row>
                  <Col md={2}>
                    <Fragment>
                      <div className={styles.NavigationMenu}>
                        <Styles>
                          <ul className={navSelected === "upload-template" ||
                            navSelected === "create-template" ||
                            navSelected === props.templateId ?
                            styles.NavItemSelected : styles.NavItem}
                            onClick={() => {
                              setNavSelected("upload-template");
                            }}>
                            <NavLink
                              to={`${url}/upload`}
                              style={{ color: "black", textDecoration: "none" }}
                            //activeClassName="current"
                            >
                              {TemplateConstants.UPLOAD_TITLE}
                            </NavLink >
                          </ul>
                          <ul className={navSelected === "clone-template" ?
                            styles.NavItemSelected : styles.NavItem}
                            onClick={() => {
                              setNavSelected("clone-template");
                            }}>
                            <NavLink
                              to={`${url}/clone`}
                              style={{ color: "black", textDecoration: "none" }}
                            //activeClassName="currentReuse"
                            >
                              {TemplateConstants.REUSE_TITLE}
                            </NavLink>
                          </ul>
                        </Styles>
                      </div>
                    </Fragment>
                  </Col>
                  <Col md={9} className={styles.mycol}>
                    <Container>
                      <Switch>
                        <Route exact path={`${url}`}>
                          <Redirect to={`${url}/upload`} />
                        </Route>
                        <Route path={`${url}/upload`}>
                          <UploadTemplateForm
                            props={props.templateId}
                            actionValue={splitPath}
                          />
                        </Route>
                        {/* <Route path={`${url}/reuse`}>
                      <ReUsePublishTemplate />
                      </Route> */}
                        <Route path={`${url}/clone`}>
                          <CloneTemplate />
                        </Route>
                        <Route path={`${url}/selected`}>
                          <SelectedTemplate />
                        </Route>
                      </Switch>
                    </Container>
                  </Col>
                </Row >
              </TabContainer >
            </Container >
          </PageContent >
      }
    </Container >
  );
};

export default CreateTemplate;
