import React from "react"

import styles from "./ContentDetails.module.css"

const ContentDetails = ({data}) => {
    return <div className={styles.ContentDetailsContainer}>
        <span className={styles.ContentDetailsHeader}>{data.contentName}</span>
        <p className={styles.ContentDescription}>{data.description}</p>
        <span className={styles.CreatedDate}>Created: {data.createdDate}</span>
    </div>
}

export default ContentDetails