import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ManageCampaign from "../ManageCampaign/ManageCampaign";
import CreateCustomer from "../ManageCustomerList/CreateCustomer/CreateCustomer";
import ManageCustomerList from "../ManageCustomerList/ManageCustomerList";
import CreateCustomer2 from "../ManageCustomerList/CreateCustomer-2/CreateCustomer";
import ManageTemplate from "../ManageTemplate/ManageTemplate";
import ManageContent from "../ManageContent/ManageContent";
import ManageCopy from "../ManageCopy/ManageCopy";
import ManageUser from "../UserManagement/ManageUser";
import NavBar from "../NavBar/NavBar";
import styles from "./Content.module.css";
import ManageReport from "../Reports/ManageReport";

const Content = (props) => {

       return <div className={styles.Content}>
              <NavBar logout={props} />
              <div className={styles.ContentContainer}>
                     <Switch>
                            <Route path="/ctmp/template" component={ManageTemplate}
                                   render={() => <div><h1>Template</h1></div>} />
                            <Route exact path="/ctmp/template" component={ManageTemplate}
                                   render={() => <Redirect to="/ctmp/template" />} />
                            <Route path="/ctmp/copy" component={ManageCopy}
                                   render={() => <div><h1>Copy</h1></div>} />
                            <Route path="/ctmp/content" component={ManageContent}
                                   render={() => <div><h1>Content</h1></div>} />
                            <Route path="/ctmp/customer-list/create-customer"
                                   component={CreateCustomer2} />
                            <Route path="/ctmp/customer-list/create"
                                   component={CreateCustomer} />
                            <Route path="/ctmp/customer-list"
                                   component={ManageCustomerList} />
                            <Route path="/ctmp/report" component={ManageReport}
                                   render={() => <div><h1>Report</h1></div>} />
                            <Route path="/ctmp/user-management" component={ManageUser}
                                   render={() => <div><h1>User management</h1></div>} />
                            <Route path="/ctmp/dashboard" component={ManageCampaign} />
                            <Route path="/"
                                   render={() => <Redirect to="/ctmp/dashboard" />} />
                     </Switch>
              </div>
       </div>;

};

export default Content;