/* eslint-disable */
import styles from "./RowActionMenu.module.css";
import { useAppSelector } from "../../../../../redux/store";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import React, { useContext } from "react";
import { Context } from "../../../../../context";

const view = "view";
const edit = "edit";
const delete_record = "delete";

const ActionMenuItems = (item) => {
    const jwt = useAppSelector(state => state.authReducer.jwt);
    const { user, value, capability } = useContext(Context);
    const [role, setRole] = user;
    const [context, setContext] = value;

    const deleteCampaign = async (campaignId) => {
        const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${campaignId}`);
        const callParams = {
            method: "DELETE",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const mystatus = await serverResponse.json();
        return serverResponse.status;
    }

    switch (item.actionItem) {
        case view:
            return <div key="view" className={styles.MenuItem}
                onClick={() => {
                    window.location.assign(`/ctmp/dashboard/view-campaign/${item.action.campaignId}/${item.action.version}`)
                }}>View</div>
        case edit:
            return <div onClick={() => {
                window.location.assign(`/ctmp/dashboard/edit-campaign/${item.action.campaignId}/${item.action.version}`)
            }}
                key="edit" className={styles.MenuItem}>Edit
            </div>
        case delete_record:
            return [

                // <div key="delete"
                //     onClick={() => { deleteCampaign(item.action.campaignId) }}
                //     className={styles.MenuItem}>Delete</div>

                <PopupDialog
                    key={"delete" + item.action.campaignId}
                    height="30rem"
                    width="67rem"
                    renderPopupContent={(closeModal) => (
                        <DiscardDialog
                            yesAction={async () => {
                                localStorage.setItem("deleteCampaignPopup", "false")
                                const statusVar = await deleteCampaign(item.action.campaignId);
                                statusVar !== 200 &&
                                    localStorage.setItem("forceDelete", "false");
                                statusVar === 200 &&
                                    localStorage.setItem("forceDelete", "true");
                                setContext("delete")
                            }}
                            noAction={() => { closeModal();localStorage.setItem("deleteCampaignPopup", "false") } }
                            className="DeleteCampaign"
                            headerText="Delete Campaign"
                            bodyText={item.action.name}
                        />
                    )}
                    renderControlContent={(openModal) => (
                        <div key="delete" onClick={() => {openModal();localStorage.setItem("deleteCampaignPopup", "true")}} className={styles.MenuItem}>
                            Delete
                        </div>
                    )}
                />
            ]
    }
}
export default ActionMenuItems