import React, { useMemo } from "react";

import styles from "./TableFooter.module.css";
import Pages from "./Pages/Pages";
import ItemsPerPage from "./ItemsPerPage/ItemsPerPage";

export const PAGE_SIZES = [10, 20, 50];

const TableFooter = ({
                         changePageSizeTo,
                         totalItems,
                         goToPage,
                         currentPage,
                         selectedPageSize
                     }) => {

    const pageSizes = useMemo(() => {
            const pageSizeResolver = (pageSizes, i) => {
                const aPageSize = PAGE_SIZES[i];
                let currentPageSizes = [...pageSizes, aPageSize];
                currentPageSizes = currentPageSizes.filter(function( element ) {
                    return element !== undefined;
                 });
                if (totalItems / aPageSize > 1) {
                    return pageSizeResolver(currentPageSizes, i + 1);
                }
                return currentPageSizes;
            };
            return pageSizeResolver([], 0);
        }
        , [totalItems]);

    return (
        <div className={ styles.Footer }>
            <Pages
                totalItems={ totalItems }
                goToPage={ goToPage }
                currentPage={ currentPage }
                pageSize={ selectedPageSize }/>
            <ItemsPerPage totalItems={ totalItems }
                          currentPage={ currentPage }
                          changePageSizeTo={ changePageSizeTo }
                          selectedPageSize={ selectedPageSize }
                          pageSizes={ pageSizes.map(aPageSize => ({
                              label: "" + aPageSize,
                              value: aPageSize
                          })) }/>
        </div>
    );
};

export default TableFooter;