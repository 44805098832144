import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import styles from "./CreateCopy.module.css";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import Button from "../../General/Button/Button";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import BackButton from "../../General/Button/Button";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";

const CloneCopy = (props) => {
  const history = useHistory();
  const [cloneExistingCopy, setCloneExistingCopy] = useState();
  const [cloneTemplate, setCloneTemplate] = useState();
  const [cloneName, setCloneName] = useState();
  const [cloneDescription, setCloneDescription] = useState();
  const [saveCloneObject, setSaveCloneObject] = useState({});
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const [templates, setTemplates] = useState([]);
  const [existingCopies, setExistingCopies] = useState([]);

  /*get published templates*/
  useEffect(() => {
    const getPublishedTemplateDetails = async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/search`
      );
      const callParams = {
        method: "POST",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({ "status": ["Published"] })
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log("templates - ", serverResponseJson.templates)
      serverResponseJson.templates.map((template) => {
        setTemplates((templates) => [
          ...templates,
          { label: template.name, value: template._id }
        ]);
      });
    };
    getPublishedTemplateDetails();
  }, []);

  /*get existing Copies*/
  useEffect(() => {
    const getexistingCopies = async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/search`
      );
      const callParams = {
        method: "POST",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({ "status": ["Published"] })
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log("existingCopies - ", serverResponseJson.contentCopyList)
      serverResponseJson.contentCopyList.map((existingCopy) => {
        setExistingCopies((existingCopies) => [
          ...existingCopies,
          { label: existingCopy.name, value: existingCopy._id }
        ]);
      });
    };
    getexistingCopies();
  }, []);

  /* notify if user do not enter mandatory fields */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !cloneName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneTemplate
      ? [...toBeCompletedArray, "Template"]
      : toBeCompletedArray;
    toBeCompletedArray = !cloneExistingCopy
      ? [...toBeCompletedArray, "Existing Copy"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [
    cloneName,
    cloneDescription,
    cloneExistingCopy,
    cloneTemplate
  ]);

  /* update the values when changed */
  useEffect(() => {
    setSaveCloneObject({
      name: cloneName,
      description: cloneDescription,
      ...(cloneExistingCopy && {
        template: [
          {
            name: cloneExistingCopy.label,
            id: cloneExistingCopy.value
          }
        ]
      }),
      ...(cloneTemplate && {
        template: [
          {
            name: cloneTemplate.label,
            id: cloneTemplate.value
          }
        ]
      }),
      createdBy: userName
    });
  }, [
    cloneDescription,
    cloneName,
    cloneExistingCopy,
    cloneTemplate
  ]);

  /* save */
  const saveCloneData = async (saveCloneObject) => {
    console.log("--->" + JSON.stringify(saveCloneObject));
    let url, methodValue;
    url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/clone`);
    methodValue = "POST";
    saveCloneObject.status = "Draft";
    saveCloneObject.existingCopyId = cloneExistingCopy.value;

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(saveCloneObject)
    };

    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log("serverResponseJson status - " + serverResponseJson.status);
    return serverResponse.status;
  };

  return (
    <div style={{ textAlign: "left" }}>
      <p className={styles.UpdateFormHeader}>Clone campaign copy</p>
      <span className={styles.UpdateFormSubTitle}>
        Create a clone of existing copy in use on the platform.
      </span>
      <div style={{ marginBottom: "2rem", marginTop: "3rem" }}>
        <LabeledSelect
          className={styles.Select}
          options={existingCopies}
          placeholder="Select"
          value={cloneExistingCopy}
          defaultLabel={
            cloneExistingCopy
              ? cloneExistingCopy.label
              : undefined
          }
          defaultId={
            cloneExistingCopy
              ? cloneExistingCopy.value
              : undefined
          }
          title={
            <>
              Existing Copy<span>*</span>
            </>
          }
          onValueChange={(option) => setCloneExistingCopy(option)}
        /></div>
      <div style={{ marginBottom: "2rem" }}>
        <LabeledSelect
          className={styles.Select}
          options={templates}
          placeholder="Select"
          value={cloneTemplate}
          defaultLabel={
            cloneTemplate
              ? cloneTemplate.label
              : undefined
          }
          defaultId={
            cloneTemplate
              ? cloneTemplate.value
              : undefined
          }
          title={
            <>
              Template<span>*</span>
            </>
          }
          onValueChange={(option) => setCloneTemplate(option)}
        /></div>
      <div style={{ marginBottom: "2rem" }}>
        <LabeledInput
          title={
            <>
              New Name<span>*</span>
            </>
          }
          data={cloneName}
          placeholder="Type the campaign copy name"
          className={styles.Input}
          onValueChange={(value) => setCloneName(value)}
        /></div>
      <div style={{ marginBottom: "2rem" }}>
        <LabeledInput
          title={
            <>
              New Description<span>*</span>
            </>
          }
          data={cloneDescription}
          className={styles.Input}
          placeholder="Type the description"
          onValueChange={(value) => setCloneDescription(value)}
        /></div>
      {/* <div>
      <label style={{ marginBottom: "0.2rem", marginTop: "1rem" }}>
        Campaign copy preview
      </label>
          <TemplatePreviewCard/>
      </div> */}

      {
        toBeCompleted.length === 0 ? (
          <div className={styles.savediscardcontainer}>
            <div className={styles.discardchild}>
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push("/ctmp/copy");
                      toast.success(
                        <Msg id="discard" templateName={cloneName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    }}
                    noAction={closeModal}
                  />
                )}
                renderControlContent={(openModal) => (
                  <BackButton onClick={openModal} name="CopyNewCompleteDiscard">
                    Discard
                  </BackButton>
                )}
              />
            </div>
            <div className={styles.savechild}>
              <Button
                onClick={async () => {
                  let statusVar = await saveCloneData(saveCloneObject);
                  localStorage.setItem("blockNavigation", "false");
                  history.push("/ctmp/copy");
                  statusVar !== 200 &&
                    toast.error(
                      <ErrorMsg id="save" copyName={cloneName} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Error />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                  statusVar === 200 &&
                    toast.success(
                      <Msg id="save" copyName={cloneName} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                }}
                name="CopyNewCompleteSave"
              />
            </div>
          </div>
        ) :
          <div className={styles.floatcontainer}>
            <div className={styles.floatchild}>
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      history.push("/ctmp/copy");
                    }}
                    noAction={closeModal}
                    className="Discard"
                  />
                )}
                renderControlContent={(openModal) => (
                  // <Button onClick={openModal} name="Discard" />
                  console.log("")
                )}
              />
            </div>
            <div className={styles.floatchild}>
              {/* save */}
              <div className={styles.floatchild2} style={{ paddingLeft: "7rem" }}>
                <SaveAndDiscardWithPopupButtonSet
                  yesPopupHeight="55rem"
                  noPopupHeight="30rem"
                  yesTitle="Save" //save
                  noTitle="Discard" //discard
                  className={styles.CopyNewEdit}
                  renderDiscardPopupContent={(closeModal) => (
                    //save and close
                    <DiscardDialog
                      yesAction={() => {
                        history.push("/ctmp/copy");
                      }}
                      noAction={closeModal}
                      className="Discard"
                    />
                  )}
                  renderSavePopupContent={(closeModal) => (
                    <SaveDialog
                      saveAction={async () => {
                        let statusVar = await saveCloneData(saveCloneObject);
                        console.log("statusVar--->" + statusVar)
                        closeModal();
                        history.push("/ctmp/copy");
                        statusVar === 200 &&
                          toast.success(
                            <Msg id="save" copyName={cloneName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        statusVar !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" copyName={cloneName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                />
              </div>
            </div>
          </div>}

    </div>
  );
};
export default CloneCopy;
