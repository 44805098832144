/* eslint-disable */
import React, { useRef, useState, useContext } from "react";
import { Context } from "../../../context";

import styles from "./UploadBar.module.css";
// import JSZip from "jszip";

const UploadBar = ({ onUploadClick, uploadedFile, uploadType, resetFile }) => {
    const { user, value } = useContext(Context);
    const [context, setContext] = value;
    const inputFile = useRef();
    const [fileChosen, setFileChosen] = useState();
    const [fileName, setFileName] = useState("No File Chosen");

    return <div className={styles.Container}>
        <div className={styles.FileChooserContainer}>
            <input type="file" id="file" accept={uploadType === "uploadCopy" || uploadType === "uploadVIN" ? ".xlsx" :
                uploadType === "uploadApprovalProof" ||
                    uploadType === "uploadLegalProof" ||
                    uploadType === "uploadDivisionProof" ? ".pdf,.docx,.msg" : ".zip"}
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    const file = event.target.files[0];
                    setFileName(file.name);
                    console.log("-----in uploadbar---" + context);
                    console.log("-----in uploadbar---" + fileName);
                    setFileChosen(file);
                    //    sessionStorage.setItem(file.name, file);
                    uploadedFile(file);
                    setContext(file.name);
                    console.log("-----in uploadbar---" + context);
                    console.log("-----in uploadbar---" + fileName);

                }}
            />
            <button onClick={() => inputFile.current.click()}
                className={styles.ChooseFileButton}>Choose File
            </button>
            {/* {(context === null || context ==="" || context === undefined) && <div className={ styles.FileNameLabel }>{ fileName }</div>}
            {context !== "" && <div className={ styles.FileNameLabel }>{ context }</div>} */}
            <div className={styles.FileNameLabel}>{resetFile === "true" ? "No File Chosen" : fileName}</div>
        </div>
        {uploadType === "uploadCopy" ?
            <button disabled={!fileChosen} className={styles.UploadButton} onClick={onUploadClick}>
                Show Content
            </button> : ""
            /* uploadType === "uploadVIN" ? "" :
                <button disabled={!fileChosen} className={styles.UploadButton} onClick={onUploadClick}>
                    Upload
                </button> */
        }
    </div>;
};

export default UploadBar;