
import React, { useState } from "react"

/* eslint-disable  */
import styles from "./Preview.module.css"
//import { ReactComponent as TemplateSmall } from "../../../../../assets/icons/TemplateSmall.svg";
//import { ReactComponent as Zoom } from "../../../../../assets/icons/Zoom.svg";
//import { ReactComponent as TemplateBig } from "../../../../../assets/icons/Template.svg";
import { ReactComponent as PreviewImage } from "../../../../../assets/icons/preview.svg";
import { Modal } from "react-bootstrap";

const Preview = ({ value,title,classNameRef }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <div className={classNameRef}>
   <div> <label style={{color: "var(--ct-blue-secondary)",cursor:"pointer",paddingRight:"5rem"}} onClick={handleShow}>{title}</label></div>
   
  {/* <div title="Preview Image"><PreviewImage style={{marginRight:"7rem",marginBottom:"0.5rem"}} onClick={handleShow}></PreviewImage></div> */}

    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <div className={styles.TemplateBigPreview}><img src={value} className={styles.BigSvg} /></div>
      </Modal.Header>
    </Modal>
  </div>
}

export default Preview
