/* eslint-disable */
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ManageContentHeader.module.css";
import Button from "../../General/Button/Button";
import { Context } from "../../../context";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { CapabilityConstants } from "../../Constants/CapabilitiyConstants";

const ManageContentHeader = ({ onSearch }) => {
  const history = useHistory();
  const [timeout, setTimeoutVal] = useState();
  const [searchText, setSearchText] = useState();
  const { capability } = useContext(Context);
  const [capabilities, setCapabilities] = capability;

  const navToCreateContent = useCallback(() => {
    history.push(`${history.location.pathname}/create-content`);
  }, [history]);

  useEffect(() => {
    timeout && clearTimeout(timeout);
    setTimeoutVal(
      setTimeout(() => {
        onSearch(searchText);
      }, 500)
    );
  }, [onSearch, searchText, setTimeoutVal]);

  return (
    <div className={styles.MngTmpltHdr}>
      <div className={styles.MngTmpltHdrLeft}>
        <h3>Manage Content</h3>
        <span className={styles.Separator} />
        <div className={styles.SearchBarContainer}>
          <SearchIcon className={styles.SearchIcon} />
          <input
            className={styles.Search}
            placeholder="Search Content"
            type="text"
            style={{ width: "400px" }}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
      </div>
      {capabilities.includes(CapabilityConstants.CONTENT_CREATE) ?
        <Button onClick={navToCreateContent} name="Create Content" /> : ""}
    </div>
  );
};

export default ManageContentHeader;
