import React, { useMemo, useContext } from "react";
import styles from "./RowActionMenu.module.css";
import { useAppSelector } from "../../../../../redux/store";
import { useHistory } from "react-router-dom";
import PopupDialog from "../../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../../../AddUser/Dialog/DiscardDialog/DiscardDialog";
import { Context } from "../../../../../context";

import {
     STATUS_ADMIN,
     STATUS_AUTHOR,
     STATUS_DESIGNER,
     STATUS_DEVELOPER,
     STATUS_SUPERADMIN
} from "../Status/Status";

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-unused-vars */

const RowActionMenu = ({ action, id }) => {
     const history = useHistory();
     const jwt = useAppSelector(state => state.authReducer.jwt);
     const { user, value } = useContext(Context);
     const [role,setRole] = user;
     const [context, setContext] = value;

     const deleteUser = async (userId) => {
          const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/${userId}`);
          const callParams = {
               method: "DELETE",
               headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                    // "user-name": userName,
                    // "user-email": email

               }
          };
          const serverResponse = await fetch(url.toString(), callParams);
         // const reloadPage = await serverResponse.then(window.location.reload());
         const mystatus = await serverResponse.json();
         return serverResponse.status;
     }

     const actions = useMemo(() => {
          switch (action.status[0].toLowerCase()) {
               case STATUS_SUPERADMIN:
                    return [
                         <div
                           onClick={() => {
                              history.push(`/ctmp/user-management/edit-user/${id}`);
                           }}
                           key="edit"
                           className={styles.MenuItem}
                         >
                           Edit
                         </div>,
                        <PopupDialog
                        key={"delete"+id}
                         height="30rem"
                         width="67rem"
                         renderPopupContent={(closeModal) => (
                           <DiscardDialog
                             yesAction={async () => {
                               const statusVar = await deleteUser(id);
                               statusVar !== 200 &&
                               localStorage.setItem("userDelete", "false");
                               statusVar === 200 &&
                               localStorage.setItem("userDelete", "true");
                               setContext("delete")
                             }}
                             noAction={closeModal}
                             bodyText="Are you sure you want to deactivate this User?"
                             headerText="Deactivation Message"
                             className="DeleteTemplate"
                          />
                          )}
                          renderControlContent={(openModal) => (
                            <div key="delete" onClick={openModal} className={styles.MenuItem}>
                              Deactivate User
                            </div>
                          )}
                        />
                         ];
               case STATUS_DEVELOPER:
                    return [
                         <div
                         onClick={() => {
                            history.push(`/ctmp/user-management/edit-user/${id}`);
                         }}
                         key="edit"
                         className={styles.MenuItem}
                       >
                         Edit
                       </div>,
                    <PopupDialog
                       key={"delete"+id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                          <DiscardDialog
                            yesAction={async () => {
                              const statusVar = await deleteUser(id);
                              statusVar !== 200 &&
                              localStorage.setItem("userDelete", "false");
                              statusVar === 200 &&
                              localStorage.setItem("userDelete", "true");
                              setContext("delete")
                            }}
                            noAction={closeModal}
                            bodyText="Are you sure you want to deactivate this User?"
                            headerText="Deactivation Message"
                            className="DeleteTemplate"
                         />
                         )}
                         renderControlContent={(openModal) => (
                           <div key="delete" onClick={openModal} className={styles.MenuItem}>
                             Deactivate User
                           </div>
                         )}
                       />
                    ];
               case STATUS_DESIGNER:
                         return [
                              <div
                              onClick={() => {
                                 history.push(`/ctmp/user-management/edit-user/${id}`);
                              }}
                              key="edit"
                              className={styles.MenuItem}
                            >
                              Edit
                            </div>,
                        <PopupDialog
                        key={"delete"+id}
                         height="30rem"
                         width="67rem"
                         renderPopupContent={(closeModal) => (
                           <DiscardDialog
                             yesAction={async () => {
                               const statusVar = await deleteUser(id);
                               statusVar !== 200 &&
                               localStorage.setItem("userDelete", "false");
                               statusVar === 200 &&
                               localStorage.setItem("userDelete", "true");
                               setContext("delete")
                             }}
                             noAction={closeModal}
                             bodyText="Are you sure you want to deactivate this User?"
                             headerText="Deactivation Message"
                             className="DeleteTemplate"
                          />
                          )}
                          renderControlContent={(openModal) => (
                            <div key="delete" onClick={openModal} className={styles.MenuItem}>
                              Deactivate User
                            </div>
                          )}
                        />
                         ];
               case STATUS_AUTHOR:
                         return [
                              <div
                           onClick={() => {
                              history.push(`/ctmp/user-management/edit-user/${id}`);
                           }}
                           key="edit"
                           className={styles.MenuItem}
                         >
                           Edit
                         </div>,
                       <PopupDialog
                       key={"delete"+id}
                        height="30rem"
                        width="67rem"
                        renderPopupContent={(closeModal) => (
                          <DiscardDialog
                            yesAction={async () => {
                              const statusVar = await deleteUser(id);
                              statusVar !== 200 &&
                              localStorage.setItem("userDelete", "false");
                              statusVar === 200 &&
                              localStorage.setItem("userDelete", "true");
                              setContext("delete")
                            }}
                            noAction={closeModal}
                            bodyText="Are you sure you want to deactivate this User?"
                            headerText="Deactivation Message"
                            className="DeleteTemplate"
                         />
                         )}
                         renderControlContent={(openModal) => (
                           <div key="delete" onClick={openModal} className={styles.MenuItem}>
                             Deactivate User
                           </div>
                         )}
                       />
                         ];
               case STATUS_ADMIN:
                              return [
                                   <div
                                   onClick={() => {
                                      history.push(`/ctmp/user-management/edit-user/${id}`);
                                   }}
                                   key="edit"
                                   className={styles.MenuItem}
                                 >
                                   Edit
                                 </div>,
                               <PopupDialog
                               key={"delete"+id}
                                height="30rem"
                                width="67rem"
                                renderPopupContent={(closeModal) => (
                                  <DiscardDialog
                                    yesAction={async () => {
                                      const statusVar = await deleteUser(id);
                                      statusVar !== 200 &&
                                      localStorage.setItem("userDelete", "false");
                                      statusVar === 200 &&
                                      localStorage.setItem("userDelete", "true");
                                      setContext("delete")
                                    }}
                                    noAction={closeModal}
                                    bodyText="Are you sure you want to deactivate this User?"
                                    headerText="Deactivation Message"
                                    className="DeleteTemplate"
                                 />
                                 )}
                                 renderControlContent={(openModal) => (
                                   <div key="delete" onClick={openModal} className={styles.MenuItem}>
                                     Deactivate User
                                   </div>
                                 )}
                               />
                              ];
               default:
                    return [<div key="na" className={styles.MenuItem}>N/A</div>];
          }

     }, [action]);

     return <div className={styles.MenuContainer}>
          {actions}
     </div>;
};

export default RowActionMenu;