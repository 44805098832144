/* eslint-disable*/
import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "./AddUser.module.css";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import MultiSelector from "../../General/MultiSelector/MultiSelector";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import Button from "../../General/Button/Button";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import { RoleConstants } from "../../Constants/RoleConstants";

const AddUser = (id) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const [email, setEmail] = useState();
  //const [emailHierarchy, setEmailHierarchy] = useState();
  const [hierarchy, setHierarchy] = useState();
  const [teams, setTeams] = useState([]);
  const [role, setRole] = useState();
  const [saveUserObject, setSaveUserObject] = useState({});
  const [editUser, setEditUser] = useState(false);
  const [name, setName] = useState();
  const history = useHistory();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  let roles = [];
  const [HValues, setHValues] = useState([]);
  const [userCreatedBy, setUserCreatedBy] = useState();

  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );

  /*get Values from database when user clicks edit in userManagement*/
  useEffect(() => {
    if (id.props !== undefined) {
      setEditUser(true);
      const getUserDetails = async () => {
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/user/${id.props}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };

        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = await serverResponse
          .json()
          .then((response) => {
            const res = response.userDetails;
            setUserCreatedBy(res.createdBy);
            if (res.hierarchy.length > 0) {
              setHierarchy({
                label: res.hierarchy[0].name,
                value: res.hierarchy[0].id
              });
            }
            else {
              setHierarchy({
                label: "callie.thomas@toyota.com",
                value: "callie.thomas@toyota.com"
              });
            }
            setEmail(res.email);
            setRole({ label: res.roles[0].name, value: res.roles[0].id });
            res.teams.map((team) => {
              setTeams((teams) => [
                ...teams,
                { label: team.name, value: team.id }
              ]);
            });
          });
      };
      getUserDetails();
    }
  }, []);

  /* notify if user do not enter mandatory fields */
  useEffect(() => {
    let toBeCompletedArray = [];
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    toBeCompletedArray = (!email || !pattern.test(email))
      ? [...toBeCompletedArray, "Enter Valid User Email"]
      : toBeCompletedArray;
    toBeCompletedArray = !hierarchy
      ? [...toBeCompletedArray, "Mapping user hierarchy"]
      : toBeCompletedArray;
    toBeCompletedArray = !teams || !teams.length
      ? [...toBeCompletedArray, "Select User Teams"]
      : toBeCompletedArray;
    toBeCompletedArray = !role
      ? [...toBeCompletedArray, "Select User Role"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [email, hierarchy, teams, role]);

  /* update the values when changed */
  useEffect(() => {
    setSaveUserObject({
      // email: email,
      email: email,
      ...(hierarchy && {
        hierarchy: [
          {
            name: hierarchy.label,
            id: hierarchy.value,
            email: hierarchy.email
          },
        ]
      }),
      ...(role && {
        roles: [
          {
            name: role.label,
            id: role.value,
          },
        ]
      }),
      ...(teams && {
        teams: teams.map((c) => ({
          name: c.label,
          id: c.value,
        })),
      }),
      createdBy: editUser === true ? userCreatedBy : userName,
      name: email,
    });
  }, [email, role, teams, hierarchy]);

  /*save user functinality*/
  const saveUser = async (saveUserObject) => {
    // saveUserObject.name = email.substring(0, email.lastIndexOf("@"));
    saveUserObject.name = email.substring(0, email.lastIndexOf("@"));
    console.log(JSON.stringify(hierarchy))
    console.log(JSON.stringify(HValues))
    //saveUserObject.name = emailHierarchy;
    console.log(JSON.stringify(saveUserObject));
    let url, methodValue;
    editUser === true
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/user/${id.props}`
      )),
        (methodValue = "PUT"))
      : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user`)),
        (methodValue = "POST"));

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(saveUserObject),
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    return serverResponseJson.status;
  };

  //roles has to be be passed to backend as below
  //for SuperAdmin or Admin - SuperAdmin
  //for Author or Developer or Designer - SuperAdmin & Admin
  const updateRoles = async () => {
    if (roles.includes(RoleConstants.AUTHOR) || roles.includes(RoleConstants.DEVELOPER) || roles.includes(RoleConstants.DESIGNER)) {
      roles = [];
      roles.push(RoleConstants.SUPERADMIN);
      roles.push(RoleConstants.ADMIN);
    }
    else if (roles.includes(RoleConstants.ADMIN)) {
      roles = [];
      roles.push(RoleConstants.SUPERADMIN);
    }
  }

  const getMappingHeirarchy = async () => {
    (async () => {
      const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/hierarchy`);
      const callParams = {
        method: "POST",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
        },
        body: JSON.stringify({ roles })
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      console.log(serverResponseJson.status)
      console.log("serverResponseJson ", serverResponseJson)
      if (serverResponseJson.status === "No Records Found!") {
        callback([]);
      } else {
        serverResponseJson.map((o) => {
          setHValues((serverResponseJson) => [
            ...serverResponseJson,
            { label: o.name, value: o.id, email: o.email }
          ]);
        });
      }
    })();
  };

  return (
    <div style={{ textAlign: "left" }}>
      <div>
        {(editUser === true) ? <p className={styles.UpdateFormHeader}>Edit user</p> : <p className={styles.UpdateFormHeader}>Add New User</p>}
        {(editUser === true) ? <span className={styles.UpdateFormSubTitle}>
          To edit this user, please review the following information:
        </span> : <span className={styles.UpdateFormSubTitle}>
          To add a new user, please enter the following information:
        </span>}
        <div className={styles.BrandAndCampaign}>
          <LabeledInput
            title={
              <>
                Enter User Email<span> *</span>{" "}
              </>
            }
            data={email}
            placeholder="Type the email"
            className={styles.Input}
            onValueChange={(value) => setEmail(value)}
          />
          <LabeledSelect
            optionsUrl="v1/ctmp/user/role"
            className={styles.Input}
            value={role}
            defaultLabel={role ? role.label : undefined}
            defaultId={role ? role.value : undefined}
            title={
              <>
                Select User Role<span> *</span>
              </>
            }
            onValueChange={(option) => {
              setRole(option);
              { option ? roles.push(option.label) : null; };
              updateRoles();
              setHValues([]);
              getMappingHeirarchy();
            }
            }
          />
        </div>
        <div className={styles.BrandAndCampaign}>
          <div className={styles.teamsParent}>
            <div>
              <label>
                Select User Teams<span> *</span>
              </label>
            </div>
            <MultiSelector
              className={styles.Select}
              name="Team"
              optionsUrl="v1/user/distinctTeams"
              valuesChanged={(teams) => setMultiProperty(teams, setTeams)}
              value={teams}
            />
          </div>
          <LabeledSelect
            className={styles.Input}
            options={HValues}
            placeholder="Select"
            value={hierarchy}
            defaultLabel={hierarchy ? hierarchy.label : undefined}
            defaultId={hierarchy ? hierarchy.value : undefined}
            title={
              <>
                Mapping user hierarchy<span> *</span>
              </>
            }
            onValueChange={(option) => setHierarchy(option)}
          />
        </div>
        {/*Buttons*/}
        {editUser === true ? (
          <div className={styles.SaveAndDiscardButton}>
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="55rem"
              noPopupHeight="30rem"
              yesTitle="Save"
              noTitle="Cancel"
              className={styles.Buttons}
              renderDiscardPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/user-management");
                  }}
                  noAction={closeModal}
                />
              )}
              renderSavePopupContent={(closeModal) => (
                <SaveDialog
                  itemsToBeComplete={toBeCompleted}
                  saveAction={async () => {
                    var result = await saveUser(saveUserObject);
                    if (result === "User Updated Successfully") {
                      let displayMessage = "The user " + saveUserObject.email + " has been successfully updated."
                      closeModal();
                      history.push("/ctmp/user-management");
                      toast.success(
                        <Msg message={displayMessage} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    }
                    else {
                      closeModal();
                      let displayMessage = "Failed to update the user " + saveUserObject.email + " please try again."
                      history.push("/ctmp/user-management");
                      toast.error(
                        <ErrorMsg message={displayMessage} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      )
                    }
                  }}
                  className="Edit User"
                  discardAction={closeModal}
                />
              )}
            />
          </div>
        ) : (
          <div className={styles.SaveAndDiscardButton}>
            {toBeCompleted.length === 0 ?
            <div className={styles.floatcontainer}>
            <div className={styles.floatchild}>
            <div style={{display:"flex"}}>
             <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/user-management");
                  }}
                  noAction={closeModal}
                  className="Discard"
                />
              )}
              renderControlContent={(openModal) => (
                <Button onClick={openModal} name="DiscardAddUser" />
              )}
            />
              
              <Button
              onClick={async () => {
                var result = await saveUser(saveUserObject);
                if (result === "User Inserted Successfully") {
                  let displayMessage = "The user " + saveUserObject.email + " has been successfully created."
                  history.push("/ctmp/user-management");
                  toast.success(
                    <Msg message={displayMessage} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                }
                else {
                  let displayMessage;
                  if (result === "Email is already registered") {
                    displayMessage = "Failed to create the user " + saveUserObject.email + ". " + result + "."
                  } else {
                    displayMessage = "Failed to create the user " + saveUserObject.email + ", please try again."
                  }
                  history.push("/ctmp/user-management");
                  toast.error(
                    <ErrorMsg message={displayMessage} />,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Error />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  )
                }
                }}
              name="AddUser"
            /></div></div></div>
            :
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="55rem"
              noPopupHeight="30rem"
              yesTitle="Add User"
              noTitle="Discard"
              className={styles.Buttons}
              renderDiscardPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/user-management");
                  }}
                  noAction={closeModal}
                />
              )}
              renderSavePopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    var result = await saveUser(saveUserObject);
                    if (result === "User Inserted Successfully") {
                      let displayMessage = "The user " + saveUserObject.email + " has been successfully created."
                      closeModal();
                      history.push("/ctmp/user-management");
                      toast.success(
                        <Msg message={displayMessage} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    }
                    else {
                      closeModal();
                      let displayMessage;
                      if (result === "Email is already registered") {
                        displayMessage = "Failed to create the user " + saveUserObject.email + ". " + result + "."
                      } else {
                        displayMessage = "Failed to create the user " + saveUserObject.email + ", please try again."
                      }
                      history.push("/ctmp/user-management");
                      toast.error(
                        <ErrorMsg message={displayMessage} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      )
                    }
                  }}
                  className="Add User"
                  discardAction={closeModal}
                  itemsToBeComplete={toBeCompleted}
                />
              )}
            />}
          </div>
        )}
      </div>
    </div>
  );
};
export default AddUser;
