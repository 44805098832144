import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import AppTable from "../General/AppTable/AppTable";
import { FilterControlType } from "../General/Table/TableHead/TableHeadFilters/TableHeadFilters";
import { columns } from "./manage-customer-list-table-structure";
import styles from "./ManageCustomerList.module.css";

type ManageCustomerListProps = {
  className?: string;
};

type ManageCustomerListResponse = {
  count: number;
  customerList: any[];
};

const ManageCustomerList = ({
  className
}: ManageCustomerListProps): JSX.Element => {
  const sortOptions = useMemo(
    () => [
      { label: "Latest Created", value: "latestCreated" },
      { label: "Oldest Created", value: "oldestCreated" },
      { label: "Latest Published", value: "latestLive" },
      { label: "Oldest Published", value: "oldestLive" }
    ],
    []
  );

  const history = useHistory();
  const CreateCustomerInfo = () => {
    console.log("history", history);
    console.log("history.location.pathname", history.location.pathname);
    history.push(history.location.pathname + "/create-customer/create/vehicle-details");
  };

  return (
    <div className={[className, styles.Container].join(" ")}>
      <AppTable
        mapResponseToData={(jsonResponse: ManageCustomerListResponse) =>
          jsonResponse.customerList
        }
        getTotalItems={(jsonResponse: ManageCustomerListResponse) =>
          jsonResponse.count
        }
        sortOptions={sortOptions}
        className={styles.CustomerList}
        title="Customer List"
        actionButtonTitle="Create Customer List"
        onActionButtonClick={CreateCustomerInfo}
        columns={columns}
        filterMetaData={[
          {
            name: "Status",
            apiCallName: "status",
            optionsUrl: "v1/ctmp/status?filter=customerList",
            type: FilterControlType.MultiSelector
          },
          {
            name: "Date Range",
            apiCallName: "dateRange",
            type: FilterControlType.DateRange
          },
          {
            name: "Creator",
            //options: creatorOptions,
            optionsUrl: "v1/ctmp/createdBy/CustomerList",
            apiCallName: "createdBy",
            type: FilterControlType.MultiSelector
          }
        ]}
      />
    </div>
  );
};

export default ManageCustomerList;
