/* eslint-disable  */
import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import styles from "./ManageCopyTableFilters.module.css";
import MultiSelector from "../../../../General/MultiSelector/MultiSelector";
import DateRangeSelector from "../../../../General/PopupSelector/DateRangeSelector/DateRangeSelector";
import { ReactComponent as Align } from "../../../../../assets/icons/Align.svg";
import { ReactComponent as AlignWhite } from "../../../../../assets/icons/AlignWhite.svg";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";

const ManageCopyTableFilters = ({ onFiltersChange, filters }) => {
    const setMultiProperty = useCallback(
        (object, setter) =>
            object && object.length ? setter([...object]) : setter(null),
        []
    );

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [category, setCategory] = useState(filters.category);
    const [subCategory, setSubCategory] = useState(filters.subCategory);
    const [status, setStatus] = useState(filters.status);
    const [brand, setBrand] = useState(filters.brand);
    const [dateRange, setDateRange] = useState(filters.dateRange);
    const [channels, setChannels] = useState(filters.channels);
    const [createdBy, setCreatedBy] = useState(filters.createdBy);

    useEffect(() => {
        setMultiProperty(filters.category, setCategory);
        setMultiProperty(filters.subCategory, setSubCategory);
        setMultiProperty(filters.status, setStatus);
        setMultiProperty(filters.brand, setBrand);
        // setMultiProperty(filters.dateRange, setDateRange);
        setMultiProperty(filters.channels, setChannels);
        setMultiProperty(filters.createdBy, setCreatedBy);
    }, [setMultiProperty, filters]);

    return (
        <div>
            <button
                onClick={() => setShowFilterModal(true)}
                className={
                    Object.keys(filters).length === 0 ||
                        filters.hasOwnProperty("freeSearch")
                        ? styles.Filter
                        : styles.FilterSelected
                }
            >
                {Object.keys(filters).length === 0 ? (
                    <React.Fragment>
                        <Align />
                        <span
                            style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
                        >
                            Filter
                        </span>
                    </React.Fragment>
                ) : filters.hasOwnProperty("freeSearch") ? (
                    <React.Fragment>
                        <Align />
                        <span
                            style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
                        >
                            Filter
                        </span>
                    </React.Fragment>
                ) : filters.hasOwnProperty("fromDate") ? (
                    <React.Fragment>
                        <AlignWhite />
                        <span
                            style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
                        >
                            Filter ({Object.keys(filters).length - 1})
                        </span>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <AlignWhite />
                        <span
                            style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
                        >
                            Filter ({Object.keys(filters).length})
                        </span>
                    </React.Fragment>
                )}
            </button>
            <ReactModal
                shouldCloseOnOverlayClick={true}
                isOpen={showFilterModal}
                onRequestClose={() => setShowFilterModal(false)}
                className={styles.FilterModal}
                overlayClassName={styles.FilterModalOverlay}
            >
                <div className={styles.FilterTitleContainer}>
                    <div className={styles.FilterTitleHeading}>
                        <p>
                            <span>Filters</span>
                        </p>
                        <CloseX
                            onClick={() => setShowFilterModal(false)}
                            className={styles.CloseIcon}
                        />
                    </div>
                </div>
                <div className={styles.FiltersContainer}>
                    {/*<MultiSelector
                        className={styles.Categories}
                        name="Associated Templates"
                        optionsUrl="v1/ctmp/category"
                        valuesChanged={(category) =>
                            setMultiProperty(category, setCategory)
                        }
                        value={category}
                    />
                     <MultiSelector
                        className={styles.SubCategories}
                        name="Associated Campaigns"
                        optionsUrl="v1/ctmp/subcategories"
                        valuesChanged={(subCategories) =>
                            setMultiProperty(subCategories, setSubCategory)
                        }
                        value={subCategory}
                    /> */}
                    <MultiSelector
                        className={styles.Status}
                        name="Status"
                        optionsUrl="v1/ctmp/status?filter=copy"
                        valuesChanged={(status) => setMultiProperty(status, setStatus)}
                        value={status}
                    />
                    {/* <MultiSelector
            className={styles.Brand}
            name="Created by"
            optionsUrl="v1/ctmp/brand"
            valuesChanged={(brand) => setMultiProperty(brand, setBrand)}
            value={brand}
          /> */}
                    <DateRangeSelector
                        className={styles.DateRange}
                        onDiscard={() => setDateRange(null)}
                        onApply={(dateRange) => {
                            setDateRange(dateRange[0]);
                        }}
                        value={dateRange}
                    />
                    {/* <MultiSelector
                        className={styles.Channels}
                        name="Channel"
                        optionsUrl="v1/ctmp/channel"
                        valuesChanged={(channels) =>
                            setMultiProperty(channels, setChannels)
                        }
                        value={channels}
                    /> */}
                    <MultiSelector className={styles.Creator}
                        name="Creator"
                        optionsUrl="v1/ctmp/createdBy/Copy"
                        valuesChanged={(creators) => setMultiProperty(
                            creators, setCreatedBy)}
                        value={createdBy}
                    />
                </div>
                <div className={styles.ApplyDiscardButtonContainer}>
                    <button
                        onClick={() => {
                            setShowFilterModal(false);
                            onFiltersChange({});
                            setDateRange(null)
                        }}
                        className={styles.DiscardButton}
                    >
                        Discard
                    </button>
                    <button
                        onClick={() => {
                            setShowFilterModal(false);
                            onFiltersChange({
                                ...(category && { category }),
                                ...(subCategory && { subCategory }),
                                ...(status && { status }),
                                ...(brand && { brand }),
                                ...(dateRange && {
                                    fromDate: dateRange.startDate,
                                    toDate: dateRange.endDate
                                }),
                                ...(channels && { channels }),
                                ...(createdBy && { createdBy })
                            });
                        }}
                        className={styles.ApplyButton}
                    >
                        Apply
                    </button>
                </div>
            </ReactModal>
        </div>
    );
};

export default ManageCopyTableFilters;
