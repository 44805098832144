import React from "react";

import styles from "./SaveAndDiscardWithPopupButtonSet.module.css";
import PopupDialog from "../../PopupDialog/PopupDialog";

type SaveAndDiscardWithPopupButtonSetProps = {
    className?: string
    renderDiscardPopupContent: (closeModal: () => void) => JSX.Element
    renderSavePopupContent: (closeModal: () => void) => JSX.Element
    /* css height style property (accepts a value of px, em, rem, %, vh, vw) */
    yesPopupHeight?: string
    /* css width style property (accepts a value of px, em, rem, %, vw, vh) */
    yesPopupWidth?: string
    /* css height style property (accepts a value of px, em, rem, %, vh, vw) */
    noPopupHeight?: string
    /* css width style property (accepts a value of px, em, rem, %, vw, vh) */
    noPopupWidth?: string
    yesTitle?: string
    noTitle?: string
}

const SaveAndDiscardWithPopupButtonSet = ({
                                              className,
                                              renderDiscardPopupContent,
                                              renderSavePopupContent,
                                              yesPopupHeight,
                                              yesPopupWidth,
                                              noPopupHeight,
                                              noPopupWidth,
                                              yesTitle = "Yes",
                                              noTitle = "No"
                                          }: SaveAndDiscardWithPopupButtonSetProps) => {
    return <div
        className={[className, styles.ButtonContainer].join(" ")}>
        <PopupDialog width={noPopupWidth}
                     height={noPopupHeight}
                     renderPopupContent={renderDiscardPopupContent}
                     renderControlContent={
                         (openModal) => <button onClick={openModal}
                                                className={styles.DiscardButton}>
                             {noTitle}
                         </button>
                     }/>
{yesTitle === "Publish" ?
<PopupDialog width={yesPopupWidth}
                     height={yesPopupHeight}
                     renderPopupContent={renderSavePopupContent}
                     renderControlContent={(openModal => <button
                         onClick={openModal}
                         className={styles.ApplyButtonDisable}
                     >{yesTitle}</button>)}/> :
        <PopupDialog width={yesPopupWidth}
                     height={yesPopupHeight}
                     renderPopupContent={renderSavePopupContent}
                     renderControlContent={(openModal => <button
                         onClick={openModal}
                         className={styles.ApplyButton}
                     >{yesTitle}</button>)}/>}
    </div>;
};

export default SaveAndDiscardWithPopupButtonSet;