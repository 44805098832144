import styles from "./CustomerListDetails.module.css";

type CustomerListDetailsProps = {
  className?: string;
  listName: string;
  listDescription: string;
  createdDate: string;
};

const CustomerListDetails = ({
  className,
  listName,
  listDescription,
  createdDate
}: CustomerListDetailsProps): JSX.Element => {
  return (
    <div className={styles.Container}>
      <div className={styles.CustomerListHeader}>{listName}</div>
      <p className={styles.CustomerListDescription}>{listDescription}</p>
      <div className={styles.CreatedDate}>{`List Created: ${createdDate}`}</div>
    </div>
  );
};

export default CustomerListDetails;
