import React from "react";

import styles from "./PageContent.module.css";

const PageContent = ({ className, children }) => {
    return <div className={ [className, styles.PageContent].join(" ") }>
        { children }
    </div>;
};

export default PageContent;