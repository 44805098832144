import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Xarrow from "react-xarrows";
import Box from "../../General/Box/Box";

const boxStyle = {
  border: "grey solid 2px",
  // borderRadius: "10px",
  // padding: "5px",
  marginTop: "50px"
};



const FlowChart = (formBody) => {
  console.log("formBody",formBody.formBody)
  // const formBody = {
  //   "rules": [{
  //     "id": 1,
  //     "key": "make",
  //     "value": "Car",
  //     "logical_connector": "<",
  //     "rule_ref": useRef(null)
  //   }, {
  //     "id": 2,
  //     "key": "make",
  //     "value": "Toyota2",
  //     "logical_connector": "<",
  //     "rule_ref": useRef(null)
  //   }, {
  //     "id": 3,
  //     "key": "make",
  //     "value": "Toyota2",
  //     "logical_connector": "<",
  //     "rule_ref": useRef(null)
  //   }],
  //   "connector": "AND",
  //   "connector_ref": useRef(null)
  // };
  // {
  //   "rules": [{
  //     "id": 1,
  //     "key": "make",
  //     "value": "Car",
  //     "logical_connector": "<",
  //     "rule_ref": useRef(null)
  //   }, {
  //     "id": 2,
  //     "key": "make",
  //     "value": "Toyota2",
  //     "logical_connector": "<",
  //     "rule_ref": useRef(null)
  //   }],
  //   "connector": "OR",
  //   "connector_ref": useRef(null)
  // };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%"
      }}>
      <Container style={{ marginTop: "10px" }}>
        <Row>
          <Col>
            {formBody.formBody.rules.map((r) => {
              return <Row>
                <Box boxRef={r.rule_ref} keys={r.key} value={r.value} />
              </Row>
            })}
          </Col>
          <Col>
            <Row>
              <Card ref={formBody.formBody.connector_ref} style={boxStyle}>
                <Card.Header> {formBody.formBody.connector}</Card.Header>
              </Card>
            </Row>
          </Col>
        </Row>
      </Container>
      {formBody.formBody.rules.map((r) => {
        return <Xarrow
          start={r.rule_ref}
          end={formBody.formBody.connector_ref}
        />
      })}
    </div>
  );
};

export default FlowChart;
