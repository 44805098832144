/* eslint-disable*/
import { format } from "date-fns";

export const mapCampaignPriority = (serverResponse) => [...serverResponse.map(
    item => ({
        campaignName: item.name,
        priority: item.priority,
        description: item.shortDescription,
        statusOn: item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy"),
        associatedCustomerList: item.associatedCustomerList
    }))];