import React from "react";
import RowAction from "./Row/RowAction/RowAction";
import CampaignDetails from "./Row/CampaignDetails/CampaignDetails";
import CategoryCell from "./Row/Category/CategoryCell";
import ChannelCell from "./Row/Channel/ChannelCell";
import RowActionMenu from "./Row/RowActionMenu/RowActionMenu";
import Status from "./Row/Status/Status";
// import { Link } from "react-router-dom";


export const columns = [
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell: { value } }) => <RowAction
            menu={<RowActionMenu action={value} id={value.campaignId} name={value.name} />} />
    },
    {
        Header: "Brand",
        accessor: "brand"
    },
    {
        Header: "Campaign Details",
        accessor: "campaignDetails",
        Cell: ({ cell: { value } }) => (<CampaignDetails data={value} />)
    },
    {
        Header: "Created by",
        accessor: "creator"
    },
    {
        Header: "Channel",
        accessor: "channel",
        Cell: ({ cell: { value } }) => (<ChannelCell data={value} />)
    },
    {
        Header: "Category & Sub Category",
        accessor: "categoryDetails",
        Cell: ({ cell: { value } }) => (<CategoryCell data={value} />)
    },
    {
        Header: "Go Live Date",
        accessor: "goLiveDate"
    },
    {
        Header: "Version",
        accessor: "version",
        Cell: ({ cell: { value } }) => (
            // <Link to={`/ctmp/dashboard/version-control/${value.campaginId}`}>
            <span
                style={{
                    fontSize: "1.2rem",
                    lineHeight: "2.4rem",
                    color: "var(--ct-blue-secondary)"
                }}>{value.versionNumber}</span>
            // </Link>
        )
    },
    // {
    //     Header: "Status",
    //     accessor: "roleStatus",
    //     Cell: ({ cell: { value } }) => <Status value={ value }/>
    // }
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => <Status value={value} />
    }
];