import React, { useState } from "react";
import styles from "./Header.module.css";
import { ReactComponent as Logo } from "../../assets/icons/ctp-mp-mktg-logo.svg";
// import { ReactComponent as Notification } from "../../assets/icons/notification-bell.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as ProfileArrowIcon } from "../../assets/icons/profile-arrow-down.svg";
import { Popover } from "react-tiny-popover";

const Header = ({authAccount}) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return <header className={ styles.Header }>
        <div className={ styles.IconBox }>
            <Logo/>
        </div>
        <div className={ styles.ControlBox }>
            {/* <div className={ styles.SearchBox }>
                <input type="text" placeholder="Search..."/>
            </div>
            <div className={ styles.NotificationBox }>
                <Notification/>
            </div> */}
            <div className={ styles.UserInfoBox }>
                <div className={ styles.UserInfoName }>{ authAccount.account.name }</div>
                <div
                    className={ styles.UserInfoEmail }>{ authAccount.account.userName }
                </div>
            </div>
            <Popover isOpen={ isPopoverOpen }
                     positions={ ["bottom"] }
                     padding={ 5 }
                     onClickOutside={ () => setIsPopoverOpen(false) }
                     content={ <div className={ styles.PopOver } >
                        <div><span className={ styles.PopOverEdit } onClick= { () => setIsPopoverOpen(false) }>Edit Profile</span></div>
                        <div><span className={ styles.PopOverEdit } onClick= { () => setIsPopoverOpen(false) }>Logout</span></div>
                     </div> }>
                <div
                    onClick={ () => setIsPopoverOpen(!isPopoverOpen) }
                    className={ styles.ProfileBox }>
                    <div className={ styles.ProfileIcon }>
                        <ProfileIcon/>
                    </div>
                    <div className={ styles.ProfileArrow }>
                        <ProfileArrowIcon/>
                    </div>
                </div>
            </Popover>
        </div>
    </header>;
};

export default Header;