import React, { useState } from "react";
import styles from "./VersionControlTemplateGroup.module.css";
import { ReactComponent as TemplateSmall } from "../../../../../assets/icons/TemplateSmall.svg";

/* eslint-disable  @typescript-eslint/no-unused-vars */

const VersionControlTemplateGroup = (props) => {
  const campaginVersions = props.data;
  const [templateGroup, showTemplateGroup] = useState(true);
  let emailTemplate = [];

  const sortTemplates = () => {
    campaginVersions.forEach((version) => {
      version.channel.forEach((channel) => {
        if (channel.name === "Email") {
          emailTemplate.push(version);
        }
      });
    });
    // console.log(emailTemplate);
  };
  sortTemplates();

  const templateGroupToggle = () => {
    if (templateGroup) {
      showTemplateGroup(false);
    } else {
      showTemplateGroup(true);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.TemplateGroupTitle} onClick={templateGroupToggle}>
        <div className={styles.TemplateType}>
          Template Type {" "}
          <span
            className={templateGroup ? styles.ChevronDown : styles.ChevronUp}
          />
        </div>
        <div>Items: {emailTemplate.length}</div>
      </div>
      <div
        className={styles.TemplateGroup}
        style={templateGroup ? { display: "inherit" } : { display: "none" }}
      >
        {emailTemplate.slice().reverse().map((template, index) => (
          <span key={index} className={styles.TemplateDetails}>
            <div className={styles.TemplatePreview}><a href={`/ctmp/dashboard/version-control/${template.campaignId}/preview/${template.version}/details`}><TemplateSmall/></a></div>
            <a className={styles.VersionNumber} href={`/ctmp/dashboard/version-control/${template.campaignId}/preview/${template.version}/details`}>V{template.version}</a>
            <div className={styles.TemplateName}>TEMPLATE NAME</div>
          </span>
        ))}
      </div>
    </React.Fragment>
  );
};

export default VersionControlTemplateGroup;
