/* eslint-disable*/
import styles from "./AddUser.module.css";
import React, { useState, useCallback, useEffect } from "react";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import MultiSelector from "../../General/MultiSelector/MultiSelector";
import RadioButtonControl from "../../General/RadioButtonControl/RadioButtonControl";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
/*alerts imports*/
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import Button from "../../General/Button/Button";
import PopupDialog from "../../General/PopupDialog/PopupDialog";

const AddTeam = () => {
  const [teamName, setTeamName] = useState();
  const [teamDescription, setTeamDescription] = useState();
  // const [teamMembers , setTeamMembers] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [addTeamObject,setAddTeamObject] = useState({});
  const userName = useAppSelector((state) => state.authReducer.userName);
  const history = useHistory();
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );
  
  /* update the values when changed */
  useEffect(() => {
    setAddTeamObject({
      teamName: teamName,
      teamDescription:teamDescription,
      // ...(teamMembers && {
      //   teamMembers: teamMembers.map((c) => ({
      //     name: c.label,
      //     id: c.value,
      //   })),
      // }),
      createdBy: userName
    });
  }, [teamName, teamDescription]);

  /* to check everything is entered or not */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !teamName
      ? [...toBeCompletedArray, "Team Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !teamDescription
      ? [...toBeCompletedArray, "Team Description"]
      : toBeCompletedArray;
    // toBeCompletedArray = !teamMembers || !teamMembers.length
    //   ? [...toBeCompletedArray, "Select Team Members"]
    //   : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [teamName, teamDescription]);

   /* save Team */
   const saveTeam = async (addTeamObject) => {
    console.log("--->" + JSON.stringify(addTeamObject));
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/teams`);
    
    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(addTeamObject)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
   return serverResponse.status;
  };

  return (
    <React.Fragment>
      <div className={styles.RoleContentDisplay}>
        <div className={styles.RoleContainer}>
          <div style={{ paddingTop: "4rem", paddingLeft: "4rem" }}>
            <p className={styles.UpdateFormHeader}>Add New Team</p>
            <span className={styles.UpdateFormSubTitle}>
              To add a new team, please enter the following information:
            </span>
            <div>
              <LabeledInput
                title={
                  <>
                    Team Name<span> *</span>{" "}
                  </>
                }
                data={teamName}
                placeholder="Team Name"
                className={styles.Input}
                onValueChange={(value) => setTeamName(value)}
              />
            </div>
            <div>
              <LabeledInput
                title={
                  <>
                    Team Description<span> *</span>{" "}
                  </>
                }
                data={teamDescription}
                placeholder="Description"
                className={styles.Input}
                onValueChange={(value) => setTeamDescription(value)}
              />
            </div>
                    {/* api for retreiving all users */}
            {/* <div style={{marginTop:"4rem",width:"50%"}}>
            <label>
                    Team Members<span> *</span>
            </label>
            <MultiSelector
                  className={styles.Select}
                  name="Select"
                  optionsUrl="v1/ctmp/subcategories"
                  // optionsUrl="v1/ctmp/status"
                  valuesChanged={(teamMembers) =>
                    setMultiProperty(teamMembers, setTeamMembers)
                  }
                  value={teamMembers}
                />
            </div> */}
            </div>
            </div>
            </div>
         
          <div className={styles.SaveAndDiscardButton} style={{marginTop:"1rem"}}>
          {toBeCompleted.length === 0 ?
            <div className={styles.floatcontainer}>
            <div className={styles.floatchild}>
            <div style={{display:"flex"}}>
             <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    history.push("/ctmp/user-management");
                  }}
                  noAction={closeModal}
                  className="Discard"
                />
              )}
              renderControlContent={(openModal) => (
                <Button onClick={openModal} name="DiscardAddTeam" />
              )}
            />
              
              <Button
              onClick={async () => {
                var result = await saveTeam(addTeamObject);
                console.log("--->"+result)
                 if(result === 200){
                 let displayMessage =  "The team "+addTeamObject.teamName +" has been successfully created." 
                 history.push("/ctmp/user-management");
                 toast.success(
                   <Msg message={displayMessage}/>,
                   {
                     position: toast.POSITION.TOP_CENTER,
                     hideProgressBar: true,
                     autoClose: 3000,
                     icon: <Success />,
                     transition: Slide,
                     closeOnClick: true
                   }
                 );
                }
                else{
                 let displayMessage = "Failed to create the team "+addTeamObject.teamName +" please try again."
                 history.push("/ctmp/user-management");
                     toast.error(
                       <ErrorMsg message={displayMessage}/>,
                       {
                         position: toast.POSITION.TOP_CENTER,
                         hideProgressBar: true,
                         autoClose: 3000,
                         icon: <Error />,
                         transition: Slide,
                         closeOnClick: true
                       }
                     )
                }
                }}
              name="AddTeam"
            /></div></div></div>
            :
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="39rem"
              noPopupHeight="55rem"
              yesTitle="Add Team" //publish
              noTitle="Discard" //save
              className={styles.Buttons}
              renderDiscardPopupContent={(closeModal) => (
                <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/user-management");
                }}
                noAction={closeModal}
              />
              )}
              renderSavePopupContent={(closeModal) => (
                <SaveDialog
                itemsToBeComplete={toBeCompleted}
                saveAction={async () => {
                 var result =  await saveTeam(addTeamObject);
                 closeModal();
                 if(result === 200){
                  let displayMessage =  "The team "+addTeamObject.teamName +" has been successfully created." 
                  closeModal();
                  history.push("/ctmp/user-management");
                  toast.success(
                    <Msg message={displayMessage}/>,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                 }
                 else{
                  closeModal();
                  let displayMessage = "Failed to create the team "+addTeamObject.teamName +" please try again."
                  history.push("/ctmp/user-management");
                      toast.error(
                        <ErrorMsg message={displayMessage}/>,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      )
                 }
                }}
                discardAction={closeModal}
                className="Add_Team"
              />
              )}
            />}
          </div>

    </React.Fragment>
  );
};
export default AddTeam;
