import React from "react";

export const Msg = (prop) => {
    return (
        <div>
            <div>SUCCESSFUL</div>
            {prop.id === "delete" ?
                <span>
                    Campaign {prop.campaignName ? prop.campaignName : ""} is deleted successfully
                </span> :
                <span>
                    Campaign priority level successfully updated
                </span>
            }
        </div>
    );
};

export const ErrorMsg = (prop) => {
    return (
        <div>
            <div style={{ color: "#BC0000 !important" }}>ERROR</div>
            {prop.id === "delete" ?
                <span>
                    Campaign {prop.campaignName ? prop.campaignName : ""} is not deleted successfully
                </span> :
                <span>
                    Start date should be before the End date!
                </span>
            }
        </div>
    );
};
