import React from "react";
import styles from "./SidePageContent.module.css";


const SidePageContent = ({ className, children }) => {
    return <div className={ [className, styles.SidePageContent].join(" ") }>
        { children }
    </div>;
};

export default SidePageContent;