/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import styles from "./CreateCopy.module.css";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import XLSX from "xlsx";
import { Context } from "../../../context";
import { Slide, toast } from "react-toastify";
import { Msg, ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import BackButton from "../../General/Button/Button";
import Button from "../../General/Button/Button";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";
// import { SheetJSApp } from "../../ManageCustomerList/CreateCustomer-2/CreateCustomerRight/CreateCustomerVinList";

const CreateNewCopy = (props) => {
    const history = useHistory();
    const [uploadCopyName, setUploadCopyName] = useState();
    const [uploadCopyDescription, setUploadCopyDescription] = useState();
    const [uploadCopyTemplate, setUploadCopyTemplate] = useState();
    const [saveUploadObject, setSaveUploadObject] = useState({});
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [contentFile, setContentFile] = useState();
    const [output, setOutput] = useState([{ data: "", cols: "" }])
    const [data, setData] = useState([]);
    const [cols, setCols] = useState([]);
    const { value } = useContext(Context);
    const [context, setContext] = value;
    const [isShown, setIsShown] = useState(false);
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const userName = useAppSelector((state) => state.authReducer.userName);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const pathName = history.location.pathname.split("/");
    const [s3ZipFileKey, sets3ZipFileKey] = useState();
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [editFileName, setEditFileName] = useState();
    const [templates, setTemplates] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    /*get published templates*/
    useEffect(() => {
        const getPublishedTemplateDetails = async () => {
            setIsLoaded(true)
            const url = new URL(
                `${process.env.REACT_APP_API_HOSTNAME}v1/template/search`
            );
            const callParams = {
                method: "POST",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify({ "status": ["Published"] })
            };
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            console.log("templates - ", serverResponseJson.templates)
            serverResponseJson.templates.map((template) => {
                setTemplates((templates) => [
                    ...templates,
                    { label: template.name, value: template._id }
                ]);
            });
            setIsLoaded(false)
        };
        getPublishedTemplateDetails();
    }, []);

    /*get Values from database when user clicks edit in manageCopy*/
    useEffect(() => {
        if (props.props !== undefined) {
            const getContentDetails = async () => {
                setIsLoaded(true)
                const url = new URL(
                    `${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/${props.props}`
                );
                const callParams = {
                    method: "GET",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt}`
                    }
                };
                const serverResponse = await fetch(url.toString(), callParams);
                serverResponse.json().then((response) => {
                    const res = response.data;
                    setUploadCopyName(res.name);
                    setEditFileName(res.contentCopyUplodedFileName);
                    sets3ZipFileKey(res.s3FileKey)
                    setUploadCopyDescription(res.description);
                    setUploadCopyTemplate({
                        label: res.template[0].name,
                        value: res.template[0]._id
                    });
                });
                setIsLoaded(false)
            };
            getContentDetails();
            setEditMode(true);
            if (pathName[3] === "view-copy") {
                setViewMode(true);
            } else {
                setViewMode(false)
            }
        }
    }, []);

    /* notify if user do not enter mandatory fields */
    useEffect(() => {
        let toBeCompletedArray = [];
        toBeCompletedArray = !uploadCopyTemplate
            ? [...toBeCompletedArray, "Template"]
            : toBeCompletedArray;
        toBeCompletedArray = !uploadCopyName
            ? [...toBeCompletedArray, "Name"]
            : toBeCompletedArray;
        toBeCompletedArray = !uploadCopyDescription
            ? [...toBeCompletedArray, "Description"]
            : toBeCompletedArray;
        toBeCompletedArray =
            !contentFile && !editFileName
                ? [...toBeCompletedArray, "Load Zip File"]
                : toBeCompletedArray;
        setToBeCompleted(toBeCompletedArray);
    }, [
        uploadCopyTemplate,
        uploadCopyName,
        uploadCopyDescription,
        contentFile
    ]);

    /* update the values when changed */
    useEffect(() => {
        setSaveUploadObject({
            name: uploadCopyName,
            contentFile: contentFile,
            description: uploadCopyDescription,
            ...(uploadCopyTemplate && {
                template: [
                    {
                        name: uploadCopyTemplate.label,
                        id: uploadCopyTemplate.value
                    }
                ]
            })
        });
    }, [
        uploadCopyName,
        uploadCopyDescription,
        uploadCopyTemplate,
        contentFile
    ]);

    /* generate an array of column objects */
    const make_cols = (refstr) => {
        let o = [],
            C = XLSX.utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) {
            o[i] = { name: XLSX.utils.encode_col(i), key: i };
        }
        return o;
    };

    const displayResults = async (uploadedFile) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {
                header: 1
            });
            /* Update state */
            // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
            setData(data);
            setIsShown(false);
            setCols(make_cols(ws["!ref"]))
        };
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    }

    const showData = async () => {
        setIsShown(true);
    }

    /* save funtion */
    const saveUploadData = async (saveUploadObject) => {
        setIsLoaded(true)
        let formData = new FormData();
        editMode === true
        // Update the formData object 
        formData.append("contentZip", contentFile);
        formData.append('name', uploadCopyName);
        formData.append('status', "Draft");
        pathName[3] === "edit-copy" ? "" : formData.append('createdBy', userName);
        formData.append('description', uploadCopyDescription);
        formData.append('template', JSON.stringify([{ name: uploadCopyTemplate.label, id: uploadCopyTemplate.value }]))

        for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
        }
        console.log("--->" + JSON.stringify(saveUploadObject));
        let url, methodValue;
        url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy`)
        pathName[3] === "edit-copy" ? (url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/${props.props}`), methodValue = "PUT") :
            methodValue = "POST";

        let callParams = {
            method: methodValue,
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                // "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
                "X-CORRELATIONID": 0
            },
            // body: JSON.stringify(saveUploadObject)
            body: formData
        };
        console.log("------save upload object---------->" + JSON.stringify(saveUploadObject))
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = await serverResponse.json();
        console.log("response----->" + serverResponseJson.status);
        return serverResponse.status;
    };

    /*reset when flag is true */
    useEffect(() => {
        if (props.props !== undefined) {
            if (deleteFlag === true) {
                setContentFile("")
                setEditFileName(undefined)
            } else {
                console.log(contentFile + "" + editFileName)
            }
        } else { console.log("upload copy") }

    }, [deleteFlag])

    //downloadFile
    const downloadFile = async () => {
        //'https://mktpl.dev.telematicsct.com/v1/content-copy/62b0b08b2cdc397371e47e89/data-file/1' 
        fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/${props.props}/data-file/1`, {
            method: 'GET',
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: `Bearer ${jwt}`

            }
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    // `FileName.zip`,
                    `${editFileName}`
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    return (
        <div style={{ textAlign: "left" }}>
            {(isLoaded === true) ? <LoadSpinner /> : ""}
            <p className={styles.UpdateFormHeader}>
                {pathName[3] === "edit-copy" ? "Edit existing campaign copy" :
                    pathName[3] === "view-copy" ? "View existing campaign copy" :
                        "Upload campaign copy"}
            </p>
            <span className={styles.UpdateFormSubTitle}>
                To update campaing copy, select its template format, then re-upload the updated Excel copy file.
            </span>
            <div className={viewMode === true ? styles.NodeDisplayDisable : ""}>
                <div style={{ marginBottom: "2rem", marginTop: "3rem" }}>
                    <LabeledSelect
                        className={styles.Select}
                        options={templates}
                        placeholder="Select"
                        value={uploadCopyTemplate}
                        defaultLabel={
                            uploadCopyTemplate
                                ? uploadCopyTemplate.label
                                : undefined
                        }
                        defaultId={
                            uploadCopyTemplate
                                ? uploadCopyTemplate.value
                                : undefined
                        }
                        title={
                            <>
                                Select template<span>*</span>
                            </>
                        }
                        onValueChange={(option) => setUploadCopyTemplate(option)}
                    />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                    <LabeledInput
                        title={
                            <>
                                Name<span>*</span>
                            </>
                        }
                        data={uploadCopyName}
                        placeholder="Type the campaign copy name"
                        className={styles.Input}
                        onValueChange={(value) => setUploadCopyName(value)}
                    /></div>
                <div style={{ marginBottom: "2rem" }}>
                    <LabeledInput
                        title={
                            <>
                                Description<span>*</span>
                            </>
                        }
                        data={uploadCopyDescription}
                        className={styles.Input}
                        placeholder="Type the description"
                        onValueChange={(value) => setUploadCopyDescription(value)}
                    />
                </div>
            </div>
            <div className={styles.ParentContainer}>
                <div className={styles.child1}>
                    {props.props !== undefined && !deleteFlag ?
                        <div>
                            <label>
                                Copy File
                                <br />
                                {props.props !== undefined ? (
                                    <a
                                        //href='https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=assets/marketing-platform/emailTemplate/832314109865/images/test.zip'
                                        //className={ id.actionValue === "view-template" ?styles.TemplateDeleteDownloadView:styles.TemplateDeleteDownload}
                                        className={viewMode === true ? styles.TemplateDeleteDownload : styles.TemplateDeleteDownload}

                                        onClick={downloadFile}
                                    >
                                        {editFileName}
                                    </a>
                                ) : (
                                    ""
                                )}
                            </label>
                        </div> : ""}
                </div>

                <div className={viewMode === true ? styles.NodeDisplayDisable : styles.child1}>
                    <div style={{ paddingLeft: "13rem" }}>
                        <PopupDialog
                            height="30rem"
                            width="67rem"
                            renderPopupContent={(closeModal) => (
                                <SaveDialog
                                    saveAction={async () => {
                                        setDeleteFlag(true);

                                        toast.success(
                                            <Msg id="delete" templateName={uploadCopyName} />,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                hideProgressBar: true,
                                                autoClose: 3000,
                                                icon: <Success />,
                                                transition: Slide,
                                                closeOnClick: true
                                            }
                                        );
                                        setContext();
                                        closeModal();

                                    }}
                                    discardAction={closeModal}
                                    className="DeleteCopyFile"
                                    itemsToBeComplete={toBeCompleted}
                                />
                            )}
                            renderControlContent={(openModal) => (
                                // id.actionValue !== "view-template"?
                                props.props !== undefined && !deleteFlag ?
                                    <BackButton
                                        onClick={openModal}
                                        name="Delete Copy File" /> : ""
                            )}
                        />
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: "0.2rem" }}>
                {(editFileName === "" || editFileName === undefined) ?
                    <div>
                        <LabeledFileUploadBar
                            title={
                                <>
                                    Upload new copy (xls file)<span>*</span>
                                </>
                            }
                            newUpload="no"
                            uploadType="uploadCopy"
                            onUploadClick={(value) => { showData() }}
                            uploadedFile={(value) => { setContentFile(value); displayResults(value) }}
                        /></div> :
                    <div style={{ opacity: "0.7", pointerEvents: "none" }}>
                        <LabeledFileUploadBar
                            title={
                                <>
                                    Upload new copy (xls file)<span>*</span>
                                </>
                            }
                            newUpload="no"
                            uploadType="uploadCopy"
                            onUploadClick={(value) => { showData() }}
                            uploadedFile={(value) => { setContentFile(value); displayResults(value) }}
                        /></div>}

                {data.length > 0 && isShown == true ?
                    <React.Fragment>
                        <label style={{ paddingTop: "5rem" }}> Campaign copy preview </label>
                        <div className="table-responsive" style={{ "max-height": "450px", "marginTop": "0rem", "border": "1px solid #dddddd" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <tbody>
                                    {data.slice(0, 1).map((r, i) => (
                                        <tr>
                                            {r.map((c, k) => (
                                                <tr>
                                                    <td style={{ backgroundColor: "#F5F6FA", padding: "2rem 2rem 3rem 2rem" }}>
                                                        {r[k]}
                                                    </td>
                                                    <td style={{ backgroundColor: "#FFFFFF", padding: "2rem 2rem 3rem 2rem" }}>
                                                        {data.slice(1)[0][k]}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                    : ""}
                {/* <SheetJSApp name="uploadcontent"/> */}
            </div>

            {pathName[3] === "view-copy" ?
                <div>
                    <BackButton
                        onClick={() => {
                            localStorage.setItem("blockNavigation", false);
                            history.push("/ctmp/copy");
                        }}
                        name="Back"
                    />
                </div>
                :
                toBeCompleted.length === 0 ? (
                    <div className={styles.savediscardcontainer}>
                        <div className={styles.discardchild}>
                            <PopupDialog
                                height="30rem"
                                width="67rem"
                                renderPopupContent={(closeModal) => (
                                    <DiscardDialog
                                        yesAction={() => {
                                            setContext();
                                            history.push("/ctmp/copy");
                                            toast.success(
                                                <Msg id="discard" templateName={uploadCopyName} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Success />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                        }}
                                        noAction={closeModal}
                                    />
                                )}
                                renderControlContent={(openModal) => (
                                    <BackButton onClick={openModal} name={editMode === true ? "CopyEditCompleteDiscard" : "CopyNewCompleteDiscard"}>
                                        Discard
                                    </BackButton>
                                )}
                            />
                        </div>
                        <div className={styles.savechild}>
                            <Button
                                onClick={async () => {
                                    let statusVar = await saveUploadData(saveUploadObject);
                                    localStorage.setItem("blockNavigation", "false");
                                    history.push("/ctmp/copy");
                                    setIsLoaded(false);
                                    statusVar !== 200 &&
                                        toast.error(
                                            <ErrorMsg id="save" templateName={uploadCopyName} />,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                hideProgressBar: true,
                                                autoClose: 3000,
                                                icon: <Error />,
                                                transition: Slide,
                                                closeOnClick: true
                                            }
                                        );
                                    statusVar === 200 &&
                                        toast.success(
                                            <Msg id="save" templateName={uploadCopyName} />,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                hideProgressBar: true,
                                                autoClose: 3000,
                                                icon: <Success />,
                                                transition: Slide,
                                                closeOnClick: true
                                            }
                                        );
                                }}
                                name={editMode === true ? "CopyEditCompleteSave" : "CopyNewCompleteSave"}
                            />
                        </div>
                    </div>
                ) :
                    <div className={styles.floatcontainer}>
                        <div className={editMode === true ? styles.floatchildEdit : styles.floatchild}>
                            <PopupDialog
                                height="30rem"
                                width="67rem"
                                renderPopupContent={(closeModal) => (
                                    <DiscardDialog
                                        yesAction={() => {
                                            history.push("/ctmp/copy");
                                        }}
                                        noAction={closeModal}
                                        className="Discard"
                                    />
                                )}
                                renderControlContent={(openModal) => (
                                    // <Button onClick={openModal} name="Discard" />
                                    console.log("")
                                )}
                            />
                        </div>
                        <div className={editMode === true ? styles.floatchildEdit : styles.floatchild}>
                            {/* save */}
                            <div className={styles.floatchild2} style={{ paddingLeft: "7rem" }}>
                                <SaveAndDiscardWithPopupButtonSet
                                    yesPopupHeight="55rem"
                                    noPopupHeight="30rem"
                                    yesTitle="Save" //save
                                    noTitle="Discard" //discard
                                    className={styles.CopyNewEdit}
                                    renderDiscardPopupContent={(closeModal) => (
                                        //save and close
                                        <DiscardDialog
                                            yesAction={() => {
                                                history.push("/ctmp/copy");
                                            }}
                                            noAction={closeModal}
                                            className="Discard"
                                        />
                                    )}
                                    renderSavePopupContent={(closeModal) => (
                                        <SaveDialog
                                            saveAction={async () => {
                                                let statusVar = await saveUploadData(saveUploadObject);
                                                console.log("statusVar--->" + statusVar)
                                                closeModal();
                                                console.log(context)
                                                setContext("");
                                                history.push("/ctmp/copy");
                                                setIsLoaded(false)
                                                statusVar === 200 &&
                                                    toast.success(
                                                        <Msg id="save" templateName={uploadCopyName} />,
                                                        {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            hideProgressBar: true,
                                                            autoClose: 3000,
                                                            icon: <Success />,
                                                            transition: Slide,
                                                            closeOnClick: true
                                                        }
                                                    );
                                                statusVar !== 200 &&
                                                    toast.error(
                                                        <ErrorMsg id="save" templateName={uploadCopyName} />,
                                                        {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            hideProgressBar: true,
                                                            autoClose: 3000,
                                                            icon: <Error />,
                                                            transition: Slide,
                                                            closeOnClick: true
                                                        }
                                                    );
                                            }}
                                            discardAction={closeModal}
                                            itemsToBeComplete={toBeCompleted}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>}
        </div>
    );
};
export default CreateNewCopy;
