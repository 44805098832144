/* eslint-disable */
import React from "react";
import styles from "../../../../src/App.css";
export const Msg = (prop) => {
 let text;
 console.log(prop.id + "-------------------" + prop.message);
 (prop.id === "delete") ? (text = "User deactivated successfully") : (text = prop.message);
 
  return (
    <div>
      <div>SUCCESSFUL</div>
      <span>
        {text}
      </span>
    </div>
  );
};

export const ErrorMsg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.message);
  (prop.id === "delete") ? (text = "User deactivation is unsuccessfull") : (text = prop.message);
  return (
    <div>
      <div style={{color:"#BC0000 !important" }}>ERROR</div>
      <span>
      {text}
      </span>
    </div>
  );
};
