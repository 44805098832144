import React from "react"

import styles from "./TeamCell.module.css"

const TeamCell = ({data}) => {
    return <div className={styles.TeamCellContainer}>
        {data.map((item, index) => (<span key={index} className={styles.TeamItem}>{item}</span>))}
    </div>
}

export default TeamCell