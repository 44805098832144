/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import MultiPickerCard from "./Card/MultiPickerCard";
import styles from "./MultiPicker.module.css";

type SelectorProps = {
  data: any;
  setSelected: any;
  setSelectedList?: any;
  previousSelected?: any;
  status?: any;
  disabled?: boolean;
  mode: any;
};

const MultiPicker = ({ data, setSelected, setSelectedList, previousSelected, status, disabled, mode }: SelectorProps) => {
  //   const [selectedItems, setSelectedItems] = useState([]);

  //   useEffect(() => {
  //     console.log("Calling on change with:", selectedItems)
  //     setSelected(selectedItems);
  //   }, [selectedItems, setSelected]);
  if (status === "Template") {
    return (
      <div className={mode === "create-template" ? styles.MultiPickerTemplate2 : styles.MultiPickerTemplate}>
        {data.map((d) => (
          <MultiPickerCard
            disabled={disabled}
            selected={previousSelected.includes(d.title)}
            key={d.title}
            icon={d.icon}
            description={d.description}
            title={d.title}
            onSelect={(selectedCard) => {
              if (!disabled) {
                if (previousSelected.findIndex((s) => s === selectedCard) === -1) {
                  setSelected((currentSelected) => [
                    // ...currentSelected,
                    selectedCard
                  ]);
                } else {
                  setSelected((currentSelected) =>
                    currentSelected.filter((s) => s !== selectedCard)
                  );
                }
              }

            }}
          />
        ))}
      </div>
    );
  }
  else {
    return (
      <div className={styles.MultiPicker}>
        {data.map((d) => (
          <MultiPickerCard
            disabled={disabled}
            selected={previousSelected.includes(d.title)}
            key={d.title}
            icon={d.icon}
            description={d.description}
            title={d.title}
            onSelect={(selectedCard) => {
              if (previousSelected.findIndex((s) => s === selectedCard) === -1) {
                setSelected((currentSelected) => [
                  // ...currentSelected,
                  selectedCard
                ]);
              } if (previousSelected.findIndex((s) => s === selectedCard) === -1) {
                setSelectedList((currentSelected) => [
                  ...currentSelected,
                  selectedCard
                ]);
              }
              else {
                setSelected((currentSelected) =>
                  currentSelected.filter((s) => s !== selectedCard)
                );
              }
            }}
          />
        ))}
      </div>
    );
  }


};

export default MultiPicker;
