/* eslint-disable*/
import React, { useEffect, useState, useCallback } from "react";
import styles from "./AddUser.module.css";
import Table from "../../General/Table/Table";
import TableFooter,{ PAGE_SIZES } from "../../General/Table/TableFooter/TableFooter";
import { columns } from "../ManageUserTable/manage-userList-table-structure";
import { mapUsers } from "../../../utils/object-mappers/manage-users/map-userList";
import { useAppSelector } from "../../../redux/store";

const ListUsers = (id) => {

    const [totalUsers, setTotalUsers] = useState(0);
    const [Users, setUsers] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
    let jwt = useAppSelector((state) => state.authReducer.jwt);

    const goToPage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const changePageSizeTo = useCallback((newPageSize) => {
        setCurrentPage(1);
        setSelectedPageSize(newPageSize);
    }, []);

    useEffect(() => {
        (async () => {
           let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user`);
            let callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                    // "user-info": JSON.stringify({ userName: userName, userEmail: email })

                }
            };
            const params = {
              page: currentPage,
              pagesize: selectedPageSize
             };
            url.search = new URLSearchParams(params).toString();
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            setTotalUsers(serverResponseJson.totalUsers);
            setUsers(mapUsers(serverResponseJson.user));
            setIsLoaded(false);
        })();

    }, [selectedPageSize, currentPage]);
  
  return (
    <div style={{ textAlign: "left" }}>
      <div style={{ paddingBottom: "4rem" }}>
        <p className={styles.UpdateFormHeader}>List Users</p>
        <span className={styles.UpdateFormSubTitle}>
          Overview of CT Marketing Portal's users
        </span>
      </div>
      
      <Table columns={columns} data={Users} />
      <TableFooter
            changePageSizeTo={changePageSizeTo}
            selectedPageSize={selectedPageSize}
            currentPage={currentPage}
            goToPage={goToPage}
            totalItems={totalUsers} />
    </div>
  );
};
export default ListUsers;
