/* eslint-disable */
import React, { Fragment, useState } from "react";
import styles from "./ManageCopy.module.css";
import CreateCopy from "./CreateCopy/CreateCopy";
import ManageCopyHeader from "./ManageCopyHeader/ManageCopyHeader";
import ManageCopyTable from "./ManageCopyTable/ManageCopyTable";
import { Route, Switch } from "react-router-dom";

const ManageCopy = ({ match }) => {
    const [search, setSearch] = useState();
    const [contentDetails, setContentDetails] = useState();

    return (
        <div className={styles.ManageContent}>
            <Switch>
                <Route
                    path={match.url + "/edit-copy/:id"}
                    render={(match) => (
                        <Fragment>
                            <CreateCopy contentId={match.match.params.id} />
                        </Fragment>
                    )}
                />
                <Route
                    path={match.url + "/view-copy/:id"}
                    render={(match) => (
                        <Fragment>
                            <CreateCopy contentId={match.match.params.id} />
                        </Fragment>
                    )}
                />
                <Route
                    path={match.url + "/create-copy"}
                    render={(match) => (
                        <Fragment>
                            <CreateCopy />
                        </Fragment>
                    )}
                />

                <Route
                    exact
                    path={match.url}
                    render={() => (
                        <Fragment>
                            <ManageCopyHeader onSearch={(search) => setSearch(search)} />
                            <ManageCopyTable search={search} />
                        </Fragment>
                    )}
                />
            </Switch>
        </div>
    );
};

export default ManageCopy;
