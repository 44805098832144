import React from "react";

import styles from "./LabeledFileUploadBar.module.css";
import UploadBar from "../UploadBar/UploadBar";
import UploadBarWithButton from "../UploadBarWithButton/UploadBarWithButton";
const LabeledFileUploadBar = ({
  title,
  newUpload,
  onUploadClick,
  uploadType,
  uploadedFile,
  resetFile
  }) => {
  return (
    <div className={uploadType === "uploadApprovalProof" ||
    uploadType === "uploadDivisionProof" || uploadType === "uploadLegalProof"?
    styles.ContainerCampaign:styles.Container}>
      <label>{title}</label>
      {newUpload === "yes"?<UploadBarWithButton/>:<UploadBar uploadType={uploadType} uploadedFile={uploadedFile} onUploadClick={onUploadClick} resetFile={resetFile}/>}
    </div>
  );
};


export default LabeledFileUploadBar;
