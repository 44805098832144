import React from "react";

import styles from "./LabeledDiv.module.css";

const LabeledDiv = ({ title, className, children }) => {
    return <div className={ styles.Container }>
        <label>{ title }</label>
        <div className={ [styles.Div, className].join(" ") }>
            { children }
        </div>
    </div>;
};

export default LabeledDiv;