import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import PageContent from "../../General/PageContent/PageContent";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import styles from "./CreateCustomer.module.css";
import CreateCustomerNavigationMenu from "./CreateCustomerNavigationMenu";
import CustomerNavTab from "./NavTab";

/* eslint-disable  @typescript-eslint/no-unused-vars */
const CreateCustomer = () =>{
    const percentage = 50;

    return <Container fluid>
    <TitleWithPathHeader title="Create Customer List"
                         path="/manage customer/create customer"/>
    <PageContent className={styles.CreateCustomer}>
    <Container fluid>
    <Row>
    <Col md={2}>
    <CreateCustomerNavigationMenu />
    </Col>
    <Col md={10}>
    <Container>
        <Row>
         <p className={styles.Header}>Create New list</p>
    </Row>
    <Row><p className={styles.Content}>Continue making changes to a list that is in Draft state.</p></Row>
    </Container>
    <Container>
        <Row>
            <Col md={6} style={{marginLeft:'-1.2rem'}}>
               <LabeledInput title={<>Name<span>*</span></>}
                              className={styles.Select}
                            //   data={null}
                              onValueChange={value => console.log(value)}
                />
                <LabeledInput title={<>Description<span>*</span></>}
                              className={styles.Select}
                            //   data={null}
                              onValueChange={value => console.log(value)}
                />
            </Col>
            <Col>
            <Row>
            <h3>Customer Count</h3>
            </Row>
            <div className={styles.CustomerCount}>
            <Container>
            <Row style={{marginLeft:'-1.2rem'}}>
            <Col md={5} className={styles.Circle}>
               <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({pathColor:'#0A9F98'})} />
            </Col>
               <Col className={styles.CircleText}>
               <h4><li>Customer</li></h4>
               <h5>934,569</h5>
               </Col>
               </Row>
               </Container>
            </div>
            </Col>
        </Row>
    </Container>
    <Container>
    <Row>
        <Col className={styles.CustomerNavTab}>
        <CustomerNavTab/>
        </Col>
    </Row>
    </Container>
    </Col>
    </Row>
    </Container>
    </PageContent>
   </Container>
};

export default CreateCustomer