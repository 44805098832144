import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import Button from '../../General/Button/Button';
import LabeledFileUploadBar from '../../General/LabeledFileUploadBar/LabeledFileUploadBar';
import LabeledInput from '../../General/LabeledInput/LabeledInput';
import PopupDialog from '../../General/PopupDialog/PopupDialog';
import DiscardDialog from '../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog';
import styles from "./TemplateTypes.module.css";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ErrorMsg, Msg } from '../CreateTemplate/AlertComponent';
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import SaveAndDiscardWithPopupButtonSet from '../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet';
import SaveDialog from '../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog';
import JSZip from "jszip";
import { Context } from "../../../context";

const AddTemplateType = () => {
    const [templateTypeName, setTemplateTypeName] = useState();
    const [templateTypeDescription, setTemplateTypeDescription] = useState();
    const history = useHistory();
    const pathName = history.location.pathname.split("/");
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [zipFile, setZipFile] = useState();
    //const [editZipFileName, setEditZipFileName] = useState();
    const [resetFilename, setResetFileName] = useState();
    const { value } = useContext(Context);
    const [context, setContext] = value;

    /* notify if user do not enter mandatory fields */
    useEffect(() => {
        let toBeCompletedArray = [];
        toBeCompletedArray = !templateTypeName
            ? [...toBeCompletedArray, "Name"]
            : toBeCompletedArray;
        toBeCompletedArray = !templateTypeDescription
            ? [...toBeCompletedArray, "Description"]
            : toBeCompletedArray;
        toBeCompletedArray =
            !zipFile
                ? [...toBeCompletedArray, "Load Zip File"]
                : toBeCompletedArray;
        setToBeCompleted(toBeCompletedArray);
    }, [
        templateTypeName,
        templateTypeDescription,
        zipFile
    ]);

    //validate zip file
    const isValidZipFile = async (file) => {
        let blsValidZip = true;
        //limit file size to 10mb
        const sizeinmb = (file.size / (1024 * 1024)).toFixed(3);
        console.log("sizeinmb - ", sizeinmb);
        if (sizeinmb >= 10) {
            blsValidZip = false;
            console.log(file + " File too Big, please select a file less than 10mb");
            setContext("");
            console.log(context)
            alert("File too Big, please select a file less than 10mb");
            return blsValidZip;
        }

        //Zip file should contain only HMTL, CSS and images
        let jZip = new JSZip();
        let validFileExtensions = ["jpg", "jpeg", "png", "gif", "html", "css", "mp4", "txt"];
        let mandatoryFileExtensions = ["html", "txt", "jpeg", "png"];
        let mandatoryFilesCount = 0;
        let imageCount = 0;
        jZip.loadAsync(file).then(function (zip) {
            Object.keys(zip.files).forEach(function (filename) {
                console.log("file name " + filename);
                let fileExtension = filename.split(".")[1];
                if (fileExtension !== undefined) {
                    //should be a file not folder
                    let bIsValidFile = false;
                    for (let j = 0; j < validFileExtensions.length; j++) {
                        let curExtension = validFileExtensions[j];
                        if (fileExtension.toLowerCase() === curExtension.toLowerCase()) {
                            bIsValidFile = true;
                            break;
                        }
                    }
                    if (!bIsValidFile) {
                        blsValidZip = false;
                        // alert("Zip file should contain only HMTL, CSS and images");
                        setResetFileName("true")
                        return blsValidZip;
                    }
                    //check for mandatory files (.html,.txt and .png/jpeg)
                    for (let j = 0; j < mandatoryFileExtensions.length; j++) {
                        let curExtension = mandatoryFileExtensions[j];
                        if (fileExtension.toLowerCase() === curExtension.toLowerCase()) {
                            if (fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "png") {
                                imageCount++;
                            } else {
                                mandatoryFilesCount++;
                            }
                            break;
                        }
                    }
                }
            });

            //mandatory Files Count should be 2, else invalid
            if (mandatoryFilesCount !== 2 || imageCount !== 1) {
                blsValidZip = false;
                setResetFileName("true")
                setZipFile("");
                //alert("Zip file should contain html,txt, png/jpeg files");
                return blsValidZip;
            }

            //set zipfile for valid zip
            if (blsValidZip) {
                setZipFile(file);
                setResetFileName("")
                console.log("file content - ", file);
            }
        });
        return blsValidZip;
    };

    const saveUploadData = async (saveUploadObject) => {
        console.log(saveUploadObject)

    };

    return (
        <div>
            <p className={styles.UpdateFormHeader}>
                {pathName[4] === "edit-template-type" ? "Edit template type" : "Add template type"}
            </p>
            <LabeledInput
                //component_status={viewTemplate}
                title={<>Name<span>*</span></>}
                data={templateTypeName}
                placeholder="Type the template name"
                className={styles.Input}
                onValueChange={(value) => setTemplateTypeName(value)}
            />
            <LabeledInput
                //component_status={viewTemplate}
                title={<>Description<span>*</span></>}
                placeholder="Type the description"
                className={styles.Input}
                data={templateTypeDescription}
                onValueChange={(value) => setTemplateTypeDescription(value)}
            />
            <div style={{ marginTop: "4rem" }}>
                <LabeledFileUploadBar
                    title={<>Upload new template type (HMTL,images and CSS zip file)<span>*</span></>}
                    newUpload="no"
                    onUploadClick={console.log("")}
                    uploadType="uploadTemplate"
                    resetFile={resetFilename}
                    uploadedFile={(value) => isValidZipFile(value)}
                />
            </div>
            {resetFilename === "true" ? <div style={{ color: "red", fontSize: "1.4rem" }}>Zip file should contain html,txt, png/jpeg files</div> : ""}

            {toBeCompleted.length === 0 ? (
                <div className={styles.savediscardcontainer}>
                    <div className={styles.discardchild}>
                        <PopupDialog
                            height="30rem"
                            width="67rem"
                            renderPopupContent={(closeModal) => (
                                <DiscardDialog
                                    yesAction={() => {
                                        setContext();
                                        history.push("/ctmp/template");
                                        toast.success(
                                            <Msg id="discard" templateName={"uploadTemplateName"} />,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                hideProgressBar: true,
                                                autoClose: 3000,
                                                icon: <Success />,
                                                transition: Slide,
                                                closeOnClick: true
                                            }
                                        );
                                    }}
                                    noAction={closeModal}
                                />
                            )}
                            renderControlContent={(openModal) => (
                                <Button onClick={openModal} name={"CopyNewCompleteDiscard"}>
                                    Discard
                                </Button>
                            )}
                        />
                    </div>
                    <div className={styles.savechild}>
                        <Button
                            onClick={async () => {
                                let statusVar = await saveUploadData("saveUploadObject");
                                localStorage.setItem("blockNavigation", "false");
                                history.push("/ctmp/template");
                                //setIsLoaded(false);
                                statusVar !== 200 &&
                                    toast.error(
                                        <ErrorMsg id="save" templateName={"uploadTemplateName"} />,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            hideProgressBar: true,
                                            autoClose: 3000,
                                            icon: <Error />,
                                            transition: Slide,
                                            closeOnClick: true
                                        }
                                    );
                                statusVar === 200 &&
                                    toast.success(
                                        <Msg id="save" templateName={"uploadTemplateName"} />,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            hideProgressBar: true,
                                            autoClose: 3000,
                                            icon: <Success />,
                                            transition: Slide,
                                            closeOnClick: true
                                        }
                                    );
                            }}
                            name={"CopyNewCompleteSave"}
                        />
                    </div>
                </div>) :
                <div className={styles.savediscardcontainer2}>
                    <div >
                        <SaveAndDiscardWithPopupButtonSet
                            yesPopupHeight="55rem"
                            noPopupHeight="30rem"
                            yesTitle="Save" //save
                            noTitle="Discard" //discard
                            className={styles.CopyNewEdit}
                            renderDiscardPopupContent={(closeModal) => (
                                //save and close
                                <DiscardDialog
                                    yesAction={() => {
                                        history.push("/ctmp/template");
                                    }}
                                    noAction={closeModal}
                                    className="Discard"
                                />
                            )}
                            renderSavePopupContent={(closeModal) => (
                                <SaveDialog
                                    saveAction={async () => {
                                        let statusVar = await saveUploadData("saveUploadObject");
                                        //console.log("statusVar--->" + statusVar)
                                        closeModal();
                                        //console.log(context)
                                        setContext("");
                                        history.push("/ctmp/template");
                                        //setIsLoaded(false)
                                        statusVar === 200 &&
                                            toast.success(
                                                <Msg id="save" templateName={"uploadCopyName"} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Success />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                        statusVar !== 200 &&
                                            toast.error(
                                                <ErrorMsg id="save" templateName={"uploadCopyName"} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Error />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                    }}
                                    discardAction={closeModal}
                                    itemsToBeComplete={toBeCompleted}
                                />
                            )}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default AddTemplateType