/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { Fragment, useState,useContext } from "react";
import styles from "./ManageUser.module.css";
import ManageUserHeader from "./ManageUserHeader/ManageUserHeader";
import ManageUserTable from "./ManageUserTable/ManageUserTable";
import { Context } from "../../context";
import { CapabilityConstants } from "../Constants/CapabilitiyConstants";
// import VersionPreview from "./VersionControl/VersionPreview/VersionPreview";
import CreateUser from "./AddUser/CreateUser";
import { Redirect, Route, Switch } from "react-router-dom";

const ManageUser = ({ match }) => {
  const [search, setSearch] = useState();
  const { capability,user } = useContext(Context);
  const [capabilities,setCapabilities] = capability;
 
  return (
    <div className={styles.ManageUser}>
      <Switch>
        <Route
          path={match.url + "/edit-user/:id"}
          render={(match) => (
            <Fragment>
              <CreateUser userId={match.match.params.id} />
            </Fragment>
          )}
        />

        {/* <Route
          path={match.url + "/view-User/:id"}
          render={(match) => (
            <Fragment>
              <CreateUser userId={match.match.params.id} />
            </Fragment>
          )}
        /> */}

        <Route path={match.url + "/addUser"} component={CreateUser} />

        {/* <Route
          exact
          path={match.url}
          render={() => (
            <Fragment>
              <ManageUserHeader onSearch={(search) => setSearch(search)} />
              <ManageUserTable search={search} />
            </Fragment>
          )}
        /> */}

        <Route
           exact
          path={match.url}
          render={() =>
            capabilities.includes(CapabilityConstants.USER_MANAGEMENT_ACCESS)?(
            <Fragment>
              <ManageUserHeader onSearch={(search) => setSearch(search)} />
              <ManageUserTable search={search} />
            </Fragment>) : <Redirect to ="/" />
          }>
      </Route>
      </Switch>
    </div>
  );
};

export default ManageUser;
