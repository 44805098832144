/* eslint-disable  */
import React, { useEffect, useState, useCallback,useContext } from "react";
import _ from "lodash";
import ManageCopyTableHead from "./ManageCopyTableHead/ManageCopyTableHead";
import { columns } from "./manage-copy-table-structure";
import styles from "./ManageCopyTable.module.css";
import Table from "../../General/Table/Table";
import TableFooter, { PAGE_SIZES } from "../../General/Table/TableFooter/TableFooter";
import { mapCopy } from "../../../utils/object-mappers/manage-copy/map-copy";
import { useAppSelector } from "../../../redux/store";
import { Slide, toast } from "react-toastify";
import { Msg } from "../CreateCopy/AlertComponent";
import { ErrorMsg } from "../../ManageTemplate/CreateTemplate/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { Context } from "../../../context";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";

const ManageCopyTable = ({ search }) => {
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const [totalContents, setTotalContents] = useState(0);
    const [contents, setContents] = useState([]);
    const [sortBy, setSortBy] = useState();
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
    const {value} = useContext(Context);
    const [context, setContext] = value;
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoaded(true)
            let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy`);
            let callParams = {
                method: "GET",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`
                }
            };
            if (!_.isEmpty(filters)) {
                url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content-copy/search`); //check SR

                callParams = {
                    method: "POST",
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify(
                        filters.freeSearch
                            ? { ...filters }
                            : {
                                ...Object.keys(filters)
                                    .map((value) =>
                                        value === "status" ||
                                            value === "createdBy"
                                            ? {
                                                [value]: filters[value].map((val) => val.label)
                                            }
                                            : { [value]: filters[value] }
                                    )
                                    .reduce(
                                        (previousValue, currentValue) => ({
                                            ...previousValue,
                                            ...currentValue
                                        }),
                                        {}
                                    )
                            }
                    )
                };
            } 
            // else {
                const params = {
                    page: currentPage,
                    pagesize: selectedPageSize,
                    ...(sortBy && { sortBy })
                };
                url.search = new URLSearchParams(params).toString();
            // }
           
            const serverResponse = await fetch(url.toString(), callParams);
            const serverResponseJson = await serverResponse.json();
            setTotalContents(serverResponseJson.totalContentCopies);
            setContents(mapCopy(serverResponseJson.contentCopyList));
            setIsLoaded(false)
            localStorage.getItem("forceCopyPublish") === "true" ?
            toast.success(<Msg id="publish" templateName="" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Success />,
            transition: Slide,
            closeOnClick: true
          })
        : localStorage.getItem("forceCopyPublish") === "false"?
         toast.error(<ErrorMsg id="publish" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Error />,
            transition: Slide,
            closeOnClick: true
          })
        : localStorage.getItem("forceCopyDelete") === "true" ?
         toast.success(<Msg id="delete" templateName="" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Success />,
            transition: Slide,
            closeOnClick: true
          })
        : localStorage.getItem("forceCopyDelete") === "false"?
         toast.error(<ErrorMsg id="delete" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Error />,
            transition: Slide,
            closeOnClick: true
          })
        : localStorage.getItem("forceCopyUnpublish") === "false"?
         toast.error(<ErrorMsg id="unpublish" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Error />,
            transition: Slide,
            closeOnClick: true
          })
        : localStorage.getItem("forceCopyUnpublish") === "true"?
         toast.success(<Msg id="unpublish" templateName="" />, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            icon: <Success />,
            transition: Slide,
            closeOnClick: true
          })
        : "";
      
         localStorage.removeItem("forceCopyPublish");
          localStorage.removeItem("forceCopyDelete");
          localStorage.removeItem("forceCopyUnpublish");
         setContext();
    
    })();
    }, [selectedPageSize, currentPage, sortBy, filters,context]);

    useEffect(() => {
        if (search) {
            setFilters({
                freeSearch: search
            });
        } else if (!_.isEmpty(filters) && filters.freeSearch) {
            setFilters({});
        }
    }, [search]);

    const goToPage = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const changePageSizeTo = useCallback((newPageSize) => {
        setCurrentPage(1);
        setSelectedPageSize(newPageSize);
    }, []);

    const onSortChange = useCallback((newSortBy) => {
        setFilters({});
        setSortBy(newSortBy);
    }, []);

    const onFilterChange = useCallback((filters) => {
        setFilters(filters);
    }, []);

    return (
        <div className={styles.ManageContentModule}>
            <ManageCopyTableHead
                onSortChange={onSortChange}
                onFiltersChange={onFilterChange}
                filters={filters}
            />
        {(isLoaded === true) ? <LoadSpinner /> : <Table columns={columns} data={contents} />}
            <TableFooter
                changePageSizeTo={changePageSizeTo}
                selectedPageSize={selectedPageSize}
                currentPage={currentPage}
                goToPage={goToPage}
                totalItems={totalContents}
            />
        </div>
    );
};

export default ManageCopyTable;
