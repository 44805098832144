import fetchIntercept from 'fetch-intercept';
import { authProvider } from "../../../authConfig";
let token_final = "";

 fetchIntercept.register({
    request: async function(url, config) {

        let jwt = "";
        jwt = config.headers.Authorization.split(" ").pop()
        const exp = localStorage.getItem("tokenExp");
       
    // Req for new token and exp time when jwt is null or expires
      if(jwt === "" || parseInt(exp, 10) * 1000 < Date.now()){
        const token = await authProvider.getIdToken();
        token_final = token.idToken.rawIdToken
        jwt = token_final;
        localStorage.removeItem("tokenExp"); // remove token to keep updated token
        localStorage.setItem("tokenExp",token.idToken.expiration) // update token
        config.headers.Authorization = `Bearer ${jwt}`
          return [url, config];
        }else{
          if(token_final === ''){
            return [url, config];
          }else{
            config.headers.Authorization = `Bearer ${token_final}`
            return [url, config];
          }
        }
      },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
})