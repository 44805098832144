import { format } from "date-fns";


export const mapCampaigns = (serverResponse) => [...serverResponse.map(
    item => ({
        //action: {status : item.roleStatus? item.roleStatus.map(role => role) : "", campaignId: item.id, version:item.version},
        action: { status: item.status, campaignId: item.id, version: item.version, name: item.name },
        brand: item.brandName,
        campaignDetails: {
            campaignName: item.name,
            description: item.shortDescription,
            createdDate: item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy")
        },
        channel: item.channel.map(c => c.name),
        creator: item.createdBy,
        categoryDetails: {
            id: item.category ? item.category.id : "",
            category: item.category ? item.category.name : "",
            subCategory: item.category && item.category.subCategories ? item.category.subCategories[0].name : ""
        },
        goLiveDate: item.goLiveDate ? format(new Date(item.goLiveDate), "MM/dd/yyyy") : "N/A",
        version: { versionNumber: `v${item.version}`, campaginId: item.id },
        status: item.status,
        id: item.id,
        roleStatus: item.roleStatus ? item.roleStatus.map(role => role) : ""
    }))];