/* eslint-disable  */
import React, { useEffect, useState, useCallback,useContext } from "react";
import _ from "lodash";
import ManageContentTableHead from "./ManageContentTableHead/ManageContentTableHead";
import { columns } from "./manage-content-table-structure";
import styles from "./ManageContentTable.module.css";
import Table from "../../General/Table/Table";
import TableFooter, {
  PAGE_SIZES
} from "../../General/Table/TableFooter/TableFooter";
import { mapContents } from "../../../utils/object-mappers/manage-content/map-contents";
import { useAppSelector } from "../../../redux/store";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";
import { Slide, toast } from "react-toastify";
import { Msg } from "../CreateContent/AlertComponent";
import { ErrorMsg } from "../../ManageTemplate/CreateTemplate/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { Context } from "../../../context";

const ManageContentTable = ({ search }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  // const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);

  const [totalContents, setTotalContents] = useState(0);
  const [contents, setContents] = useState([]);
  const [sortBy, setSortBy] = useState();

  const [filters, setFilters] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
  const {value} = useContext(Context);
  const [context, setContext] = value;

  useEffect(() => {
    (async () => {
      setIsLoaded(true)
      //let url = new URL("https://mktpl.dev.telematicsct.com/v1/content");
      let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content`);
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          //  "user-info": JSON.stringify({ userName: userName, userEmail: email })
        }
      };
      if (!_.isEmpty(filters)) {
        //url = new URL("https://mktpl.dev.telematicsct.com/v1/content/search");
        // url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/search`);
        url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content/search`); //check SR

        callParams = {
          method: "POST",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
            // "user-info": JSON.stringify({ userName: userName, userEmail: email })
          },
          body: JSON.stringify(
            filters.freeSearch
              ? { ...filters }
              : {
                  ...Object.keys(filters)
                    .map((value) =>
                      value === "status" ||
                      value === "channels" ||
                      value === "brand" ||
                      value === "category" ||
                      value === "subCategory" ||
                      value === "createdBy"
                        ? {
                            [value]: filters[value].map((val) => val.label)
                          }
                        : { [value]: filters[value] }
                    )
                    .reduce(
                      (previousValue, currentValue) => ({
                        ...previousValue,
                        ...currentValue
                      }),
                      {}
                    )
                }
          )
        };
      } 
      // else {
        const params = {
          page: currentPage,
          pagesize: selectedPageSize,
          ...(sortBy && { sortBy })
        };
        url.search = new URLSearchParams(params).toString();
      // }

      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse.json();
      setTotalContents(serverResponseJson.totalContents);
      setContents(mapContents(serverResponseJson.contents));
      setIsLoaded(false);
      localStorage.getItem("forceContentPublish") === "true"?
      toast.success(<Msg id="publish" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceContentPublish") === "false"?
   toast.error(<ErrorMsg id="publish" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceContentDelete") === "true" ?
   toast.success(<Msg id="delete" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceContentDelete") === "false"?
   toast.error(<ErrorMsg id="delete" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceContentUnpublish") === "false"?
   toast.error(<ErrorMsg id="unpublish" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceContentUnpublish") === "true"?
   toast.success(<Msg id="unpublish" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : "";

   localStorage.removeItem("forceContentPublish");
    localStorage.removeItem("forceContentDelete");
    localStorage.removeItem("forceContentUnpublish");
   setContext();
    })();
  }, [selectedPageSize, currentPage, sortBy, filters,context]);

  useEffect(() => {
    if (search) {
      setFilters({
        freeSearch: search
      });
    } else if (!_.isEmpty(filters) && filters.freeSearch) {
      setFilters({});
    }
  }, [search]);

  const goToPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const changePageSizeTo = useCallback((newPageSize) => {
    setCurrentPage(1);
    setSelectedPageSize(newPageSize);
  }, []);

  const onSortChange = useCallback((newSortBy) => {
    setFilters({});
    setSortBy(newSortBy);
  }, []);

  const onFilterChange = useCallback((filters) => {
    setFilters(filters);
  }, []);

  return (
    <div className={styles.ManageContentModule}>
      <ManageContentTableHead
        onSortChange={onSortChange}
        onFiltersChange={onFilterChange}
        filters={filters}
      />
      {(isLoaded === true) ? <LoadSpinner /> : <Table columns={columns} data={contents} />}
  
      <TableFooter
        changePageSizeTo={changePageSizeTo}
        selectedPageSize={selectedPageSize}
        currentPage={currentPage}
        goToPage={goToPage}
        totalItems={totalContents}
      />
    </div>
  );
};

export default ManageContentTable;
