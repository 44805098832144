import React from "react";

import styles from "./MultiPickerCard.module.css";

const MultiPickerCard = ({ icon, title, description, onSelect, selected }) => {

    return <div onClick={ () => onSelect(title) }
                className={ selected ? [styles.CardContainer, styles.CardSelected].join(" ") : title === "Email" || title ==="Toyota" || title === "Lexus" ? styles.CardContainer : styles.CardContainerDisabled }>
        { icon && <div>
            { icon.render({ className: styles.Icon }) }
        </div> }
        <div className={ styles.TitleDescriptionCombo }>
            <div className={ styles.Title }>{ title }</div>
            <p className={ styles.Description }>{ description }</p>
        </div>
    </div>;
};

export default MultiPickerCard;