import React, { Fragment, useState } from "react";
import styles from "./ManageTemplate.module.css";
import ManageTemplateHeader from "./ManageTemplateHeader/ManageTemplateHeader";
import ManageTemplateTable from "./ManageTemplateTable/ManageTemplateTable";

/* eslint-disable  @typescript-eslint/no-unused-vars */
// import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import CreateTemplate from "./CreateTemplate/CreateTemplate";
import Preview from "./CreateTemplate/Preview";
import TemplateTypes from "./TemplateTypes/TemplateTypes";

const ManageTemplate = ({ match }) => {
  const [search, setSearch] = useState();

  const [templateDetails, setTemplateDetails] = useState();

  return (
    <div className={styles.ManageTemplate}>
      <Switch>
        <Route
          path={match.url + "/preview/:id"}
          render={(match) => (
            <Fragment>
              <Preview templateId={match.match.params.id} />
            </Fragment>
          )}
        />
        <Route
          path={match.url + "/view-template/:id"}
          render={(match) => (
            <Fragment>
              <CreateTemplate templateId={match.match.params.id} />
            </Fragment>
          )}
        />
        <Route
          path={match.url + "/edit-template/:id"}
          render={(match) => (
            <Fragment>
              <CreateTemplate templateId={match.match.params.id} />
            </Fragment>
          )}
        />
        <Route
          path={match.url + "/create-template"}
          component={CreateTemplate}
        />
        <Route
          path={match.url + "/template-types"}
          component={TemplateTypes}
        />
        <Route
          exact
          path={match.url}
          render={() => (
            <Fragment>
              {/* <ToastContainer
                style={{ width: "146rem", borderRadius: "8px" }}
              /> */}
              <ManageTemplateHeader onSearch={(search) => setSearch(search)} />
              <ManageTemplateTable search={search} />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

export default ManageTemplate;
