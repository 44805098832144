/* eslint-disable  @typescript-eslint/no-unused-vars */
import { Fragment, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Card from "react-bootstrap/Card";
import styles from "./ViewReport.module.css";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import { ReactComponent as Mail } from "../../../assets/icons/mail3.svg";

const Home_Progress = () => {
  const [campaignName, setCampaignName] = useState();
  const [isShown, setIsShown] = useState(false);
  let percentageCount = 230;

  return (
    <Fragment>
       <div className={styles.ReportMessage}>
        <div>
         Campaign reporting is the process of reporting on the results of your marketing campaign.<br/>
         We can find how many emails are delivered,clicked and opened.
        </div>
        <div style={{width: "25%", float:"right"}}>
                  <Card style={{ border: "none" }}>
                    <Card.Body className={styles.cardBody}>
                      <div
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                      >
                        {/* <div className={styles.reportchild}> */}
                        <div style={{ paddingLeft:"1rem",float:"right"}}>
                        
                          <Mail style={{paddingBottom:"0.7rem"}}/>
                    
                        </div>
                        <div
                          className={styles.reportchild}
                        >
                          258,345,567
                          <div style={{ fontSize: "1.4rem" }}>Total Emails Sent</div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
              <div style={{paddingTop:"1rem"}}>
            {isShown && (
              <div style={{ fontSize: "1.4rem", width: "100%" }}>
                Total number of emails sent till date.
              </div>
            )}
          </div>
                </div>
       </div>
      <div className={styles.BrandAndCampaign}>
        <LabeledSelect
          optionsUrl="v1/campaigns/search"
          value={campaignName}
          className={styles.Select}
          defaultLabel={campaignName ? campaignName.label : undefined}
          defaultId={campaignName ? campaignName.value : undefined}
          title={
            <>
              Campaign<span>*</span>
            </>
          }
          onValueChange={(option) => setCampaignName(option)}
        />
        </div>

      <div>
        <div style={{ paddingTop: "3rem", paddingLeft: "1rem" }}>
          <label>Campaign Name:</label>
          <span style={{ fontSize: "1.4rem", paddingLeft: "1rem" }}>
            {campaignName === undefined || campaignName === null
              ? ""
              : campaignName.label}
          </span>
        </div>
        <div style={{ paddingLeft: "1rem" }}>
          <label>Brand:</label>
          <span style={{ fontSize: "1.4rem", paddingLeft: "1rem" }}>
            Toyota
          </span>
        </div>
      </div>

      {/* <div className={styles.FloatContainer}>
    <div className={styles.floatChild}>
        <div className={styles.Chart}>
              Email sent in last 24 hours
              <div className={styles.ChartCard}>
                <div className={styles.PieChart}>
                  <CircularProgressbar
                    value={100}
                    // text={`${percentageCount}%`}
                    text={`${percentageCount}`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "black",
                      trailColor: "#F5F6FA"
                      // pathColor: "#0A9F98"
                    })}
                  />
                </div>
              </div>
          </div>
     </div>
    <div className={styles.floatChild1}>
    <div className={styles.Chart}>
    Email sent in last 30 days
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={180}
                text={`${180}`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#FFBF00"
                })}
              />
            </div>
          </div>
              </div>
    </div>

   
    <div className={styles.floatChild}>
        <div className={styles.Chart}>
              Email opened in last 24 hours
              <div className={styles.ChartCard}>
                <div className={styles.PieChart}>
                  <CircularProgressbar
                    value={100}
                    // text={`${percentageCount}%`}
                    text={`${percentageCount}`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "black",
                      trailColor: "#F5F6FA"
                      // pathColor: "#0A9F98"
                    })}
                  />
                </div>
              </div>
          </div>
     </div>
    <div className={styles.floatChild1}>
    <div className={styles.Chart}>
    Email opened in last 30 days
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={180}
                text={`${180}`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#FFBF00"
                })}
              />
            </div>
          </div>
          </div>
    </div>
   
    <div className={styles.floatChild}>
        <div className={styles.Chart}>
              Email clicked in last 24 hours
              <div className={styles.ChartCard}>
                <div className={styles.PieChart}>
                  <CircularProgressbar
                    value={100}
                    // text={`${percentageCount}%`}
                    text={`${percentageCount}`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "black",
                      trailColor: "#F5F6FA"
                      // pathColor: "#0A9F98"
                    })}
                  />
                </div>
              </div>
          </div>
     </div>
    <div className={styles.floatChild1}>
    <div className={styles.Chart}>
    Email clicked in last 30 days
          <div className={styles.ChartCard}>
            <div className={styles.PieChart}>
              <CircularProgressbar
                value={180}
                text={`${180}`}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  trailColor: "#F5F6FA",
                  pathColor: "#FFBF00"
                })}
              />
            </div>
          </div>
              </div>
    </div>
   
    </div> */}

      <div className={styles.FloatContainerFlex}>
        <div className={styles.child}>
          <div style={{ fontWeight: "bold" }}> Emails in last 24 hours</div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Delivered</div>

            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={100}
                  // text={`${percentageCount}%`}
                  text={`${70}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA"
                    // pathColor: "#0A9F98"
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Clicked</div>
            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={180}
                  text={`${40}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA",
                    pathColor: "#FFBF00"
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Opened</div>
            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={180}
                  text={`${40}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA",
                    pathColor: "#C8A2C8"
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.FloatContainerFlex}>
        <div className={styles.child}>
          <div style={{ fontWeight: "bold" }}> Emails in last 30 days</div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Delivered</div>

            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={100}
                  // text={`${percentageCount}%`}
                  text={`${percentageCount}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA"
                    // pathColor: "#0A9F98"
                  })}
                />
              </div>
              {/* <div className={styles.ChartKey}>
                  <div className={styles.ChartLabelWithDot}>
                    <span className={styles.ChartKeyDot} />
                    <div className={styles.ChartKeyLabel}> Customer</div>
                  </div>
                  <div className={styles.ChartKeyNumber}>{totalCount}</div>
                </div> */}
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Clicked</div>
            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={180}
                  text={`${180}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA",
                    pathColor: "#FFBF00"
                  })}
                />
              </div>
              {/* <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div> */}
            </div>
          </div>
        </div>
        <div className={styles.child}>
          <div className={styles.Chart}>
            <div style={{ paddingLeft: "7rem" }}>Opened</div>
            <div className={styles.ChartCard}>
              <div className={styles.PieChart}>
                <CircularProgressbar
                  value={180}
                  text={`${180}`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "black",
                    trailColor: "#F5F6FA",
                    pathColor: "#C8A2C8"
                  })}
                />
              </div>
              {/* <div className={styles.ChartKey}>
              <div className={styles.ChartLabelWithDot}>
                <span className={styles.ChartKeyDot} />
                <div className={styles.ChartKeyLabel}> Customer</div>
              </div>
              <div className={styles.ChartKeyNumber}>{totalCount}</div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home_Progress;
