import React, { useCallback, useState } from "react";
import { Tab } from 'react-bootstrap';
import SidePageContent from "../../General/SidePageContent/SidePageContent";
import styles from "./CreateCustomer.module.css";

/* eslint-disable  @typescript-eslint/no-unused-vars */
const CreateCustomerNavigationMenu = () => {
  const numbers = ["Import VIN List", "Re-Use Customer List", "Clone Customer List", "Edit Draft List", "Create New List"];
  const listItems = numbers.map((menu) =>
    <div className={styles.MenuList} onClick={(value) => console.log}>{menu}</div>
  );
  const [selected, setSelected] = useState("Campaign");

  const setSelectedCallback = useCallback((newSelected) => {
    setSelected(newSelected);
  }, [setSelected])

  return <SidePageContent className={styles.NavigationMenu}>
    {listItems}
    <Tab.Container defaultActiveKey="first">
    </Tab.Container>
  </SidePageContent>

};

export default CreateCustomerNavigationMenu