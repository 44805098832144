/* eslint-disable */
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CapabilityConstants } from "../../Constants/CapabilitiyConstants";
import Button from "../../General/Button/Button";
import { Context } from "../../../context";
import styles from "./ManageCampaignHeader.module.css";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const ManageCampaignHeader = ({ onSearch }) => {
    const history = useHistory();
    const [timeout, setTimeoutVal] = useState();
    const [searchText, setSearchText] = useState();
    const { capability } = useContext(Context);
    const [capabilities, setCapabilities] = capability;

    const navToCreateCampaign = useCallback(
        () => {
            history.push(`${history.location.pathname}/create-campaign`);
        },
        [history]);

    useEffect(() => {
        timeout && clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            onSearch(searchText);
        }, 500));
    }, [onSearch, searchText, setTimeoutVal]);

    return <div className={styles.MngCmpgnHdr}>
        <div className={styles.MngCmpgnHdrLeft}>
            <h3>Manage Campaign</h3>
            <span className={styles.Separator} />
            <div className={styles.SearchBarContainer}>
                <SearchIcon className={styles.SearchIcon} />
                <input className={styles.Search} placeholder="Search Campaign"
                    type="text"
                    style={{ width: "400px" }}
                    onChange={(event => setSearchText(
                        event.target.value))} />
            </div>
        </div>
        {capabilities.includes(CapabilityConstants.CAMPAIGN_CREATE) ?
            <Button onClick={navToCreateCampaign}
                name="Create Campaign" /> : ""}
    </div>;
};

export default ManageCampaignHeader;