import React, { useMemo } from "react"
import _ from "lodash"

import styles from "./Pages.module.css"
const PAGE_DISPLAY_MAX = 5

const Pages = ({totalItems, pageSize, goToPage, currentPage}) => {

    const finalPage = useMemo(() => Math.ceil(totalItems / pageSize), [totalItems, pageSize])
    const setsOfFive = useMemo(() => Math.ceil(finalPage * PAGE_DISPLAY_MAX), [finalPage])
    const pageRange = useMemo(() => _.range(0, setsOfFive * PAGE_DISPLAY_MAX, PAGE_DISPLAY_MAX)
            .filter(val => val + 1 <= currentPage && val + PAGE_DISPLAY_MAX + 1 > currentPage)
            .map(value => _.range(value + 1, Math.min(value + PAGE_DISPLAY_MAX + 1, finalPage + 1)))
        , [setsOfFive, currentPage, finalPage])
    // const pageChangeButtonsToDisplay = useMemo(() => (Math.min(5, Math.ceil(totalItems / pageSize))), [pageSize, totalItems])

    return <div className={ styles.Pages }>
        <button
            disabled={currentPage === 1}
            onClick={ () => goToPage(1) } className={ styles.PageNav }>
            <i className={ [styles.Arrow, styles.Left, styles.FarLeft].join(" ") }/>
            <i className={ [styles.Arrow, styles.Left].join(" ") }/>
        </button>
        <button
            disabled={currentPage === 1}
            onClick={() => goToPage(currentPage - 1)}
            className={ styles.PageNav }><i
            className={ [styles.Arrow, styles.Left, styles.FarLeft].join(" ") }/></button>

        { [...(pageRange.length ? pageRange[0] : [])]
            .map(index => <button key={ index }
                                  onClick={ () => {
                                      goToPage(index)
                                  } }
                                  className={ currentPage === index
                                      ? [styles.PageNav, styles.Selected].join(" ")
                                      : styles.PageNav }>
                { index }
            </button>) }

        <button
            disabled={currentPage === finalPage}
            onClick={ () => goToPage( currentPage + 1) }
            className={ styles.PageNav }><i
            className={ [styles.Arrow, styles.Right, styles.FarRight].join(" ") }/></button>
        <button
            disabled={currentPage === finalPage}
            onClick={ () => goToPage(finalPage) } className={ styles.PageNav }>
            <i className={ [styles.Arrow, styles.Right].join(" ") }/>
            <i className={ [styles.Arrow, styles.Right, styles.FarRight].join(" ") }/>

        </button>
    </div>
}

export default Pages