// import React, { useCallback } from "react";
import { Fragment } from "react";

import styles from "./VersionControlHeader.module.css";
import TitleWithPathHeader from "../../../General/TitleWithPathHeader/TitleWithPathHeader";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";

const VersionControlHeader = (props) => {
  return (
    <Fragment>
      <div className={styles.VerCtrlHdr}>
        <div className={styles.VerCtrlLeft}>
          <TitleWithPathHeader
            title={props.title}
            path={props.breadcrumb}
          />
        </div>
        {props.search ? (
          <div className={styles.SearchBarContainer}>
            <span className={styles.Separator} />
            <SearchIcon className={styles.SearchIcon} />
            <input
              className={styles.Search}
              placeholder="Search Version"
              type="text"
            />
          </div>
        ) : null}
        {/* <div className={styles.SearchBarContainer}>
          <span className={styles.Separator} />
          <SearchIcon className={styles.SearchIcon} />
          <input
            className={styles.Search}
            placeholder="Search Version"
            type="text"
          />
        </div> */}
      </div>
    </Fragment>
  );
};

export default VersionControlHeader;
