import React from "react";
import RowAction from "../../General/Table/RowAction/RowAction";
import RowActionMenu from "./Row/RowActionMenu/RowActionMenu";
import Status from "./Row/Status/Status";
import TeamCell from "./Row/Team/TeamCell";

export const columns = [
    {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell: { value } }) => <RowAction
            menu={<RowActionMenu action={value} id={value.userId} />} />
    },
    {
        Header: "User Email",
        accessor: "userEmail",
        Cell: ({ cell: { value } }) => value
    },
    {
        Header: "Team",
        accessor: "team",
        Cell: ({ cell: { value } }) => <TeamCell data={value} />
    },
    {
        Header: "Created by",
        accessor: "creator",
        Cell: ({ cell: { value } }) => value
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => value
    },
    {
        Header: "Created On",
        accessor: "createdDate",
        Cell: ({ cell: { value } }) => value
    },
    {
        Header: "Role",
        accessor: "role",
        Cell: ({ cell: { value } }) => <Status value={value} />
    }

];