import React from "react";
import styles from "./Button.module.css";

const Button = ({ onClick, name }) => {
  if (name === "Create Campaign Content") {
    return <div>  <button onClick={onClick} className={styles.ContentBtn}>
      {name}
    </button></div>
  } else if (name === "Back") {
    return <div>  <button onClick={onClick} className={styles.backBtn}>
      {name}
    </button></div>
  }
  else if (name === "ContentBack") {
    return <div>  <button onClick={onClick} className={styles.ContentBack}>
      Back
    </button></div>
  }
  else if (name === "Ok") {
    return <div>  <button onClick={onClick} className={styles.OkBtn}>
      {name}
    </button></div>
  }
  else if (name === "Discard") {
    return <div>  <button onClick={onClick} className={styles.DiscardBtn}>
      {name}
    </button></div>
  } else if (name === "DiscardCreateTemplate" || name === "DiscardCreateClone") {
    return <div>  <button onClick={onClick} className={styles.DiscardCreateTemplate}>
      Discard
    </button></div>
  }
  else if (name === "Delete Copy File") {
    return <div>  <button onClick={onClick} className={styles.DeleteCopyFile}>
      Delete
    </button></div>
  }
  /* CustomerList */
  else if (name === "BtnCustomerEditSave") {
    return <div>  <button onClick={onClick} className={styles.BtnCustomerEditSave}>
      Save
    </button></div>
  } else if (name === "BtnVinListBack") {
    return <div>  <button onClick={onClick} className={styles.BtnVinListBack}>
      Back
    </button></div>
  } else if (name === "Delete Vin List File") {
    return <div>  <button onClick={onClick} className={styles.DeleteVinListFile}>
      {name}
    </button></div>
  }
  else if (name === "Save") {
    return <div>  <button onClick={onClick} className={styles.SaveBtn}>
      {name}
    </button></div>
  }
  else if (name === "Save & Close") {
    return <div>  <button onClick={onClick} className={styles.SaveCloseBtn}>
      {name}
    </button></div>
  }
  else if (name === "SaveCampaign") {
    return <div>  <button onClick={onClick} className={styles.SaveCloseCampaignBtn}>
      Save
    </button></div>
  }
  else if (name === "Publish") {
    return <div>  <button onClick={onClick} className={styles.PublishBtn}>
      {name}
    </button></div>
  }
  else if (name === "TemplateSave") {
    return <div>  <button onClick={onClick} className={styles.TemplateSave}>
      Save
    </button></div>
  }
  else if (name === "TemplateSaveClose") {
    return <div>  <button onClick={onClick} className={styles.TemplateSaveClose}>
      Save & Close
    </button></div>
  }
  else if (name === "ContentSave") {
    return <div>  <button onClick={onClick} className={styles.ContentSave}>
      Save
    </button></div>
  }
  else if (name === "ContentEditSaveAndClose") {
    return <div>  <button onClick={onClick} className={styles.ContentEditSaveAndClose}>
      Save & Close
    </button></div>
  }
  /* Copy */
  else if (name === "CopyNewCompleteSave") {
    return <div>  <button onClick={onClick} className={styles.CopyNewCompleteSave}>
      Save
    </button></div>
  }
  else if (name === "CopyNewCompleteDiscard") {
    return <div>  <button onClick={onClick} className={styles.CopyNewCompleteDiscard}>
      Discard
    </button></div>
  }
  else if (name === "CopyEditCompleteSave") {
    return <div>  <button onClick={onClick} className={styles.CopyEditCompleteSave}>
      Save
    </button></div>
  }
  else if (name === "CopyEditCompleteDiscard") {
    return <div>  <button onClick={onClick} className={styles.CopyEditCompleteDiscard}>
      Discard
    </button></div>
  }
  else if (name === "CopyCloneSave") {
    return <div>  <button onClick={onClick} className={styles.CopyCloneSave}>
      Save
    </button></div>
  }
  else if (name === "CopyCloneDiscard") {
    return <div>  <button onClick={onClick} className={styles.CopyCloneDiscard}>
      Discard
    </button></div>
  }

  /* Content Edit */
  else if (name === "ContentEditsave&close") {
    return <div>  <button onClick={onClick} className={styles.ContentEditsaveclose}>
      Save & Close
    </button></div>
  }
  else if (name === "ContentEditAddCreativeBrief") {
    return <div>  <button onClick={onClick} className={styles.ContentEditAddCreativeBrief}>
      Next:Add Creative Brief
    </button></div>
  }

  else if (name === "DiscardAddUser") {
    return <div>  <button onClick={onClick} className={styles.DiscardAddUser}>
      Discard
    </button></div>
  }
  else if (name === "AddUser") {
    return <div>  <button onClick={onClick} className={styles.AddUser}>
      Add User
    </button></div>
  }
  else if (name === "DiscardAddTeam") {
    return <div>  <button onClick={onClick} className={styles.DiscardAddUser}>
      Discard
    </button></div>
  }
  else if (name === "AddTeam") {
    return <div>  <button onClick={onClick} className={styles.AddUser}>
      Add Team
    </button></div>
  }
  else if (name === "AddCreativeBrief") {
    return <div>  <button onClick={onClick} className={styles.creativeBriefContent}>
      Next:Add Creative Brief
    </button></div>
  }
  else if (name === "Next:Add Creative Brief") {
    return <div>  <button onClick={onClick} className={styles.NextcreativeBriefContent}>
      {name}
    </button></div>
  }
  else if (name === "ContentBriefEditPublish") {
    return <div> <button onClick={onClick} className={styles.ContentBriefEditPublish}>
      Publish
    </button></div>
  }
  else if (name === "Save&CloseCampaign") {
    return <div>  <button onClick={onClick} className={styles.SaveCloseCampaignBtn}>
      Save & Close
    </button></div>
  }
  else if (name === "TemplatePublish") {
    return <div>  <button onClick={onClick} className={styles.TemplatePublish}>
      Publish
    </button></div>
  }
  else if (name === "Back_CustomerList" || name === "Request Creative Approval") {
    return <div>  <button onClick={onClick} className={styles.Back_CustomerList}>
      Back
    </button></div>
  }
  else if (name === "CompleteTemplateDiscard") {
    return <div>  <button onClick={onClick} className={styles.CompleteTemplateDiscard}>
      Discard
    </button></div>
  }
  else if (name === "CompleteTemplateSave") {
    return <div>  <button onClick={onClick} className={styles.SaveCloseCampaignBtn}>
      Save
    </button></div>
  }
  else if (name === "Template Types") {
    return <div>  <button onClick={onClick} className={styles.BtnTemplateTypes}>
      {name}
    </button></div>
  }
  else {
    return <div><button onClick={onClick} className={styles.Btn}>
      {name}
    </button></div>
  }
};

export default Button;
