export const TemplateConstants = {
    CREATE_TEMPLATE_TITLE: 'Create Template',
    CREATE_TEMPLATE_PATH: "/Manage Template/Create Template",
    VIEW_TEMPLATE_TITLE: 'View Template',
    VIEW_TEMPLATE_PATH: "/Manage Template/View Template",
    EDIT_TEMPLATE_TITLE: 'Edit Template',
    EDIT_TEMPLATE_PATH: "/Manage Template/Edit Template",
    UPLOAD_TITLE: "Upload Template",
    REUSE_TITLE: "Clone Template",
    SELECTED_TITLE: "Selected Template"
}