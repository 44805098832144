/* eslint-disable*/
import { useHistory } from "react-router";
import React, { useState } from "react";
import styles from "./AddUser.module.css";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AddRole from "./AddRole";
import EditRole from "./EditRole";

const ManageRole = ({ props }) => {
  const history = useHistory();
  let { url } = useRouteMatch();
  const pathName = history.location.pathname.split("/");
  console.log(pathName + "---1--" + props);
  console.log(url);
  const [subNavSelected, setSubNavSelected] = useState("addRole");

  return (
    <React.Fragment>
      <div className={styles.RoleTabs}>
        <div
          className={
            pathName[5] === "addRole" ? styles.RoleItemSelected : styles.RoleItem
          }
          onClick={() => {
            setSubNavSelected("addRole");
            history.push(`/ctmp/user-management/addUser/manageRoles/${props}`);
          }}
        >
          Add New Role
        </div>

        <div
          className={
            pathName[5] === "editRole"
              ? styles.RoleItemSelected
              : styles.RoleItem
          }
          onClick={() => {
            setSubNavSelected("editRole");
            history.push(`/ctmp/user-management/addUser/manageRoles/editRole`);
          }}
        >
          Edit Role
        </div>
      </div>

      <Switch>
      <Route exact path={`${url}`}>
          <Redirect to={`${url}/addRole`} />
        </Route>
        <Route path={`${url}/addRole`}>
          <AddRole />
        </Route>
        <Route path={`${url}/editRole`}>
          <EditRole/>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ManageRole;
