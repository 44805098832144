
import React, { useState } from "react"

import styles from "./Preview.module.css"
//import { ReactComponent as TemplateSmall } from "../../../../../assets/icons/TemplateSmall.svg";
import { ReactComponent as Zoom } from "../../../../../assets/icons/Zoom.svg";
//import { ReactComponent as TemplateBig } from "../../../../../assets/icons/Template.svg";
import { Modal } from "react-bootstrap";

const Preview = ({ value }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <div>
    <span onClick={handleShow}>
      <div className={styles.TemplatePreview}><img src={value} className={styles.SmallSvg} /><Zoom className={styles.ZoomSvg} /></div>
    </span>
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <div style={{paddingLeft:"7rem"}}><img style={{width:"640px",minHeight:"640px"}} src={value} /></div>
      </Modal.Header>
    </Modal>
  </div>
}

export default Preview
