/* eslint-disable */
import styles from "./DiscardDialog.module.css";
import { ReactComponent as CloseX } from "../../../../assets/icons/Close.svg";
import { ReactComponent as WarningTriangle } from "../../../../assets/icons/warning-triangle.svg";
import SaveAndDiscardButtonSet from "../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";
import { useState, useCallback, useEffect } from "react";
import MultiSelector from "../../../General/MultiSelector/MultiSelector";
import LabeledSelect from "../../../General/LabeledSelect/LabeledSelect";
import template1 from '../../../../assets/icons/lexus-cpp.png';
import template2 from '../../../../assets/icons/toyota-cpp.png';
import LabeledInput from "../../../General/LabeledInput/LabeledInput";
import { useAppSelector } from "../../../../redux/store";
import DateSelector from "../../../General/PopupSelector/DateSelector/DateSelector";
import Button from "../../../General/Button/Button";
import { useHistory } from "react-router-dom";
import { ErrorMsg, Msg } from "../AlertComponent";
import { Slide, toast } from "react-toastify";
import { ReactComponent as Success } from "../../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";

const DiscardDialog = ({ className, yesAction, noAction, visiblity }) => {
  const [selectTemplate, setSelectedTemplate] = useState();
  const [selectedemailCopy, setSelectedEmailCopy] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [ruleName, setRuleName] = useState();
  const [parameter, setDeliveryParameter] = useState([]);
  const [logicalConnector, setLogicalConnector] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState('');
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const [startDate, setAdhocStartDate] = useState();
  const [endDate, setAdhocEndDate] = useState();
  const [daysErrorMessage, setDaysErrorMessage] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isDuplicateSubCat, setIsDuplicateSubCat] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isRuleNameEmpty, setIsRuleNameEmpty] = useState(false);
  const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);
  const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);

  const options_templates = [
    { value: template1, label: 'Template1' },
    { value: template2, label: 'Template2' },
    { value: template1, label: 'Template3' }
  ]

  const options_deliveryParameters = [
    { value: 1, label: "Wait period for new notification" }
  ]

  const options_logicalConnector = [
    { value: 1, label: "=" },
    { value: 2, label: ">=" },
    { value: 3, label: "<=" }
  ]

  let obj = { "template": template1 };
  const getTemplateImage = async (templateSelected) => {

    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/template/${templateSelected.value}/image-previewurl`
    );

    const callParams = {
      method: "GET",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = serverResponse.json().then((response) => {
      const res = response;
      setSelectedTemplate({ ...templateSelected, ...res })
      console.log(res);
    });
  }

  const handleTextChange = (e) => {
    setDaysErrorMessage("")
    const value = e.replace(/\D/g, "");
    if (value === '') {
      setNumberOfDays([])
    } else {
      console.log(value);
      if (value.length > 3) {
        setDaysErrorMessage("Please enter only 3 digit numeric value")
        setNumberOfDays([])
      } else {
        setNumberOfDays(value)
      }
    }
  };

  /*get subcategories */
  const getSubcategories = async (categoryname) => {
    setSubCategoryList([]);
    if (categoryname === "") {
      setSubCategory(null)
    } else {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/subcategories?category=${categoryname}`
      );

      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          // "user-name": userName,
          // "user-email": email
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((response) => {
        if (response !== null && response.length !== 0) {
          response.map((result) => {
            setSubCategoryList((subCategoryList) => [
              ...subCategoryList,
              { label: result.displayName, value: result.id, displayName: result.name }
            ]);
          })
        } else {
          setSubCategoryList((subCategoryList) => [
            ...subCategoryList,
            { label: "", value: "", displayName: "" }
          ]);
        }
      })
    }
  }

  /*save subcategories */
  const saveSubCategories = async (subCategoryname) => {
    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/subcategories`
    );

    const callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        "categoryId": categoryId,
        "name": subCategoryname,
        "displayName": subCategoryname
      })
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log("serverResponseJson status - " + JSON.stringify(serverResponseJson));
    return serverResponse.status;
  }

  const checkCategoryAndSubCategories = async () => {
    //if category or subcategory values not entered, then make setEmpty true to display message
    if (category === undefined || category === "" || category === null || category.length <= 0 ||
      subCategory === undefined || subCategory === "" || subCategory === null) {
      setIsEmpty(true);
    }
  }

  const checkDuplicateSubCategories = async (subCatVal) => {
    let duplicateSubCat = false;
    subCategoryList.map((subCat) => {
      if (subCat.label !== "" && subCat.label === subCatVal) {
        console.log(subCat.label + " --- " + subCategory + " Both are duplicate!")
        setIsDuplicateSubCat(true)
        duplicateSubCat = true;
      }
    })
    return duplicateSubCat;
  }

  return (
    <div className={[className, styles.Container].join(" ")}>
      <div className={styles.Header}>
        <p>
          <span>
            {visiblity === "true" ? className : "Error: Channel needed"}
          </span>
        </p>
        <CloseX onClick={noAction} className={styles.CloseIcon} />
      </div>

      {className === "Delete Template" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p> Are you sure you want to delete this Template?</p>
        </div>
      ) : className === "Publish Copy" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to publish this Copy?</p>
        </div>
      ) : className === "UnPublish Copy" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to unpublish this Copy?</p>
        </div>
      ) : className === "Unpublish Template" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to unpublish this Template?</p>
        </div>
      ) : className === "Publish Template" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to publish this Template?</p>
        </div>
      ) : className === "Delete Copy" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to delete this Copy?</p>
        </div>
      ) : className === "Delete Content" ? (
        <div className={styles.Body}>
          <WarningTriangle />
          <p>Are you sure you want to delete this Content?</p>
        </div>
      ) : className === "Add Sub Category" ? (
        <p>
          {isEmpty ? <label className={styles.WarningMessage}>Category and Sub Category are required!</label> :
            isDuplicateSubCat ? <label className={styles.WarningMessage}>Sub Category is already available!</label> :
              ""}
          <div className={styles.CategorySubCategoryCombo}>
            <div style={{ width: "50%" }}>
              <LabeledSelect
                className={styles.Select}
                optionsUrl="v1/ctmp/category"
                title={
                  <>
                    Select Category<span> *</span>
                  </>
                }
                onValueChange={async (option) => {
                  if (option) {
                    setIsEmpty(false);
                  }
                  setCategory(option);
                  setCategoryId(option.value);
                  if (option === null) {
                    setSubCategory(null)
                    await getSubcategories(null)
                  } else {
                    const status = await getSubcategories(option.value)
                  }
                }}
              />
            </div><div style={{ width: "47%" }}>
              <LabeledInput
                title={
                  <>
                    Add Sub Category (limit 100 characters)<span> *</span>
                  </>
                }
                className={styles.AddSubCat}
                onValueChange={async (value) => {
                  if (value.trim()) {
                    setIsEmpty(false);
                  }
                  setSubCategory(value.trim())
                  const isDuplicate = await checkDuplicateSubCategories(value.trim());
                  setIsDuplicateSubCat(isDuplicate)
                }}
                placeholder="Add Sub Category"
              />
            </div>
          </div>
        </p>
      ) : className === "Create Delivery Rule" || className === "Create Delivery Rule AdHoc" ? (
        <p>
        {className !== "Create Delivery Rule AdHoc" ? 
          isRuleNameEmpty ? <label className={styles.WarningMessage2} >Please enter Rule Name!</label> : "" :
          (isRuleNameEmpty || isStartDateEmpty || isEndDateEmpty) ? <label className={styles.WarningMessage2} >Rule Name, Start Date and End Date are required!</label> : ""}
          <div className={styles.DeliveryRulesCombo}>
            <div>
              <LabeledInput
                className={styles.RuleName}
                title={
                  <>
                    Rule Name<span> *</span>
                  </>
                }
                placeholder="Add a name"
                onValueChange={(option) => {
                  if (option.trim()) {
                    setIsRuleNameEmpty(false);
                  }
                  setRuleName(option.trim())
                }}
              />
            </div>
            <div className={styles.line}></div>
            <div>
              <LabeledSelect
                className={styles.DeliveryParameter}
                //optionsUrl="v1/ctmp/category"
                options={options_deliveryParameters}
                title={
                  <>
                    Delivery Parameter<span> </span>
                  </>
                }
                onValueChange={(option) => setDeliveryParameter(option.label)}
              />
            </div>
            <div>
              <LabeledSelect
                className={styles.LogicalConnectors}
                options={options_logicalConnector}
                defaultLabel={logicalConnector ? logicalConnector.label : "="}
                defaultId={logicalConnector ? logicalConnector.value : "1"}
                title={
                  <>
                    Logical Connector<span> </span>
                  </>
                }
                onValueChange={(option) => setLogicalConnector(option.label)}
              />
            </div>
            <div>
              <LabeledInput
                className={styles.NumberOfDays}
                title={
                  <>
                    Number of Days<span></span>
                  </>
                }
                // data={campaignName}
                data={numberOfDays}
                placeholder="Enter Number of Days"
                onValueChange={(value) => handleTextChange(value)}
              />
              {daysErrorMessage ? <div style={{ color: "red", paddingLeft: "6rem" }}>{daysErrorMessage}</div> : ""}
            </div>
            {className === "Create Delivery Rule AdHoc" ?
              <div className={styles.DeliveryRulesCombo1}>
                <div className={styles.StartDate}>
                  <label>
                    Start Date<span> *</span>
                  </label>
                  <DateSelector
                    className={styles.CalendarSelector1}
                    onDiscard={console.log}
                    onApply={(date) => {
                      if (date) {
                        setIsStartDateEmpty(false);
                      }
                      setAdhocStartDate(date)
                    }}
                    value={startDate}
                  />
                </div>
                <div className={styles.EndDate}>
                  <label>
                    End Date<span> *</span>
                  </label>
                  <DateSelector
                    className={styles.CalendarSelector2}
                    onDiscard={console.log}
                    onApply={(date) => {
                        if (date) {
                          setIsEndDateEmpty(false);
                        }
                        setAdhocEndDate(date)
                      }}
                    value={endDate}
                  />
                </div>
              </div>
              : ""}
          </div>
        </p>
      ) : (className === "Email Copy" || className === "CT Marketing Card Copy" || className === "General Marketing Card Copy") ?
        (visiblity === "true" ?
          <p><div>
            <LabeledSelect
              className={styles.Select}
              optionsUrl={className === "Email Copy" ? `v1/content/channel/Email` :
                className === "CT Marketing Card Copy" ? `v1/content/channel/CT Marketing Card` :
                  `v1/content/channel/General Marketing Card`
              }
              value={selectedemailCopy}
              title={
                <>
                  Select Template<span></span>
                </>
              }
              defaultLabel=""
              defaultId=""
              onValueChange={(option) => setSelectedEmailCopy(option)}
            />
          </div></p> :
          <p className={styles.Alert}>Please choose '{className.replace("Copy", "")}' as a Channel before selecting the Copy</p>
        ) :
        (className === "Email Template" || className === "CT Marketing Card Template" || className === "General Marketing Card Template") ?
          (visiblity === "true" ?
            <div>
              <LabeledSelect
                className={styles.Select}
                // optionsUrl={`v1/template/channel/${className}`}
                optionsUrl={className === "Email Template" ? `v1/template/channel/Email` :
                  className === "CT Marketing Card Template" ? `v1/template/channel/CT Marketing Card` :
                    `v1/template/channel/General Marketing Card`
                }
                value={selectTemplate}
                title={
                  <>
                    Select Template<span> *</span>
                  </>
                }
                defaultLabel=""
                defaultId=""
                onValueChange={(option) => getTemplateImage(option)}
              // setSelectedTemplate({...option, ...obj})
              />
            </div> :
            <p className={styles.Alert}>Please choose '{className.replace("Template", "")}' as a Channel before selecting the Template</p>
          ) :
          <div className={styles.Body}>
            <p><span style={{ paddingRight: "3rem" }}><WarningTriangle /></span>
              Are you sure you want to discard the changes without saving?
            </p>
          </div>
      }
      {
        className === "Publish Copy" ?
          <SaveAndDiscardButtonSet
            yesAction={() => yesAction(selectedemailCopy)}
            noAction={noAction}
            yesTitle="Yes"
            noTitle="No"
            className={styles.Buttons}
          />
          :
          className === "UnPublish Copy" ?
            <SaveAndDiscardButtonSet
              yesAction={() => yesAction(selectedemailCopy)}
              noAction={noAction}
              yesTitle="Yes"
              noTitle="No"
              className={styles.Buttons}
            /> :
            className === "Delete Copy" ?
              <SaveAndDiscardButtonSet
                yesAction={() => yesAction(selectedemailCopy)}
                noAction={noAction}
                yesTitle="Yes"
                noTitle="No"
                className={styles.Buttons}
              /> :
              className.includes("Copy") ?
                visiblity == "true" ?
                  <SaveAndDiscardButtonSet
                    yesAction={() => yesAction(selectedemailCopy)}
                    noAction={noAction}
                    yesTitle="Select"
                    noTitle="Cancel"
                    className={styles.Buttons}
                  /> :
                  <Button name="Ok" onClick={noAction}></Button>
                :
                (className === "Email Template" || className === "CT Marketing Card Template" || className === "General Marketing Card Template") ?
                  visiblity == "true" ? <SaveAndDiscardButtonSet
                    yesAction={() => yesAction(selectTemplate)}
                    noAction={noAction}
                    yesTitle="Select"
                    noTitle="Cancel"
                    className={styles.Buttons}
                  /> :
                    <Button name="Ok" onClick={noAction}></Button>
                  :
                  (className === "Add Sub Category") ?
                    <SaveAndDiscardButtonSet
                      yesAction={async () => {
                        checkCategoryAndSubCategories(subCategory);
                        const isDuplicate = await checkDuplicateSubCategories();
                        console.log("isDuplicate - " + isDuplicate);
                        let statusVal = 0;
                        //if it is duplicate or category or subcategory values not entered, then don't save
                        { isDuplicate || !category || category.length <= 0 || !subCategory ? "" :
                        statusVal = await saveSubCategories(subCategory)
                        statusVal !== 200 &&
                            toast.error(
                              <ErrorMsg
                                id="subcategory"
                                templateName={subCategory}
                              />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                            statusVal === 200 &&
                            toast.success(
                              <Msg id="subcategory" templateName={subCategory} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                       }
                        yesAction(category, subCategory, isDuplicate);
                      }}
                      noAction={noAction}
                      yesTitle="Add"
                      noTitle="Discard"
                      className={styles.Buttons}
                    />
                    :
                    (className === "Create Delivery Rule" || className === "Create Delivery Rule AdHoc") ?
                      <SaveAndDiscardButtonSet
                        yesAction={() => {
                          if (!ruleName) {
                            setIsRuleNameEmpty(true);
                          }
                          if (startDate === "MM/DD/YYYY") {
                            setIsStartDateEmpty(true);
                          }
                          if (endDate === "MM/DD/YYYY") {
                            setIsEndDateEmpty(true);
                          }
                          yesAction(ruleName,
                            parameter,
                            logicalConnector,
                            numberOfDays,
                            startDate,
                            endDate)
                        }}
                        noAction={noAction}
                        yesTitle="Add"
                        noTitle="Cancel"
                        className={styles.Buttons}
                      />
                      :
                      <SaveAndDiscardButtonSet
                        yesAction={yesAction}
                        noAction={noAction}
                        className={styles.Buttons}
                      />
      }
    </div >
  );
};

export default DiscardDialog;
