/* eslint-disable*/
import React, { useCallback, useState, useEffect, useContext } from "react";
import NavLink from "./NavLink/NavLink";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as CampaignIcon } from "../../assets/icons/campaign-icon.svg";
import { ReactComponent as TemplateIcon } from "../../assets/icons/template-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as ContentIcon } from "../../assets/icons/content-icon.svg";
import { ReactComponent as CustomerListIcon } from "../../assets/icons/customer-list-icon.svg";
import { ReactComponent as ReportIcon } from "../../assets/icons/report-icon.svg";
import { ReactComponent as UserMngmtIcon } from "../../assets/icons/user-mgmt-icon.svg";
import { useAppSelector } from "../../redux/store";
import { authProvider } from "../../authConfig";
import { Context } from "../../context";
import styles from "./NavBar.module.css";
import { CapabilityConstants } from "../Constants/CapabilitiyConstants";


const NavBar = (props) => {
  const { user, capability } = useContext(Context);
  const [role, setRole] = user;
  const [capabilities, setCapabilities] = capability;
  const history = useHistory();

  // let targetToBeSelected = "";
  const location = useLocation();
  const { pathname } = location;
  let splitLocation = pathname.split("/");
  // splitLocation[2] === "dashboard"
  //   ? (targetToBeSelected = "Campaign")
  //   : splitLocation[2] === "template"
  //   ?targetToBeSelected = "Template"
  //   : splitLocation[2] === "content"
  //   ? (targetToBeSelected = "Content")
  //   : splitLocation[2] === "customer-list"
  //   ? targetToBeSelected = "Customer List"
  //   : splitLocation[2] === "report"
  //   ? targetToBeSelected = "Report"
  //   : splitLocation[2] === "user-management"
  //   ? targetToBeSelected = "User Management"
  //   : targetToBeSelected = "Campaign"
  // const [selected, setSelected] = useState(targetToBeSelected);
  const [selected, setSelected] = useState("Campaign");
  let jwt = useAppSelector((state) => state.authReducer.jwt);
  const email = useAppSelector((state) => state.authReducer.email);
  const dispatch = useDispatch();

  const fetchJWT = async () => {
    const token = await authProvider.getIdToken();
    jwt = token.idToken.rawIdToken
    return jwt;
  }


  /*get user role*/
  useEffect(() => {
    (async () => {
      jwt === "" ? await fetchJWT() : jwt
      console.log("jwt---------->" + jwt)
      const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/email/${email}`);
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
        }
      };
      try {
        const serverResponse1 = await fetch(url.toString(), callParams);
        const mystatus1 = await serverResponse1.json();
        const serverResponse = await fetch(url.toString(), callParams);
        const mystatus = await serverResponse.json();
        mystatus.status === "no record found" ? setRole("Author") : setRole(mystatus.role)
        setCapabilities([...mystatus.capabilities]);
      }
      catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  /*get expiry time in JWT and compare with current time*/
  useEffect(() => {
    let targetToBeSelected = "";
    splitLocation[2] === "dashboard"
      ? (targetToBeSelected = "Campaign")
      : splitLocation[2] === "template"
        ? targetToBeSelected = "Template"
        : splitLocation[2] === "copy"
          ? targetToBeSelected = "Copy"
          : splitLocation[2] === "content"
            ? (targetToBeSelected = "Content")
            : splitLocation[2] === "customer-list"
              ? targetToBeSelected = "Customer List"
              : splitLocation[2] === "report"
                ? targetToBeSelected = "Report"
                : splitLocation[2] === "user-management"
                  ? targetToBeSelected = "User Management"
                  : targetToBeSelected = "Campaign"
    setSelected(targetToBeSelected);
  }, [splitLocation])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      let response;
      if (localStorage.getItem("blockNavigation") == "true") {
        let confirmAction = confirm("Are you sure you want to navigate without saving?");
        if (confirmAction) {
          localStorage.setItem("blockNavigation", "false");
          response = true;
        } else {
          history.location.pathname.split("/")[2] === "template"
            ? setSelected("Template")
            : history.location.pathname.split("/")[2] === "copy"
              ? setSelected("Copy")
              : history.location.pathname.split("/")[2] === "content"
                ? setSelected("Content")
                : history.location.pathname.split("/")[2] === "customer-list"
                  ? setSelected("Customer List")
                  : history.location.pathname.split("/")[2] === "report"
                    ? setSelected("Report")
                    : history.location.pathname.split("/")[2] === "user-management"
                      ? setSelected("User Management")
                      : setSelected("Campaign")
          response = false
        }
      }
      return response;
    });
    return () => {
      // alert("here")
      // unblock();
    };
  }, [])

  const setSelectedCallback = useCallback(
    (newSelected) => {
      setSelected(newSelected);
    },
    [setSelected]
  );

  return (
    <div className={styles.MainHeader}>
      <NavLink
        selected={selected === "Campaign"}
        name="Campaign"
        linkTo="/ctmp/dashboard"
        icon={CampaignIcon}
        setSelected={setSelectedCallback}
      />
      <NavLink
        selected={selected === "Template"}
        name="Template"
        linkTo="/ctmp/template"
        icon={TemplateIcon}
        setSelected={setSelectedCallback}
      />
      <NavLink
        selected={selected === "Copy"}
        name="Copy"
        linkTo="/ctmp/copy"
        icon={CopyIcon}
        setSelected={setSelectedCallback}
      />
      <NavLink
        selected={selected === "Content"}
        name="Content"
        linkTo="/ctmp/content"
        icon={ContentIcon}
        setSelected={setSelectedCallback}
      />
      <NavLink
        selected={selected === "Customer List"}
        name="Customer List"
        linkTo="/ctmp/customer-list"
        icon={CustomerListIcon}
        setSelected={setSelectedCallback}
      />
      <NavLink
        selected={selected === "Report"}
        name="Report"
        linkTo="/ctmp/report"
        icon={ReportIcon}
        setSelected={setSelectedCallback}
      />
      {capabilities.includes(CapabilityConstants.USER_MANAGEMENT_ACCESS) ?
        <NavLink
          selected={selected === "User Management"}
          name="User Management"
          linkTo="/ctmp/user-management"
          icon={UserMngmtIcon}
          setSelected={setSelectedCallback}
        /> : ""}

    </div>
  );
};

export default NavBar;
