import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import styles from "./CreateCustomer.module.css";
import { useAppSelector } from "../../../redux/store";


/* eslint-disable  @typescript-eslint/no-unused-vars */
const RuleForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [keyList, setKeyList] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [associatedKey, setAssociatedKey] = useState(null);
  const ref = useRef(null);
  const jwt = useAppSelector(state => state.authReducer.jwt);
  // const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    (async () => {
      // const url = new URL(`https://mktpl.dev.telematicsct.com/v1/ctmp/meta/rulebuilder/vehicle-fields`);
      const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/meta/rulebuilder/vehicle-fields`);
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt}`
          // "user-name": userName,
          // "user-email": email

        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((data) => {
        setWidgetList((widgetList) => [
          ...widgetList,
          data
        ]);
        setKeyList(data.filter((item) => {
          setAssociatedKey(item.associatedKeyId)
          return item.fieldType === "key"
        }))
        setValueList(data.filter((item) => {
          return item.fieldType === "value"
        }))
      });
    })();
  }, [])



  return (
    <div ref={ref}>
      <Button onClick={handleClick} className={styles.AddRuleButton}> Add Rule </Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3" className={styles.PopoverTitle}>Rules</Popover.Title>
          <Popover.Content>
            <Form.Label className={styles.Title}>Parameter<span>*</span></Form.Label>
            <Form.Control as="select" className={styles.SelectRuleForm} onChange={(value) => {
              console.log("value", value.target.value)
              setAssociatedKey(value.target.value)
            }}>
              {keyList && keyList !== null ? keyList.map((element) => {
                return <option value={element.id}>{element.fieldValue}</option>
              }) : null}
            </Form.Control>
            <Form.Label className={styles.Title}>Logical Connecter<span>*</span></Form.Label>
            <Form.Control as="select" className={styles.SelectRuleForm}>
              <option>=</option>
              <option>!=</option>
              <option>&#60;</option>
              <option>&gt;</option>
              <option>&ge;</option>
              <option>&le;</option>
            </Form.Control>
            <Form.Label className={styles.Title}>Value<span>*</span></Form.Label>
            <Form.Control as="select" className={styles.SelectRuleForm}>
              {valueList && valueList !== null ? valueList.map((element) => {
                if (element.fieldType === "value" && element.associatedKeyId === associatedKey) {
                  return <option>{element.fieldValue}</option>
                }
                return null
              }) : null}
            </Form.Control>
            <div className={styles.Block}><p>Connectors</p></div>
            <Form.Label className={styles.Title}>Select Existing Connectors<span>*</span></Form.Label>
            <Form.Control as="select" className={styles.SelectRuleForm}>
              <option>And</option>
              <option>And (2)</option>
            </Form.Control>
            <div>
              <div className={styles.Dashed}></div>
              <div style={{ display: 'inline-block', fontSize: 'medium' }}>OR</div>
              <div className={styles.Dashed}></div>
            </div>
            <div style={{ padding: '15px' }}><Button variant="outline-primary" size="lg" block onClick={handleClick} className={styles.A}> Add New Connector </Button></div>
            <div style={{ padding: '15px' }}><Button variant="outline-danger" size="lg" block onClick={handleClick} className={styles.A}> Add </Button></div>
            <div style={{ padding: '15px' }}><Button variant="outline-danger" size="lg" block onClick={handleClick} className={styles.A}> Discard </Button></div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );

};

export default RuleForm