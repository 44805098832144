/* eslint-disable  @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import RadioButton from "./RadioButton/RadioButton";

import styles from "./RadioButtonControl.module.css";

export type RadioButtonOption = {
  value: string | number | boolean;
  title: string;
};

export type RadioButtonControlProps = {
  previousSelection? : any,
  options: RadioButtonOption[];
  onSelect: (selectedOption: RadioButtonOption) => void;
};

const RadioButtonControl = ({ options, onSelect, previousSelection }: RadioButtonControlProps) => {
  const [selected, setSelected] = useState<RadioButtonOption>();

  // useEffect(() => {
  //   onSelect(selected);
  // }, [selected, onSelect]);

  return (
    <div className={styles.Container}>
      {options.map((op) => (
        <RadioButton
          selected={previousSelection && previousSelection.value === op.value}
          onSelect={(value) => {
            // setSelected(value);
            onSelect && onSelect(value)
          }}
          key={op.title}
          option={op}
        />
      ))}
    </div>
  );
};

export default RadioButtonControl;
