  /* eslint-disable */
  import { useState, useEffect } from "react";
  import { useHistory } from "react-router-dom";
  import { useAppSelector } from "../../../redux/store";
  import styles from "./CreateContent.module.css";
  import LabeledInput from "../../General/LabeledInput/LabeledInput";
  import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
  import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
  import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
  import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
  import PopupDialog from "../../General/PopupDialog/PopupDialog";
  import Button from "../../General/Button/Button";
  import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
  
  const UnassignedCopy = (props) => {
    /* eslint-disable */
    const history = useHistory();
    const [unassignedSelectTemplate, setUnassignedSelectTemplate] = useState();
    const [unassignedExistingCopy, setUnassignedExistingCopy] = useState();
    const [saveContentObject, setSaveContentObject] = useState({});
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const userName = useAppSelector((state) => state.authReducer.userName);
  
    /*for now defaultvalues for Templates */
    const TemplateValues = [
      {
        label: "Template A1",
        value: "Template A1"
      },
      {
        label: "Template A2",
        value: "Template A2"
      }
    ];
  
    /* notify if user do not enter mandatory fields */
    useEffect(() => {
      let toBeCompletedArray = [];
      toBeCompletedArray = !unassignedSelectTemplate
        ? [...toBeCompletedArray, "Template"]
        : toBeCompletedArray;
      toBeCompletedArray = !unassignedExistingCopy
        ? [...toBeCompletedArray, "Existing Copy"]
        : toBeCompletedArray;
      setToBeCompleted(toBeCompletedArray);
    }, [
     unassignedExistingCopy,unassignedSelectTemplate
    ]);
  
    /* update the values when changed */
  //   useEffect(() => {
  //     setSaveContentObject({
  //       name: createContentName,
  //       description: createContentDescription,
  //       ...(createContentSelectedChannels && {
  //         channel: createContentSelectedChannels.map((c) => ({
  //           name: c
  //         }))
  //       }),
  //       ...(createContentBrand && {
  //         brand: createContentBrand.map((c) => ({
  //           name: c
  //         }))
  //       }),
  //       ...(createContentSelectTemplate && {
  //         template: [
  //           {
  //             name: createContentSelectTemplate.label,
  //             id: createContentSelectTemplate.value
  //           }
  //         ]
  //       }),
  //       ...(createContentGeneration && {
  //         generation: [
  //           {
  //             name: createContentGeneration.label,
  //             id: createContentGeneration.value
  //           }
  //         ]
  //       }),
  //       createdBy: userName,
  //       // date: `${format(createContentDate, "MM/dd/yyyy")}`
  //       date: createContentDate
  //     });
  //   }, [
  //     createContentName,
  //     createContentDescription,
  //     createContentSelectTemplate,
  //     createContentSelectedChannels,
  //     createContentBrand,
  //     createContentGeneration,
  //     createContentDate
  //   ]);
  
    /* save funtion */
  //   const saveContentData = async (saveContentObject) => {
  //     console.log("--->" + JSON.stringify(saveContentObject));
  //     let url, methodValue;
  //     editMode === true
  //       ? ((url = new URL(
  //           `${process.env.REACT_APP_API_HOSTNAME}v1/content/${props.props}`
  //         )),
  //         (methodValue = "PUT"))
  //       : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/content`)),
  //         (methodValue = "POST"));
  
  //     saveContentObject.status = "Draft";
  
  //     let callParams = {
  //       method: methodValue,
  //       headers: {
  //         "x-api-key": `${process.env.REACT_APP_API_KEY}`,
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${jwt}`,
  //         "X-CORRELATIONID": 0
  //       },
  //       body: JSON.stringify(saveContentObject)
  //     };
  //     console.log("------save content object---------->"+JSON.stringify(saveContentObject))
  //     const serverResponse = await fetch(url.toString(), callParams);
  //     const serverResponseJson = await serverResponse.json();
  //     return serverResponseJson;
  //   };
  
    return (
      <div style={{ textAlign: "left" }}>
        <p className={styles.UpdateFormHeader}>Use draft campaign copy</p>
        <span className={styles.UpdateFormSubTitle}>
         Use any existing copy in draft status already present in the platform.
        </span>
        <div style={{marginBottom:"2rem",marginTop:"3rem"}}>
        <LabeledSelect
          className={styles.Select}
          options={TemplateValues}
          placeholder="Select"
          value={unassignedSelectTemplate}
          defaultLabel={
            unassignedSelectTemplate
              ? unassignedSelectTemplate.label
              : undefined
          }
          defaultId={
            unassignedSelectTemplate
              ? unassignedSelectTemplate.value
              : undefined
          }
          title={
            <>
              Template<span>*</span>
            </>
          }
          onValueChange={(option) => setUnassignedSelectTemplate(option)}
        /></div>
        <div style={{marginBottom:"2rem",marginTop:"3rem"}}>
        <LabeledSelect
          className={styles.Select}
          options={TemplateValues}
          placeholder="Select"
          value={unassignedExistingCopy}
          defaultLabel={
            unassignedExistingCopy
              ? unassignedExistingCopy.label
              : undefined
          }
          defaultId={
            unassignedExistingCopy
              ? unassignedExistingCopy.value
              : undefined
          }
          title={
            <>
              Existing copy in draft status<span>*</span>
            </>
          }
          onValueChange={(option) => setUnassignedExistingCopy(option)}
        /></div>
    
   
            <div className={styles.floatcontainer}>
              <div className={styles.floatchild}>
                <PopupDialog
                  height="30rem"
                  width="67rem"
                  renderPopupContent={(closeModal) => (
                    <DiscardDialog
                      yesAction={() => {
                        history.push("/ctmp/content");
                      }}
                      noAction={closeModal}
                      className="Discard"
                    />
                  )}
                  renderControlContent={(openModal) => (
                    // <Button onClick={openModal} name="Discard" />
                    console.log("")
                  )}
                />
              </div>
              <div className={styles.floatchild}>
                <div className={styles.floatchild1}>
                  {/* save */}
                </div>
                <div className={styles.floatchild2}>
                  <SaveAndDiscardWithPopupButtonSet
                    yesPopupHeight="55rem"
                    noPopupHeight="30rem"
                    yesTitle="Use" //save
                    noTitle="Discard" //discard
                    className={styles.ButtonsEdit}
                    renderDiscardPopupContent={(closeModal) => (
                      //save and close
                      <DiscardDialog
                      yesAction={() => {
                        history.push("/ctmp/content");
                      }}
                      noAction={closeModal}
                      className="Discard"
                    />
                    )}
                    renderSavePopupContent={(closeModal) => (
                      <SaveDialog
                      saveAction={async () => {
                        // let id_newcontent = await saveContentData(saveContentObject);
                        // closeModal();
                        // if (props.props !== undefined) {
                        //   history.push(
                        //     `/ctmp/content/edit-content/${props.props}/creative-brief`
                        //   );
                        // } else {
                        //   // history.push("/ctmp/content/create-content/creative-brief")
                        //   history.push(
                        //     `/ctmp/content/create-content/creative-brief/${id_newcontent}`
                        //   );
                        // }
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                    )}
                  />
                </div>
              </div>
            </div>
     
        </div>
      );
  };
  export default UnassignedCopy;
  