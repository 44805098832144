/* eslint-disable */
import React, { useState, useEffect } from "react";
import styles from "./CreateCampaign.module.css";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import { ReactComponent as CloseX } from "../../../assets/icons/Close-Small.svg";
import DiscardDialog from "../../ManageTemplate/CreateTemplate/DiscardTemplate/DiscardTemplate";
import LabeledInput from "../../General/LabeledInput/LabeledInput";

const CreateNewDeliveryRule = ({ title, selectedCategory, newDeliveryRules, data }) => {
    return (
        <div>
            <PopupDialog
                height="30rem"
                width="90rem"
                renderPopupContent={(closeModal) => (
                    <DiscardDialog
                        yesAction={async (ruleName,
                            parameter,
                            logicalConnector,
                            numberOfDays,
                            startDate,
                            endDate) => {
                            newDeliveryRules([{ ruleName, parameter, logicalConnector, numberOfDays, startDate, endDate }]);
                            if (!ruleName || startDate === "MM/DD/YYYY" || endDate === "MM/DD/YYYY") {
                                console.log("Required data missing!")
                            } else {
                                closeModal()
                            }
                        }}
                        noAction={closeModal}
                        className={selectedCategory === "AdHoc Campaigns" ? "Create Delivery Rule AdHoc" : "Create Delivery Rule"}
                        visiblity="true"
                    />
                )}
                renderControlContent={(openModal) =>
                    <div>
                        <label style={{ color: "var(--ct-blue-secondary)" }} className={styles.deliverRuleLabel} onClick={openModal}>{title}</label>
                        <LabeledInput
                            className={styles.deliverRuleInput}
                            placeholder="There are currently no rules for this campaign."
                            data={data}
                            onValueChange={(value) => console.log('')}
                            title={
                                <>
                                    Delivery Rules
                                </>
                            }
                        />
                    </div>
                }
            />
        </div>
    );
};

export default CreateNewDeliveryRule;
