/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./CreateContent.module.css";
import PageContent from "../../General/PageContent/PageContent";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import CreateNewContent from "./CreateNewContent";
import ContentCreativeBrief from "./ContentCreativeBrief";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../../hooks/ScrollToTop";
import { NavLink } from "react-router-dom";
import ClonePublishedCopy from "./ClonePublishedCopy";
import UploadCopy from "./UploadCopy";
import UnassignedCopy from "./UnassignedCopy";

const CreateContent = (props) => {
  //   const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const pathName = history.location.pathname.split("/");
  console.log(pathName[pathName.length - 1]);
  const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);
  const [editContent, setEditContent] = useState(false);
  const [viewContent, setViewContent] = useState(false);

  //set view and edit content based on path
  useEffect(() => {
    if (props) {
      if (pathName[3] === "edit-content") {
        setEditContent(true);
      } else if (pathName[3] === "view-content") {
        setViewContent(true);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={styles.CreateContent}>
        {props && editContent ? (
          <TitleWithPathHeader
            title="Edit Content"
            path={" Manage Content / Edit Content"}
          />
        ) : props && viewContent ? (
          <TitleWithPathHeader
            title="View Content"
            path={" Manage Content / View Content"}
          />
        ) : (
          <TitleWithPathHeader
            title="Create Content"
            path={" Manage Content / Create Content"}
          />
        )}
        {editContent === true ?
          <div className={styles.CreateContentModule1}>
            <div className={styles.RightContentEditCopy}><Route
              render={() => (
                pathName[5] === "creative-brief" ? <ContentCreativeBrief props={props.contentId} /> : <CreateNewContent props={props.contentId} />
              )}
            />
            </div>
          </div> :
          viewContent === true ?
            <div className={styles.CreateContentModule1}>
              <div className={styles.RightContentEditCopy}><Route
                render={() => (
                  pathName[5] === "creative-brief" ? <ContentCreativeBrief props={props.contentId} /> : <CreateNewContent props={props.contentId} />
                )}
              />
              </div>
            </div>
            :
            <div className={pathName[5] !== "creative-brief" ? styles.CreateContentModule : styles.CreateContentModule}>
              {/* NAV MENU*/}
              <div className={styles.LeftContent}>
                <ul
                  className={
                    navSelected === "new-content" ||
                      navSelected === "content" ||
                      navSelected === "creative-brief" ||
                      navSelected === "create-content" ||
                      navSelected === "edit-content" ||
                      navSelected === props.contentId
                      ? styles.NavItemSelected
                      : styles.NavItem
                  }
                  onClick={() => {
                    setNavSelected("new-content");
                    // history.push("/ctmp/content/create/new-content");
                  }}
                >
                  <NavLink
                    style={{ color: "black", textDecoration: "none" }}
                    to={`${url}/new-content`}
                  >
                    {editContent ? "Edit Content" : viewContent ? "View Content" : "Create New Content"}
                  </NavLink>
                </ul>

                <ul
                  className={
                    navSelected === "upload-copy"
                      ? styles.NavItemSelected
                      : styles.NavItem
                  }
                  onClick={() => {
                    setNavSelected("upload-copy");
                    // history.push("/ctmp/content/create/upload-copy");
                  }}
                >
                </ul>
              </div>
              {/* RIGHT MENU*/}
              <div className={styles.RightContent}>
                <div className={styles.CreateCustomerContent}>
                  <React.Fragment>
                    <ScrollToTop />

                    <Switch>
                      <Route exact path={`${url}`}>
                        <Redirect to={`${url}/new-content`} />
                      </Route>

                      <Route path={`${url}/new-content`}>
                        <CreateNewContent props={props.contentId} />
                      </Route>

                      <Route path={`${url}/creative-brief`}>
                        <ContentCreativeBrief props={props.contentId} />
                      </Route>

                      <Route path={`${url}/upload-copy`}><UploadCopy /></Route>
                      <Route path={`${url}/unassigned-copy`}><UnassignedCopy /></Route>
                      <Route path={`${url}/clone-published-copy`}>
                        <ClonePublishedCopy />
                      </Route>
                    </Switch>
                  </React.Fragment>
                </div>
              </div>
            </div>
        }
      </div>
    </React.Fragment>
  );
};

export default CreateContent;
