/* eslint-disable  */import React,{ useEffect, useState, useCallback, useContext } from "react";
import _ from "lodash";
import ManageTemplateTableHead from "./ManageTemplateTableHead/ManageTemplateTableHead";
import { columns } from "./manage-template-table-structure";
import styles from "./ManageTemplateTable.module.css";
import Table from "../../General/Table/Table";
import TableFooter, {
  PAGE_SIZES
} from "../../General/Table/TableFooter/TableFooter";
import { mapTemplates } from "../../../utils/object-mappers/manage-template/map-templates";
import { useAppSelector } from "../../../redux/store";

import { Slide, toast } from "react-toastify";
import { Msg } from "../CreateTemplate/AlertComponent";
import { ErrorMsg } from "../CreateTemplate/AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { Context } from "../../../context";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";

const ManageTemplateTable = ({ search }) => {
 
  // const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);

  const [totalTemplates, setTotalTemplates] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [sortBy, setSortBy] = useState();

  const [filters, setFilters] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
  const { user, value } = useContext(Context);
  const [role,setRole] = user;
  const [context, setContext] = value;
  
  let jwt = useAppSelector((state) => state.authReducer.jwt);
  // if (jwt !== "") {
  //   localStorage.setItem("token", jwt);
  // }
  // if (jwt === "") {
  //   jwt = localStorage.getItem("token");
  // }

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      //  let url = new URL(
      //      "https://mktpl.dev.telematicsct.com/v1/campaigns");
      let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/template`);
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          //  "user-info": JSON.stringify({ userName: userName, userEmail: email })
        }
      };
      if (!_.isEmpty(filters)) {
       
        //  url = new URL(
        //     "https://mktpl.dev.telematicsct.com/v1/campaigns/search");
        url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/template/search`
        );

        callParams = {
          method: "POST",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
            // "user-info": JSON.stringify({ userName: userName, userEmail: email })
          },
          body: JSON.stringify(
            filters.freeSearch
              ? { ...filters }
              : {
                  ...Object.keys(filters)
                    .map((value) =>
                      value === "status" ||
                      value === "channels" ||
                      value === "brand" ||
                      value === "createdBy"
                        ? ({
                            [value]: filters[value].map((val) => val.label)
                          })
                        : ({ [value]: filters[value] })
                    )
                    .reduce(
                      (previousValue, currentValue) => ({
                        ...previousValue,
                        ...currentValue
                      }),
                      {}
                    )
                }
          )
        };
      } 
      // else {
        const params = {
          page: currentPage,
          pagesize: selectedPageSize,
          ...(sortBy && { sortBy })
        };
        url.search = new URLSearchParams(params).toString();
      // }
     
      const serverResponse = await fetch(url.toString(), callParams,{ signal: abortController.signal });
      const serverResponseJson = await serverResponse.json();
      localStorage.removeItem("token");
      setTotalTemplates(serverResponseJson.totalTemplates);
      setTemplates(mapTemplates(serverResponseJson.templates));
      setIsLoaded(false);
      //for now localstorage; this will be changed in next push.
  localStorage.getItem("forcePublish") === "true"
  ? toast.success(<Msg id="publish" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forcePublish") === "false"
  ? toast.error(<ErrorMsg id="publish" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceDelete") === "true"
  ? toast.success(<Msg id="delete" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceDelete") === "false"
  ? toast.error(<ErrorMsg id="delete" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceUnpublish") === "false"
  ? toast.error(<ErrorMsg id="unpublish" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Error />,
      transition: Slide,
      closeOnClick: true
    })
  : localStorage.getItem("forceUnpublish") === "true"
  ? toast.success(<Msg id="unpublish" templateName="" />, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
      icon: <Success />,
      transition: Slide,
      closeOnClick: true
    })
  : "";

    localStorage.removeItem("forcePublish");
    localStorage.removeItem("forceDelete");
    localStorage.removeItem("forceUnpublish");
    setContext();
    })();
    return () => {
      abortController.abort();
    };
  }, [selectedPageSize, currentPage, sortBy, filters, context]);

  useEffect(() => {
    if (search) {
      setFilters({
        freeSearch: search
      });
    } else if (!_.isEmpty(filters) && filters.freeSearch) {
      setFilters({});
    }
  }, [search]);

  const goToPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const changePageSizeTo = useCallback((newPageSize) => {
    setCurrentPage(1);
    setSelectedPageSize(newPageSize);
  }, []);

  const onSortChange = useCallback((newSortBy) => {
    setSortBy(newSortBy);
    setFilters({});
  }, []);

  const onFilterChange = useCallback((filters) => {
    setFilters(filters);
  }, []);

  return (
    <div className={styles.ManageTemplateModule}>
      <ManageTemplateTableHead
        onSortChange={onSortChange}
        onFiltersChange={onFilterChange}
        filters={filters}
      />
        {(isLoaded === true) ? <LoadSpinner /> : <Table columns={columns} data={templates} />}
      
      <TableFooter
        changePageSizeTo={changePageSizeTo}
        selectedPageSize={selectedPageSize}
        currentPage={currentPage}
        goToPage={goToPage}
        totalItems={totalTemplates}
      />
    </div>
  );
};

export default ManageTemplateTable;
