import React from "react"

import styles from "./CampaignDetails.module.css"

const CampaignDetails = ({data}) => {
    return <div className={styles.CampaignDetailsContainer}>
        <span className={styles.CampaignDetailsHeader}>{data.campaignName}</span>
        <p className={styles.CampaignDescription}>{data.description}</p>
        <span className={styles.CreatedDate}>{data.createdDate}</span>
    </div>
}

export default CampaignDetails