/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./AddUser.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../../hooks/ScrollToTop";
import { NavLink } from "react-router-dom";
import AddUser from "./AddUser";
import ListUsers from "./ListUsers";
import ManageRole from "./ManageRole";
import ManageTeam from "./ManageTeam";

const CreateUser = (props) => {
  //   const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const pathName = history.location.pathname.split("/");
  const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);

  return (
    <React.Fragment>
      <div className={styles.CreateUser}>
        <TitleWithPathHeader
          title={"User Management"}
          path={" User Management/Manage Users, Roles & Teams"}
        />

        <div className={styles.AddUserModule}>
          {/* NAV MENU*/}
          <div className={styles.LeftContent}>
            <ul
              className={
                navSelected === "addUser" || (pathName[pathName.length - 1]) === "addUser" || (pathName.includes("successPage"))
                  ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("addUser");
                // history.push("/ctmp/content/create/new-content");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/addUser`}
              >
                {pathName[3] === "edit-user" ? "Edit User" : "Add User"}
              </NavLink>
            </ul>

            <ul
              className={
                navSelected === "listUsers"
                  ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("listUsers");
                // history.push("/ctmp/content/create/upload-copy");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/listUsers`}
              >
                List Users
              </NavLink>
            </ul>

            {/* <ul
              className={
                navSelected === "manageRoles"
                  ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("manageRoles");
                // history.push("/ctmp/content/create/upload-copy");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/manageRoles`}
              >
              Manage Roles
              </NavLink>
            </ul> */}

            <ul
              className={
                navSelected === "manageTeams"
                  ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("manageTeams");
                // history.push("/ctmp/content/create/upload-copy");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/manageTeams`}
              >
                Manage Teams
              </NavLink>
            </ul>

          </div>
          {/* RIGHT MENU*/}
          <div className={styles.RightContent}>

            <React.Fragment>
              <ScrollToTop />

              <Switch>
                <Route exact path={`${url}`}>
                  <Redirect to={`${url}/addUser`} />
                </Route>

                <Route path={`${url}/addUser`}>
                  <AddUser props={props.userId} />
                </Route>

                <Route path={`${url}/listUsers`}>
                  <ListUsers props={props.contentId} />
                </Route>
                <Route path={`${url}/manageRoles`}>
                  <ManageRole props={"addRole"} />
                </Route>
                <Route path={`${url}/manageTeams`}>
                  <ManageTeam props={"addTeam"} />
                </Route>

              </Switch>
            </React.Fragment>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default CreateUser;
