import React, { Fragment, useMemo } from "react";
import { useHistory } from "react-router-dom";

import styles from "./CurrentPathDisplay.module.css";

const CurrentPathDisplay = ({ className, path }) => {
    const history = useHistory();
    const pathName = useMemo(() => path || history.location.pathname, [path, history]);

    return <div className={ [className, styles.CurrentPathDisplayContainer].join(" ") }>
        { pathName.substring(1).split("/").map(
            path => <Fragment key={ path }>
                <span>{ path }</span>
                <span>/</span>
            </Fragment>) }
    </div>;
};

export default CurrentPathDisplay;