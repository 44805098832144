import { format } from "date-fns";


export const mapCustomer = (serverResponse) => [...serverResponse.map(
    item => ({
        action: {
            status: item.status,
            id: item.id,
            name: item.name,
            associatedCampaigns: item.associatedCampaigns,
            type: item.customerListType
        },
        customerListDetails: {
            name: item.name,
            description: item.shortDescription,
            createdDate: item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy")
        },
        // associatedCampaigns: item.associatedCampaigns.map(c => c.campaignName),
        lastUpdated: item.modifiedDate && format(new Date(item.modifiedDate), "MM/dd/yyyy"),
        associatedCampaigns: item.associatedCampaigns,
        creator: item.createdBy,
        percentOfVINs: item.percentOfVINs,
        numberOfVINs: item.numberOfVINs,
        goLiveDate: item.goLiveDate || "NA",
        version: `v${item.version}`,
        status: item.status,
        id: item.id
    }))];