/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./CreateCopy.module.css";
import PageContent from "../../General/PageContent/PageContent";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../../hooks/ScrollToTop";
import { NavLink } from "react-router-dom";
import CreateNewCopy from "./CreateNewCopy";
import CloneCopy from "./CloneCopy";

const CreateCopy = (props) => {
    //   const jwt = useAppSelector((state) => state.authReducer.jwt);
    const history = useHistory();
    let { path, url } = useRouteMatch();
    const pathName = history.location.pathname.split("/");
    console.log(pathName[pathName.length - 1]);
    const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);
    const [editCopy, setEditCopy] = useState(false);
    const [viewCopy, setViewCopy] = useState(false);

    //set view and edit copy based on path
    useEffect(() => {
        if (props) {
            if (pathName[3] === "edit-copy") {
                setEditCopy(true);
            } else if (pathName[3] === "view-copy") {
                setViewCopy(true);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <div className={styles.CreateContent}>
                {props && editCopy ? (
                    <TitleWithPathHeader
                        title="Edit Copy"
                        path={" Manage Copy / Edit Copy"}
                    />
                ) : props && viewCopy ? (
                    <TitleWithPathHeader
                        title="View Copy"
                        path={" Manage Copy / View Copy"}
                    />
                ) : (
                    <TitleWithPathHeader
                        title="Create Copy"
                        path={" Manage Copy / Create Copy"}
                    />
                )}
                {editCopy === true ?
                    <div className={styles.CreateContentModule1}>
                        <div className={styles.RightContentEditCopy}><Route
                            render={() => (
                                <CreateNewCopy props={props.contentId} />
                            )}
                        /> </div></div> :
                    viewCopy === true ?
                        <div className={styles.CreateContentModule1}>
                            <div className={styles.RightContentEditCopy}><Route
                                render={() => (
                                    <CreateNewCopy props={props.contentId} />
                                )}
                            /> </div></div>
                        :
                        <div className={pathName[5] !== "creative-brief" ? styles.CreateContentModule : styles.CreateContentModule}>
                            {/* NAV MENU*/}
                            <div className={styles.LeftContent}>
                                <ul
                                    className={
                                        navSelected === "upload-copy" ||
                                            navSelected === "create-copy" ||
                                            navSelected === "edit-copy" ||
                                            navSelected === props.contentId
                                            ? styles.NavItemSelected
                                            : styles.NavItem
                                    }
                                    onClick={() => {
                                        setNavSelected("upload-copy");
                                        // history.push("/ctmp/content/create/new-content");
                                    }}
                                >
                                    <NavLink
                                        style={{ color: "black", textDecoration: "none" }}
                                        to={`${url}/upload-copy`}
                                    >
                                        Upload Copy
                                    </NavLink>
                                </ul>
                                <ul
                                    className={
                                        navSelected === "clone-copy"
                                            ? styles.NavItemSelected
                                            : styles.NavItem
                                    }
                                    onClick={() => {
                                        setNavSelected("clone-copy");
                                        // history.push(
                                        //   "/ctmp/content/create-content/clone-published-copy"
                                        // );
                                    }}
                                >
                                    <NavLink
                                        style={{ color: "black", textDecoration: "none" }}
                                        to={`${url}/clone-copy`}
                                    >
                                        Clone Copy
                                    </NavLink>
                                </ul>
                            </div>
                            {/* RIGHT MENU*/}
                            <div className={styles.RightContent}>
                                <div className={styles.CreateCustomerContent}>
                                    <React.Fragment>
                                        <ScrollToTop />

                                        <Switch>
                                            <Route exact path={`${url}`}>
                                                <Redirect to={`${url}/upload-copy`} />
                                            </Route>

                                            <Route path={`${url}/upload-copy`}>
                                                <CreateNewCopy props={props.contentId} />
                                            </Route>

                                            <Route path={`${url}/clone-copy`}>
                                                <CloneCopy />
                                            </Route>

                                            {/*<Route path={`${url}/creative-brief`}>
                                        <ContentCreativeBrief props={props.contentId} />
                        </Route>

                                    <Route path={`${url}/upload-copy`}><UploadCopy /></Route>
                                    <Route path={`${url}/unassigned-copy`}><UnassignedCopy /></Route>
                                    <Route path={`${url}/clone-published-copy`}>
                                        <ClonePublishedCopy />
                                    </Route> */}
                                        </Switch>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </React.Fragment>
    );
};

export default CreateCopy;
