import styles from "./PublishDialog.module.css";
import {ReactComponent as CloseX} from "../../../../../assets/icons/Close.svg";
import {ReactComponent as WarningTriangle} from "../../../../../assets/icons/warning-triangle.svg";
import SaveAndDiscardButtonSet
    from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";


type PublishDialogProps = {
    className?: string
    bodyText?: string
    headerText?: string
    yesAction: () => void
    noAction: () => void
    itemsToBeComplete: string[]
}

const PublishDialog = ({className, yesAction, noAction, bodyText, headerText, itemsToBeComplete}: PublishDialogProps) => {
    if(headerText === "No_Permissions"){
       return <div className={[className, styles.Container].join(" ")}>
        <div className={styles.HeaderPermission}>
        {(headerText === "No_Permissions") && <p>Note</p>}
        <CloseX onClick={noAction} className={styles.CloseIcon}/>
        </div>
        <div className={styles.BodyPermission}>
            <WarningTriangle />
            {(bodyText !== "" || bodyText !== null || bodyText !== undefined) && <p>Details to be verified before publishing</p>}
        </div>
       
        </div>
    }else{
    return <div className={[className, styles.Container].join(" ")}>
        <div className={styles.Header}>
            {(headerText !== "" || headerText !== null || headerText !== undefined) && <p>{headerText}</p>}
            {(headerText === "" || headerText === null || headerText === undefined) && <p><span>Discard Message</span></p>}
          
            <CloseX onClick={noAction} className={styles.CloseIcon}/>
        </div>
      
            {(itemsToBeComplete.length > 0) && <div className={styles.BodyItems}><p>Required Fields To Be Entered</p>
                <ul>
                    {itemsToBeComplete.map(value => <li
                        key={value}>{value}</li>)}
                </ul>
                </div>
                }
               
           
        <div className={styles.Body}>
            <WarningTriangle />
            {(bodyText !== "" || bodyText !== null || bodyText !== undefined) && <p>{bodyText}</p>}
            {(bodyText === "" || bodyText === null || bodyText === undefined) && <p>Are you sure you want to discard the changes without saving?</p>}
        </div>
        <SaveAndDiscardButtonSet yesAction={yesAction}
                                 noAction={noAction}
                                 status={itemsToBeComplete.length}
                                 className={styles.Buttons}/>
    </div>;
    }
};

export default PublishDialog;