import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store";
/* eslint-disable  @typescript-eslint/no-unused-vars */

import styles from "./VersionControl.module.css";
import VersionControlHeader from "./VersionControlHeader/VersionControlHeader";
import VersionControlTableHeader from "./VersionControlTableHeader/VersionControlTableHeader";
import VersionControlTable from "./VersionControlTable/VersionControlTable";
import VersionTemplateDetails from "./VersionTemplateDetails/VersionTemplateDetails";

import { Route, Switch } from "react-router-dom";
import VersionPreview from "./VersionPreview/VersionPreview";

const VersionControl = ({ match }) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  // const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);

  const [totalItems, setItems] = useState([]);
  const [reload, setReloadState] = useState(false);
  const campaignId = match.params.id;
  // console.log(match)
  useEffect(() => {
    (async () => {
      // let url = new URL(
      //   `https://mktpl.dev.telematicsct.com/v1/campaigns/${match.params.id}/versions`
      // );
      let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${match.params.id}/versions`);
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          // "user-name": userName,
          // "user-email": email
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse
        .json()
        .then((response) => {
          setItems(response);
        });
    })();
  }, [reload]);

  return (
    <React.Fragment>
      <Switch>
        <Route
          path={match.url + "/preview/:versionNumber/details"}
          render={({ match }) => (
            <React.Fragment>
              <VersionControlHeader title={"Template Details"} breadcrumb={" manage campaign / version control / template details"} />
              <div className={styles.VersionControlModule}>
                <VersionTemplateDetails data={totalItems} match={match} id={campaignId} />
              </div>
            </React.Fragment>
          )}
        />

        <Route
          path={match.url + "/preview/:versionNumber"}
          render={({ match }) => (
            <React.Fragment>
              <VersionControlHeader title={"Version Control"} breadcrumb={" manage campaign / version control"} />
              <div className={styles.VersionControlModule}>
                <VersionPreview data={totalItems} match={match} />
              </div>
            </React.Fragment>
          )}
        />

        <Route
          exact
          path={match.url + "/"}
          render={() => (
            <React.Fragment>
              <VersionControlHeader title={"Version Control"} breadcrumb={" manage campagin / version control"} />
              <div className={styles.VersionControlModule}>
                <VersionControlTableHeader data={totalItems} />
                <VersionControlTable
                  data={totalItems}
                  reload={setReloadState}
                />
              </div>
            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

export default VersionControl;
