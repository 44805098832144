/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../context";
import { useHistory } from "react-router-dom";
import BackButton from "../../General/Button/Button";
import LabeledFileUploadBar from "../../General/LabeledFileUploadBar/LabeledFileUploadBar";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import PopupDialog from "../../General/PopupDialog/PopupDialog";
import SaveDialog from "../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog";
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/Message-square.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as NewTag } from "../../../assets/icons/new-tag.svg";
import { ReactComponent as Volume } from "../../../assets/icons/volume.svg";
import styles from "./CreateTemplate.module.css";
import DiscardDialog from "../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog";
import { useAppSelector } from "../../../redux/store";
import MultiPicker from "../../General/MultiPicker/MultiPicker";
import LoadSpinner from "../../General/LoadSpinner/LoadSpinner";
import JSZip from "jszip";
import LabeledSelect from "../../General/LabeledSelect/LabeledSelect";
import CreateSubCategory from "../../ManageCampaign/CreateCampaign/CreateSubCategory";
import Select from "react-select";

const UploadTemplateForm = (id) => {
  const history = useHistory();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [templateName, setTemplateName] = useState();
  const [templateDescription, setTemplateDescription] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [zipFile, setZipFile] = useState();
  const [editZipFileName, setEditZipFileName] = useState();
  const [createTemplateObject, setCreateTemplateObject] = useState({});
  const [viewTemplate, setViewTemplate] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState();
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const userName = useAppSelector((state) => state.authReducer.userName);
  const [s3ZipFileKey, sets3ZipFileKey] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const { value, capability } = useContext(Context);
  const [setContext] = value;
  const formData = new FormData();
  const pathName = history.location.pathname.split("/");
  const [isLoaded, setIsLoaded] = useState(false);
  const [resetFilename, setResetFileName] = useState();
  const [brand, setBrand] = useState();
  const [subCategory, setSubCategory] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [category, setCategory] = useState();
  const [region, setRegion] = useState({label: "US", value:"1"});

  /*get Values from database when user clicks edit in manageTemplate*/
  useEffect(() => {
    if (id.props !== undefined) {
      const getTemplateDetails = async () => {
        setIsLoaded(true)
        const url = new URL(
          `${process.env.REACT_APP_API_HOSTNAME}v1/template/${id.props}`
        );
        const callParams = {
          method: "GET",
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        };
        const serverResponse = await fetch(url.toString(), callParams);
        const serverResponseJson = serverResponse.json().then((response) => {
          const res = response.templates[0];
          console.log(JSON.stringify(res));
          setTemplateName(res.name);
          setBrand({ label: res.brand, value: res.brand });
          setRegion({ label: res.regionName, value: res.regionId });
          setPreviewImageUrl(res.previewImageUrl);
          setTemplateDescription(res.description);
          setEditZipFileName(res.templateFileName);
          sets3ZipFileKey(res.s3ZipFileKey);
          setCategory({ label: res.category.name, value: res.category.id });
          if (res.category.subCategories[0].label !== "" && res.category.subCategories.length > 0) {
            setSubCategory({
              label: res.category.subCategories[0].displayName,
              value: res.category.subCategories[0].id,
              displayName: res.category.subCategories[0].name
            });
          };
          res.channel.map((channel) => {
            setSelectedChannels((selectedChannels) => [
              ...selectedChannels,
              channel.name
            ]);
          });
        });
        setIsLoaded(false)
      };
      getTemplateDetails();
      if (id.actionValue === "view-template") {
        localStorage.setItem("blockNavigation", "false")
        setViewTemplate(true);
      } else {
        setViewTemplate(false);
      }
    }
  }, []);

  /* to check everything is entered or not */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !templateName
      ? [...toBeCompletedArray, "Name"]
      : toBeCompletedArray;
    toBeCompletedArray = !templateDescription
      ? [...toBeCompletedArray, "Description"]
      : toBeCompletedArray;
    toBeCompletedArray = !brand
      ? [...toBeCompletedArray, "Brand"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !selectedChannels || !selectedChannels.length
        ? [...toBeCompletedArray, "Channel"]
        : toBeCompletedArray;
    toBeCompletedArray =
      !zipFile && !editZipFileName
        ? [...toBeCompletedArray, "Load Zip File"]
        : toBeCompletedArray;
    toBeCompletedArray = !category
      ? [...toBeCompletedArray, "Category"]
      : toBeCompletedArray;
    toBeCompletedArray = !subCategory
      ? [...toBeCompletedArray, "Sub Categories"]
      : toBeCompletedArray;
    toBeCompletedArray = !region
      ? [...toBeCompletedArray, "Region"]
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [templateName, templateDescription, brand, selectedChannels, zipFile, category, subCategory, region]);

  /* update the values when changed */
  useEffect(() => {
    setCreateTemplateObject({
      name: templateName,
      description: templateDescription,
      brand: brand,
      regionId: region.value,
      regionName: region.label,
      status: "Live",
      ...(selectedChannels && {
        channel: selectedChannels.map((c) => ({
          name: c,
          id: 0
        }))
      }),
      previewImageUrl: previewImageUrl,
      ...((category && {
        category: {
          id: category.value,
          name: category.label,
          ...((subCategory && {
            subCategories: [
              {
                id: subCategory.value,
                name: subCategory.displayName,
                displayName: subCategory.label
              }
            ]
          }))
        }
      })),
      createdBy: userName,
      templatezip: zipFile
    });
  }, [templateName, templateDescription, brand, region, selectedChannels, zipFile, previewImageUrl, category, subCategory]);

  /*save to LocalObject and push to backend*/
  const saveTemplateDetails = async () => {
    setIsLoaded(true);
    let url, methodValue;
    localStorage.setItem("blockNavigation", "false")
    localStorage.removeItem("templateObject");
    localStorage.setItem(
      "templateObject",
      JSON.stringify(createTemplateObject)
    );

    formData.delete("name");
    formData.delete("description");
    formData.delete("brand");
    formData.delete("regionId");
    formData.delete("regionName");
    formData.delete("status");
    formData.delete("channel");
    formData.delete("createdBy");
    formData.delete("templatezip");
    formData.delete("fileDeleteFlag")

    //append data to formdata (using formdata we can send data including files)
    formData.append("name", templateName);
    formData.append("description", templateDescription);
    formData.append("brand", brand.label);
    formData.append("regionId", region.value);
    formData.append("regionName", region.label);
    formData.append("status", "Draft");
    formData.append("fileDeleteFlag", deleteFlag)
    formData.append(
      "channel",
      JSON.stringify(
        selectedChannels.map((c) => ({
          name: c,
          id: 0
        }))
      )
    );
    formData.append(`category[id]`, (createTemplateObject.category.id))
    formData.append(`category[name]`, (createTemplateObject.category.name))
    formData.append(`category[subCategories][${0}][id]`, (createTemplateObject.category.subCategories[0].id))
    formData.append(`category[subCategories][${0}][name]`, (createTemplateObject.category.subCategories[0].name))
    formData.append(`category[subCategories][${0}][displayName]`, (createTemplateObject.category.subCategories[0].displayName))

    //formData.append('previewImageUrl', previewImageUrl);
    id.actionValue === "edit-template" ? "" : formData.append("createdBy", userName)
    zipFile !== undefined ?
      formData.append("templatezip", zipFile) :
      "";

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    createTemplateObject.status = "Draft";
    id.actionValue === "edit-template"
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/${id.props}`
      )),
        (methodValue = "PUT"))
      : ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/upload`
      )),
        (methodValue = "POST"));

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        //"Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      //body: JSON.stringify(createTemplateObject)
      body: formData
    };

    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log("serverResponseJson status - " + serverResponseJson.status);
    return serverResponse.status;
  };

  /*publish data to backend */
  /* const publishDetails = async () => {
    let url, methodValue;
    createTemplateObject.status = "Live";
    id.actionValue === "edit-template"
      ? ((url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/template/${id.props}`
      )),
        (methodValue = "PUT"))
      : ((url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/template`)),
        (methodValue = "POST"));

    let callParams = {
      method: methodValue,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(createTemplateObject)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
    console.log(serverResponseJson.status);
    return serverResponse.status;
  }; */

  //send templatedetails zip file
  /* const uploadTemplateDetails = async () => {
    formData.append("templatezip", zipFile);
    console.log("formData ", formData);
    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/template/upload`
    );
    //const url = new URL(`http://localhost:7100/v1/template/upload`); //test locally

    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    };

    const serverResponse = await fetch(url.toString(), callParams);
    console.log("status - " + serverResponse.status);
  }; */

  //delete File
  /* const deleteCopyFile = async () => {
    let templateobj = {
      templateId: id.props
    };
    // 'https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=templates/1646372839874/test.zip'
    const url = new URL(
      `${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${s3ZipFileKey}`
    );
    const callParams = {
      method: "DELETE",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(templateobj)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const mystatus = await serverResponse.json();
    console.log("here" + mystatus);
    return mystatus;
  } */

  //validate zip file
  const isValidZipFile = async (file) => {
    let blsValidZip = true;
    //limit file size to 10mb
    const sizeinmb = (file.size / (1024 * 1024)).toFixed(3);
    console.log("sizeinmb - ", sizeinmb);
    if (sizeinmb >= 10) {
      blsValidZip = false;
      console.log(file + " File too Big, please select a file less than 10mb");
      setContext("");
      alert("File too Big, please select a file less than 10mb");
      return blsValidZip;
    }

    //Zip file should contain only HMTL, CSS and images
    let jZip = new JSZip();
    let validFileExtensions = ["jpg", "jpeg", "png", "gif", "html", "css", "mp4", "txt"];
    let mandatoryFileExtensions = ["html", "txt", "jpeg", "png"];
    let mandatoryFilesCount = 0;
    let imageCount = 0;
    jZip.loadAsync(file).then(function (zip) {
      Object.keys(zip.files).forEach(function (filename) {
        console.log("file name " + filename);
        let fileExtension = filename.split(".")[1];
        if (fileExtension !== undefined) {
          //should be a file not folder
          let bIsValidFile = false;
          for (let j = 0; j < validFileExtensions.length; j++) {
            let curExtension = validFileExtensions[j];
            if (fileExtension.toLowerCase() == curExtension.toLowerCase()) {
              bIsValidFile = true;
              break;
            }
          }
          if (!bIsValidFile) {
            blsValidZip = false;
            // alert("Zip file should contain only HMTL, CSS and images");
            setResetFileName("true")
            return blsValidZip;
          }
          //check for mandatory files (.html,.txt and .png/jpeg)
          for (let j = 0; j < mandatoryFileExtensions.length; j++) {
            let curExtension = mandatoryFileExtensions[j];
            if (fileExtension.toLowerCase() == curExtension.toLowerCase()) {
              if (fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "png") {
                imageCount++;
              } else {
                mandatoryFilesCount++;
              }
              break;
            }
          }
        }
      });

      //mandatory Files Count should be 2, else invalid
      if (mandatoryFilesCount !== 2 || imageCount !== 1) {
        blsValidZip = false;
        setResetFileName("true")
        setZipFile("");
        //alert("Zip file should contain html,txt, png/jpeg files");
        return blsValidZip;
      }

      //set zipfile for valid zip
      if (blsValidZip) {
        setZipFile(file);
        setResetFileName("")
        console.log("file content - ", file);
      }
    });
    return blsValidZip;
  };

  //block if the template is in edit mode
  useEffect(() => {
    if (id.props) {
      if (id.actionValue === "view-template") {
        localStorage.setItem("blockNavigation", "false");
      } else {
        localStorage.setItem("blockNavigation", "true");
      }
    }
  }, [createTemplateObject]);

  //downloadFile
  const downloadFile = async () => {
    fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${s3ZipFileKey}`, {
      method: 'GET',
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`

      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          // `FileName.zip`,
          `${editZipFileName}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  /*reset when flag is true */
  useEffect(() => {
    if (id.props !== undefined) {
      if (deleteFlag === true) {
        setZipFile("")
        setEditZipFileName("")
      } else {
        console.log(zipFile + "" + editZipFileName)
      }
    } else { console.log("create template") }

  }, [deleteFlag])

  useEffect(() => {
    if (subCategoryList === null || subCategoryList.length === 0) {
      setSubCategory(null)
      handleChange(null)
    }
  }, [subCategoryList])

  const handleChange = (value) => {
    if (value === "" || value === null || value === undefined) {
      setSubCategory(null)
    } else {
      setSubCategory(value)
    }
  };

  /*get subcategories */
  const getSubcategories = async (categoryname) => {
    setSubCategoryList([]);
    if (categoryname === "") {
      setSubCategory(null)
    } else {
      const url = new URL(
        `${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/subcategories?category=${categoryname}`
      );

      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((response) => {

        if (response !== null && response.length !== 0) {
          response.map((result) => {
            setSubCategoryList((subCategoryList) => [
              ...subCategoryList,
              { label: result.displayName, value: result.id, displayName: result.name }
            ]);
          })
        } else {
          setSubCategoryList((subCategoryList) => [
            ...subCategoryList,
            { label: "", value: "", displayName: "" }
          ]);
        }
      })
    }
  }

  return (
    <div style={{ textAlign: "left" }}>
      {(isLoaded === true) ? <LoadSpinner /> : ""}
      <p className={styles.UpdateFormHeader}>
        {pathName[3] === "edit-template" ? "Edit an existing template" :
          pathName[3] === "view-template" ? "View an existing template" :
            "New template setup"}
      </p>
      <span className={styles.UpdateFormSubTitle}>
        If necessary, redefine which channel this template will be used for.<br />
        You can also change the name and description of the template and replace the existing html bundle.
      </span>

      <div
        className={
          id.actionValue === "view-template"
            ? styles.disableContainer
            : styles.ChannelContainer
        }
      >
        <label>
          Channels<span> *</span>
        </label>
        <MultiPicker
          setSelected={(selected) => setSelectedChannels(selected)}
          previousSelected={selectedChannels}
          status="Template"
          mode={id.actionValue}
          data={[
            {
              id: 1,
              icon: MailIcon,
              title: "Email",
              description:
                "Choose a template and send automated transactional or marketing lite emails to the selected audience."
            },
            {
              icon: MessageIcon,
              title: "Push Notification",
              description:
                "Choose a template and send automated transactional or marketing lite push notifications to the selected audience."
            },
            {
              icon: Phone,
              title: "CT Marketing Card",
              description:
                "Choose a template and send automated transactional or marketing lite OneApp Cards to the selected audience."
            },
            {
              icon: NewTag,
              title: "General Marketing Card",
              description:
                "Choose a template and send automated transactional or marketing lite cards to the selected audience."
            },
            {
              icon: Volume,
              title: "Announcement Center",
              description:
                "Choose a template and send automated transactional or marketing lite announcements to the selected audience."
            }
          ]}
        />
      </div>
      
      <LabeledInput
        component_status={viewTemplate}
        title={<>Name<span> *</span></>}
        data={templateName}
        placeholder="Type the template name"
        className={
          id.actionValue === "view-template"
            ? styles.disabledInput
            : styles.Input
        }
        onValueChange={(value) => setTemplateName(value)}
      />
      <LabeledInput
        component_status={viewTemplate}
        title={<>Description<span> *</span></>}
        placeholder="Type the description"
        className={
          id.actionValue === "view-template"
            ? styles.disabledInput
            : styles.Input
        }
        data={templateDescription}
        //   data={null}
        onValueChange={(value) => setTemplateDescription(value)}
      />

      <div className={id.actionValue === "view-template" ? styles.disableContainer : ""} >
        <LabeledSelect
          optionsUrl="v1/ctmp/brand"
          defaultLabel={brand ? brand.label : undefined}
          defaultId={brand ? brand.value : undefined}
          title={
            <>
              Brand<span> *</span>
            </>
          }
          onValueChange={(option) => setBrand(option)}
        />
      </div>

      <div className={id.actionValue === "view-template" ? styles.disableContainer : styles.disablePointerEvents} >
        <LabeledSelect
          defaultLabel={region ? region.label : undefined}
          defaultId={region ? region.value : undefined}
          title={
            <>
              Region<span> *</span>
            </>
          }
          onValueChange={(option) => setRegion(option)}
        />
      </div>

      <div className={id.actionValue === "view-template" ? styles.disablePointerEvents : ""} >
        <CreateSubCategory title={
          <>
            + Add Sub Category
          </>}>
        </CreateSubCategory>
      </div>

      <div className={styles.CategorySubCategoryCombo}>
        <LabeledSelect
          className={
            id.actionValue === "view-template"
            ? styles.DisableCategory
            : styles.Select
          }
          optionsUrl="v1/ctmp/category"
          title={
            <>
              Select Category<span> *</span>
            </>
          }
          defaultLabel={category ? category.label : undefined}
          defaultId={category ? category.value : undefined}
          onValueChange={async (option) => {
            setCategory(option);
            if (option === null) {
              setSubCategory(null)
              await getSubcategories(null)
            } else {
              const status = await getSubcategories(option.value)
            }
          }}
        />
        <div style={{ display: "flex", flexDirection: "column", width: "45%", color: "black" }}>
          <label className={id.actionValue === "view-template" ? styles.DisableSubcat : ""}>Select Sub Category<span> *</span></label>
          <Select
            className={
              id.actionValue === "view-template"
            ? styles.DisableSubcat
            : styles.SubCategoryDropDown
            }
            value={subCategory}
            onChange={(option) => handleChange(option)}
            isClearable
            options={subCategoryList}
          >
          </Select>
        </div>
      </div>

      <div className={styles.ParentContainer}>
        <div className={styles.child1}>
          {id.props !== undefined && !deleteFlag ?
            <div>
              <label>
                Template File Bundle
                <br />
                {id.props !== undefined ? (
                  <a
                    //href='https://mktpl.dev.telematicsct.com/v1/template/zip/file?fileKey=assets/marketing-platform/emailTemplate/832314109865/images/test.zip'
                    className={id.actionValue === "view-template" ? styles.TemplateDeleteDownload : styles.TemplateDeleteDownload}
                    onClick={downloadFile}

                  >
                    {editZipFileName}
                  </a>
                ) : (
                  ""
                )}
              </label>
            </div> : ""}
        </div>

        <div className={styles.child1}>
          <div style={{ paddingTop: "3.3rem" }}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <SaveDialog
                  saveAction={async () => {
                    setDeleteFlag(true);
                    toast.success(
                      <Msg id="delete" templateName={templateName} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                    setContext();
                    closeModal();

                  }}
                  discardAction={closeModal}
                  className="DeleteCopyFile"
                  itemsToBeComplete={toBeCompleted}
                />
              )}
              renderControlContent={(openModal) => (
                id.actionValue !== "view-template" ?
                  id.props !== undefined && !deleteFlag ?
                    <BackButton
                      onClick={openModal}
                      name="Delete Copy File" /> : "" : ""
              )}
            />
          </div>
        </div>
      </div>

      <div
        className={
          id.actionValue === "view-template"
            ? styles.disableUploadWidth
            : ""
        }
      >
        <div style={{ marginBottom: "0.2rem" }}>
          {(editZipFileName === "" || editZipFileName === undefined) ?
            <div>
              <LabeledFileUploadBar
                title={<>Upload new template (HMTL,images and CSS zip file)<span> *</span></>}
                newUpload="no"
                onUploadClick={console.log("")}
                uploadType="uploadTemplate"
                resetFile={resetFilename}
                uploadedFile={(value) => isValidZipFile(value)}
              //uploadedFile={setZipFile}
              /></div> :
            <div style={{ opacity: "0.7", pointerEvents: "none" }}>
              <LabeledFileUploadBar
                title={<>Upload new template (HMTL,images and CSS zip file)<span> *</span></>}
                newUpload="no"
                onUploadClick={console.log("")}
                uploadType="uploadTemplate"
                resetFile={resetFilename}
                uploadedFile={(value) => isValidZipFile(value)}
              //uploadedFile={setZipFile}
              /></div>}
          {resetFilename === "true" ? <div style={{ color: "red", fontSize: "1.4rem" }}>Zip file should contain html,txt, png/jpeg files</div> : ""}
        </div>
      </div>

      {/* Buttons at bottom of the page */}
      {id.actionValue === "edit-template" && (
        <div className={styles.Container}>
          <div className={styles.floatcontainer}>
            {/* Discard */}
            <div className={styles.floatchildButton}>
              <PopupDialog
                height="30rem"
                width="67rem"
                renderPopupContent={(closeModal) => (
                  <DiscardDialog
                    yesAction={() => {
                      setContext();
                      history.push("/ctmp/template");
                      toast.success(
                        <Msg id="discard" templateName={templateName} />,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Success />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      );
                    }}
                    noAction={closeModal}
                  />
                )}
                renderControlContent={(openModal) => (
                  <BackButton onClick={openModal} name="Discard">
                    Discard
                  </BackButton>
                )}
              />
            </div>
            <div className={styles.floatchild}>
              <div className={styles.floatchild2}>
                {/* Save & Close */}
                <div className={styles.saveClose}>
                  {toBeCompleted.length === 0 ? (
                    <div>
                      <BackButton
                        onClick={async () => {
                          const statusVar = await saveTemplateDetails();
                          localStorage.setItem("blockNavigation", "false");
                          setIsLoaded(false);
                          history.push("/ctmp/template");
                          statusVar !== 200 &&
                            toast.error(
                              <ErrorMsg
                                id="save"
                                templateName={templateName}
                              />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          statusVar === 200 &&
                            toast.success(
                              <Msg id="save" templateName={templateName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                        }}
                        name="TemplateSaveClose"
                      />
                    </div>
                  ) : (
                    <PopupDialog
                      height="30rem"
                      width="67rem"
                      renderPopupContent={(closeModal) => (
                        <SaveDialog
                          saveAction={async () => {
                            const statusVar = await saveTemplateDetails();
                            localStorage.setItem("blockNavigation", "false");
                            setIsLoaded(false);
                            closeModal();
                            history.push("/ctmp/template");
                            statusVar !== 200 &&
                              toast.error(
                                <ErrorMsg
                                  id="save"
                                  templateName={templateName}
                                />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Error />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                            statusVar === 200 &&
                              toast.success(
                                <Msg id="save" templateName={templateName} />,
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                  icon: <Success />,
                                  transition: Slide,
                                  closeOnClick: true
                                }
                              );
                          }}
                          discardAction={closeModal}
                          itemsToBeComplete={toBeCompleted}
                        />
                      )}
                      renderControlContent={(openModal) => (
                        <BackButton
                          onClick={openModal}
                          name="TemplateSaveClose"
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              {/* Save */}
              <div className={styles.floatchild1}>
                {toBeCompleted.length === 0 ? (
                  <div>
                    <BackButton
                      onClick={async () => {
                        const statusVar = await saveTemplateDetails();
                        localStorage.setItem("blockNavigation", "false");
                        setIsLoaded(false);
                        statusVar !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" templateName={templateName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        statusVar === 200 &&
                          toast.success(
                            <Msg id="save" templateName={templateName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                      }}
                      name="TemplateSave"
                    />
                  </div>
                ) : (
                  <PopupDialog
                    height="30rem"
                    width="67rem"
                    renderPopupContent={(closeModal) => (
                      <SaveDialog
                        saveAction={async () => {
                          const statusVar = await saveTemplateDetails();
                          localStorage.setItem("blockNavigation", "false");
                          setIsLoaded(false);
                          closeModal();
                          statusVar !== 200 &&
                            toast.error(
                              <ErrorMsg
                                id="save"
                                templateName={templateName}
                              />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Error />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                          statusVar === 200 &&
                            toast.success(
                              <Msg id="save" templateName={templateName} />,
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                                icon: <Success />,
                                transition: Slide,
                                closeOnClick: true
                              }
                            );
                        }}
                        discardAction={closeModal}
                        itemsToBeComplete={toBeCompleted}
                      />
                    )}
                    renderControlContent={(openModal) => (
                      <BackButton onClick={openModal} name="TemplateSave" />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {id.actionValue === "view-template" && (
        <div>
          <BackButton
            onClick={() => {
              localStorage.setItem("blockNavigation", "false");
              history.push("/ctmp/template");
            }}
            name="Back"
          />
        </div>
      )}
      {id.actionValue === "create-template" && (
        <div className={styles.floatcontainer_create}>
          {/* <div className={styles.floatchildButton}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    setContext();
                    history.push("/ctmp/template");
                    toast.success(
                      <Msg id="discard" templateName={templateName} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                  }}
                  noAction={closeModal}
                />
              )}
              renderControlContent={(openModal) => (
                <BackButton onClick={openModal} name="Discard">
                  Discard
                </BackButton>
              )}
            />
          </div> */}
          {/* save */}
          <div className={styles.floatchild}>
            <PopupDialog
              height="30rem"
              width="67rem"
              renderPopupContent={(closeModal) => (
                <DiscardDialog
                  yesAction={() => {
                    setContext();
                    history.push("/ctmp/template");
                    toast.success(
                      <Msg id="discard" templateName={templateName} />,
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                        icon: <Success />,
                        transition: Slide,
                        closeOnClick: true
                      }
                    );
                  }}
                  noAction={closeModal}
                />
              )}
              renderControlContent={(openModal) => (
                <BackButton onClick={openModal} name="DiscardCreateTemplate">
                  Discard
                </BackButton>
              )}
            /></div>
          <div className={styles.floatchild}>
            <div className={styles.save}>
              {toBeCompleted.length === 0 ? (
                <div>
                  <BackButton
                    onClick={async () => {
                      const statusVar = await saveTemplateDetails();
                      localStorage.setItem("blockNavigation", "false");
                      history.push("/ctmp/template");
                      setIsLoaded(false);
                      statusVar !== 200 &&
                        toast.error(
                          <ErrorMsg id="save" templateName={templateName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Error />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                      statusVar === 200 &&
                        toast.success(
                          <Msg id="save" templateName={templateName} />,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                            icon: <Success />,
                            transition: Slide,
                            closeOnClick: true
                          }
                        );
                    }}
                    name="TemplateSave"
                  />
                </div>
              ) : (
                <PopupDialog
                  height="30rem"
                  width="67rem"
                  renderPopupContent={(closeModal) => (
                    <SaveDialog
                      saveAction={async () => {
                        const statusVar = await saveTemplateDetails();
                        localStorage.setItem("blockNavigation", "false");
                        closeModal();
                        setIsLoaded(false);
                        history.push("/ctmp/template");
                        statusVar !== 200 &&
                          toast.error(
                            <ErrorMsg id="save" templateName={templateName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Error />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                        statusVar === 200 &&
                          toast.success(
                            <Msg id="save" templateName={templateName} />,
                            {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                              icon: <Success />,
                              transition: Slide,
                              closeOnClick: true
                            }
                          );
                      }}
                      discardAction={closeModal}
                      itemsToBeComplete={toBeCompleted}
                    />
                  )}
                  renderControlContent={(openModal) => (
                    <BackButton onClick={openModal} name="TemplateSave">
                      Save
                    </BackButton>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadTemplateForm;
