import React from "react";

import styles from "./LabeledDescriptionInput.module.css";

const LabeledDescriptionInput = ({
                                     title,
                                     textAreaPlaceholder,
                                     onValueChange,
                                     padding,
                                     data
                                 }) => {
        return <div className={ padding === undefined || padding === !false ? styles.Container : styles.ContainerNoPadding }>
                                    <label>{ title }</label>
        <textarea name="description" className={styles.containerTextArea}
        value={data ? data : undefined}
                  placeholder={ textAreaPlaceholder }
                  onChange={ event => onValueChange(event.target.value) }/>
    </div>;
};

export default LabeledDescriptionInput;