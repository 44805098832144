import React from "react";
import { useTable } from "react-table";

import styles from "./Table.module.css";

const Table = ({ columns, data }) => {
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({
        columns,
        data
    });
    return <div className={ styles.tableFixHead }>
        <table className={ styles.Table } { ...getTableProps() }>
            <thead>
            {
                headerGroups.map(headerGroup => (
                    <tr { ...headerGroup.getHeaderGroupProps() }>
                        {
                            headerGroup.headers.map(column => (
                                <th key={column} { ...column.getHeaderProps() }>{ column.render(
                                    "Header") }</th>
                            ))
                        }
                    </tr>
                ))
            }
            </thead>
            <tbody { ...getTableBodyProps() }>
            { rows.length > 0 ?
                rows.map((row, _i) => {
                    prepareRow(row);
                    return (
                        <tr { ...row.getRowProps() }>
                            {
                                row.cells.map(cell => {
                                    return <td key={cell} { ...cell.getCellProps() }>{ cell.render(
                                        "Cell") }</td>;
                                })
                            }
                        </tr>
                    );
                }) : <tr><td><span>No Records Found...</span></td></tr>
            }
            </tbody>

        </table>
    </div>;
};

export default Table;