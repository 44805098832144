import styles from "./Header.module.css";

type HeaderProps = {
    className?: string
    children?: JSX.Element
}

const Header = ({className, children}: HeaderProps) => {
    return <div className={[className, styles.Container].join(" ")}>
        {children}
    </div>;
};

export default Header;