import React, { useState, useEffect } from "react";
/* eslint-disable  @typescript-eslint/no-unused-vars */
import { useAppSelector } from '../../../../redux/store';
import { useHistory } from "react-router-dom";
import styles from "./VersionTemplateDetails.module.css";
import VersionControlTableHeader from "../VersionControlTableHeader/VersionControlTableHeader";
import TemplatePreviewCard from '../../../General/TemplatePreviewCard/TemplatePreviewCard';
import TemplateHistoryItem from './TemplateHistoryItem/TemplateHistoryItem';
import CustomerSegment from './CustomerSegment/CustomerSegment';
import Button from "../../../General/Button/Button";

const VersionTemplateDetails = (props) => {
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  // const userName = useAppSelector((state) => state.authReducer.userName);
  // const email = useAppSelector((state) => state.authReducer.email);
  const [showingTemplates, showTemplates] = useState(true);
  const [showingCustomerSegments, showCustomerSegments] = useState(false);
  const [campaignResponse, setResponse] = useState({});
  const history = useHistory();
  const match = props.match;
  // console.log(props.id)
  const templateNav = () => {
    showTemplates(true);
    showCustomerSegments(false)
  };

  const customerNav = () => {
    showCustomerSegments(true);
    showTemplates(false);
  }

  useEffect(() => {
    (async () => {
      // let url = new URL(
      //   `https://mktpl.dev.telematicsct.com/v1/campaigns/${props.id}/versions/${match.params.versionNumber}/`
      // );
      let url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/campaigns/${props.id}/versions/${match.params.versionNumber}/`);
      let callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
          // "user-name": userName,
          // "user-email": email
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = await serverResponse
        .json()
        .then((response) => {
          console.log(response);
          setResponse(response)
        });
    })();
  }, []);

  return (
    <React.Fragment>
      <VersionControlTableHeader data={props.data} />
      <div className={styles.Container}>
        <div className={styles.TableNav}>
          <div className={showingTemplates ? styles.NavItemSelected : styles.NavItem} onClick={templateNav}>Templates</div>
          <div className={showingCustomerSegments ? styles.NavItemSelected : styles.NavItem} onClick={customerNav}>Customer Segments</div>
        </div>
        {showingTemplates ?
          <div className={styles.TableRow}>
            <div className={styles.TableCol}>

              <div className={styles.PrimaryVersion}>
                <div className={styles.PrimaryLabel}>Version {campaignResponse.version}</div>
                <div className={styles.VersionDateTime}>
                  Version first created: {campaignResponse.modifiedDate}
                </div>
              </div>

              {/* Version TEMPLATE info */}
              {console.log(campaignResponse.template)}
              {campaignResponse.template && campaignResponse.template.length > 0
                ? campaignResponse.template.map((template, index) => (
                  <TemplateHistoryItem key={index} name={index + 1} datetime={"!datetime"} line={campaignResponse.template.length === index + 1 ? false : true} />
                ))
                : "Loading..."}
              <Button name="Back" onClick={() => history.goBack()}></Button>
            </div>

            <span className={styles.VerticalSep} />

            <div className={styles.TableCol}>
              <div className={styles.TemplateContainer}>

                {campaignResponse.template && campaignResponse.template.length > 0
                  ? campaignResponse.template.map((template, index) => (
                    <TemplatePreviewCard key={index} number={index + 1} name={null} />
                  ))
                  : "Loading..."}

              </div>
            </div>
          </div>
          : (<CustomerSegment />)}
      </div>
    </React.Fragment>
  );
};

export default VersionTemplateDetails;
