import styles from "./DiscardDialog.module.css";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";
import { ReactComponent as WarningTriangle } from "../../../../../assets/icons/warning-triangle.svg";
import SaveAndDiscardButtonSet from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";

const DiscardDialog = ({ className, yesAction, noAction, name }) => {
  return (
    <div className={[className, styles.Container].join(" ")}>
      <div className={styles.Header}>
        <p>
          <span>{className}</span>
        </p>
        <CloseX onClick={noAction} className={styles.CloseIcon} />
      </div>
      <div className={styles.Body}>
        <WarningTriangle />
        {className === "Delete Customer List" ? (
          <p>Are you sure you want to delete this Customer List ?</p>
        ) : className === "Unpublish Customer List" ? (
          <p>Are you sure you want to unpublish this Customer List ?</p>
        ) : className === "Publish Customer List" ? (
          <p>Are you sure you want to publish this Customer List ?</p>
        ) : className === "Confirm" ? (
          <p>Are you sure you want to delete the rule?</p>
        ) : (
          <p>Are you sure you want to discard the changes without saving?</p>
        )}
      </div>
      <SaveAndDiscardButtonSet
        yesAction={yesAction}
        noAction={noAction}
        className={styles.Buttons}
      />
    </div>
  );
};

export default DiscardDialog;
