/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAppSelector } from "../../../redux/store";
import styles from "./VersionHistoryItem.module.css";
import VersionComment from "./VersionComment/VersionComment";
import AddComment from "../../General/VersionHistoryItem/VersionComment/AddComment/AddComment";
import RowAction from "../../General/Table/RowAction/RowAction";
import VersionRowActionMenu from "../../ManageCampaign/ManageCampaignTable/Row/RowActionMenu/VersionRowActionMenu";
import OutsideClickDetector from "../Utility/OutsideClickDetector";
// import Button from "../Button/Button";

const VersionHistoryItem = (props) => {
  const ref = useRef(null);
  const jwt = useAppSelector((state) => state.authReducer.jwt);
  const [commentInput, showCommentInput] = useState(false);
  const [hover, setHover] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false);
  const comments = props.comments;
  const [style, setStyle] = useState({ display: "none" });
  const actionItem = {
    status: props.roleStatus,
    campaignId: props.campaignId,
    version: props.versionNumber
  };

  const openMenu = () => {
    if (commentInput) {
      showCommentInput(false);
    } else {
      showCommentInput(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      console.log(e);
      console.log(ref);
      console.log(hover);
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (hover === true && ref.current && !ref.current.contains(e.target)) {
        setHover(false);
        setStyle({ display: "none" });
      } else {
        setStyle({ display: "block" });
       }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [hover]);

  // return <span onClick={openMenu} className={styles.Circle} />;
  const latestVersion = () => {
    if (props.index === 0) {
      return <span onClick={openMenu} className={styles.Dot} />;
    } else {
      return (
        <div>
          <span
            onClick={() => {
              setStyle({ display: "block" });
              setHover(true);
            }}
            className={styles.Circle}
          >
            {hover ? (
              <div style={style}>
                <RowAction
                  menu={
                    <VersionRowActionMenu
                      action={actionItem}
                      id={props.campaignId}
                      commentFlag={(value) => { showCommentInput(value) }}
                    />
                  }
                  options={[{key:"version",name:"version"}]}
                />
              </div>
            ) : (
              ""
            )}
          </span>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={styles.ItemHistory}>
        <div className={styles.TrailItem} ref={ref}>
          {latestVersion()}
        </div>

        {/* <div className={styles.TrailItem}><RowAction menu={ <RowActionMenu openMenu={openMenu} action={"version_control"}/> }/></div> */}

        <div className={styles.VersionDateTime}>
          <a
            className={styles.Version}
            href={`/ctmp/dashboard/version-control/${props.campaignId}/preview/${props.versionNumber}`}
          >
            V{props.versionNumber}
          </a>
          <div className={styles.Date}>Last Edited: {props.datetime}</div>
        </div>
        <div className={styles.Status}>
          <div
            className={
              props.status === "Approved"
                ? styles.CurrentVersion
                : props.status === "Draft"
                ? styles.Draft
                : props.status === "Reused"
                ? styles.Reused
                : props.status === "Live"
                ? styles.Live
                : ""
            }
          >
            {props.status === "Approved" ||
            props.status === "Draft" ||
            props.status === "Reused" ||
            props.status === "Live" ? (
              <label className={styles.StatusLabel}>
                {props.status === "Approved"
                  ? "Current Version"
                  : props.status === "Live"
                  ? "Gone Live"
                  : props.status}
              </label>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* {comments.length === 0 ? null : (<span className={styles.TrailLine} />) } */}
      {/*  {comments || props.index === 0 ? (<span className={styles.TrailLine} />) : null} */}

      {props.index !== props.length - 1 ? (
        <span className={styles.TrailLine} />
      ) : null}

      {/* <span className={styles.TrailLine} /> */}
      {commentInput? (
        <AddComment
          data={props}
          showComment={showCommentInput}
          reload={props.reload}
        />
      ) : null}
      {comments && comments.length > 0
        ? comments
            .slice()
            .reverse()
            .map((item, index) => (
              <React.Fragment key={index}>
                <VersionComment
                  key={index}
                  commentDate={item.commentDate}
                  comment={item.comment}
                  commentBy={item.commentBy}
                />
                <span className={styles.TrailLine} />
              </React.Fragment>
            ))
        : null}
        {/* {props.reload(props.reload)} */}
      {/* {commentInput ? <AddComment data={props} showComment={showCommentInput} reload={props.reload}/> : null} */}
    </React.Fragment>
  );
};

export default VersionHistoryItem;
