import Parser from 'html-to-react';
import { useState } from 'react';
import { useAppSelector } from '../../../redux/store';
import LabeledInput from '../../General/LabeledInput/LabeledInput';
import LabeledSelect from '../../General/LabeledSelect/LabeledSelect';
import styles from "./TemplateTypes.module.css";
import { SampleTemplateTypes } from './SampleTemplateTypes';

const ViewTemplateType = () => {
  const [templateType, setTemplateType] = useState();
  const [htmlTemplate, setHtmlTemplate] = useState();
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  const templates = [
    {
      label: "Type1",
      value: "1"
    },
    {
      label: "Type2",
      value: "2"
    }
  ];

  //downloadFile
  const downloadFile = async () => {
    fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${"s3ZipFileKey"}`, {
      method: 'GET',
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: `Bearer ${jwt}`

      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          // `FileName.zip`,
          `${templateType.label}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  return (
    <div>
      <div>
        <p className={styles.UpdateFormHeader}>
          {"View template type"}
        </p>
        <LabeledSelect
          className={styles.Select}
          options={templates}
          placeholder="Select"
          defaultLabel={templateType ? templateType.label : undefined}
          defaultId={templateType ? templateType.value : undefined}
          title={
            <>
              Select template type<span>*</span>
            </>
          }
          onValueChange={(option) => {
            setTemplateType(option);
            console.log("option - " + option)
            if (option !== null) {
              if (option.label === "Type1") {
                //setHtmlTemplate(sourceType1);
                setHtmlTemplate(SampleTemplateTypes.Type1);
              } else {
                //setHtmlTemplate(sourceType2);
                setHtmlTemplate(SampleTemplateTypes.Type2);
              }
            } else {
              setHtmlTemplate(undefined);
            }
          }}
        />
      </div>

      {htmlTemplate !== undefined ?
        <div>
          <LabeledInput
            component_status={"false"}
            title={<>Name<span>*</span></>}
            data={templateType ? templateType.label : ""}
            placeholder="Type the template name"
            className={styles.Input}
          //onValueChange={(value) => setTemplateTypeName(value)}
          />
          <LabeledInput
            component_status={"false"}
            title={<>Description<span>*</span></>}
            placeholder="Type the description"
            className={styles.Input}
            data={templateType ? "View template " + templateType.label : ""}
          //onValueChange={(value) => setTemplateTypeDescription(value)}
          />
          {/* Download button */}
          <div className={styles.Container}>
            <label>
              Download
              <br />
              <a
                className={styles.TemplateDeleteDownload}
                onClick={downloadFile}
              >
                {templateType ? templateType.label : ""}
              </a>
            </label>
          </div>
          <div className={styles.line}></div>
        </div>
        : ""}

      {Parser.Parser().parse(htmlTemplate)}
    </div >
  )
}

export default ViewTemplateType