import styles from "./SaveDialog.module.css";
import { ReactComponent as Check } from "../../../../../assets/icons/Check.svg";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";
// import LabeledDescriptionInput from "../../../../General/LabeledDescription/LabeledDescriptionInput";
import SaveAndDiscardButtonSet from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";

type SaveDialogProps = {
  className?: string;
  itemsToBeComplete: string[];
  discardAction: () => void;
  saveAction: () => void;
  noNote?: boolean;
};

const SaveDialog = ({
  className,
  itemsToBeComplete,
  discardAction,
  saveAction,
  noNote
}: SaveDialogProps) => {
  return (
    <div className={[className, styles.Container].join(" ")}>
      <div className={styles.ContentContainer}>
        <div className={styles.Header}>
          {className === "DeleteCopyFile" || className ==="DeleteTemplateType" || className === "DeleteVinListFile" ? <p><span>Deletion Message</span></p> : <p><span>Save as Draft</span> <Check /></p>}
          <CloseX className={styles.CloseIcon} onClick={discardAction} />
        </div>

        {className === "DeleteCopyFile" ?
          <div className={styles.Body}>
            {itemsToBeComplete.length === 0 ? <p>Are you sure you want to delete the existing Copy file?</p> : <p>Required Fields To Be Entered</p>}
            <ul>
              {itemsToBeComplete.map((value) => (
                <li key={value}>{value}</li>
              ))}
            </ul>
          </div> : className === "DeleteVinListFile" ?
            <div className={styles.Body}>
              <p>Are you sure you want to delete the existing Vin List file?</p>
            </div> : className === "DeleteTemplateType" ?
            <div className={styles.Body}>
              <p>Are you sure you want to delete the existing Template Type?</p>
            </div> :
            <div className={styles.Body}>
              {itemsToBeComplete.length === 0 ? <p>All required items are complete.</p> : <p>Required Fields To Be Entered</p>}
              {/* <p>Required Fields To Be Entered</p> */}
              <ul>
                {itemsToBeComplete.map((value) => (
                  <li key={value}>{value}</li>
                ))}
              </ul>
            </div>
        }

      </div>
      {className === "DeleteCopyFile" || className === "DeleteVinListFile" || className ==="DeleteTemplateType"?
        <SaveAndDiscardButtonSet
          yesAction={saveAction}
          noAction={discardAction}
          noTitle="No"
          yesTitle="Yes"
          status={0}
          className={styles.Buttons}
        /> :
        <SaveAndDiscardButtonSet
          yesAction={saveAction}
          noAction={discardAction}
          noTitle="Discard"
          yesTitle="Save"
          status={itemsToBeComplete.length}
          className={styles.Buttons}
        />
      }
    </div>
  );
};

export default SaveDialog;
