export const SampleTemplateTypes = {
  Type1: `<!DOCTYPE html>
  <html
    style="
      box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
      font-size: 15px;
      line-height: 1.5;
      overflow-x: hidden;
    "
  >
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Template - Red Theme</title>
      <style type="text/css">
        tr.noBorder td {
          border: 0;
        }
        tr.spaceUnder > td {
          padding-bottom: 3em;
        }
        .header {
          height: 100px;
          max-width: 640px;
          margin: 0 auto;
          text-align: center;
        }
  
        .carDetailsRedTheme {
          border: 24px solid #eb0a1e;
        }
  
        .carDetails {
          max-width: 592px;
          margin: 0 auto;
        }
  
        .headline {
          height: auto;
          font-size: 32px;
          line-height: 44px;
          font-weight: bold;
          color: #eb0a1e;
          text-align: center;
          padding: 0px 60px;
          margin-top: 6px;
        }
  
        .carImage {
          text-align: center;
        }
  
        .carImage img {
          width: 100%;
          max-width: 336px;
          height: auto;
        }
  
        .vinNumber {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: #262626;
        }
  
        .vinLabel {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #595959;
        }
  
        .textContainer {
          margin: 0 auto;
          padding: 0px 40px;
        }
  
        .bodyText {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: center;
        }
  
        .updateButton {
          min-width: 192px;
          max-width: 282px;
          height: 52px;
          background-color: #000000;
          border-radius: 100px;
          color: white;
          margin-bottom: 20px;
          border: #000000;
        }
  
        .updateButton a {
          text-decoration: none;
          color: white;
        }
  
        .centerDiv {
          margin: 0 auto;
          text-align: center;
        }
  
        .pt-32 {
          padding-top: 32px;
        }
  
        .phoneLabel {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
  
        .mainContainer {
          /* min-width: 592px; */
          max-width: 592px;
          padding: 24px;
          margin: 0 auto;
        }
  
        .serviceDetailsHeader {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
        }
  
        .serviceDetailsSubheading {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
  
        .servicesContainer {
          /* width: 560px; */
          padding-top: 20px;
        }
  
        .flexServices {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-items: center;
          justify-content: center;
          width: 266px;
          /* padding-left: 40px; */
        }
  
        .flexLeft {
          flex-grow: 0;
        }
  
        .flexRight {
          flex-grow: 0;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          flex-direction: column;
          justify-content: center;
          display: flex;
          /* padding-top: 32px; */
        }
  
        .superscript {
          font-size: 10px;
          vertical-align: super;
        }
  
        .serviceTable {
          margin: 0 auto;
  
          /*padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 30px;
      position:absolute;*/
        }
  
        .servicePill {
          margin: 0 auto;
          /* min-width: 270px; */
          max-width: 320px;
        }
  
        .serviceFlex {
          display: flex;
          flex-direction: column;
        }
  
        .serviceGridPill {
          display: grid;
          grid-template-columns: 100px 188px;
          grid-template-rows: auto;
          grid-template-areas: "icon serviceName";
          margin: 0 auto;
        }
  
        .serviceIcon {
          grid-area: icon;
        }
  
        .serviceName {
          grid-area: serviceName;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          /* padding-top: 10px; */
        }
  
        .featureDetails {
          background-color: #f5f5f5;
          color: #000000;
        }
  
        .featureDetailsHeading {
          text-align: center;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #000000;
        }
  
        .featureDetailsSubHeading {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          padding: 10px 27px 0px 27px;
        }
  
        .featureDetailsService {
          text-align: center;
          padding-top: 32px;
        }
  
        .semiboldText {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
  
        .lightText {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
  
        .footer {
          background-color: #cecfd0;
          color: #494950;
        }
  
        .text-center {
          text-align: center;
        }
  
        .links {
          color: #5592ff;
          text-decoration: underline;
        }
  
        .legal {
          padding: 0 27px;
        }
  
        .legalText {
          margin-top: 15px;
          font-size: 10px;
          line-height: 18px;
          font-weight: 400;
          margin-left: 12px;
        }
  
        .legalText1 {
          margin-left: -27px;
        }
  
        .legalText2 {
          margin-left: 0px;
        }
  
        .bottomFooter {
          min-height: 100px;
          max-width: 640px;
          margin: 0 auto;
          text-align: center;
          color: #494950;
        }
  
        .footerRedTheme {
          background-color: white;
          color: #494950;
        }
  
        .bottomFooter div {
          padding: 25px 64px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
  
        .bottomFooter span {
          font-weight: 600;
        }
  
        @media (max-width: 420px) {
          .headline {
            height: auto;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            padding: 0px 60px;
            margin-top: 10px;
          }
  
          .featureDetailsHeading {
            text-align: center;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
          }
  
          .featureDetailsSubHeading {
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            padding: 10px 27px 0px 27px;
          }
        }
      </style>
    </head>
  
    <body>
                           <!-------------------------- Header  Division ----------------------->
  
      <div class="header" style="mso-hide: all">
        <img style="padding-top: 2rem" src="https://assets.telematicsct.com/assets/marketing-platform/Toyota/connected-logo.png" alt="Brand header image" />
      </div>
  
                          <!------------------- Car Details Division ---------------------------->
      <div class="carDetails carDetailsRedTheme" style="mso-hide: all">
        <div class="headline">Lorem eu ultricies varius</div>
        <div class="carImage">
          <img
            src="https://assets.telematicsct.com/assets/marketing-platform/Toyota/Vehicle.png"
            alt="Car image"
          />
          <div class="vinNumber">{{vin}}</div>
          <div class="vinLabel">Your VIN number</div>
        </div>
        <div class="textContainer">
          <p class="bodyText">
              There are many variations of passages of Lorem Ipsum available, but
              the majority have suffered alteration in some form, by injected
              humour.
          </p>
          <p class="bodyText">
              There are many variations of passages of Lorem Ipsum available, but
              the majority have suffered alteration in some form, by injected
              humour.
          </p>
        </div>
        <div class="centerDiv pt-32">
          <button
            target="_blank"
            href=""
            class="updateButton"
          >
            <a target="_blank" href=""
              >Update Account Now</a
            >
          </button>
        </div>
      </div>
                         <!----------------------Services Division------------------------->
  
      <div class="mainContainer" style="mso-hide: all">
        <div class="textContainer centerDiv">
          <div class="serviceDetailsHeader">
             Lorem eu ultricies varius:
          </div>
        </div>
  
        <div class="servicesContainer">
          <table
            class="serviceTable"
            border="0"
            cellpadding="0"
            cellspacing="0"
            align="center"
            style="mso-hide: all"
          >
            <tr>
              <td>
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Toyota/RemoteConnect.png"
                  alt="service image"
                />
              </td>
              <td>
                <div class="serviceName">
                  <p>Lorem eu ultricies</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Toyota/RemoteConnect.png"
                  alt="service image"
                />
              </td>
              <td>
                <div class="serviceName">
                  <p>Lorem eu ultricies</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Toyota/RemoteConnect.png"
                  alt="service image"
                />
              </td>
              <td>
                <div class="serviceName">
                  <p>Lorem eu ultricies</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
                           <!----------------------Features Division------------------------->
  
      <div
        class="mainContainer featureDetails"
        style="background-color: #f5f5f5; mso-hide: all"
      >
        <div class="featureDetailsHeading">Lorem eu ultricies</div>
        <div class="featureDetailsSubHeading">
          Lorem eu ultricies:
        </div>
        <div class="featureDetailsService">
          <div class="semiboldText">Lorem eu ultricies</div>
          <div class="lightText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </div>
        </div>
        <div class="featureDetailsService">
          <div class="semiboldText">Lorem eu ultricies</div>
          <div class="lightText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </div>
        </div>
        <div class="featureDetailsService">
          <div class="semiboldText">Lorem eu ultricies</div>
          <div class="lightText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </div>
        </div>
        <div class="centerDiv pt-32">
          <button
            target="_blank"
            href=""
            class="updateButton"
          >
            <a target="_blank" href=""
              >Update Account Now</a
            >
          </button>
        </div>
      </div>
                              <!----------------------Legal Footer Division------------------------->
  
      <div class="mainContainer footer" style="mso-hide: all">
        <div class="text-center" style="padding: 0px 27px 10px 27px">
          <div class="semiboldText">Lorem eu ultricies?</div>
          <div class="lightText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </div>
          <div style="margin-top: 20px">
            <a
              class="lightText links"
              target="_blank"
              href=""
              >Contact us to get started</a
            >
          </div>
        </div>
        <div class="legal">
          <ol style="padding-left: 10px">
            <li class="legalText legalText2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at
              accumsan eros. Nam neque est, tincidunt non tincidunt eget, mollis
              et felis. Mauris viverra quam quam, in maximus nulla laoreet ac.
              Nullam egestas sollicitudin enim, sit amet lacinia quam. Duis
              suscipit sed lectus vitae consectetur. Proin fermentum, lorem eu
              ultricies varius, arcu enim blandit sem, et vehicula mi sem vel
              justo. Sed non rhoncus urna, ut lacinia turpis. Pellentesque sed
              erat luctus, venenatis orci et, iaculis quam. Nunc mattis tortor
              massa, vitae rutrum urna egestas et. Phasellus ac nisi porttitor,
              tristique diam in, laoreet turpis. Maecenas et consectetur nunc,
              quis tristique neque. Vivamus id dapibus lacus. Ut quis placerat
              arcu. Suspendisse ac consectetur nisi. Phasellus mi nulla, interdum
              sed pulvinar non, facilisis quis turpis.
            </li>
          </ol>
          <p class="legalText legalText2" style="padding-left: 1rem">
             Integer sed libero porta, laoreet justo sit amet, pharetra purus.
          </p>
          <div class="legalText legalText2">
              Integer sed libero porta, laoreet justo sit amet, pharetra purus.
            <a
              class="links"
              target="_blank"
              href=""
              >click here</a
            >. Integer sed libero porta, laoreet justo sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit pharetra purus.
          </div>
          <div class="legalText legalText2">
              Integer sed libero porta, laoreet Lorem ipsum dolor sit amet, consectetur adipiscing elit justo sit amet, pharetra purus.
          </div>
          <div class="legalText legalText2">
              Integer sed libero porta, laoreet justo sit amet, pharetra purus.
          </div>
        </div>
      </div>
                          <!----------------------Footer Division------------------------->
      <div class="bottomFooter footerRedTheme" style="mso-hide: all">
        <div>
          <span>Integer sed libero porta</span>: 6565 libero Dr.porta, TX 88888
        </div>
      </div>
  
                          <!----------------------Outlook Version------------------------->
   <!--[if mso]>
    <v:shape>
      <table cellspacing="0" cellpadding="0" style="width:640px;" align="center">
    </v:shape>
     <v:shape>
          <table class="header" align="center" style="padding: 0 0 32px 0">
            <tr>
              <td>
                <img
                  src=""
                  alt="Header image"
                />
              </td>
            </tr>
          </table> 
     </v:shape>
     
     
       <div>
    <table class="carDetails" cellspacing="0" cellpadding="0"  style="border: 24px solid #eb0a1e; width:576px;"  align="center">
    <tr>
    <td><div class="headline">XXXXXXXXXXX</div></td>
    </tr>
  
      <tr class="noBorder">
       <td style="text-align: center;">
        <img src="Images/GeneralCarImage.png"
        alt="Car image" /></td></tr>
        <tr><td style="text-align: center;font-weight: 600;font-size: 18px;line-height: 22px;color: #262626;">
          {{vin}}</td></tr>
        <tr><td  style="text-align: center;font-weight: normal;font-size: 14px;line-height: 17px;color: #595959;">
          Your VIN number</td></tr>
    
      <tr class="noBorder"><td style="padding: 15px 40px 5px 40px;">
        <p class="bodyText">
          There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour.
        </p></td></tr>
        <tr class="noBorder"><td style="padding: 10px 40px 0 40px;">
        <p class="bodyText">
          Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text.
        </p>
      </td>
     </tr>
     <tr><td>&nbsp;</td></tr>
     <tr class="noBorder">
     <td style="padding: 20px 0 20px 0">
      <div>
        <a href=""
          <center>
            <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" target="_blank" align="center" style="height:90px;v-text-anchor:middle;width:292px;" arcsize="135%" mso-position-horizontal:"right" strokecolor="#e6e6e8" fillcolor="#14141a">
              <w:anchorlock/>
              <center style="color:#fefbfb;font-family:sans-serif;font-size:13px;padding: 15px 30px;">Update Account Now</center>
            </v:roundrect>
          </center>
        </a>
    </div>
  </td>
  </tr>
  </table>
  </div>
      </v:shape>
    
    
    
     <div class="mainContainer">
        <div class="textContainer centerDiv">
          <div class="serviceDetailsHeader" style="margin-top:20px;">
           Lorem eu ultricies varius:
          </div>
        </div>
  
        <div class="servicesContainer">
          <table class="serviceTable"  border="0" cellpadding="0" cellspacing="0" align="center">
            <tr style="margin-top:20px;">
              <td> <img src="Images/GeneralServiceImage.png"
                alt="service image" /></td>
                <td><div class="serviceName">
                  <p>Lorem ipsum</p>
                </div></td>
            </tr>
            <tr style="margin-top:20px;">
              <td> <img src="Images/GeneralServiceImage.png"
                alt="service image" /></td>
                <td><div class="serviceName">
                  <p>Lorem ipsum</p>
                </div></td>
            </tr>
            <tr style="margin-top:20px;">
              <td> <img src="Images/GeneralServiceImage.png"
                alt="service image" /></td>
                <td><div class="serviceName">
                  <p>Lorem ipsum</p>
                </div></td>
            </tr>
            <tr style="margin-top:20px;">
                    <td>&nbsp</td>
            </tr>
          </table>
        </div>
     </div>
     
     
     <div>
  <table class="featureDetails" cellpadding="0" cellspacing="0" align="center" style="width:576px;background-color: #f5f5f5;">
  <tr style="line-height:0px;margin-top:20px;">
  <td>
    <div class="featureDetailsHeading">Lorem ipsum dolor sit amet</div>
    <div class="featureDetailsSubHeading" style="background-color: #f5f5f5;">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit:
      </div>
  </td>
  </tr>
  <tr class="featureDetailsService" style="background-color:#f5f5f5;line-height:0px;" align="center">
  <td>
      <div class="semiboldText">Cras malesuada</div>
     <div class="lightText">
           Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </div>
  </td>
  </tr>
  <tr class="featureDetailsService" style="background-color:#f5f5f5;" align="center">
  <td>
      <div class="semiboldText">Cras malesuada</div>
     <div class="lightText">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </div>
  </td>
  </tr>
  
  <tr class="featureDetailsService" style="background-color:#f5f5f5;" align="center">
  <td>
      <div class="semiboldText">Cras malesuada</div>
      <div class="lightText">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </div>
  </td>
  </tr>
  <tr>
  <td>&nbsp;</td></tr>
  <tr class="noBorder">
     <td style="padding: 20px 0 20px 0">
      <div>
        <a href=""
          <center>
            <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" target="_blank" align="center" style="height:90px;v-text-anchor:middle;width:292px;" arcsize="135%" mso-position-horizontal:"right" strokecolor="#e6e6e8" fillcolor="#14141a">
              <w:anchorlock/>
              <center style="color:#fefbfb;font-family:sans-serif;font-size:13px;padding: 15px 30px;">Update Account Now</center>
            </v:roundrect>
          </center>
      </a>
    </div>
  </td>
  </tr>
  </table>
   </div>
   
   
    <table cellpadding="0" cellspacing="0" align="center" style="width:576px;background-color:#cecfd0;color: #494950;">
    <tr>
    <td style="padding:0px 27px 10px 27px; margin-top:20px;text-align:center; font-weight: 600;font-size: 16px;line-height: 24px;">
     Lorem ipsum dolor sit amet?
    </td>
    </tr>
    <tr><td style="padding:0px 27px 0px 27px;text-align:center;font-weight: 400;font-size: 16px;line-height: 24px;">
      Proin fermentum, lorem eu ultricies varius, arcu enim blandit sem, et
            vehicula mi sem vel justo {{vehicleYear}} {{vehicleModelExt}}, Sed non
            rhoncus urna, ut lacinia turpis
    </td></tr>
    <tr><td style="padding:0px 27px 10px 27px;text-align:center;line-height: 24px;">
        <div style="margin-top: 20px;margin-bottom:20px;">
          <a class="lightText links" target="_blank"
            href="">Contact us to get started</a>
        </div>
      </div>
    </td>
    </tr>
  
  <div style="padding: 0 27px 0 27px;">
  <tr><td style="line-height:18px;font-weight: 400;font-size:10px;margin:0px 45px 15px 38px;">
       1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at
              accumsan eros. Nam neque est, tincidunt non tincidunt eget, mollis
              et felis. Mauris viverra quam quam, in maximus nulla laoreet ac.
              Nullam egestas sollicitudin enim, sit amet lacinia quam. Duis
              suscipit sed lectus vitae consectetur. Proin fermentum, lorem eu
              ultricies varius, arcu enim blandit sem, et vehicula mi sem vel
              justo. Sed non rhoncus urna, ut lacinia turpis. Pellentesque sed
              erat luctus, venenatis orci et, iaculis quam. Nunc mattis tortor
              massa, vitae rutrum urna egestas et. Phasellus ac nisi porttitor,
              tristique diam in, laoreet turpis. Maecenas et consectetur nunc,
              quis tristique neque. Vivamus id dapibus lacus. Ut quis placerat
              arcu. Suspendisse ac consectetur nisi. Phasellus mi nulla, interdum
              sed pulvinar non, facilisis quis turpis.</li>
          </td></tr>
         <tr><td style="margin:0px 30px 10px 47px;font-size: 10px;line-height:18px;font-weight:400;">
          Integer sed libero porta, laoreet justo sit amet, pharetra purus.</li></td>
          </tr>
  
          <tr><td class="legalText legalText2" style="margin-left:35px;margin-right:35px;margin-bottom:10px;">
           tristique diam in, laoreet turpis. Maecenas et consectetur nunc, quis
            tristique neque
          </td></tr>
        <tr><td class="legalText legalText2" style="margin-left:35px;margin-right:35px;margin-bottom:10px;">
          tristique diam in, laoreet turpis. Maecenas et consectetur nunc, quis
            tristique neque
          owner. </td></tr>
        <tr><td class="legalText legalText2" style="margin-left:35px;margin-bottom:25px;margin-right:25px;">
          tristique diam in, laoreet turpis. Maecenas et consectetur nunc, quis
            tristique neque </td></tr>
    
     </td>
    </tr>
  </div>
    </table>
    
    
      <table
          cellpadding="0"
          cellspacing="0"
          align="center"
          style="
            min-height: 100px;
            max-width: 592px;
            text-align: center;
            background-color: white;
            color: #494950;
          "
        >
          <tr>
            <td
              style="
                padding: 25px 64px 25px 64px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
              "
            >
             <span>Integer sed libero porta</span>: 6565 libero Dr.porta, TX 88888
            </td>
          </tr>
        </table>
      </table>
      
    <![endif]-->
  
    </body>
  </html>
  `,
  Type2: `<!DOCTYPE html>
  <html
    style="
      box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
      font-size: 15px;
      line-height: 1.5;
      overflow-x: hidden;
    "
  >
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Type2 - Black Theme</title>
      <style type="text/css">
        tr.noBorder td {
          border: 0;
        }
  
        tr.spaceUnder > td {
          padding-bottom: 3em;
        }
  
        .header {
          height: 100px;
          max-width: 640px;
          margin: 0 auto;
          text-align: center;
        }
  
        .carDetailsBlackTheme {
          border: 24px solid #000000;
        }
  
        .carDetails {
          max-width: 592px;
          margin: 0 auto;
        }
  
        .headline {
          height: auto;
          font-size: 29px;
          line-height: 40px;
          text-align: center;
          padding: 0px 60px;
          /* text-transform: uppercase; */
          margin-top: 10px;
        }
  
        .carImage {
          text-align: center;
        }
  
        .carImage img {
          width: 100%;
          /* max-width: 336px; */
          max-width: 480px;
          height: auto;
        }
  
        .vinNumber {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #595959;
        }
  
        .vinLabel {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #595959;
        }
  
        .textContainer {
          margin: 0 auto;
          padding: 0px 40px;
        }
  
        .bodyText {
          font-weight: 400;
          font-size: 17px;
          line-height: 24px;
          color: #000000;
          text-align: center;
        }
  
        .updateButton {
          min-width: 192px;
          max-width: 282px;
          height: 52px;
          background-color: #000000;
          border-radius: 100px;
          color: white;
          margin-bottom: 20px;
          border: #000000;
        }
  
        .updateButton a {
          text-decoration: none;
          color: white;
          font-size: 18px;
        }
  
        .centerDiv {
          margin: 0 auto;
          text-align: center;
        }
  
        .pt-32 {
          padding-top: 32px;
        }
  
        .phoneLabel {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
  
        .mainContainer {
          /* min-width: 592px; */
          max-width: 592px;
          padding: 24px;
          margin: 0 auto;
        }
  
        .serviceDetailsHeader {
          font-size: 29px;
          line-height: 30px;
          text-align: center;
        }
  
        .serviceDetailsSubheading {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
  
        .servicesContainer {
          /* width: 560px; */
          padding-top: 20px;
        }
  
        .flexServices {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-items: center;
          justify-content: center;
          width: 266px;
          /* padding-left: 40px; */
        }
  
        .flexLeft {
          flex-grow: 0;
        }
  
        .flexRight {
          flex-grow: 0;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          flex-direction: column;
          justify-content: center;
          display: flex;
          /* padding-top: 32px; */
        }
  
        .superscript {
          font-size: 10px;
          vertical-align: super;
        }
  
        .serviceTable {
          max-width: 592px;
          margin: 0 auto;
        }
  
        .serviceName {
          grid-area: serviceName;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          /* padding-top: 10px; */
        }
        .serviceImages {
          padding-top: 2rem;
        }
  
      .servicePill {
        margin: 0 auto;
        /* min-width: 270px; */
        max-width: 320px;
      }
      .serviceFlex {
        display: flex;
        flex-direction: column;
      }
      .serviceGridPill {
        display: grid;
        grid-template-columns: 100px 188px;
        grid-template-rows: auto;
        grid-template-areas: "icon serviceName";
        margin: 0 auto;
      }
      .serviceIcon {
        grid-area: icon;
      }
  
  
        .featureDetails {
          background-color: #f5f5f5;
          color: #000000;
        }
  
        .featureDetailsHeading {
          text-align: center;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #000000;
        }
  
        .featureDetailsSubHeading {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          padding: 10px 27px 0px 27px;
        }
  
        .featureDetailsService {
          text-align: center;
          padding-top: 32px;
        }
  
        .semiboldText {
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          color: black;
        }
  
        .lightText {
          font-weight: 400;
          font-size: 17px;
          line-height: 24px;
          color: black;
        }
  
        .footer {
          background-color: #cecfd0;
          color: #494950;
        }
  
        .text-center {
          text-align: center;
        }
  
        .links {
          color: #121111;
          text-decoration: underline;
        }
  
        .legal {
          padding: 0 27px;
        }
  
        .legalText {
          margin-top: 15px;
          font-size: 10px;
          line-height: 18px;
          font-weight: 400;
          margin-left: 12px;
        }
  
        .legalText1 {
          margin-top: 0px;
          font-size: 10px;
          line-height: 18px;
          font-weight: 400;
          margin-left: 12px;
        }
  
        .legalText2 {
          margin-left: 0px;
          color: #121111;
        }
  
        .bottomFooter {
          min-height: 100px;
          max-width: 593px;
          margin: 0 auto;
          text-align: center;
          color: #494950;
        }
  
        .footerBlackTheme {
          background-color: black;
          color: white;
        }
  
        .bottomFooter div {
          padding: 25px 64px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
  
        .bottomFooter span {
          font-weight: 200;
        }
  
      .servicesSubHeader {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
  
        @media (max-width: 420px) {
          .headline {
            height: auto;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            padding: 0px 60px;
            /* text-transform: uppercase; */
            margin-top: 10px;
          }
  
          .featureDetailsHeading {
            text-align: center;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
          }
  
          .featureDetailsSubHeading {
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            padding: 10px 27px 0px 27px;
          }
        }
      </style>
    </head>
  
    <body>
      <!---------------------------- Header ------------------------------>
      <table class="header" align="center" style="mso-hide: all">
        <tr>
          <td>
            <img style="padding-top: 2rem" src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/connected-logo.png" alt="Brand header image" />
          </td>
        </tr>
      </table>
  
      <!------------------------------ Car Details Division--------------------------->
      <div class="carDetails carDetailsBlackTheme" style="mso-hide: all">
        <div class="headline">ENJOY EVEN MORE <br/>PEACE OF MIND</div>
        <div class="carImage">
          {{#if vehicleImageUrl}}
          <img src="{{vehicleImageUrl}}" alt="Car image" />
          {{else}}
          <img
            src="https://www.toyota.com/config/pub/3d/toyota/1007736/1000868/Exterior/1/680_383_PNG/613502c05e6e1c45faa7e34522330fdfc8d33554b7066c1993f7c8b5b7f23d45.png"
            alt="Car image"
          />
          {{/if}}
          <div class="vinNumber">Your {{vehicleModelExt}} VIN:</div>
          <div class="vinLabel">{{vin}}</div>
        </div>
        <div class="textContainer">
          <p class="bodyText">
            Sed aliquet turpis tortor, et ullamcorper quam tristique at.
          </p>
          <p class="bodyText">
            Morbi rutrum ac odio quis facilisis. Ut et varius enim. Proin ante
            diam, lobortis eget facilisis vitae, dapibus ut elit. Donec
            consectetur diam ut nunc iaculis, nec mollis elit auctor.
          </p>
          <p class="bodyText">
            Quisque porta placerat lobortis. Nulla auctor risus nunc, id suscipit
            ligula tempus ut
          </p>
        </div>
        <div class="centerDiv pt-32">
          <button target="_blank" href="" class="updateButton">
            <a target="_blank" href="">View Account</a>
          </button>
        </div>
      </div>
  
      <!-------------------------------Services (set 1) Division ----------------------->
      <div class="mainContainer" style="mso-hide: all">
        <div class="textContainer centerDiv">
          <div class="serviceDetailsHeader">In quis magna rhoncus:</div>
        </div>
  
        <div class="servicesContainer centerDiv">
          <table
            class="serviceTable"
            border="0"
            cellpadding="0"
            cellspacing="0"
            align="center"
            style="mso-hide: all"
          >
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Sed aliquet turpis tortor</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Maecenas feugiat convallis libero a condimentum. Quisque porta
                    placerat lobortis. Nulla auctor risus nunc, id suscipit ligula
                    tempus ut. Aliquam congue odio ante, quis tempor quam molestie
                    ac. Nullam sit amet molestie nibh, eu viverra purus.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Sed aliquet turpis tortor</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Maecenas feugiat convallis libero a condimentum. Quisque porta
                    placerat lobortis. Nulla auctor risus nunc, id suscipit ligula
                    tempus ut. Aliquam congue odio ante, quis tempor quam molestie
                    ac. Nullam sit amet molestie nibh, eu viverra purus.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Sed aliquet turpis tortor</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Maecenas feugiat convallis libero a condimentum. Quisque porta
                    placerat lobortis. Nulla auctor risus nunc, id suscipit ligula
                    tempus ut. Aliquam congue odio ante, quis tempor quam molestie
                    ac. Nullam sit amet molestie nibh, eu viverra purus.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Sed aliquet turpis tortor</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Maecenas feugiat convallis libero a condimentum. Quisque porta
                    placerat lobortis. Nulla auctor risus nunc, id suscipit ligula
                    tempus ut. Aliquam congue odio ante, quis tempor quam molestie
                    ac. Nullam sit amet molestie nibh, eu viverra purus.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Sed aliquet turpis tortor</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Maecenas feugiat convallis libero a condimentum. Quisque porta
                    placerat lobortis. Nulla auctor risus nunc, id suscipit ligula
                    tempus ut. Aliquam congue odio ante, quis tempor quam molestie
                    ac. Nullam sit amet molestie nibh, eu viverra purus.
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
  
      <!--------------------------Horizontal Line------------------->
      <div class="mainContainer" style="mso-hide: all">
        <hr />
      </div>
  
      <!--------------------------Services (Set 2) Division------------------->
      <div class="mainContainer" style="mso-hide: all">
        <div class="textContainer centerDiv">
          <div class="serviceDetailsHeader">Sed aliquet turpis tortor:</div>
        </div>
  
        <div class="servicesContainer centerDiv">
          <table
            class="serviceTable"
            border="0"
            cellpadding="0"
            cellspacing="0"
            align="center"
            style="mso-hide: all"
          >
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Donec consectetur</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Nulla volutpat nibh vitae erat sagittis, ac condimentum ante
                    scelerisque. Proin aliquet ac libero euismod tempus.
                    Suspendisse potenti.Aliquam sed magna volutpat, consequat odio
                    in, malesuada magna. Duis aliquam nisi eu ultrices
                    fermentum.Proin interdum mattis condimentum.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Donec consectetur</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Nulla volutpat nibh vitae erat sagittis, ac condimentum ante
                    scelerisque. Proin aliquet ac libero euismod tempus.
                    Suspendisse potenti.Aliquam sed magna volutpat, consequat odio
                    in, malesuada magna. Duis aliquam nisi eu ultrices
                    fermentum.Proin interdum mattis condimentum.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="serviceImages">
                <img
                  src="https://assets.telematicsct.com/assets/marketing-platform/Lexus/RemoteConnect.png"
                  alt="service image"
                  width="100px"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="serviceName">
                  <p>Donec consectetur</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p class="bodyText">
                    Nulla volutpat nibh vitae erat sagittis, ac condimentum ante
                    scelerisque.
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
  
      <!--------------------------Features Division------------------->
      <div
        class="mainContainer featureDetails"
        style="background-color: #f5f5f5; mso-hide: all"
      >
        <div style="text-align: center">
          <div class="lightText">
            Maecenas feugiat convallis libero a condimentum. Quisque porta
            placerat lobortis. Nulla auctor risus nunc, id suscipit ligula tempus
            ut. Aliquam congue odio ante, quis tempor quam molestie ac.
          </div>
        </div>
        <div class="centerDiv pt-32">
          <button target="_blank" href="" class="updateButton">
            <a target="_blank" href="">Download the App</a>
          </button>
        </div>
        <div class="featureDetailsService">
          <div class="lightText">
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            <a class="links" target="_blank" href="">click here</a>.
          </div>
        </div>
      </div>
  
      <!--------------------------Legal Footer Division------------------->
      <div class="mainContainer footer" style="mso-hide: all">
        <div class="text-center" style="padding: 0px 27px 10px 27px">
          <div class="semiboldText">Morbi nisl magna, porta et ex ac?</div>
          <div class="lightText">
            Aliquam ut metus in erat commodo vehicula at nec lorem malesuada
            {{vehicleYear}} {{vehicleModelExt}},
            <br />
            Vivamus pellentesque mollis dictum.
          </div>
          <div style="margin-top: 20px">
            <a class="lightText links" target="_blank" href=""
              >Contact us to get started</a
            >
          </div>
        </div>
        <div class="legal">
          <ol style="padding-left: 10px">
            <li class="legalText legalText2">
              Morbi nisl magna, porta et ex ac, consectetur elementum orci. Aenean
              vitae mattis enim. Aliquam ut metus in erat commodo vehicula at nec
              lorem. Quisque in augue at arcu cursus malesuada. Aenean aliquam
              neque eget viverra rhoncus. Proin bibendum at nulla quis eleifend.
              Maecenas mi magna, imperdiet a aliquam fermentum, vulputate non
              urna. Ut at blandit sem, id iaculis augue. Sed ut mauris blandit,
              aliquam libero ac, suscipit elit.
            </li>
            <li class="legalText1 legalText2">
              Fusce porttitor dolor et facilisis posuere. Pellentesque ac
              fermentum justo. Integer vehicula massa a ante efficitur vulputate.
              Vestibulum ut rutrum urna. Duis ultricies lacus eget enim convallis
              commodo. Quisque at lacus lorem. Cras sollicitudin diam eget erat
              congue ornare non non neque. Donec tristique ex quis tincidunt
              porttitor.
            </li>
          </ol>
        </div>
      </div>
  
      <!--------------------------Footer Division------------------->
      <div class="bottomFooter footerBlackTheme" style="mso-hide: all">
        <div>
          <span>Maecenas mi magna, imperdiet a aliquam fermentum, TX 8888</span>
        </div>
      </div>
  
      <!------------------------- Outlook Version----------------------->
  
  <!--[if mso]>
  
  <v:shape>
  <table cellspacing="0" cellpadding="0" style="width:640px;" align="center">
  </v:shape>
  
      <v:shape>
          <table class="header" align="center" style="padding: 0 0 32px 0">
                  <tr>
                      <td>
                          <img
                          src=""
                          alt="Brand Header image" />
                      </td>
                  </tr>
          </table>
      </v:shape>
      
      
    <v:shape> 
          <div>
              <table class="carDetails" cellspacing="0" cellpadding="0"  style="border: 24px solid #000000; width:576px;"  align="center">
                  <tr>
                      <td><div class="headline">XXXXXXX XXXX <br/>XXXXXXX</div></td>
                  </tr>
  
                  <tr class="noBorder">
                      <td style="text-align: center;">
                          {{#if vehicleImageUrl}}
                          <img src={{vehicleImageUrl}} alt="Car image" width="526"/>
                          {{else}}
                          <img src="Images/GeneralCarImage.png"
                          alt="Car image" width="526"/>
                          {{/if}}
                      </td>
                  </tr>
                  <tr>
                      <td style="text-align: center;font-weight: 400;font-size: 14px;line-height: 17px;color: #595959;">
                      Your {{vehicleModelExt}} VIN:
                      </td>
                  </tr>
                  <tr>
                      <td  style="text-align: center;font-weight: normal;font-size: 14px;line-height: 17px;color: #595959;">
                      {{vin}}
                      </td>
                  </tr>
              
                  <tr class="noBorder">
                      <td style="padding: 15px 40px 5px 40px;">
                          <p class="bodyText">
                            Fusce porttitor dolor et facilisis posuere. Pellentesque ac fermentum justo. 
                            Integer vehicula massa a ante efficitur vulputate.
                          </p>
                      </td>
                  </tr>
                  <tr class="noBorder">
                      <td style="padding: 10px 40px 0 40px;">
                          <p class="bodyText">
                          YouFusce porttitor dolor et facilisis posuere. Pellentesque ac fermentum justo. 
                          Integer vehicula massa a ante efficitur vulputate. Vestibulum ut rutrum urna. 
                          Duis ultricies lacus eget enim convallis commodo
                          </p>
                      </td>
                  <tr class="noBorder">
                      <td style="padding: 10px 40px 0 40px;">
                          <p class="bodyText">
                          Sed aliquet turpis tortor, et ullamcorper quam tristique at. Morbi rutrum ac odio quis facilisis. 
                          Ut et varius enim. Proin ante diam, lobortis eget facilisis vitae, dapibus ut elit.
                          </p>
                      </td>
                  </tr>
                  <tr><td>&nbsp;</td></tr>
                  <tr class="noBorder">
                      <td style="padding: 20px 0 20px 0">
                          <div>
                              <a href=""
                                  <center>
                                  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" target="_blank" align="center" style="height:90px;v-text-anchor:middle;width:292px;" arcsize="135%" mso-position-horizontal:"right" strokecolor="#e6e6e8" fillcolor="#14141a">
                                      <w:anchorlock/>
                                      <center style="color:#fefbfb;font-family:sans-serif;font-size:18px;padding: 16px 30px;">View Account</center>
                                  </v:roundrect>
                                  </center>
                              </a>
                          </div>
                      </td>
                  </tr>
              </table>
          </div>
      </v:shape>
    
    
    <div class="mainContainer">
        <div class="mainContainer">
              <div class="textContainer centerDiv">
                      <div class="serviceDetailsHeader" style="margin-top:20px;">
                          Maecenas feugiat convallis:
                      </div>
              </div>
  
        <div class="servicesContainer">
          <table class="serviceTable"  border="0" cellpadding="0" cellspacing="0" align="center" style="padding-left:20px;padding-right:20px">
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image" width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p>Maecenas feugiat convallis libero</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Quisque porta placerat lobortis. Nulla auctor risus nunc, id suscipit ligula tempus ut. 
                          Aliquam congue odio ante, quis tempor quam molestie ac. Nullam sit amet molestie nibh, eu viverra purus. 
                          Vivamus pellentesque mollis dictum. Vestibulum pretium nibh at hendrerit facilisis.</p>
                      </div></td>
                  </tr>
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p>Maecenas feugiat convallis libero</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Nulla volutpat nibh vitae erat sagittis, ac condimentum ante scelerisque. 
                              Proin aliquet ac libero euismod tempus. Suspendisse potenti. Aliquam sed magna volutpat, 
                              consequat odio in, malesuada magna. Duis aliquam nisi eu ultrices fermentum. 
                              Proin interdum mattis condimentum.</p>
                      </div></td>
                  </tr>
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p> Quisque porta placerat lobortis</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p> Sed aliquet turpis tortor, et ullamcorper quam tristique at.
                              Morbi rutrum ac odio quis facilisis. Ut et varius enim. Proin ante diam, lobortis eget facilisis vitae, 
                              dapibus ut elit. Donec consectetur diam ut nunc iaculis, nec mollis elit auctor. 
                              Pellentesque pretium nulla et eros auctor semper. Donec pellentesque interdum ligula et pellentesque. Curabitur eleifend dui justo.</p>
                      </div></td>
                  </tr>
  
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p> Quisque porta placerat lobortis</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Donec consectetur diam ut nunc iaculis, nec mollis elit auctor. Pellentesque pretium nulla et eros auctor semper. 
                          Donec pellentesque interdum ligula et pellentesque. Curabitur eleifend dui justo.</p>
                      </div></td>
                  </tr>
  
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p>Nulla auctor risus</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Sed aliquet turpis tortor, et ullamcorper quam tristique at.
                           Morbi rutrum ac odio quis facilisis. Ut et varius enim. 
                           Proin ante diam, lobortis eget facilisis vitae, dapibus ut elit.</p>
                      </div></td>
                  </tr>
                  <tr style="margin-top:20px;">
                          <td>&nbsp</td>
                  </tr>
                  <tr>
                      <td>
                          <hr/>   
                      </td>
                  </tr>
                  <tr style="margin-top:20px;">
                          <td>&nbsp</td>
                  </tr>
            
                  <div class="textContainer centerDiv">
                          <div class="serviceDetailsHeader" style="margin-top:20px;">
                          Sed aliquet turpis tortor:
                          </div>
                  </div>
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                 </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p>Nulla auctor risus</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Maecenas feugiat convallis libero a condimentum. Quisque porta placerat lobortis. Nulla auctor risus nunc, id suscipit ligula tempus ut. 
                              Aliquam congue odio ante, quis tempor quam molestie ac. 
                              Nullam sit amet molestie nibh, eu viverra purus.</p>
                      </div></td>
                  </tr>
  
                  <tr style="margin-top:20px;" align="center">
                      <td> <img src="Images/GeneralServiceImage.png"
                      alt="service image"  width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td style="padding-bottom:10px;padding-top:10px;"><div class="servicesSubHeader">
                          <p>Morbi nisl magna</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Aliquam ut metus in erat commodo vehicula at nec lorem. 
                              Quisque in augue at arcu cursus malesuada. Aenean aliquam neque eget viverra rhoncus.</p>
                      </div></td>
                  </tr>
  
                  <tr style="margin-top:20px;" align="center">
                      <td style="padding-bottom:10px;padding-top:10px;"> <img src="Images/GeneralServiceImage.png"
                      alt="service image" width="100"/></td>
                  </tr>
                  <tr style="margin-top:20px;">
                      <td><div class="servicesSubHeader">
                          <p>Morbi nisl magna</p>
                      </div></td>
                  </tr>
                  <tr>
                      <td><div class="bodyText">
                          <p>Morbi nisl magna, porta et ex ac, consectetur elementum orci. Aenean vitae mattis enim.</p>
                      </div></td>
                  </tr>
                      <tr style="margin-top:20px;">
                          <td>&nbsp</td>
                  </tr>
           </table>
        </div>
     </div>
   
      <div>
          <table class="featureDetails" cellpadding="0" cellspacing="0" align="center" style="width:576px;background-color: #f5f5f5;padding-left:20px;padding-right:20px">
          <tr class="featureDetailsService" style="background-color:#f5f5f5;" align="center">
          <td>
              <div class="lightText">
              Maecenas feugiat convallis libero a condimentum. Quisque porta placerat lobortis.
               Nulla auctor risus nunc, id suscipit ligula tempus ut. Aliquam congue odio ante, quis tempor quam molestie ac.
              </div>
          
          <tr class="noBorder">
              <td style="padding: 20px 0 20px 0">
              <div>
              <a href=""
                  <center>
                  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" target="_blank" align="center" style="height:90px;v-text-anchor:middle;width:292px;" arcsize="135%" mso-position-horizontal:"right" strokecolor="#e6e6e8" fillcolor="#14141a">
                      <w:anchorlock/>
                      <center style="color:#fefbfb;font-family:sans-serif;font-size:18px;padding: 15px 30px;">Download the App</center>
                  </v:roundrect>
                  </center>
              </a>
              </div>
          </td>
          </tr>
          <tr><td>
              <div class="featureDetailsService">
              <div class="lightText">
                  Maecenas feugiat convallis libero a condimentum. Quisque porta placerat lobortis. 
                  Nulla auctor risus nunc, id suscipit ligula tempus ut,
                  <a class="links" target="_blank" href="">click here</a>.
              </div>
              </div>
          </td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          </table>
      </div>
   
   
   <table cellpadding="0" cellspacing="0" align="center" style="width:576px;background-color:#cecfd0;color: #494950;">
          <tr>
          <td style="padding:0px 27px 10px 27px; margin-top:20px;text-align:center; font-weight: 600;font-size: 17px;line-height: 24px;color:black">
             Nullam sit amet molestie?
          </td>
          </tr>
          <tr>
              <td style="padding:0px 27px 0px 27px;text-align:center;font-weight: 400;font-size: 17px;line-height: 24px;color:black">
                   Nullam sit amet molestie nibh, eu viverra purus,
                  <br />on your {{vehicleYear}} {{vehicleModelExt}},
                  <br />Vivamus pellentesque mollis.
              </td>
          </tr>
              <tr>
                  <td style="padding:0px 27px 10px 27px;text-align:center;line-height: 24px;">
                  <div style="margin-top: 20px;margin-bottom:20px;">
                      <a class="links" target="_blank"
                      href="">Contact us to get started</a>
                  </div>
                  </div>
                  </td>
              </tr>
  
  <div style="padding: 0 27px 0 27px;color:black">
  <tr><td style="line-height:18px;font-weight: 400;font-size:10px;margin:0px 45px 0px 38px;">
       1. Morbi nisl magna, porta et ex ac, consectetur elementum orci. 
       Aenean vitae mattis enim. Aliquam ut metus in erat commodo vehicula at nec lorem. 
       Quisque in augue at arcu cursus malesuada. Aenean aliquam neque eget viverra rhoncus. Proin bibendum at nulla quis eleifend. 
       Maecenas mi magna, imperdiet a aliquam fermentum, vulputate non urna. Ut at blandit sem, id iaculis augue. 
       Sed ut mauris blandit, aliquam libero ac, suscipit elit.
          </td></tr>
         <tr><td style="line-height:18px;font-weight: 400;font-size:10px;margin:0px 45px 15px 38px;">
            2.  Fusce porttitor dolor et facilisis posuere. Pellentesque ac fermentum justo. 
            Integer vehicula massa a ante efficitur vulputate. Vestibulum ut rutrum urna. Duis ultricies lacus eget enim convallis commodo.
             Quisque at lacus lorem. Cras sollicitudin diam eget erat congue ornare non non neque. 
             Donec tristique ex quis tincidunt porttitor.</td>
          </tr>
     </td>
    </tr>
  </div>
    </table>
     <table cellpadding="0" cellspacing="0" align="center" style="min-height:100px;max-width:576px;text-align:center;background-color: black;color:white;">
              <tr><td style="padding:25px 64px 25px 64px;font-size:16px;line-height:24px;font-weight:400">
              <span style="font-weight:800;">Cras sollicitudin diam eget erat: 9999 diam Dr. lorem, TX 8888</td></tr>
      </table>
   </table>
  
  <![endif]-->
    </body>
  </html>
  `
}