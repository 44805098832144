import { Fragment, useCallback, useState } from "react";
import ReactModal from "react-modal";

import styles from "./PopupDialog.module.css";

export type PopupDialogProps = {
    className?: string
    renderPopupContent: (closeModal: (() => void)) => JSX.Element
    renderControlContent: (openModal: (() => void)) => JSX.Element
    /* css height style property (accepts a value of px, em, rem, %, vh, vw) */
    height?: string
    /* css width style property (accepts a value of px, em, rem, %, vw, vh) */
    width?: string
}

const PopupDialog = ({
    className,
    renderPopupContent,
    renderControlContent,
    height,
    width
}: PopupDialogProps) => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = useCallback(() => { setShowModal(false) }, [setShowModal]);
    const openModal = useCallback(() => { setShowModal(true) }, [setShowModal]);
    return (
        <Fragment>
            {renderControlContent(openModal)}
            <ReactModal isOpen={showModal}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => setShowModal(false)}
                className={[styles.ContentModal, className].join(" ")}
                style={{
                    content: {
                        height,
                        width
                    }
                }}
                overlayClassName={styles.ContentModalOverlay}
            >
                {
                    renderPopupContent(closeModal)
                }
            </ReactModal>
        </Fragment>
    );
};

export default PopupDialog;