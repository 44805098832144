export const CapabilityConstants = {
    CAMPAIGN_VIEW: "CAMPAIGN_VIEW",
    CAMPAIGN_CREATE: "CAMPAIGN_CREATE",
    CAMPAIGN_PUBLISH: "CAMPAIGN_PUBLISH",
    CAMPAIGN_DELETE: "CAMPAIGN_DELETE",
    CAMPAIGN_LAUNCH:"CAMPAIGN_LAUNCH",
    CAMPAIGN_PAUSE:"CAMPAIGN_PAUSE",
    CAMPAIGN_APPROVE:"CAMPAIGN_APPROVE",
    CAMPAIGN_DENY:"CAMPAIGN_DENY",
    TEMPLATE_VIEW: "TEMPLATE_VIEW",
    TEMPLATE_CREATE: "TEMPLATE_CREATE",
    TEMPLATE_PUBLISH: "TEMPLATE_PUBLISH",
    TEMPLATE_DELETE: "TEMPLATE_DELETE",
    CONTENT_VIEW: "CONTENT_VIEW",
    CONTENT_CREATE: "CONTENT_CREATE",
    COPY_CREATE: "COPY_CREATE",
    CONTENT_PUBLISH: "CONTENT_PUBLISH",
    CONTENT_DELETE: "CONTENT_DELETE",
    CUSTOMERLIST_VIEW: "CUSTOMERLIST_VIEW",
    CUSTOMERLIST_CREATE: "CUSTOMERLIST_CREATE",
    CUSTOMERLIST_PUBLISH: "CUSTOMERLIST_PUBLISH",
    CUSTOMERLIST_DELETE: "CUSTOMERLIST_DELETE",
    USER_MANAGEMENT_ACCESS:"USER_MANAGEMENT_ACCESS"
}
