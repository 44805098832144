/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./ViewReport.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../../hooks/ScrollToTop";
import { NavLink } from "react-router-dom";
import CancelPaidChart from "./Cancel";
import FailedBillingChart from "./Failed";
import Home_Progress from "./Home";

const ViewReport = (props) => {
  //   const jwt = useAppSelector((state) => state.authReducer.jwt);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const pathName = history.location.pathname.split("/");
  const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);
  console.log(pathName)
  console.log(navSelected)

  return (
    <React.Fragment>
      <div className={styles.ViewReport}>
        <TitleWithPathHeader
          title={"Report"}
          path={" Report/View Report"}
        />

        <div className={styles.AddReportModule}>
          {/* NAV MENU*/}
          <div className={styles.LeftContent}>
          <ul
              className={
                navSelected === "report" || navSelected === "home"
                ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("home");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/home`}
              >
                Home
              </NavLink>
            </ul>
            <ul
              className={
               navSelected === "cancelPaid"
                ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("cancelPaid");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/cancelPaid`}
              >
                Cancel Paid Product
              </NavLink>
            </ul>

            <ul
              className={
                navSelected === "failedBilling"
                  ? styles.NavItemSelected
                  : styles.NavItem
              }
              onClick={() => {
                setNavSelected("failedBilling");
              }}
            >
              <NavLink
                style={{ color: "black", textDecoration: "none" }}
                to={`${url}/failedBilling`}
              >
               Failed Billing
              </NavLink>
            </ul>

          </div>
          {/* RIGHT MENU*/}
          <div className={navSelected === "home" || navSelected === "report" ? styles.RightContentReport : styles.RightContent}>
          
              <React.Fragment>
                <ScrollToTop />

                <Switch>
                  <Route exact path={`${url}`}>
                    <Redirect to={`${url}/home`} />
                  </Route>

                  <Route path={`${url}/cancelPaid`}>
                    <CancelPaidChart/>
                  </Route>

                  <Route path={`${url}/failedBilling`}>
                  <FailedBillingChart/>
                  </Route>

                  <Route path={`${url}/home`}>
                  <Home_Progress/>
                  </Route>
                          
                </Switch>
              </React.Fragment>
            </div>
          </div>
        </div>
      
    </React.Fragment>
  );
};

export default ViewReport;
