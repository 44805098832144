import { createStore } from "redux";

import rootReducer from './reducers'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const store = createStore(rootReducer);

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector