import React, { useState,useRef } from "react";
import { Button, Container, Row, Tab, Tabs } from 'react-bootstrap';
import styles from "./CreateCustomer.module.css";
import FlowChart from "./FlowChart";
import RuleForm from "./RuleForm";


/* eslint-disable  @typescript-eslint/no-unused-vars */
const CustomerNavTab = () =>{
    const [key, setKey] = useState('VehicleDetails');
    const CreateRule = () =>{
      console.log("create rule")
      return (<RuleForm/>)
    }
    const formBody = {
    "rules": [{
      "id": 1,
      "key": "make",
      "value": "Car",
      "logical_connector": "<",
      "rule_ref": useRef(null)
    }, {
      "id": 2,
      "key": "make",
      "value": "Toyota2",
      "logical_connector": "<",
      "rule_ref": useRef(null)
    }, {
      "id": 3,
      "key": "make",
      "value": "Toyota2",
      "logical_connector": "<",
      "rule_ref": useRef(null)
    }],
    "connector": "AND",
    "connector_ref": useRef(null)
  };
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className={styles.CustomerNavTab}
      >
        <Tab eventKey="VehicleDetails" title="Vehicle Details">
        <Container>
          <Row className={styles.VehicleDetails}>
          <FlowChart formBody={formBody}/>
          {/* <FlowChart formBody={formBody}/> */}
          <RuleForm/>
          <Button onClick={console.log} variant="outline-secondary" className={styles.Discard}>discard</Button>
          <Button onClick={console.log} variant="outline-danger" className={styles.Save}>save </Button>
          <Button onClick={console.log} variant="outline-danger" className={styles.Publish}>publish</Button>
          </Row>
        </Container>
        </Tab>
        <Tab eventKey="SubscriptionDetails" title="Subscription Details">
        <Container>
          <Row className={styles.VehicleDetails}>
       </Row>
       </Container>
        </Tab>
        <Tab eventKey="UsageDetails" title="Usage Details">
        </Tab>
        <Tab eventKey="UserDetails" title="User Details">
        </Tab>
        <Tab eventKey="CompileList" title="Compile List">
        </Tab>
      </Tabs>
    );

};

export default CustomerNavTab