import React from "react";

import styles from "./TitleWithPathHeader.module.css";
import CurrentPathDisplay from "../CurrentPathDisplay/CurrentPathDisplay";

const TitleWithPathHeader = ({ title, path }) => {
    return <div className={ styles.Container }>
        <CurrentPathDisplay path={path} />
        <div className={styles.Title}>
            { title }
        </div>
    </div>;
};

export default TitleWithPathHeader;