import { format } from "date-fns";

export const mapTemplates = (serverResponse) => [
  ...serverResponse.map((item) => ({
    action: { status: item.status, templateId: item._id },
    associatedCampaigns: item.associatedCampaigns,
    templateDetails: {
      templateName: item.name,
      description: item.description,
      createdDate:
        item.createdDate && format(new Date(item.createdDate), "MM/dd/yyyy")
    },
    channel: item.channel.name ? [item.channel.name] : item.channel.map((c) => c.name),
    creator: item.createdBy ? item.createdBy : "dev",
    categoryDetails: {
      id: item.category ? item.category.id : "",
      category: item.category ? item.category.name : "NA",
      subCategory:
        item.category && item.category.subCategories
          ? item.category.subCategories[0].name
          : ""
    },
    goLiveDate: item.goLiveDate
      ? format(new Date(item.goLiveDate), "MM/dd/yyyy")
      : "N/A",
    version: { versionNumber: `v${item.version}`, campaginId: item.id },
    status: item.status,
    id: item.id,
    preview: item.previewImageUrl
  }))
];
