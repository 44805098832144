import JSZip from 'jszip';
import { useState, useContext, useEffect } from 'react';
import { Slide, toast } from 'react-toastify';
import { Context } from '../../../context';
import { useAppSelector } from '../../../redux/store';
import LabeledFileUploadBar from '../../General/LabeledFileUploadBar/LabeledFileUploadBar';
import LabeledInput from '../../General/LabeledInput/LabeledInput';
import LabeledSelect from '../../General/LabeledSelect/LabeledSelect';
import PopupDialog from '../../General/PopupDialog/PopupDialog';
import SaveAndDiscardWithPopupButtonSet from '../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet';
import DiscardDialog from '../../ManageCampaign/CreateCampaign/Dialog/DiscardDialog/DiscardDialog';
import SaveDialog from '../../ManageCampaign/CreateCampaign/Dialog/SaveDialog/SaveDialog';
import { ErrorMsg, Msg } from '../CreateTemplate/AlertComponent';
import styles from "./TemplateTypes.module.css";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";
import Button from '../../General/Button/Button';
import { useHistory } from 'react-router-dom';

const EditTemplateType = () => {
    const [templateType, setTemplateType] = useState();
    const [htmlTemplate, setHtmlTemplate] = useState();
    const jwt = useAppSelector((state) => state.authReducer.jwt);
    const [templateTypeName, setTemplateTypeName] = useState();
    const [templateTypeDescription, setTemplateTypeDescription] = useState();
    const { value } = useContext(Context);
    const [context, setContext] = value;
    const [resetFilename, setResetFileName] = useState();
    const [zipFile, setZipFile] = useState();
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const history = useHistory();
    const [deleteFlag, setDeleteFlag] = useState(false);

    const templates = [
        {
            label: "Type1",
            value: "1"
        },
        {
            label: "Type2",
            value: "2"
        }
    ];

    /* notify if user do not enter mandatory fields */
    useEffect(() => {
        let toBeCompletedArray = [];
        toBeCompletedArray = !templateTypeName
            ? [...toBeCompletedArray, "Name"]
            : toBeCompletedArray;
        toBeCompletedArray = !templateTypeDescription
            ? [...toBeCompletedArray, "Description"]
            : toBeCompletedArray;
        toBeCompletedArray =
            !zipFile
                ? [...toBeCompletedArray, "Load Zip File"]
                : toBeCompletedArray;
        setToBeCompleted(toBeCompletedArray);
    }, [
        templateTypeName,
        templateTypeDescription,
        zipFile
    ]);

    /*reset when flag is true */
    useEffect(() => {
        if (deleteFlag === true) {
            setZipFile("")
            //setEditZipFileName("")
        } else {
            console.log(zipFile)
        }

    }, [deleteFlag])

    //validate zip file
    const isValidZipFile = async (file) => {
        let blsValidZip = true;
        //limit file size to 10mb
        const sizeinmb = (file.size / (1024 * 1024)).toFixed(3);
        console.log("sizeinmb - ", sizeinmb);
        if (sizeinmb >= 10) {
            blsValidZip = false;
            console.log(file + " File too Big, please select a file less than 10mb");
            setContext("");
            console.log(context)
            alert("File too Big, please select a file less than 10mb");
            return blsValidZip;
        }

        //Zip file should contain only HMTL, CSS and images
        let jZip = new JSZip();
        let validFileExtensions = ["jpg", "jpeg", "png", "gif", "html", "css", "mp4", "txt"];
        let mandatoryFileExtensions = ["html", "txt", "jpeg", "png"];
        let mandatoryFilesCount = 0;
        let imageCount = 0;
        jZip.loadAsync(file).then(function (zip) {
            Object.keys(zip.files).forEach(function (filename) {
                console.log("file name " + filename);
                let fileExtension = filename.split(".")[1];
                if (fileExtension !== undefined) {
                    //should be a file not folder
                    let bIsValidFile = false;
                    for (let j = 0; j < validFileExtensions.length; j++) {
                        let curExtension = validFileExtensions[j];
                        if (fileExtension.toLowerCase() === curExtension.toLowerCase()) {
                            bIsValidFile = true;
                            break;
                        }
                    }
                    if (!bIsValidFile) {
                        blsValidZip = false;
                        // alert("Zip file should contain only HMTL, CSS and images");
                        setResetFileName("true")
                        return blsValidZip;
                    }
                    //check for mandatory files (.html,.txt and .png/jpeg)
                    for (let j = 0; j < mandatoryFileExtensions.length; j++) {
                        let curExtension = mandatoryFileExtensions[j];
                        if (fileExtension.toLowerCase() === curExtension.toLowerCase()) {
                            if (fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "png") {
                                imageCount++;
                            } else {
                                mandatoryFilesCount++;
                            }
                            break;
                        }
                    }
                }
            });

            //mandatory Files Count should be 2, else invalid
            if (mandatoryFilesCount !== 2 || imageCount !== 1) {
                blsValidZip = false;
                setResetFileName("true")
                setZipFile("");
                //alert("Zip file should contain html,txt, png/jpeg files");
                return blsValidZip;
            }

            //set zipfile for valid zip
            if (blsValidZip) {
                setZipFile(file);
                setResetFileName("")
                console.log("file content - ", file);
            }
        });
        return blsValidZip;
    };

    //downloadFile
    const downloadFile = async () => {
        fetch(`${process.env.REACT_APP_API_HOSTNAME}v1/template/zip/file?fileKey=${"s3ZipFileKey"}`, {
            method: 'GET',
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: `Bearer ${jwt}`

            }
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    // `FileName.zip`,
                    `${templateType.label}`
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const saveUploadData = async (saveUploadObject) => {
        console.log(saveUploadObject)

    };

    return (
        <div>
            <div>
                <p className={styles.UpdateFormHeader}>
                    {"Edit template type"}
                </p>
                <LabeledSelect
                    className={styles.Select}
                    options={templates}
                    placeholder="Select"
                    defaultLabel={templateType ? templateType.label : undefined}
                    defaultId={templateType ? templateType.value : undefined}
                    title={
                        <>
                            Select template type<span>*</span>
                        </>
                    }
                    onValueChange={(option) => {
                        setTemplateType(option);
                        console.log("option - " + option)
                        if (option !== null) {
                            if (option.label === "Type1") {
                                setHtmlTemplate("sourceHtml");
                                setDeleteFlag(false);
                                setTemplateTypeName(option.label);
                                setTemplateTypeDescription("Description Type1")
                            } else {
                                setHtmlTemplate("sourceHtml2");
                                setDeleteFlag(false);
                                setTemplateTypeName(option.label)
                                setTemplateTypeDescription("Description Type2")
                            }
                        } else {
                            setHtmlTemplate(undefined);
                        }
                    }}
                />
            </div>

            {htmlTemplate !== undefined ?
                <div>
                    <LabeledInput
                        //component_status={viewTemplate}
                        title={<>Name<span>*</span></>}
                        data={templateTypeName}
                        placeholder="Type the template name"
                        className={styles.Input}
                        onValueChange={(value) => setTemplateTypeName(value)}
                    />
                    <LabeledInput
                        //component_status={viewTemplate}
                        title={<>Description<span>*</span></>}
                        placeholder="Type the description"
                        className={styles.Input}
                        data={templateTypeDescription}
                        onValueChange={(value) => setTemplateTypeDescription(value)}
                    />
                    {/* Download button */}
                    <div className={styles.btnDownload}>
                        <div>
                            <label>
                                Download
                                <br />
                                <a
                                    className={styles.TemplateDeleteDownload}
                                    onClick={downloadFile}
                                >
                                    {templateType ? templateType.label : ""}
                                </a>
                            </label>
                        </div>
                        <div className={styles.btnDelete}>
                            <div>
                                <PopupDialog
                                    height="30rem"
                                    width="67rem"
                                    renderPopupContent={(closeModal) => (
                                        <SaveDialog
                                            saveAction={async () => {
                                                setDeleteFlag(true);
                                                toast.success(
                                                    <Msg id="delete" templateName={templateTypeName} />,
                                                    {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true,
                                                        autoClose: 3000,
                                                        icon: <Success />,
                                                        transition: Slide,
                                                        closeOnClick: true
                                                    }
                                                );
                                                setContext();
                                                closeModal();

                                            }}
                                            discardAction={closeModal}
                                            className="DeleteTemplateType"
                                            itemsToBeComplete={0}
                                        />
                                    )}
                                    renderControlContent={(openModal) => (
                                        !deleteFlag ?
                                            <Button
                                                onClick={openModal}
                                                name="Delete Copy File" /> : ""
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "3rem" }}>
                        <LabeledFileUploadBar
                            title={<>Upload new template type (HMTL,images and CSS zip file)<span>*</span></>}
                            newUpload="no"
                            onUploadClick={console.log("")}
                            uploadType="uploadTemplate"
                            resetFile={resetFilename}
                            uploadedFile={(value) => isValidZipFile(value)}
                        />
                    </div>
                    {resetFilename === "true" ? <div style={{ color: "red", fontSize: "1.4rem" }}>Zip file should contain html,txt, png/jpeg files</div> : ""}

                    {toBeCompleted.length === 0 ? (
                        <div className={styles.savediscardcontainer}>
                            <div className={styles.discardchild}>
                                <PopupDialog
                                    height="30rem"
                                    width="67rem"
                                    renderPopupContent={(closeModal) => (
                                        <DiscardDialog
                                            yesAction={() => {
                                                setContext();
                                                history.push("/ctmp/template");
                                                toast.success(
                                                    <Msg id="discard" templateName={"uploadTemplateName"} />,
                                                    {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true,
                                                        autoClose: 3000,
                                                        icon: <Success />,
                                                        transition: Slide,
                                                        closeOnClick: true
                                                    }
                                                );
                                            }}
                                            noAction={closeModal}
                                        />
                                    )}
                                    renderControlContent={(openModal) => (
                                        <Button onClick={openModal} name={"CopyNewCompleteDiscard"}>
                                            Discard
                                        </Button>
                                    )}
                                />
                            </div>
                            <div className={styles.savechild}>
                                <Button
                                    onClick={async () => {
                                        let statusVar = await saveUploadData("saveUploadObject");
                                        localStorage.setItem("blockNavigation", "false");
                                        history.push("/ctmp/template");
                                        //setIsLoaded(false);
                                        statusVar !== 200 &&
                                            toast.error(
                                                <ErrorMsg id="save" templateName={"uploadTemplateName"} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Error />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                        statusVar === 200 &&
                                            toast.success(
                                                <Msg id="save" templateName={"uploadTemplateName"} />,
                                                {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true,
                                                    autoClose: 3000,
                                                    icon: <Success />,
                                                    transition: Slide,
                                                    closeOnClick: true
                                                }
                                            );
                                    }}
                                    name={"CopyNewCompleteSave"}
                                />
                            </div>
                        </div>) :
                        <div className={styles.savediscardcontainer2}>
                            <div >
                                <SaveAndDiscardWithPopupButtonSet
                                    yesPopupHeight="55rem"
                                    noPopupHeight="30rem"
                                    yesTitle="Save" //save
                                    noTitle="Discard" //discard
                                    className={styles.CopyNewEdit}
                                    renderDiscardPopupContent={(closeModal) => (
                                        //save and close
                                        <DiscardDialog
                                            yesAction={() => {
                                                history.push("/ctmp/template");
                                            }}
                                            noAction={closeModal}
                                            className="Discard"
                                        />
                                    )}
                                    renderSavePopupContent={(closeModal) => (
                                        <SaveDialog
                                            saveAction={async () => {
                                                let statusVar = await saveUploadData("saveUploadObject");
                                                //console.log("statusVar--->" + statusVar)
                                                closeModal();
                                                //console.log(context)
                                                setContext("");
                                                history.push("/ctmp/template");
                                                //setIsLoaded(false)
                                                statusVar === 200 &&
                                                    toast.success(
                                                        <Msg id="save" templateName={"uploadCopyName"} />,
                                                        {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            hideProgressBar: true,
                                                            autoClose: 3000,
                                                            icon: <Success />,
                                                            transition: Slide,
                                                            closeOnClick: true
                                                        }
                                                    );
                                                statusVar !== 200 &&
                                                    toast.error(
                                                        <ErrorMsg id="save" templateName={"uploadCopyName"} />,
                                                        {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            hideProgressBar: true,
                                                            autoClose: 3000,
                                                            icon: <Error />,
                                                            transition: Slide,
                                                            closeOnClick: true
                                                        }
                                                    );
                                            }}
                                            discardAction={closeModal}
                                            itemsToBeComplete={toBeCompleted}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    }
                </div>
                : ""}

        </div >
    )
}

export default EditTemplateType