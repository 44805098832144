/* eslint-disable*/
import styles from "./AddUser.module.css";
import React, { useState, useCallback, useEffect } from "react";
import LabeledInput from "../../General/LabeledInput/LabeledInput";
import MultiSelector from "../../General/MultiSelector/MultiSelector";
import RadioButtonControl from "../../General/RadioButtonControl/RadioButtonControl";
import SaveAndDiscardWithPopupButtonSet from "../../General/SaveAndDiscardButtonSet/WithPopup/SaveAndDiscardWithPopupButtonSet";
import SaveDialog from "./Dialog/SaveDialog/SaveDialog";
import DiscardDialog from "./Dialog/DiscardDialog/DiscardDialog";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
/*alerts imports*/
import { Slide, toast } from "react-toastify";
import { Msg } from "./AlertComponent";
import { ErrorMsg } from "./AlertComponent";
import { ReactComponent as Success } from "../../../assets/icons/Check-square-Alert.svg";
import { ReactComponent as Error } from "../../../assets/icons/Close-Alert.svg";

const AddRole = () => {
  const [roleName, setroleName] = useState();
  const [roleCategories, setroleCategories] = useState([]);
  const [roleSubCategories, setroleSubCategories] = useState();
  const [approveCampaign, setApproveCampaign] = useState();
  const [addSubCategories, setAddSubCategories] = useState();
  const [toBeCompleted, setToBeCompleted] = useState([]);
  const [addRoleObject,setAddRoleObject] = useState({});
  const userName = useAppSelector((state) => state.authReducer.userName);
  const history = useHistory();
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );
  
  /* update the values when changed */
  useEffect(() => {
    setAddRoleObject({
      roleName: roleName,
     ...(roleCategories && {
        campaignCategories: roleCategories.map((c) => ({
          name: c.label,
          id: c.value,
        })),
      }),
      ...(roleSubCategories && {
        subCategories: roleSubCategories.map((c) => ({
          name: c.label,
          id: c.value,
        })),
      }),
      approveCampaigns: approveCampaign && approveCampaign.value,
      subCampaigns: addSubCategories && addSubCategories.value,
      createdBy: userName
    });
  }, [roleName, roleCategories, roleSubCategories, approveCampaign, addSubCategories]);

  /* to check everything is entered or not */
  useEffect(() => {
    let toBeCompletedArray = [];
    toBeCompletedArray = !roleName
      ? [...toBeCompletedArray, "RoleName"]
      : toBeCompletedArray;
    toBeCompletedArray = !roleCategories
      ? [...toBeCompletedArray, "Category"]
      : toBeCompletedArray;
    toBeCompletedArray =
      !roleSubCategories || !roleSubCategories.length
        ? [...toBeCompletedArray, "Sub-Category"]
        : toBeCompletedArray;
    toBeCompletedArray = !approveCampaign 
      ? [...toBeCompletedArray, "Approve Campaign"] 
      : toBeCompletedArray;
      toBeCompletedArray = !addSubCategories 
      ? [...toBeCompletedArray, "Add Subcategories"] 
      : toBeCompletedArray;
    setToBeCompleted(toBeCompletedArray);
  }, [roleName, roleCategories, roleSubCategories,approveCampaign,addSubCategories]);

   /* save Role */
   const saveRole = async (addRoleObject) => {
    console.log("--->" + JSON.stringify(addRoleObject));
    const url = new URL(`${process.env.REACT_APP_API_HOSTNAME}v1/user/roles`);
    
    let callParams = {
      method: "POST",
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        "X-CORRELATIONID": 0
      },
      body: JSON.stringify(addRoleObject)
    };
    const serverResponse = await fetch(url.toString(), callParams);
    const serverResponseJson = await serverResponse.json();
   return serverResponseJson.status;
  };

  return (
    <React.Fragment>
      <div className={styles.RoleContentDisplay}>
        <div className={styles.RoleContainer}>
          <div style={{ paddingTop: "4rem", paddingLeft: "4rem" }}>
            <p className={styles.UpdateFormHeader}>Add New Role</p>
            <span className={styles.UpdateFormSubTitle}>
              To add a new role, please enter the following information:
            </span>
            <div className={styles.RoleAndTeam}>
              <LabeledInput
                title={
                  <>
                    Role Name<span> *</span>{" "}
                  </>
                }
                data={roleName}
                placeholder="Enter a role"
                className={styles.Input}
                onValueChange={(value) => setroleName(value)}
              />
            </div>

            <div className={styles.RoleAndTeam}>
              <div className={styles.teamsParent}>
                <div>
                  <label>
                    Campaign Categories<span> *</span>
                  </label>
                </div>
                <MultiSelector
                  className={styles.Select}
                  name="Select"
                  optionsUrl="v1/ctmp/category"
                  valuesChanged={(roleCategories) =>
                    setMultiProperty(roleCategories, setroleCategories)
                  }
                  value={roleCategories}
                />
              </div>
              <div className={styles.teamsParent}>
                <div>
                  <label>
                    Campaign Sub-Categories<span> *</span>
                  </label>
                </div>
                <MultiSelector
                  className={styles.Select}
                  name="Select"
                  optionsUrl="v1/ctmp/subcategories"
                  // optionsUrl="v1/ctmp/status"
                  valuesChanged={(roleSubCategories) =>
                    setMultiProperty(roleSubCategories, setroleSubCategories)
                  }
                  value={roleSubCategories}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "1rem", marginTop: "-8rem" }}>
            <div className={styles.RadioClass}>
              <label>
                Can Approve Campaigns<span> *</span>
              </label>
          
              <RadioButtonControl
                previousSelection={approveCampaign}
                onSelect={(value) => {
                  setApproveCampaign(value);
                }}
                options={[
                  {
                    title: "Yes",
                    value: true
                  },
                  { title: "No", value: false }
                ]}
              />
            </div>
            <div style={{ marginTop: "4rem" }} className={styles.RadioClass}>
              <label>
                Can Add Sub-Categories<span> *</span>
              </label>

              <RadioButtonControl
                previousSelection={addSubCategories}
                onSelect={(value) => {
                  setAddSubCategories(value);
                }}
                options={[
                  {
                    title: "Yes",
                    value: true
                  },
                  { title: "No", value: false }
                ]}
              />
            </div>
          </div>
            <div className={styles.SaveAndDiscardButton} style={{marginTop:"5rem"}}>
            <SaveAndDiscardWithPopupButtonSet
              yesPopupHeight="39rem"
              noPopupHeight="55rem"
              yesTitle="Add Role" //publish
              noTitle="Discard" //save
              className={styles.Buttons}
              renderDiscardPopupContent={(closeModal) => (
                <DiscardDialog
                yesAction={() => {
                  history.push("/ctmp/user-management");
                }}
                noAction={closeModal}
              />
              )}
              renderSavePopupContent={(closeModal) => (
                <SaveDialog
                itemsToBeComplete={toBeCompleted}
                saveAction={async () => {
                 var result =  await saveRole(addRoleObject);
                 closeModal();
                 if(result === "Roles Inserted Successfully"){
                  let displayMessage =  "The role "+addRoleObject.roleName +" has been successfully created." 
                  closeModal();
                  history.push("/ctmp/user-management");
                  toast.success(
                    <Msg message={displayMessage}/>,
                    {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                      icon: <Success />,
                      transition: Slide,
                      closeOnClick: true
                    }
                  );
                 }
                 else{
                  closeModal();
                  let displayMessage = "Failed to create the role "+addRoleObject.roleName +" please try again."
                  history.push("/ctmp/user-management");
                      toast.error(
                        <ErrorMsg message={displayMessage}/>,
                        {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                          icon: <Error />,
                          transition: Slide,
                          closeOnClick: true
                        }
                      )
                 }
                }}
                discardAction={closeModal}
                className="Add_Role"
              />
              )}
            />
          </div>

    </React.Fragment>
  );
};
export default AddRole;
