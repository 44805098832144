import React from "react";

import styles from "./LabeledDescriptionInput.module.css";

type InputProps = {
    title?: any,
    textAreaPlaceholder?: string,
   onValueChange: any,
   data: any,
   padding?: boolean
  };

const LabeledDescriptionInput = ({
                                     title,
                                     textAreaPlaceholder,
                                     onValueChange,
                                     data,
                                     padding
                                 }: InputProps) => {
                                   console.log("padding", padding)
    return <div className={ padding === undefined || padding === !false ? styles.Container : styles.ContainerNoPadding }>
        <label>{ title }</label>
        <textarea name="description" className={styles.containerTextArea}
        value={data ? data : undefined}
                  placeholder={ textAreaPlaceholder }
                  onChange={ event => onValueChange(event.target.value) }/>
    </div>;
};

export default LabeledDescriptionInput;