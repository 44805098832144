/* eslint-disable */
import React from "react";
//import styles from "../../../../src/App.css";
export const Msg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = "saved")
    : prop.id === "discard"
      ? (text = "discarded")
      : prop.id === "unpublish"
        ? (text = "unpublished")
        : prop.id === "delete"
          ? (text = "deleted")
          : (text = "published");
  return (
    <div>
      <div>SUCCESSFUL</div>
      {prop.type == "file" ?
        <span>
          {prop.templateName ? prop.templateName : ""} is {text} successfully
        </span> :
        <span>
          Customerlist {prop.templateName ? prop.templateName : ""} is {text} successfully
        </span>
      }
    </div>
  );
};

export const ErrorMsg = (prop) => {
  let text;
  console.log(prop.id + "-------------------" + prop.templateName);
  prop.id === "save"
    ? (text = "saved")
    : prop.id === "discard"
      ? (text = "discarded")
      : prop.id === "unpublish"
        ? (text = "unpublished")
        : prop.id === "delete"
          ? (text = "deleted")
          : (text = "published");
  return (
    <div>
      <div style={{ color: "#BC0000 !important" }}>ERROR</div>
      {prop.id === "duplicateRule" ? "Duplicate rules are not allowed!" :
        prop.id === "notEnoughInfo" ? "Not enough information in rule builder!" :
          <span>
            Customerlist {prop.templateName ? prop.templateName : ""} is not {text} successfully
          </span>
      }
    </div>
  );
};
