/* eslint-disable  */
import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { format } from "date-fns";
import styles from "./ManageTemplateTableFilters.module.css";
import MultiSelector from "../../../../General/MultiSelector/MultiSelector";
import DateRangeSelector from "../../../../General/PopupSelector/DateRangeSelector/DateRangeSelector";
import { ReactComponent as Align } from "../../../../../assets/icons/Align.svg";
import { ReactComponent as AlignWhite } from "../../../../../assets/icons/AlignWhite.svg";
import { ReactComponent as CloseX } from "../../../../../assets/icons/Close.svg";

const ManageTemplateTableFilters = ({ onFiltersChange, filters }) => {
  const setMultiProperty = useCallback(
    (object, setter) =>
      object && object.length ? setter([...object]) : setter(null),
    []
  );

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [category, setCategory] = useState(filters.category);
  const [subCategory, setSubCategory] = useState(filters.subCategory);
  const [status, setStatus] = useState(filters.status);
  const [brand, setBrand] = useState(filters.brand);
  const [dateRange, setDateRange] = useState(filters.dateRange);
  const [channels, setChannels] = useState(filters.channels);
  const [createdBy, setCreatedBy] = useState(filters.createdBy);

  useEffect(() => {
    setMultiProperty(filters.category, setCategory);
    setMultiProperty(filters.subCategory, setSubCategory);
    setMultiProperty(filters.status, setStatus);
    setMultiProperty(filters.brand, setBrand);
    // setMultiProperty(filters.dateRange, setDateRange);
    setMultiProperty(filters.channels, setChannels);
    setMultiProperty(filters.createdBy, setCreatedBy);
  }, [setMultiProperty, filters]);

  // const creatorOptions = React.useMemo(() => {
  //     return [{
  //         label: "Srikanth Kintali",
  //         value: "6224e8d2-8066-46b5-9b9a-02babac4c31d"
  //     }, {
  //         label: "Prabu Sankar",
  //         value: "296839d6-8ba6-4a07-8b28-c59f6012b898"
  //     }, {
  //         label: "Rei Shaholli",
  //         value: "043765c1-af32-488f-9fd2-0eec5603796f"
  //     }];
  // }, []);

  return (
    <div>
      <button
        onClick={() => setShowFilterModal(true)}
        className={
          Object.keys(filters).length === 0 ||
          filters.hasOwnProperty("freeSearch")
            ? styles.Filter
            : styles.FilterSelected
        }
      >
        {Object.keys(filters).length === 0 ? (
          <React.Fragment>
            <Align />
            <span
              style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
            >
              Filter
            </span>
          </React.Fragment>
        ) : filters.hasOwnProperty("freeSearch") ? (
          <React.Fragment>
            <Align />
            <span
              style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
            >
              Filter
            </span>
          </React.Fragment>
        ) : filters.hasOwnProperty("fromDate") ? (
          <React.Fragment>
            <AlignWhite />
            <span
              style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
            >
              Filter ({Object.keys(filters).length - 1})
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AlignWhite />
            <span
              style={{ margin: "0.6rem", fontFamily: "Toyota Type Regular" }}
            >
              Filter ({Object.keys(filters).length})
            </span>
          </React.Fragment>
        )}
      </button>
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={showFilterModal}
        onRequestClose={() => setShowFilterModal(false)}
        className={styles.FilterModal}
        overlayClassName={styles.FilterModalOverlay}
      >
        <div className={styles.FilterTitleContainer}>
          <div className={styles.FilterTitleHeading}>
            <p>
              <span>Filters</span>
            </p>
            <CloseX
              onClick={() => setShowFilterModal(false)}
              className={styles.CloseIcon}
            />
          </div>
        </div>

        <div className={styles.FiltersContainer}>
          {/* <MultiSelector className={ styles.Categories }
                               name="Brand"
                               optionsUrl="v1/ctmp/category"
                               valuesChanged={ (category) => setMultiProperty(
                                   category, setCategory) }
                               value={ category }
                />
                <MultiSelector className={ styles.SubCategories }
                               name="Associated Campaigns"
                               optionsUrl="v1/ctmp/subcategories"
                               valuesChanged={ (subCategories) => setMultiProperty(
                                   subCategories, setSubCategory) }
                               value={ subCategory }
                /> */}
          <MultiSelector
            className={styles.Status}
            name="Status"
            optionsUrl="v1/ctmp/status?filter=template"
            valuesChanged={(status) => setMultiProperty(status, setStatus)}
            value={status}
          />

          <DateRangeSelector
            className={styles.DateRange}
            onDiscard={() => setDateRange(null)}
            onApply={(dateRange) => {
              setDateRange(dateRange[0]);
            }}
            // onApply={(dateRange) => console.log("jh"+JSON.stringify(dateRange[0]))}

            value={dateRange}
          />
          <MultiSelector
            className={styles.Channels}
            name="Channel"
            optionsUrl="v1/ctmp/channel"
            valuesChanged={(channels) =>
              setMultiProperty(channels, setChannels)
            }
            value={channels}
          />
          {/* <MultiSelector
            className={styles.Brand}
            name="Creator"
            optionsUrl="v1/ctmp/brand"
            valuesChanged={(brand) => setMultiProperty(brand, setBrand)}
            value={brand}
          /> */}
          <MultiSelector className={ styles.Creator }
                               name="Creator"
                               optionsUrl="v1/ctmp/createdBy/Template"
                               valuesChanged={ (creators) => setMultiProperty(
                                   creators, setCreatedBy) }
                               value={ createdBy }
                />
        </div>
        <div className={styles.ApplyDiscardButtonContainer}>
          <button
            onClick={() => {
              setShowFilterModal(false);
              setDateRange(null)
              onFiltersChange({});
            }}
            className={styles.DiscardButton}
          >
            Discard
          </button>
          <button
            onClick={() => {
              setShowFilterModal(false);
              onFiltersChange({
                ...(category && { category }),
                ...(subCategory && { subCategory }),
                ...(status && { status }),
                ...(brand && { brand }),
                ...(dateRange && {
                  fromDate: `${format(dateRange.startDate, "MM/dd/yyyy")}`,
                  toDate: `${format(dateRange.endDate, "MM/dd/yyyy")}`
                  // toDate: dateRange.endDate.format('YYYY-MM-DD')
                }),
                ...(channels && { channels }),
                ...(createdBy && { createdBy })
              });
            }}
            className={styles.ApplyButton}
          >
            Apply
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default ManageTemplateTableFilters;
