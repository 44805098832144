/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, version } from "react";
// import VersionControlHeader from "../VersionControlHeader/VersionControlHeader";
import { ReactComponent as Arrow } from "../../../../assets/icons/Arrow.svg";
import { ReactComponent as Template } from "../../../../assets/icons/Template.svg";
import Moment from 'react-moment';
import { useHistory,useRouteMatch } from "react-router-dom";

import styles from "./VersionPreview.module.css";
import Button from "../../../General/Button/Button";

const Comment = (comment) => {
  return (
    <div className={styles.Comments}>
      <div>Commented by: {comment.commentBy}</div>
      <div>Name + datetime{comment.commentDate}</div>
      <div>{comment.comment}</div>
    </div>
  );
};

const VersionPreview = (props) => {
  console.log(props);
  let { path, url } = useRouteMatch();
  let splitPath = path.split("/")[3];
  console.log(path + "--in preview--" + url);
  const history = useHistory();
  const versionsArray = props.data;
  const versionNumber = props.match.params.versionNumber;
  let versionToUse = null;

  const getComments = () => {
    versionsArray.map((item, index) => {
      if (item.version === parseFloat(versionNumber)) {
        versionToUse = item;
      }
    });
    console.log(versionToUse);
  };
  getComments();

  return (
    <React.Fragment>
      {/* <VersionControlHeader /> */}
      <div className={styles.VersionControlModule}>
        <div className={styles.DisplayLayout}>
          {/* Template Detail Information */}
          <div className={styles.DetailsDisplay}>
            <label className={styles.VersionLabel}>V{versionNumber}</label>
            <label className={styles.TemplateTitle}>
              {versionToUse !== null ? versionToUse.campaignName : "N/A"}
            </label>
            <label className={styles.EditDate}>
              Last Edited:{" "}
              {versionToUse !== null ? versionToUse.modifiedDate : "N/A"}
            </label>
            <div className={styles.SmallSeparator} />
            {/* Information col */}

            {versionToUse && versionToUse.comments
              ? versionToUse.comments.slice().reverse().map((comment, index) => (
                  <div key={index} className={styles.Comments}>
                    <div>Commented by: </div>
                    <div className={styles.CommentBy}>{comment.commentBy ? comment.commentBy : "placeholder name"} • <Moment format="LLLL" >{comment.commentDate}</Moment></div>
                    <div>"{comment.comment}"</div>
                  </div>
                ))
              : "No comments placeholder"}
            <Button name="Back" onClick={() => history.goBack()}></Button>
          </div>
          
          {/* Template Image Preview */}
          <div className={styles.PreviewDisplay}>
            <div className={styles.PreviewContainer}>
              <div className={styles.ArrowContainer}>
                <Arrow className={styles.LeftArrow} />
              </div>
              <div className={styles.PreviewImage}>
                <Template />
              </div>
              <div className={styles.ArrowContainer}>
                <Arrow className={styles.RightArrow} />
              </div>
            </div>
            <div className={styles.PreviewTemplateName}>Template Name</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VersionPreview;
