import styles from "./DiscardDialog.module.css";
import {ReactComponent as CloseX} from "../../../../../assets/icons/Close.svg";
import {ReactComponent as WarningTriangle} from "../../../../../assets/icons/warning-triangle.svg";
import SaveAndDiscardButtonSet
    from "../../../../General/SaveAndDiscardButtonSet/SaveAndDiscardButtonSet";


type DiscardDialogProps = {
    className?: string
    bodyText?: string
    headerText?: string
    yesAction: () => void
    noAction: () => void
}

const DiscardDialog = ({className, yesAction, noAction, bodyText, headerText}: DiscardDialogProps) => {
    return <div className={[className, styles.Container].join(" ")}>
        <div className={styles.Header}>
        {(headerText !== "" || headerText !== null || headerText !== undefined) && <p>{headerText}</p>}
            {(headerText === "" || headerText === null || headerText === undefined) && <p><span>Discard Message</span></p>}
          
            <CloseX onClick={noAction} className={styles.CloseIcon}/>
        </div>
        <div className={styles.Body}>
            <WarningTriangle />
            {(bodyText !== "" || bodyText !== null || bodyText !== undefined) && <p>{bodyText}</p>}
            {(bodyText === "" || bodyText === null || bodyText === undefined) && <p>Are you sure you want to discard the changes without saving?</p>}
        </div>
        <SaveAndDiscardButtonSet yesAction={yesAction}
                                 noAction={noAction}
                                 className={styles.Buttons}/>
    </div>;
};

export default DiscardDialog;