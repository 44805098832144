/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Overlay, Popover } from "react-bootstrap";
import styles from "./RuleForm.module.css";
import { useAppSelector } from "../../../../redux/store";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import { ErrorMsg } from "../CreateCustomerRight/AlertComponent";
import { Slide, toast } from "react-toastify";
import { ReactComponent as Error } from "../../../../assets/icons/Close-Alert.svg";
import PopupDialog from "../../../General/PopupDialog/PopupDialog";
import DiscardDialog from "../CreateCustomerRight/DiscardCustomer/DiscardCustomer";

const RuleForm = ({ nodes, setNodes, endpoint, navSelected, subNavSelected, showRC, classRef }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [keyList, setKeyList] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [associatedKey, setAssociatedKey] = useState(null);
  const ref = useRef(null);
  const refParam = useRef(null);
  const jwt = useAppSelector((state) => state.authReducer.jwt);

  const [parameter, setParameter] = useState();
  const [logicalConnector, setLogicalConnector] = useState();
  const [paramValue, setParamValue] = useState();
  const [connector, setConnectorValue] = useState("AND");

  const [ruleBoxes, setRuleBoxes] = useState([]);
  console.log("Class Ref---->" + classRef);
  // const box1Ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const createNode = async () => {
    if (parameter && logicalConnector && paramValue) {
      let status = await checkDuplicateRules();
      //don't allow user to add duplicate rules
      if (status !== true) {
        setNodes(() => [
          ...nodes,
          {
            parameter: parameter,
            logicalConnector: logicalConnector,
            value: paramValue,
            connector: connector
          }
        ])
        setParamValue("");
        setLogicalConnector("");
        refParam.current.value = "";
      } else {
        toast.error(<ErrorMsg id="duplicateRule" />, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
          icon: <Error />,
          transition: Slide,
          closeOnClick: true
        })
      }
    } else {
      toast.error(<ErrorMsg id="notEnoughInfo" />, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
        icon: <Error />,
        transition: Slide,
        closeOnClick: true
      })
    }
  };

  //if the same rule already added then return true
  const checkDuplicateRules = async () => {
    let duplicate = false;
    if (nodes) {
      for (let index in nodes) {
        if ((nodes[index].parameter === parameter) &&
          (nodes[index].value === paramValue)) {
          duplicate = true;
          break;
        }
      }
    }
    return duplicate;
  }

  const deleteNode = (index) => {
    nodes.splice(index, 1);
    setNodes([...nodes]);
  };

  const clearNodes = () => {
    setNodes([]);
  };

  useEffect(() => {
    setWidgetList([]);
    setKeyList([]);
    setLogicalConnector("");
    setParameter("");
    (async () => {
      const url = new URL(
        //`https://mktpl.dev.telematicsct.com/v1/ctmp/meta/rulebuilder/${endpoint}`
        `${process.env.REACT_APP_API_HOSTNAME}v1/ctmp/meta/rulebuilder/${endpoint}`
      );
      const callParams = {
        method: "GET",
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      };
      const serverResponse = await fetch(url.toString(), callParams);
      const serverResponseJson = serverResponse.json().then((data) => {
        console.log(data);
        setWidgetList((widgetList) => [...widgetList, data]);
        setKeyList(
          data.filter((item) => {
            setAssociatedKey(item.associatedKeyId);
            return item.fieldType === "key";
          })
        );
        setValueList(
          data.filter((item) => {
            return item.fieldType === "value";
          })
        );
      });
    })();
  }, [subNavSelected]);

  return (
    <React.Fragment>
      <div className={classRef === "view" ? styles.NodeDisplayDisable : styles.NodeDisplay}>
        <div className={styles.NodeContainer}>
          <Xwrapper>
            <div className={styles.NodesLeft}>
              {nodes && nodes.length > 0
                ? nodes.map((node, index) => (
                  <div
                    key={index}
                    id={`nodeItem${index + 1}`}
                    className={styles.NodeItem}
                  >
                    <PopupDialog
                      height="30rem"
                      width="67rem"
                      renderPopupContent={(closeModal) => (
                        <DiscardDialog
                          yesAction={() => {
                            deleteNode(index)
                            closeModal();
                          }}
                          noAction={closeModal}
                          className="Confirm"
                        />
                      )}
                      renderControlContent={(openModal) => (
                        <div
                          className={
                            navSelected === "re-use"
                              ? styles.NavItemDisabled
                              : styles.NodeItemTitle
                          }
                          onClick={() => {
                            openModal()
                          }}
                        >
                          <span>{node.parameter}</span>
                        </div>
                      )}
                    />
                    <div className={styles.NodeItemContent}>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {node.logicalConnector}
                      </span>{" "}
                      {node.value}
                    </div>
                  </div>
                ))
                : null}
            </div>

            <div className={styles.NodesRight}>
              {nodes && nodes.length > 0 ? (
                <div className={styles.NodeItemAnd} id={"andConnector"}>
                  <div className={styles.NodeItemConnector}>AND</div>
                </div>
              ) : null}
            </div>

            {nodes && nodes.length > 0
              ? nodes.map((node, index) => (
                <Xarrow
                  key={index}
                  start={`nodeItem${index + 1}`}
                  end="andConnector"
                  strokeWidth={2}
                  tailSize={2}
                  tailShape={"circle"}
                  showHead={false}
                  headSize={3}
                  path={"smooth"}
                />
              ))
              : null}
          </Xwrapper>
        </div>
        <div className={classRef === "view" || classRef === "edit" ? styles.RuleContainerEdit : styles.RuleContainer}>
          {showRC ? ( // don't show RuleContiner for Compile List
            <div ref={ref}>
              <Button onClick={handleClick} className={styles.AddRuleButton}>
                Add Rule
              </Button>
              <Overlay
                show={show}
                target={ref}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
                transition={false}
              >
                <Popover id="popover-contained" show>
                  <Popover.Title as="h3" className={styles.PopoverTitle}>
                    Rules
                  </Popover.Title>
                  <Popover.Content>
                    <Form.Label className={styles.Title}>
                      Parameter<span>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      ref={refParam}
                      defaultValue={parameter}
                      className={styles.SelectRuleForm}
                      onChange={(option) => {
                        setAssociatedKey(option.target.value);
                        setParameter(option.target.value);
                        let index = option.nativeEvent.target.selectedIndex;
                        let label = option.nativeEvent.target[index].text;
                        setParameter(label)
                        // if (option.target.value === "22") {
                        //   setParameter("Make");
                        // } else if (option.target.value === "23") {
                        //   setParameter("Model");
                        // } else if (option.target.value === "24") {
                        //   setParameter("Generation");
                        // } else if (option.target.value === "25") {
                        //   setParameter("Capability");
                        // } else if (option.target.value === "31") {
                        //   setParameter("Status");
                        // } else if (option.target.value === "32") {
                        //   setParameter("Type");
                        // }
                        // setParamValue(undefined);
                      }}
                    // defaultValue={parameter}
                    >
                      <option> </option>
                      {keyList && keyList !== null
                        ? keyList.map((element) => {
                          return (
                            <option
                              key={element.fieldValue}
                              label={element.fieldValue}
                              value={element.id}
                            >
                              {element.fieldValue}
                            </option>
                          );
                        })
                        : null}
                    </Form.Control>
                    <Form.Label className={styles.Title}>
                      Logical Connecter<span>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className={styles.SelectRuleForm}
                      value={logicalConnector}
                      onChange={(option) => {
                        setLogicalConnector(option.target.value);
                      }}
                    >
                      <option> </option>
                      <option value={"="}>=</option>
                      <option value={"!="}>!=</option>
                      <option value={"<"}>&#60;</option>
                      <option value={">"}>&gt;</option>
                      <option value={">="}>&ge;</option>
                      <option value={"<="}>&le;</option>
                    </Form.Control>
                    <Form.Label className={styles.Title}>
                      Value<span>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className={styles.SelectRuleForm}
                      value={paramValue}
                      onChange={(option) => {
                        setParamValue(option.target.value);
                      }}
                    >
                      <option> </option>
                      {valueList && valueList !== null
                        ? valueList.map((element, index) => {
                          if (
                            element.fieldType === "value" &&
                            element.associatedKeyId === associatedKey
                          ) {
                            return (
                              <option
                                key={element.fieldValue}
                                value={element.fieldValue}
                              >
                                {element.fieldValue}
                              </option>
                            );
                          }
                          return null;
                        })
                        : null}
                    </Form.Control>
                    <div className={styles.Block}>
                      <p>Connectors</p>
                    </div>
                    <Form.Label className={styles.Title}>
                      Select Existing Connectors<span>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className={styles.SelectRuleForm}
                      onChange={(option) => {
                        setConnectorValue(option.target.value);
                      }}
                      defaultValue={"AND"}
                    >
                      <option> </option>
                      <option value={"AND"}>And</option>
                      {/* <option value={"AND2"}>And (2)</option> */}
                    </Form.Control>
                    <div className={styles.orDivider}>
                      <div className={styles.Dashed}></div>
                      <div
                        style={{ display: "inline-block", fontSize: "medium" }}
                      >
                        OR
                      </div>
                      <div className={styles.Dashed}></div>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <Button
                        variant="outline-primary"
                        size="lg"
                        block
                        onClick={() => console.log("Parameter")}
                        className={styles.A}
                        disabled={true}
                      >
                        Add New Connector
                      </Button>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <Button
                        variant="danger"
                        size="lg"
                        block
                        onClick={createNode}
                        className={styles.AddNodeButton}
                      >
                        Add
                      </Button>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <Button
                        variant="outline-danger"
                        size="lg"
                        block
                        onClick={clearNodes}
                        className={styles.DiscardNodesButton}
                      >
                        Discard
                      </Button>
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            </div>) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RuleForm;
