import React from "react";
//import styles from './LabeledCheckbox';

const LabeledCheckbox = ({ title, className, data, onValueChange }) => {
    return (
        <div>
            <label> {title}
                <input
                    type="checkbox"
                    className={className}
                    value={data ? data : undefined}
                    onChange={(event) => { onValueChange(event.target.checked) }}
                />
            </label>
        </div>
    )
};

export default LabeledCheckbox;