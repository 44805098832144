import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./NavLink.module.css";

const NavLink = ({ name, icon, linkTo, selected, setSelected }) => {
    const history = useHistory();

    return <div onClick={ () => {
        setSelected(name);
        history.push(linkTo);
    } }
                className={ selected
                    ? [styles.NavLink, styles.NavLinkSelected].join(" ")
                    : styles.NavLink }>
        { icon.render(
            {
                className: selected ? [styles.Icon, styles.IconSelected].join(
                    " ") : styles.Icon
            }) }
        <label className={ selected
            ? [styles.NavLabel, styles.NavLabelSelected].join(" ")
            : styles.NavLabel }>{ name }</label>
    </div>;
};

export default NavLink;