import React, { Fragment, useState } from "react";

import styles from "./ManageContent.module.css";
import CreateContent from "./CreateContent/CreateContent";
import ManageContentHeader from "./ManageContentHeader/ManageContentHeader";
import ManageContentTable from "./ManageContentTable/ManageContentTable";
//import EditCopy from "./CreateContent/EditCopy";
/* eslint-disable  @typescript-eslint/no-unused-vars */

import { Route, Switch } from "react-router-dom";
import EditCopy from "./CreateContent/EditCopy";

const ManageContent = ({ match }) => {
  const [search, setSearch] = useState();

  const [contentDetails, setContentDetails] = useState();

  return (
    <div className={styles.ManageContent}>
      <Switch>
        <Route
          path={match.url + "/edit-content/:id"}
          render={(match) => (
            <Fragment>
              <CreateContent contentId={match.match.params.id} />
            </Fragment>
          )}
        />
        <Route
          path={match.url + "/view-content/:id"}
          render={(match) => (
            <Fragment>
              <CreateContent contentId={match.match.params.id} />
            </Fragment>
          )}
        />
        {/* <Route
          path={match.url + "/create"}
          component={CreateContent}
        /> */}
        <Route
          path={match.url + "/create-content"}
          render={(match) => (
            <Fragment>
              <CreateContent />
            </Fragment>
          )}
        />

        <Route
          exact
          path={match.url}
          render={() => (
            <Fragment>
              <ManageContentHeader onSearch={(search) => setSearch(search)} />
              <ManageContentTable search={search} />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

export default ManageContent;
