/* eslint-disable */
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../General/Button/Button";
import { Context } from "../../../context";
import { CapabilityConstants } from "../../Constants/CapabilitiyConstants";
import styles from "./ManageTemplateHeader.module.css";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const ManageTemplateHeader = ({ onSearch }) => {
    const { capability } = useContext(Context);
    const history = useHistory();
    const [timeout, setTimeoutVal] = useState();
    const [searchText, setSearchText] = useState();
    const [capabilities, setCapabilities] = capability;

    const navToCreateTemplate = useCallback(
        () => {
            history.push(`${history.location.pathname}/create-template`);
        },
        [history]);

    const navToTemplateTypes = useCallback(
        () => {
            history.push(`${history.location.pathname}/template-types`);
        },
        [history]);

    useEffect(() => {
        timeout && clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            onSearch(searchText);
        }, 500));
    }, [onSearch, searchText, setTimeoutVal]);


    return <div className={styles.MngTmpltHdr}>
        <div className={styles.MngTmpltHdrLeft}>
            <h3>Manage Template</h3>
            <span className={styles.Separator} />
            <div className={styles.SearchBarContainer}>
                <SearchIcon className={styles.SearchIcon} />
                <input className={styles.Search} placeholder="Search Template"
                    type="text"
                    style={{ width: "400px" }}
                    onChange={(event => setSearchText(
                        event.target.value))} />
            </div>
        </div>
        {capabilities.includes(CapabilityConstants.TEMPLATE_CREATE) ?
            <Button onClick={navToTemplateTypes} name="Template Types" /> : ""}
        {capabilities.includes(CapabilityConstants.TEMPLATE_CREATE) ?
            <Button onClick={navToCreateTemplate}
                name="Create Template" /> : ""}
    </div>;
};

export default ManageTemplateHeader;