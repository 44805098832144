import React, { Fragment, useCallback, useEffect } from "react";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import styles from "./DateRangeSelectorContent.module.css";

const DateRangeSelectorContent = ({ onDiscard, onApply, closeModal, value }) => {
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: "selection"
        }
    ]);

    useEffect(() => {
        value && setDateRange([value])
    }, [value, setDateRange])

    const closeModalAndPerformAction = useCallback((action) => {
        closeModal();
        action();
    }, [closeModal]);

    return <Fragment>
        <span className={ styles.DateRangeTitle }>Date Range</span>
        <DateRangePicker
            className={ styles.DateRangePickerWrapper }
            onChange={ item => setDateRange([item.selection]) }
            showSelectionPreview={ true }
            moveRangeOnFirstSelection={ false }
            months={ 2 }
            ranges={ dateRange }
            rangeColors={ ["var(--ct-blue-secondary)"] }
            direction="horizontal"
        />
        <div className={ styles.DateRangeButtonContainer }>
            <button onClick={ () => closeModalAndPerformAction(onDiscard) }
                    className={ styles.DiscardButton }>Discard
            </button>
            <button onClick={
                () => closeModalAndPerformAction(() => onApply(dateRange))
            }
                    className={ styles.ApplyButton }>Apply
            </button>
        </div>
    </Fragment>;
};

export default DateRangeSelectorContent;