import React from "react";
import Select from "react-select";

import { SelectStyle } from "../../../Selector/Selector";
import styles from "./ItemsPerPage.module.css";

const ItemsPerPage = ({
                          totalItems,
                          pageSizes,
                          selectedPageSize,
                          changePageSizeTo,
                          currentPage
                      }) => {
    return (
        <div className={ styles.ItemsPerPage }>
            <Select className={ styles.PageSizes }
                    menuPosition="fixed"
                    onChange={ (option) => {
                        changePageSizeTo(option.value);
                    } }
                    isSearchable={ false }
                    styles={ {
                        ...SelectStyle, control: (styles) => ({
                            ...SelectStyle.singleValue(styles),
                            width: `${ ("" + selectedPageSize).length + 5 }rem`
                        })
                    } }
                    defaultValue={ pageSizes[0] }
                    options={ pageSizes }/>
            <span
                className={ styles.Label }>{ `${ currentPage * selectedPageSize - (selectedPageSize - 1) } - ${ totalItems < selectedPageSize
                ? totalItems : Math.min(currentPage * selectedPageSize,
                    totalItems) } of ${ totalItems } Items` }</span>
        </div>
    );
};

export default ItemsPerPage;