import React from "react";
/* eslint-disable  @typescript-eslint/no-unused-vars */
import styles from "./TemplatePreviewCard.module.css";
import { ReactComponent as TemplateSmall } from "../../../assets/icons/TemplateSmall.svg";

const TemplatePreviewCard = (props) => {
  return (
    <React.Fragment>
      <div className={styles.TemplateCard}>
        <div className={styles.TemplatePreview}><TemplateSmall/></div>
        <div className={styles.TemplateNumber}>Template {props.number}</div>
        <div className={styles.TemplateName}>{props.name ? props.name : "No name provided"}</div>
      </div>
    </React.Fragment>
  );
};

export default TemplatePreviewCard;
