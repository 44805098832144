import React from "react";
import styles from "./Selector.module.css";
import chroma from "chroma-js";
import {StylesConfig} from "react-select/src/styles";

const Selector = (props) => {
    const classNames = props.className
        ? [props.className, styles.Container].join(" ") : styles.Container;
    return <div className={classNames}>
        <label className={styles.Label}>{props.label}</label>
        <select className={styles.Selector}>
            {props.options && props.options.map(option => <option
                value={option.value}
                key={option.value}>{option.name}</option>)}
        </select>

    </div>;
};

export default Selector;

export const SelectStyle: StylesConfig<any, any> = {
    control: styles => ({...styles, backgroundColor: "white"}),
    input: (styles) => {
        return {
            ...styles,
            fontSize: "1.4rem",
            lineHeight: "2rem"
        };
    },
    menu: (styles) => ({
        ...styles,
        fontSize: "1.4rem",
        lineHeight: "2rem"
    }),
    option: (styles, {isDisabled, isFocused, isSelected}) => {
        const color = chroma("#5592ff");
        return {
            ...styles,
            fontSize: "1.2rem",
            lineHeight: "2rem",
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? color.css()
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            cursor: isDisabled ? "not-allowed" : "default"
        };
    },
    singleValue: (styles) => ({
        ...styles,
        fontSize: "1.2rem",
        lineHeight: "2rem"
    }),
    multiValue: (styles) => {
        const color = chroma("#5592ff");
        return {
            ...styles,
            fontSize: "1.2rem",
            lineHeight: "2rem",
            backgroundColor: color.alpha(0.075).css()
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        fontSize: "1.2rem",
        lineHeight: "2rem",
        color: "#5592ff"
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: "#5592ff",
        ":hover": {
            backgroundColor: "#5592ff",
            color: "white"
        }
    }),
    placeholder: (provided, _state) => {
        return {
            ...provided,
            color: "var(--grey-primary)",
            fontSize: "1.2rem",
            lineHeight: "2rem"
        };
    }
};