import React, { useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./TemplateTypes.module.css";
import TitleWithPathHeader from "../../General/TitleWithPathHeader/TitleWithPathHeader";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../../hooks/ScrollToTop";
import { NavLink } from "react-router-dom";
import ViewTemplateType from "./ViewTemplateType";
import AddTemplateType from "./AddTemplateType";
import EditTemplateType from "./EditTemplateType";

const TemplateTypes = (props) => {
    //   const jwt = useAppSelector((state) => state.authReducer.jwt);
    const history = useHistory();
    let { url } = useRouteMatch();
    const pathName = history.location.pathname.split("/");
    console.log(pathName[pathName.length - 1]);
    const [navSelected, setNavSelected] = useState(pathName[pathName.length - 1]);

    return (
        <React.Fragment>
            <div className={styles.CreateTemplateTypes}>
                <TitleWithPathHeader
                    title="Template Types"
                    path={" Manage Template / Template Types"}
                />
                <div className={styles.CreateTemplateModule}>
                    {/* NAV MENU*/}
                    <div className={styles.LeftContent}>
                        {/* view template type*/}
                        <ul
                            className={
                                navSelected === "view-template-type" ||
                                    navSelected === "template-types" ||
                                    navSelected === props.contentId
                                    ? styles.NavItemSelected
                                    : styles.NavItem
                            }
                            onClick={() => {
                                setNavSelected("view-template-type");
                            }}
                        >
                            <NavLink
                                style={{ color: "black", textDecoration: "none" }}
                                to={`${url}/view-template-type`}
                            >
                                View Template Type
                            </NavLink>
                        </ul>
                        {/* add template type*/}
                        <ul
                            className={
                                navSelected === "add-template-type"
                                    ? styles.NavItemSelected
                                    : styles.NavItem
                            }
                            onClick={() => {
                                setNavSelected("add-template-type");
                            }}
                        >
                            <NavLink
                                style={{ color: "black", textDecoration: "none" }}
                                to={`${url}/add-template-type`}
                            >
                                Add Template Type
                            </NavLink>
                        </ul>
                        {/* edit template type*/}
                        <ul
                            className={
                                navSelected === "edit-template-type"
                                    ? styles.NavItemSelected
                                    : styles.NavItem
                            }
                            onClick={() => {
                                setNavSelected("edit-template-type");
                            }}
                        >
                            <NavLink
                                style={{ color: "black", textDecoration: "none" }}
                                to={`${url}/edit-template-type`}
                            >
                                Edit Template Type
                            </NavLink>
                        </ul>
                    </div>
                    {/* RIGHT MENU*/}
                    <div className={styles.RightContent}>
                        <div className={styles.TemplateTypesContent}>
                            <React.Fragment>
                                <ScrollToTop />
                                <Switch>
                                    <Route exact path={`${url}`}>
                                        <Redirect to={`${url}/view-template-type`} />
                                    </Route>

                                    <Route path={`${url}/view-template-type`}>
                                        <ViewTemplateType />
                                    </Route>

                                    <Route path={`${url}/add-template-type`}>
                                        <AddTemplateType props={props.contentId} />
                                    </Route>

                                    <Route path={`${url}/edit-template-type`}>
                                        <EditTemplateType props={props.contentId} />
                                    </Route>
                                </Switch>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default TemplateTypes;