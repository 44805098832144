/* eslint-disable  */
import React, {useEffect, useState} from "react";
import Select from "react-select";

import styles from "./ManageTemplateTableHead.module.css";
import { SelectStyle } from "../../../General/Selector/Selector";
import ManageTemplateTableFilters
    from "./ManageTemplateTableFilters/ManageTemplateTableFilters";

const options = [
    { label: "Latest Created", value: "latestCreated" },
    { label: "Oldest Created", value: "oldestCreated" },
    { label: "Latest Published", value: "latestPublished" },
    { label: "Oldest Published", value: "oldestPublished" }
    // { label: "Created By", value: "createdBy" },
    // { label: "Status", value: "status" }
];


const ManageTemplateTableHead = ({ onSortChange, onFiltersChange, filters }) => {
    const [defaultValueSort,setDefaultValueSort] = useState();

    useEffect(()=>{
     if(Object.keys(filters).length > 0){
        setDefaultValueSort(null)
     }
    },[filters])

 const selectStylesApiObject = React.useMemo(() => {
        return {
            ...SelectStyle,
            control: provided => ({
                ...provided,
                border: ".1rem solid #CECFD0"
            })
        };
    }, []);

    return <div className={styles.TableHead}>
        <div className={styles.TableHeadLeft}>
            <div className={styles.SortByContainer}>
                <label className={styles.SortBy}>Sort By</label>
                <Select className={styles.SortBySelect}
                    styles={selectStylesApiObject}
                    isClearable
                    value={defaultValueSort}
                    options={options}
                    onChange={(sortByOption) => {
                        setDefaultValueSort(sortByOption);
                           onSortChange(
                            sortByOption ? sortByOption.value : undefined);
                    }}
                />
            </div>
            <ManageTemplateTableFilters onFiltersChange={onFiltersChange} filters={filters} />
        </div>
    </div>;
};

export default ManageTemplateTableHead;